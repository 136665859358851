import React, { Component } from "react"

class DataGridEmpty extends Component {
  render() {
    return (
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        No stored data
      </div>
    )
  }
}

export default DataGridEmpty
