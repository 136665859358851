import { isMobile } from "react-device-detect"
import moment from "moment"
import { APP_COLORS } from "./constants"

const HOURLY = "Hourly"
const DAILY = "Daily"
const WEEKLY = "Weekly"
const MONTHLY = "Monthly"
const QUARTERLY = "Quarterly"
const YEARLY = "Yearly"

const perspectives = [HOURLY, DAILY, WEEKLY, MONTHLY, QUARTERLY, YEARLY]

const CHART_COLORS = [
  APP_COLORS.washed_green,
  APP_COLORS.deep_blue,
  APP_COLORS.warm_red,
  APP_COLORS.yellow,
  APP_COLORS.dirty_pink,
  APP_COLORS.purple,
  APP_COLORS.risk_high,
  APP_COLORS.deep_blue,
  APP_COLORS.risk_low,
]

const ChartUtils = {
  formatLabelValue: function (labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
      : // Three Zeroes for Thousands
        //: Math.abs(Number(labelValue)) >= 1.0e+3
        //? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
        Math.abs(Number(labelValue))
  },

  /**
   * Returns appropriate default perspective of a chart
   * @returns {string}
   */
  getDefaultPerspective: function () {
    return DAILY
  },

  getHourlyPerspective: function () {
    return HOURLY
  },

  getWeeklyPerspective: function () {
    return WEEKLY
  },

  getMonthlyPerspective: function () {
    return MONTHLY
  },

  getYearlyPerspective: function () {
    return YEARLY
  },

  /**
   * Returns an appropriate X axis interval for a given perspective
   * @param perspective
   * @returns {number}
   */
  getPerspectiveXInterval: function (perspective) {
    if (ChartUtils.isDailyPerspective(perspective)) {
      return !isMobile ? 2 : 4
    } else if (ChartUtils.isHourlyPerspective(perspective)) {
      // To calculate iterval automatically
      return "preserveEnd"
    } else if (ChartUtils.isWeeklyPerspective(perspective)) {
      return 2
    } else if (ChartUtils.isMonthlyPerspective(perspective)) {
      return 1
    }
    return 0
  },

  /**
   * Returns an X axis format for the given perspective
   * @param xValue
   * @param perspective
   * @returns {string}
   */
  getPerspectiveXFormat: function (xValue, perspective) {
    if (ChartUtils.isDailyPerspective(perspective)) {
      return moment(xValue).format("D/MM")
    } else if (ChartUtils.isWeeklyPerspective(perspective)) {
      return "w." + moment(xValue).format("w")
    } else if (ChartUtils.isHourlyPerspective(perspective)) {
      return moment(xValue).format("D/M HH:mm")
    } else if (ChartUtils.isMonthlyPerspective(perspective)) {
      return moment(xValue).format("MMM YY")
    } else if (ChartUtils.isQuarterlyPerspective(perspective)) {
      return moment(xValue).format("YYYY [Q]Q")
    } else if (ChartUtils.isYearlyPerspective(perspective)) {
      return moment(xValue).format("YYYY")
    }

    return moment(xValue).format("D/MM")
  },

  /**
   * Returns all supported perspectives
   * @returns {string[]}
   */
  getPerspectives: function (min = DAILY, max = YEARLY) {
    if (!perspectives.includes(min)) {
      throw new Error("Invalid min perspective")
    }
    if (!perspectives.includes(max)) {
      throw new Error("Invalid max perspective")
    }
    return perspectives.slice(
      perspectives.indexOf(min),
      perspectives.indexOf(max) + 1,
    )
  },

  isHourlyPerspective: function (perspective) {
    return perspective === HOURLY
  },

  isDailyPerspective: function (perspective) {
    return perspective === DAILY
  },

  isWeeklyPerspective: function (perspective) {
    return perspective === WEEKLY
  },

  isMonthlyPerspective: function (perspective) {
    return perspective === MONTHLY
  },

  isQuarterlyPerspective: function (perspective) {
    return perspective === QUARTERLY
  },

  isYearlyPerspective: function (perspective) {
    return perspective === YEARLY
  },

  /**
   * Returns chart line color for the given chart line index
   * @param lineIndex
   * @returns {string}
   */
  getChartLineColor: function (lineIndex) {
    return CHART_COLORS[lineIndex]
  },
}

export default ChartUtils
