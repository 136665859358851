import React, { useState } from "react"
import { Card, CardBody, CardHeader, Col, Row, Table, Button } from "reactstrap"
import Utils from "../Common/Utils"
import AddDiscountModal from "./AddDiscountModal"
import DeleteDiscountModal from "./DeleteDiscountModal"

const CustomerDiscountSection = ({
  discounts,
  customerId,
  refreshDiscount,
}) => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteDiscount, setDeleteDiscount] = useState({})
  const hasDiscounts = discounts
    ? discounts.find(discount => !discount.deleted)
    : false

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: 30,
        }}
      >
        <CardHeader className="card-section-header">Campaigns</CardHeader>
        <Button
          color="primary"
          size="sm"
          className="safello-font mt-3"
          onClick={() => setShowAddModal(true)}
        >
          Add code
        </Button>
      </div>

      <CardBody className="pt-0">
        <Row>
          <Col>
            {hasDiscounts ? (
              <Table striped bordered hover responsive={true}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Fee</th>
                    <th>Valid</th>
                    <th>Nr of orders(total)</th>
                    <th></th>
                  </tr>
                </thead>
                {discounts.map(
                  discount =>
                    !discount.deleted && (
                      <tbody key={`discount-row-${discount.name}`}>
                        <tr>
                          <td>{discount.name}</td>
                          <td>{discount.fee}</td>
                          <td>
                            {`${Utils.getUtcInLocalDate(
                              discount.validFrom,
                            )} - ${Utils.getUtcInLocalDate(discount.validTo)}`}
                          </td>
                          <td>{`${discount.timesUsed}(${discount.validOrders})`}</td>
                          <td>
                            {" "}
                            <Button
                              color="warning"
                              size="sm"
                              className="safello-font mt-3"
                              onClick={() => {
                                setDeleteDiscount(discount)
                                setShowDeleteModal(true)
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    ),
                )}
              </Table>
            ) : (
              "User has no discounts registered"
            )}
          </Col>
        </Row>
        <AddDiscountModal
          open={showAddModal}
          onDismiss={() => setShowAddModal(!showAddModal)}
          customerId={customerId}
          onAddDiscount={refreshDiscount}
        />
        <DeleteDiscountModal
          open={showDeleteModal}
          onDismiss={() => setShowDeleteModal(!showDeleteModal)}
          deleteDiscountId={deleteDiscount ? deleteDiscount.id : null}
          deleteDiscountName={deleteDiscount ? deleteDiscount.name : null}
          customerId={customerId}
          onDeleteDiscount={refreshDiscount}
        />
      </CardBody>
    </Card>
  )
}

export default CustomerDiscountSection
