import React from "react"
import { Table, Card, Button } from "reactstrap"
// components

const TradingPairsTable = ({ tradingPairsData = [], onEditTradingPair }) => {
  return (
    <Card style={{ maxWidth: 600 }}>
      <div style={{ overflowX: "auto" }}>
        <Table>
          <tbody>
            <tr style={{ backgroundColor: "lightgray" }}>
              <td>
                <strong>Pair</strong>
              </td>
              <td>
                <strong>Status</strong>
              </td>
            </tr>
            {tradingPairsData.map((item, index) => (
              <tr key={index}>
                <td>{item.pair}</td>
                <td>
                  <ActiveButton
                    pair={item.pair}
                    active={item.active}
                    onEditTradingPair={onEditTradingPair}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  )
}

const ActiveButton = ({ pair, active, onEditTradingPair }) => {
  return (
    <Button
      onClick={() => onEditTradingPair(pair, !active)}
      outline
      color={active ? "success" : "danger"}
    >
      {active ? "ACTIVE" : "INACTIVE"}
    </Button>
  )
}

export default TradingPairsTable
