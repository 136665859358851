import ContentWrapper from "../../Layout/ContentWrapper"
import { Button, Container } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import DataGridEmpty from "../../Common/DataGridEmpty"
import React, { useEffect, useState } from "react"
import DropdownList from "../../Common/DropdownList"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import { customerFormatter } from "../../../utils/format"
import Utils, { DataGridLocalDateTimeFormatter } from "../../Common/Utils"
import { Link } from "react-router-dom"
// Channels that can not be processed/rejected through UI
const DISABLED_ACTION_CHANNELS = ["1"]

const PayoutList = ({ location }) => {
  const queryParams = new URLSearchParams(location.search)
  const [isLoading, setIsLoading] = useState(false)
  const [payoutMethods, setPayoutMethods] = useState([])
  const [payoutChannels, setPayoutChannels] = useState([])
  const [rows, setRows] = useState([])
  const [isDisabledAction, setIsDisabledAction] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  const [statusFilterValue, setStatusFilterValue] = useState(
    queryParams.get("status"),
  )
  const [channelFilterValue, setChannelFilterValue] = useState(
    queryParams.get("channel"),
  )

  const SellIdFormatter = props => {
    const id = props.value
    return <Link to={"/orders/sell/" + id}>{id}</Link>
  }

  const columns = [
    {
      key: "id",
      name: "ID",
      width: 100,
    },
    {
      key: "sellOrderId",
      name: "Sell order",
      formatter: SellIdFormatter,
      width: 80,
    },
    {
      key: "orderId",
      name: "REF",
      width: 80,
    },
    {
      key: "customer",
      name: "Customer",
      formatter: customerFormatter,
      width: 180,
    },
    {
      key: "amount",
      name: "Amount",
      width: 120,
    },
    {
      key: "timestamp",
      name: "Timestamp",
      formatter: DataGridLocalDateTimeFormatter,
      width: 140,
    },
    {
      key: "method",
      name: "Method",
      width: 150,
    },
    {
      key: "channel",
      name: "Channel",
      width: 250,
    },
    {
      key: "status",
      name: "Status",
    },
  ]

  useEffect(() => {
    loadPayoutMethods()
    loadPayoutChannels()
  }, [])

  useEffect(() => {
    if (
      DISABLED_ACTION_CHANNELS.includes(channelFilterValue) ||
      statusFilterValue !== "PENDING"
    ) {
      setIsDisabledAction(true)
    } else {
      setIsDisabledAction(false)
    }
    // Clear the checked ids
    setSelectedRows([])
  }, [channelFilterValue, statusFilterValue])

  useEffect(() => {
    if (payoutMethods.length > 0 && payoutChannels.length > 0) {
      loadPayouts()
    }
  }, [payoutMethods, payoutChannels, statusFilterValue, channelFilterValue])

  const setQuery = (_key, _value) => {
    //TODO this triggers a reload which triggers useEffect
    //queryParams.set(key, value);
    //const url = queryParams.toString();
    //history.push(`?${url}`);
  }

  const loadPayoutMethods = () => {
    setIsLoading(true)

    BackOfficeApi.endpoints.getPayoutMethods
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        setPayoutMethods(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const loadPayoutChannels = () => {
    setIsLoading(true)

    BackOfficeApi.endpoints.getPayoutChannels
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        setPayoutChannels(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // After bulk-action taken, need to refresh page
  const refreshPage = () => {
    setIsLoading(true)
    const originUrl = window?.location?.origin
    const newUrl = `${originUrl}/orders/payout?channel=${channelFilterValue}&status=${statusFilterValue}`
    // Add short delay to page refresh
    setTimeout(() => {
      window.location.href = newUrl
    }, 3000)
  }

  const loadPayouts = () => {
    setIsLoading(true)

    BackOfficeApi.endpoints.listPayouts
      .getOne({
        payoutChannelId: channelFilterValue,
        status: statusFilterValue,
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        const formattedData = formatRows(data)
        setRows(formattedData)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const generateIsoFile = () => {
    setIsLoading(true)

    BackOfficeApi.endpoints.generateIso20022PayoutFile
      .getOne()
      .then(response => {
        if (response.ok) {
          if (response.ok) {
            return response.blob()
          }
          throw new Error()
        }
      })
      .then(blob => {
        Utils.downloadFile(blob, "PayoutFile_iso20022.xml")
        setStatusFilterValue("PROCESSING")
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const markIsoFileAsCompleted = () => {
    Utils.showSweetAlertYesCancel(
      "Are you sure you want mark the file as completed? (It might take some time to complete. DO NOT press it more than once.)",
    ).then(confirmed => {
      if (confirmed) {
        setIsLoading(true)
        BackOfficeApi.endpoints.markIso20022PayoutFileCompleted
          .post()
          .then(response => {
            if (response.ok) {
              ToastUtils.toastExecuteSuccess2()
              loadPayouts()
            } else {
              ToastUtils.toastExecuteError2()
            }
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    })
  }

  const formatRows = rows => {
    return rows.map(row => {
      let m = payoutMethods.find(m => {
        return m.id === row.method
      })

      let c = payoutChannels.find(c => {
        return c.id === row.channel
      })

      return {
        id: row.id,
        sellOrderId: row.sellOrderId,
        orderId: row.orderId,
        customer: row.customer,
        amount: Utils.formatNumber(row.amount, 2) + " " + row.currency,
        amountValue: row.amount,
        timestamp: row.timestamp,
        method: m.name,
        channel: c.name,
        status: row.status,
      }
    })
  }

  const renderStatusFilter = () => {
    return (
      <DropdownList
        className="pr-2"
        label="Status"
        buttonColor="primary"
        value={statusFilterValue}
        onSelectItem={item => {
          setStatusFilterValue(item.value)
          setQuery("status", item.value)
        }}
      >
        {[
          { value: "PENDING", text: "Pending" },
          { value: "PROCESSING", text: "Processing" },
          { value: "FAILED", text: "Halted" },
          { value: "REJECTED", text: "Rejected" },
          { value: "COMPLETED", text: "Completed" },
        ]}
      </DropdownList>
    )
  }

  const renderChannelFilter = () => {
    return (
      <DropdownList
        className="pr-2"
        label="Channel"
        buttonColor="primary"
        value={channelFilterValue}
        onSelectItem={item => {
          setChannelFilterValue(item.value)
          setQuery("channel", item.value)
        }}
      >
        {payoutChannels.map(payoutChannel => {
          return {
            value: payoutChannel.id?.toString(),
            text: payoutChannel.name,
          }
        })}
      </DropdownList>
    )
  }

  const renderFilters = () => {
    return (
      <div className="ml-auto form-inline">
        {renderStatusFilter()}
        {renderChannelFilter()}
      </div>
    )
  }
  const renderBulkActionDropdown = () => {
    let list = [
      { value: "process_payouts", text: "Process payouts" },
      { value: "reject_payouts", text: "Reject payouts" },
    ]

    const disabledMessage =
      statusFilterValue !== "PENDING"
        ? "Actions can only be taken on pending payouts."
        : "Actions are disabled for this channel."

    return (
      <DropdownList
        className="pr-2"
        buttonColor="secondary"
        label="Bulk action"
        onSelectItem={onBulkAction}
        disabled={isDisabledAction}
        disabledMessage={disabledMessage}
      >
        {list}
      </DropdownList>
    )
  }

  const renderActionButton = () => {
    if (statusFilterValue === "PENDING" && channelFilterValue === "1") {
      return (
        <Button
          color="primary"
          onClick={generateIsoFile}
          style={{ margin: "auto" }}
        >
          Generate a ISO 20022 payout file
        </Button>
      )
    } else if (
      statusFilterValue === "PROCESSING" &&
      channelFilterValue === "1"
    ) {
      return (
        <Button
          color="primary"
          onClick={markIsoFileAsCompleted}
          style={{ margin: "auto" }}
        >
          Mark ISO 20022 payout file as completed
        </Button>
      )
    }
  }

  const renderSummary = () => {
    let totalAmount = 0
    let payouts = rows.length

    rows.forEach(r => {
      totalAmount += r.amountValue
    })

    return (
      <div>
        Payouts: {payouts}
        <br />
        Total amount: {Utils.formatNumber(totalAmount, 2)}
      </div>
    )
  }

  const onBulkAction = async event => {
    const isReject = event.value === "reject_payouts"

    if (selectedRows.length < 1) {
      alert(`Please select payouts to ${isReject ? "reject" : "process"}`)
      return
    }

    const confirm = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: ${isReject ? "Reject" : "Process"} payout(s) \n
      ID: ${selectedRows.map(row => `${row.id}`)}`,
    )
    if (confirm) {
      let formData = new FormData()
      formData.append("reject", isReject)
      formData.append(
        "payoutIds",
        selectedRows.map(row => row.id),
      )

      const response =
        await BackOfficeApi.endpoints.handlePayouts.create(formData)
      if (response && response.ok) {
        ToastUtils.toastExecuteSuccess2()
        // Reset checked ids
        setSelectedRows([])
        // Remove all checked rows
        setRows(prevRows =>
          prevRows.filter(
            row => !selectedRows.some(selectedRow => selectedRow.id === row.id),
          ),
        )
        refreshPage()
      } else {
        ToastUtils.toastSaveError2()
      }
    }
  }

  return (
    <ContentWrapper>
      <div className="content-heading">
        {renderBulkActionDropdown()}
        <div>Payouts</div>
        {renderActionButton()}
        {renderFilters()}
      </div>
      <Container fluid className={isLoading ? "whirl standard" : ""}>
        {renderSummary()}
        <ReactDataGrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          minHeight={700}
          emptyRowsView={isLoading ? null : DataGridEmpty}
          minColumnWidth={200}
          rowHeight={40}
          enableVirtualization={false}
          shouldRowRender={index => index < rows.length}
          enableRowSelect="multi"
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: rows =>
              setSelectedRows(selectedRows.concat(rows.map(r => r.row))),
            onRowsDeselected: rows =>
              setSelectedRows(
                selectedRows.filter(
                  r => !rows.some(row => row.row.id === r.id),
                ),
              ),
            selectBy: {
              keys: { rowKey: "id", values: selectedRows.map(r => r.id) },
            },
          }}
        />
      </Container>
    </ContentWrapper>
  )
}

export default PayoutList
