import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Container } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import { Link } from "react-router-dom"
import DataGridEmpty from "../../Common/DataGridEmpty"
import BackOfficeApi from "../../Api/BackOfficeApi"
import SearchBar from "../../Common/SearchBar"
import ToastUtils from "../../Common/ToastUtils"
import Utils, { DataGridLocalDateTimeFormatter } from "../../Common/Utils"
import OrderUtils from "../OrderUtils"
import Time from "../../Media/Svg/time.svg"
import DropdownList from "../../Common/DropdownList"
import XmlReport from "../XmlReport"
import EditTags from "../../Common/EditTags"
import CryptoSelectorV2 from "../../Common/CryptoSelectorV2"

class BuyOrders extends Component {
  state = {
    paymentChannels: [{ id: 0, label: "All channels" }],
    sortStatus: "",
    sortChannel: 0,
    sortCrypto: "All",
    rows: [],
    searchText: "",
    searchCriteria: "id",
    customer: null,
    choosenIds: [],
    tags: [],
  }
  constructor(props, context) {
    super(props, context)

    this.fetchSize = 30

    this._columns = [
      {
        key: "orderIdentifier",
        name: "ID",
        width: 100,
        formatter: this.IdFormatter,
      },
      {
        key: "orderId",
        name: "REF #",
        width: 100,
      },
      {
        key: "customer",
        name: "Customer",
        formatter: this.CustomerFormatter,
        width: 150,
      },
      {
        key: "fiatAmount",
        name: "Amount",
        formatter: this.AmountFormatter,
        width: 80,
      },
      {
        key: "cryptoCurrency",
        name: "CC",
        width: 50,
      },
      {
        key: "issued",
        name: "Issued",
        formatter: DataGridLocalDateTimeFormatter,
        width: 120,
      },
      {
        key: "issuer",
        name: "Issuer",
        width: 80,
      },
      {
        key: "channel",
        name: "Channel",
        formatter: this.ChannelFormatter,
        width: 130,
      },
      {
        key: "status",
        name: "Status",
        width: 140,
      },
      {
        key: "walletOrder",
        name: "Wallet",
        width: 80,
        formatter: this.WalletFormatter,
      },
      {
        key: "tags",
        name: "Tags",
        formatter: this.TagsFormatter,
      },
    ]

    this.rowOffsetHeight = 0

    this.searching = false
    this.noMoreData = false
  }

  handleIdCheckClick = e => {
    const checked = e.target.checked
    const checkValue = Number(e.target.value)
    let choosenIds = this.state.choosenIds
    const index = choosenIds.indexOf(checkValue)

    if (checked) {
      if (index === -1) {
        choosenIds.push(checkValue)
      }
    } else {
      if (index > -1) {
        choosenIds.splice(index, 1)
      }
    }

    this.setState({
      choosenIds,
    })
  }

  doHeaderAction = item => {
    if (item.value === "select_all") {
      const x = document.getElementsByClassName("custom-control-input")
      for (let i = 0; i <= x.length; i++) {
        if (x[i] && !x[i].checked) {
          x[i].click()
        }
      }
      return
    }

    if (!this.state.choosenIds || this.state.choosenIds.length === 0) {
      alert("Please choose some orders first.")
      return
    }
    switch (item.value) {
      case "report":
        this.toggleModal("reportXmlModal")
        break

      case "edit_tags":
        this.toggleModal("editTagsModal")
        break

      case "banlist_addresses":
        Utils.showSweetAlertAreYouSure(
          "Banlist selected orders addresses?",
          "",
          null,
        ).then(confirmed => {
          if (confirmed) {
            this.banlistAddresses()
          }
        })
        break

      default:
      //do nothing
    }
  }

  toggleModal = modalName => {
    this.setState({
      [modalName]: !this.state[modalName],
    })
  }

  getQuery = (key, defaultValue) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    return searchParams.get(key) || defaultValue
  }

  setQuery = (key, value) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set(key, value)
    const url = searchParams.toString()
    this.props.history.push(`?${url}`)
  }

  changeSortStatus = item => {
    this.setState({
      sortStatus: item.value,
      searchText: "",
    })

    this.searching = false

    this.setQuery("status", item.value)
  }

  changeSortChannel = item => {
    this.setState({
      sortChannel: item.value,
      searchText: "",
    })

    this.searching = false

    this.setQuery("channel", item.value)
  }

  changeSortCrypto = value => {
    this.setState({
      sortCrypto: value,
      searchText: "",
    })

    this.searching = false

    this.setQuery("crypto", value)
  }

  changeSearchCriteria = item => {
    this.setState({
      searchCriteria: item.value,
    })
  }

  loadQueryParameters = () => {
    const savedStatus = this.getQuery("status", "paid")
    const savedChannel = this.getQuery("channel", 0)
    const savedCrypto = this.getQuery("crypto", "All")
    this.setState({
      sortStatus: savedStatus,
      sortChannel: parseInt(savedChannel, 10),
      sortCrypto: savedCrypto,
      customer: Utils.getQueryVariable(this.props.location, "customer"),
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.sortStatus !== prevState.sortStatus ||
      this.state.sortChannel !== prevState.sortChannel ||
      this.state.sortCrypto !== prevState.sortCrypto
    ) {
      this.loadData()
    } else if (this.props.location.search !== prevProps.location.search) {
      this.loadQueryParameters()
    }
  }

  componentDidMount() {
    this.loadQueryParameters()

    BackOfficeApi.endpoints.paymentChannels
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          paymentChannels: this.state.paymentChannels.concat(data.slice(0)),
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  loadData = (first = 0, append = false) => {
    this.setState({
      isLoading: true,
    })

    if (this.state.tags.length === 0) {
      BackOfficeApi.endpoints.getTags
        .getAll({
          type: "ORDER",
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.setState({
            tags: data,
          })
        })
    }

    if (this.searching) {
      this.onSearch(first, append)
      return
    }

    if (this.state.customer) {
      BackOfficeApi.endpoints.customerBuyOrders
        .getOne(
          { id: this.state.customer },
          { first: first, limit: this.fetchSize },
        )
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
    } else {
      BackOfficeApi.endpoints.buyOrders
        .getAll({
          first: first,
          limit: this.fetchSize,
          status: this.state.sortStatus,
          channel: this.state.sortChannel,
          crypto: this.state.sortCrypto,
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
    }
  }

  rowGetter = i => {
    const row = this.state.rows[i]
    if (row) {
      row.customer = {
        customerName: row.customerName,
        customerId: row.customerId,
        customerRiskProfile: row.customerRiskProfile,
      }

      row.orderIdentifier = {
        id: row.id,
        orderMapId: row.orderMapId,
      }

      row.fiatAmount = {
        fiatAmount: row.amount,
        currency: row.currency,
      }

      row.issuer = row.apiName

      const options = { iconOnly: true }

      const badges = [OrderUtils.getCurrentBuyOrderStatusBadge(row, options)]
      row.status = badges.concat(OrderUtils.getOrderTagBadges(row, options))

      if (
        this.state.sortStatus === "paid" &&
        !this.state.customer &&
        Date.now() - row.issued > 604800000
      ) {
        row.status.push(
          OrderUtils.getOrderStatusBadge(
            {
              title: "Delayed",
              background: "#f05050",
              textColor: "#fff",
              icon: Time,
            },
            options,
          ),
        )
      }
    }

    return row
  }

  onScroll = e => {
    let halfWayVScroll = this.state.rows.length * this.rowOffsetHeight - 700
    let currentVScroll = e.scrollTop

    if (
      currentVScroll >= halfWayVScroll &&
      !this.state.isLoading &&
      !this.noMoreData
    ) {
      if (this.state.rows.length < 1000) {
        this.loadData(this.state.rows.length, true)
      }
    }
  }

  onSearchChange = e => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout)
    }

    this.setState({
      searchText: e.target.value,
      isLoading: true,
      searchTimeout: setTimeout(() => {
        this.onSearch(0, false)
      }, 500),
    })
  }

  onSearch = (first = 0, append = false) => {
    if (this.state.searchText.length > 0) {
      this.searching = true

      BackOfficeApi.endpoints.searchBuyOrder
        .getAll({
          search: this.state.searchText,
          first: first,
          limit: this.fetchSize,
          criteria: this.state.searchCriteria,
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
    } else {
      this.searching = false
      this.loadData()
    }
  }

  addData = (data, append) => {
    if (append) {
      if (data.length < this.fetchSize) {
        this.noMoreData = true
      }
      this.setState({
        originalRows: data,
        rows: this.state.rows.concat(data.slice(0)),
        isLoading: false,
      })
    } else {
      this.setState({
        originalRows: data,
        rows: data.slice(0),
        isLoading: false,
      })
      this.noMoreData = false
    }
  }

  renderHeaderActions() {
    let list = [{ value: "edit_tags", text: "Edit tags" }]

    if (this.state.customer) {
      list.push({ value: "report", text: "Report" })
      list.push({ value: "select_all", text: "Select all" })
    }

    list.push({ value: "banlist_addresses", text: "Banlist addresses" })

    return (
      <DropdownList
        className="pr-2"
        buttonColor="secondary"
        label="Bulk action"
        onSelectItem={this.doHeaderAction}
      >
        {list}
      </DropdownList>
    )
  }

  getStatusList = () => {
    let statusList = []
    statusList.push(
      { value: "all", text: "All" },
      { divider: true },
      { value: "paid", text: "Paid" },
      { value: "all-paid", text: "All paid" },
      { value: "cryptoSent", text: "Crypto sent" },
      { value: "Awaiting response", text: "Awaiting response" },
      { value: "suspicious", text: "Suspicious" },
      { value: "frozen", text: "Frozen" },
      { value: "Support handling", text: "Support handling" },
      { value: "To be refunded", text: "To be refunded" },
      { divider: true },
      { value: "new", text: "New" },
      { value: "deleted", text: "Deleted" },
      { value: "refunded", text: "Refunded" },
      { value: "completed", text: "Completed" },
      { value: "Legacy", text: "Legacy" },
      { divider: true },
    )

    if (this.state.tags) {
      this.state.tags.forEach(tag => {
        statusList.push({ value: tag.name, text: "Tag: " + tag.name })
      })
    }

    return statusList
  }

  renderFilters() {
    if (this.state.customer) {
      return ""
    }

    return (
      <div className="ml-auto form-inline">
        <CryptoSelectorV2
          selectedCrypto={this.state.sortCrypto}
          onChange={this.changeSortCrypto}
        />
        <DropdownList
          className="pr-2"
          label="Channel"
          buttonColor="green"
          value={this.state.sortChannel}
          onSelectItem={this.changeSortChannel}
        >
          {this.state.paymentChannels != null
            ? this.state.paymentChannels.map(c => ({
                value: c.id,
                text: c.label,
              }))
            : null}
        </DropdownList>
        <DropdownList
          className="pr-2"
          label="Status"
          buttonColor="primary"
          value={this.state.sortStatus}
          onSelectItem={this.changeSortStatus}
        >
          {this.getStatusList()}
        </DropdownList>
      </div>
    )
  }

  renderSearch() {
    if (this.state.customer) {
      return ""
    }
    return (
      <div className="ml-auto position-relative form-inline">
        <DropdownList
          label="Criteria"
          buttonColor="green"
          value={this.state.searchCriteria}
          onSelectItem={this.changeSearchCriteria}
        >
          {[
            { value: "id", text: "Order ID/Ref" },
            { value: "number", text: "Swish number" },
            { value: "reference", text: "Bangiro OCR" },
            { value: "crypto-address", text: "Crypto address" },
            { value: "name", text: "Name" },
            { value: "email", text: "Email" },
            { value: "legacy-number", text: "Legacy Swish number" },
          ]}
        </DropdownList>
        <SearchBar
          value={this.state.searchText}
          onChange={this.onSearchChange}
        ></SearchBar>
        <div
          className="ball-beat loader-position"
          hidden={!this.state.isLoading}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }

  tagsEditFinished = () => {
    this.clearSelectedCheckboxes()
    this.loadData()
  }

  banlistAddresses = () => {
    const orders = this.state.choosenIds
    let formData = new FormData()
    formData.append("ids", orders)

    BackOfficeApi.endpoints.banlistOrdersAddress
      .create(formData)
      .then(response => {
        if (response.ok) {
          this.clearSelectedCheckboxes()
          ToastUtils.toastExecuteSuccess2()
        }
      })
  }

  clearSelectedCheckboxes() {
    this.setState({
      choosenIds: [],
    })

    const x = document.getElementsByClassName("custom-control-input")
    for (let i = 0; i <= x.length; i++) {
      if (x[i] && x[i].checked) {
        x[i].checked = false
      }
    }
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            {this.renderHeaderActions()}
            Buy orders{" "}
            {this.state.customer
              ? "for customer: " + this.state.customer
              : ""}{" "}
          </div>
          {this.renderSearch()}
          {this.renderFilters()}
        </div>
        <Container fluid>
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={200}
            rowHeight={40}
            ref={element => {
              if (element !== null) {
                const base = element.base
                base.onScroll = this.onScroll
                this.rowOffsetHeight = element.getRowOffsetHeight()
              }
            }}
          />
        </Container>
        <XmlReport
          orderOrTransactionIds={this.state.choosenIds}
          customerId={this.state.customer}
          open={this.state.reportXmlModal}
          onToggle={() => this.toggleModal("reportXmlModal")}
        />
        <EditTags
          ids={this.state.choosenIds}
          open={this.state.editTagsModal}
          toggle={() => this.toggleModal("editTagsModal")}
          onChange={this.tagsEditFinished}
          type="ORDER"
        />
      </ContentWrapper>
    )
  }

  IdFormatter = props => {
    const { id, orderMapId } = props.value
    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={orderMapId}
          value={orderMapId}
          onChange={this.handleIdCheckClick}
          defaultChecked={this.state.choosenIds.includes(orderMapId)}
        />
        <label
          className="custom-control-label"
          htmlFor={orderMapId}
          style={{ lineHeight: "24px" }}
        >
          <Link to={"/orders/buy/" + id}>{id}</Link>
        </label>
      </div>
    )
  }

  CustomerFormatter = props => {
    return (
      <Link to={"/customers/" + props.value.customerId}>
        {OrderUtils.renderRiskProfile(props.value.customerRiskProfile)}{" "}
        {props.value.customerName}
      </Link>
    )
  }

  ChannelFormatter = props => {
    const channel = this.state.paymentChannels.find(
      channel => channel.id === props.value,
    )
    if (channel) {
      const channelName = this.state.paymentChannels.find(
        channel => channel.id === props.value,
      ).label
      return <span>{channelName}</span>
    }
    return ""
  }

  AmountFormatter = props => {
    const style = {
      textAlign: "right",
      display: "inline-block",
      width: "100%",
    }
    return (
      <span style={style}>
        {props.value.fiatAmount.toLocaleString("sv-SE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}{" "}
        {props.value.currency}
      </span>
    )
  }

  TagsFormatter = props => {
    let tags = props.value
    let badges = []

    tags.forEach(t => {
      badges.push(
        <div
          className="tag-badge"
          key={t.id}
          style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
        >
          {t.name}
        </div>,
      )
    })

    return badges
  }

  WalletFormatter = ({ value }) => {
    return (
      <span
        style={{
          textAlign: "center",
          display: "block",
        }}
      >
        {value ? <div className="wallet-badge">wallet</div> : null}
      </span>
    )
  }
}

export default BuyOrders
