import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

/*
data format:
{
  name: string,
  id: number
  hidden?: boolean
}
*/

function EditBadgesModal({
  allData,
  activeData,
  toggle,
  open,
  name,
  onRemove,
  onAdd,
}) {
  const renderActiveBadges = () => {
    let activeBadges = []

    if (activeData) {
      activeData.forEach(badgeData => {
        activeBadges.push(
          <div
            className="tag-badge"
            key={badgeData.id}
            style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
          >
            {badgeData.name}
            <Button
              size="xs"
              color="danger"
              className="ml-2"
              onClick={() => onRemove(badgeData)}
            >
              -
            </Button>
          </div>,
        )
      })

      if (activeBadges.length > 0) {
        activeBadges.unshift(<div key="header">{`Remove ${name}`}</div>)
        activeBadges.push(
          <div key="divider">
            <hr />
          </div>,
        )
      }
    }
    return activeBadges
  }

  const renderInactiveBadges = () => {
    let inactiveBadges = []
    if (allData) {
      let filterBadges = allData.filter(badgeData => {
        return !activeData.some(e => e.id === badgeData.id) && !badgeData.hidden
      })

      filterBadges.forEach(badgeData => {
        inactiveBadges.push(
          <div
            className="tag-badge"
            key={badgeData.id}
            style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
          >
            {badgeData.name}
            <Button
              size="xs"
              color="success"
              className="ml-2"
              onClick={() => onAdd(badgeData)}
            >
              +
            </Button>
          </div>,
        )
      })
      if (inactiveBadges.length > 0) {
        inactiveBadges.unshift(<div key="header">{`Add ${name}`}</div>)
      }
    }
    return inactiveBadges
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>{`Edit ${name}`}</ModalHeader>
      <ModalBody>
        <div>{renderActiveBadges()}</div>
        <div>{renderInactiveBadges()}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditBadgesModal
