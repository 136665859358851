import React, { Component } from "react"
import { Card } from "reactstrap"
import UserStatusSection from "./UserStatusSection"
import UserDetailsSection from "./UserDetailsSection"
import UserPermissionsSection from "./UserPermissionsSection"

class UserInfoArea extends Component {
  render() {
    let cardClass = "card-area"
    let cardStyle = {}
    if (this.props.isLoading) {
      cardClass += " whirl standard"
      cardStyle = { minHeight: "500px" }
    }

    return (
      <div>
        <span className="header-area">
          User
          <span> {this.props.user ? "#" + this.props.user.id : ""}</span>
        </span>
        <Card className={cardClass} style={cardStyle}>
          <UserStatusSection user={this.props.user} />

          <div className="card-section-divider" />

          <UserDetailsSection user={this.props.user} />

          <div className="card-section-divider" />

          <UserPermissionsSection
            user={this.props.user}
            allPermissions={this.props.allPermissions}
            onUpdatePermissions={this.props.onUpdatePermissions}
          />
        </Card>
      </div>
    )
  }
}
export default UserInfoArea
