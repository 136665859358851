import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import Cards from "./Cards"
import { Row } from "reactstrap"
import BusinessRevenueChart from "./BusinessRevenueChart"
import BusinessVolumeChart from "./BusinessVolumeChart"
import BusinessOrdersChart from "./BusinessOrdersChart"
import BusinessRegistrationChart from "./BusinessRegistrationChart"

class BusinessDashboard extends Component {
  state = {}

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Business</div>
        </div>
        <Row>
          <Cards />
        </Row>
        <Row>
          <BusinessRevenueChart />
        </Row>
        <Row>
          <BusinessVolumeChart />
        </Row>
        <Row>
          <BusinessOrdersChart />
        </Row>
        <Row>
          <BusinessRegistrationChart />
        </Row>
      </ContentWrapper>
    )
  }
}
export default BusinessDashboard
