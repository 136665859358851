/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  CardHeader,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import moment from "moment"
import CardTool from "../../Common/CardTool"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import TreasuryContext from "./TreasuryContext"

const HotWallet = ({ crypto }) => {
  const treasuryContext = useContext(TreasuryContext)
  const [gatewayUpdated, setGatewayUpdated] = useState(
    moment().format("HH:mm:ss"),
  )

  const [bitstampTicker, setBitstampTicker] = useState(null)
  const [executePaymentsModal, setExecutePaymentsModal] = useState(false)
  const [gatewayUnconfirmedBalance, setGatewayUnconfirmedBalance] =
    useState("...")
  const [gatewayBalance, setGatewayBalance] = useState("...")
  const [gatewayBatchSize, setGatewayBatchSize] = useState("...")
  const [gatewayBatchAmount, setGatewayBatchAmount] = useState("...")
  const [gatewayError, setGatewayError] = useState(false)

  const {
    error,
    loading: refreshingTreasury,
    refresh: refreshTreasury,
  } = treasuryContext

  useEffect(() => {
    if (!refreshingTreasury) {
      refreshNonGateway().catch(() => ToastUtils.toastAPIError2())

      const { gatewayStatusData, bitstampTickerData } = treasuryContext
      const cryptoGatewayStatus = gatewayStatusData[crypto]
      if (!cryptoGatewayStatus) return
      setGatewayUnconfirmedBalance(cryptoGatewayStatus.unconfirmedBalance)
      setGatewayBalance(cryptoGatewayStatus.balance)
      setGatewayBatchSize(cryptoGatewayStatus.ordersToExecute)
      setGatewayBatchAmount(cryptoGatewayStatus.ordersAmount)
      setGatewayError(!!error)

      setBitstampTicker(bitstampTickerData[crypto])
    }
  }, [refreshingTreasury])

  const refreshNonGateway = async () => {
    return setGatewayUpdated(moment().format("HH:mm:ss"))
  }

  const handleCardRefreshClick = (_card, done) => {
    Promise.all([
      refreshTreasury([`${crypto.toLowerCase()}GatewayStatus`]),
      refreshNonGateway(),
    ])
      .then(done)
      .catch(() => ToastUtils.toastAPIError2())
  }

  const renderEqualAmount = (amount, currency) => {
    if (!amount || amount === "NA" || !bitstampTicker) return null
    let amountString = null
    if (currency === "EUR") {
      // BTC --> EUR
      amountString = (amount * bitstampTicker?.bid).toLocaleString("sv-SE", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: "currency",
        currency: "EUR",
      })
    } else if (currency === crypto) {
      // EUR --> BTC
      amountString =
        (amount / bitstampTicker?.ask).toLocaleString("sv-SE", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
        }) + " BTC"
    }
    return amountString ? ` (${amountString})` : null
  }

  const toggleExecutePaymentsModal = () =>
    setExecutePaymentsModal(!executePaymentsModal)

  const executePaymentBatch = () => {
    setExecutePaymentsModal(!executePaymentsModal)

    let formData = new FormData()
    formData.append("cryptoCurrency", crypto)

    BackOfficeApi.endpoints.executeGatewayPayments
      .create(formData)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(_data => {
        ToastUtils.toastExecuteSuccess2()
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
      })
  }

  return (
    <div className="card mb-2">
      <CardHeader>
        <CardTool refresh onRefresh={handleCardRefreshClick} />
        <h4>
          <em className="fa-fw fa fa-cog mr-2" />
          {crypto} Hot Wallet
        </h4>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Table className="hover responsive mb-2">
              <tbody>
                <tr>
                  <td>Unconfirmed balance, {crypto} (EUR)</td>
                  <td>
                    {Utils.formatCrypto(gatewayUnconfirmedBalance, 2)} {crypto}{" "}
                    {renderEqualAmount(gatewayUnconfirmedBalance, "EUR")}
                  </td>
                </tr>
                <tr>
                  <td>Balance, {crypto} (EUR)</td>
                  <td>
                    {Utils.formatCrypto(gatewayBalance, 2)} {crypto}{" "}
                    {renderEqualAmount(gatewayBalance, "EUR")}
                  </td>
                </tr>
                <tr>
                  <td>Number of outstanding orders</td>
                  <td>{gatewayBatchSize}</td>
                </tr>
                <tr bgcolor="#f0f0f0">
                  <td>{crypto} amount of outstanding orders</td>
                  <td>
                    {gatewayBatchAmount} {crypto}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div
              className={
                gatewayError ? "alert alert-danger" : "alert alert-info"
              }
            >
              {gatewayError ? "Error" : "Updated"} {gatewayUpdated}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              <em className="fa-fw fa fa-exclamation-circle mr-2" />
              Actions
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="primary"
              className="mr-2 mb-2"
              onClick={toggleExecutePaymentsModal}
              disabled={gatewayError}
            >
              Execute payments batch
            </Button>
          </Col>
        </Row>
      </CardBody>
      {/* Execute payments */}
      <Modal isOpen={executePaymentsModal} toggle={toggleExecutePaymentsModal}>
        <ModalHeader toggle={toggleExecutePaymentsModal}>
          Execute payment batch
        </ModalHeader>
        <ModalBody>
          You are about to execute {gatewayBatchSize} orders for a total of{" "}
          {gatewayBatchAmount} {crypto}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={executePaymentBatch}>
            Execute
          </Button>
          <Button color="secondary" onClick={toggleExecutePaymentsModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default HotWallet
