import React, { Component } from "react"
import { Row, Col, CardBody } from "reactstrap"
import ValidationImage from "../Common/ValidationImage"

class CustomerContactSection extends Component {
  render() {
    if (!this.props.customer) return <div />

    let customer = this.props.customer

    return (
      <div>
        <CardBody className="pt-0">
          <p
            className="details-header"
            style={{ padding: "1.5rem 1.25rem 0.75rem 0" }}
          >
            Contact information:
          </p>
          <Row className="no-gutters">
            <Col lg={4}>
              <p className="customer-contact-details-header text-uppercase">
                Email:
              </p>
              <div className="details-row">
                <ValidationImage
                  style={{ float: "left", marginRight: "10px" }}
                  valid={!!customer.emailActive}
                />
                <p className="details-value">{customer.email}</p>
              </div>
            </Col>
            <Col lg={4}>
              <p className="customer-contact-details-header text-uppercase">
                Phone number:
              </p>
              <p className="details-value">{customer.phoneNumber}</p>
            </Col>
            <Col lg={4}>
              <p className="customer-contact-details-header text-uppercase">
                Address:
              </p>
              <p className="details-value">{customer.address}</p>
              <p className="details-value">
                {customer.zip} {customer.city}
              </p>
              <p className="details-value">
                {customer.addressCountry || customer.country}
              </p>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
}

export default CustomerContactSection
