import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  FormGroup,
} from "reactstrap"
import React, { useEffect, useState } from "react"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"

const EditOrderLimitsModal = ({
  orderLimitData,
  isOpen,
  onDismiss,
  onEdit,
}) => {
  const [minLimit, setMinLimit] = useState(null)
  const [maxLimit, setMaxLimit] = useState(null)

  const onContinue = async () => {
    if (!minLimit && !maxLimit) {
      ToastUtils.toastCustomError("Need to input a max or min limit")
      return
    }
    if (
      minLimit == orderLimitData.min &&
      (maxLimit == orderLimitData.max ||
        (isNullOrBlank(maxLimit) && isNullOrBlank(orderLimitData.max)))
    ) {
      ToastUtils.toastCustomError("Please edit one of the limits")
      return
    }

    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: Edit ${orderLimitData?.type} order limits \n
      - Order limit name: ${orderLimitData?.name}\n
      - Order limit ID: ${orderLimitData?.id}\n`,
    )
    if (confirmed) {
      onDismiss()
      onEdit({
        id: orderLimitData.id,
        type: orderLimitData.type,
        min: minLimit,
        max: maxLimit,
      })
    }
  }

  useEffect(() => {
    if (orderLimitData) {
      setMinLimit(orderLimitData.min)
      // Needed to add this to fix issue with not wiping previous values
      if (!orderLimitData.max) {
        setMaxLimit("")
      } else {
        setMaxLimit(orderLimitData.max)
      }
    }
  }, [orderLimitData])

  return (
    <Modal isOpen={isOpen} toggle={onDismiss} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onDismiss}>Edit Order Limit</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label className="col-xl-3 col-form-label">Name</Label>
          <Label className="col-xl-3 col-form-label" style={{ paddingLeft: 0 }}>
            {orderLimitData?.name}
          </Label>
        </FormGroup>
        <FormGroup row>
          <Label className="col-xl-3 col-form-label">Type</Label>
          <Label className="col-xl-3 col-form-label" style={{ paddingLeft: 0 }}>
            {orderLimitData?.type + " limit"}
          </Label>
        </FormGroup>
        <FormGroup row>
          <Label className="col-xl-3 col-form-label">Min</Label>
          <Input
            className="col-xl-8"
            value={minLimit}
            onChange={({ target }) => setMinLimit(target.value)}
          />
        </FormGroup>
        <FormGroup row>
          <Label className="col-xl-3 col-form-label">Max</Label>
          <Input
            className="col-xl-8"
            type="email"
            name="email"
            id="email"
            value={maxLimit}
            onChange={({ target }) => setMaxLimit(target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onContinue}>
          Edit
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const isNullOrBlank = value => {
  return value === null || value === ""
}

export default EditOrderLimitsModal
