import React, { useEffect, useState } from "react"
import { Button, Row, Col, CardBody } from "reactstrap"
import Utils from "../../Common/Utils"
import { Cell, Pie, PieChart, ResponsiveContainer, Legend } from "recharts"
import OrderUtils from "../OrderUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"

const BuyOrderDetailsSection = props => {
  const [discountFee, setDiscountFee] = useState(null)
  const order = props.order

  const copyButtonColorChange = e => {
    let target = e.target
    target.style.color = "rgb(231, 132, 53)"
    setTimeout(function () {
      target.style.color = "rgb(106, 191, 91)"
    }, 1000)
  }

  const copyToClipboard = e => {
    let copyText = document.createElement("input")
    document.body.appendChild(copyText)
    copyText.value = e.target.getAttribute("data-copy")
    copyText.select()
    document.execCommand("copy", false)
    copyText.remove()
    copyButtonColorChange(e)
  }

  const copyTxTable = e => {
    const tableId = e.target.getAttribute("data-tableid")
    const txFullTable = document.getElementById(tableId)
    txFullTable.style.display = "table"
    Utils.selectElementContents(txFullTable)
    document.execCommand("copy", false)
    txFullTable.style.display = "none"
    copyButtonColorChange(e)
  }

  const bumpTransaction = () => {
    Utils.showSweetAlertAreYouSure(
      "Are you sure you want to bump the transaction?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        BackOfficeApi.endpoints.bumpTransaction
          .create({}, { id: order.id })
          .then(response => {
            if (response.ok) {
              ToastUtils.toastExecuteSuccess2()
              return
            }
            throw Error()
          })
          .catch(() => {
            ToastUtils.toastExecuteError2()
          })
      }
    })
  }

  const getDiscount = async discountFeeId => {
    if (!discountFeeId) {
      return
    }
    try {
      const res = await BackOfficeApi.endpoints.getDiscount.getOne({
        discountFeeId: discountFeeId,
      })
      if (res.ok) {
        const data = await res.json()

        if (data) setDiscountFee(data)
      } else throw new Error()
    } catch (e) {
      console.error(e)
    }
  }

  const getSafelloSharePieData = () => {
    let cells = [
      {
        name: "Safello",
        value: order.amountShareSafello,
        color: "#34b094",
      },
      {
        name: "Customer",
        value: order.amountShareCustomer,
        color: "#dcdcdc",
      },
    ]

    let amountShareApi = order.amountShareApi || 0
    let percentage =
      (order.amountShareSafello /
        (order.amountShareSafello +
          order.amountShareCustomer +
          amountShareApi)) *
      100
    let payload = [
      {
        value: roundShareValue(order.amountShareSafello),
        currency: order.currency,
        percent: roundShareValue(percentage),
        className: "safello-share-value",
      },
    ]

    return { cells: cells, payload: payload }
  }

  const getPartnerSharePieData = () => {
    if (!order.hasPartnerShares) return undefined

    let cells = [
      {
        name: order.appName,
        value: order.amountShareApi,
        color: "#1285e5",
      },
      {
        name: "Customer",
        value: order.amountShareCustomer,
        color: "#dcdcdc",
      },
    ]

    let percentage =
      (order.amountShareApi /
        (order.amountShareSafello +
          order.amountShareCustomer +
          order.amountShareApi)) *
      100
    let payload = [
      {
        value: roundShareValue(order.amountShareApi),
        currency: order.currency,
        percent: roundShareValue(percentage),
        className: "partner-share-value",
      },
    ]

    return { cells: cells, payload: payload }
  }

  const shareLegendRender = props => {
    const { payload } = props
    return payload.map((item, i) => {
      return (
        <p className={item.className} key={i} style={{ color: item.color }}>
          {Utils.formatNumber(item.value)} {item.currency} (
          {Utils.formatNumber(item.percent)}%)
        </p>
      )
    })
  }

  const roundShareValue = value => {
    return Math.round(value * 100) / 100
  }

  useEffect(() => {
    if (order && order.discountFee && discountFee === null) {
      getDiscount(order.discountFee)
    }
  }, [order])

  if (!order) return <div />

  let safelloSharePie = getSafelloSharePieData()
  let partnerSharePie = getPartnerSharePieData()

  let copyStyle = {
    color: "rgb(106, 191, 91)",
    cursor: "pointer",
  }

  const orderCompleted = Utils.getUtcInLocalDateTime(order.delivered) || " "
  const orderPaid = Utils.getUtcInLocalDateTime(order.paid)
  const orderIssued = Utils.getUtcInLocalDateTime(order.issued)
  const orderStatus = OrderUtils.getCurrentBuyOrderStatus(order)
  const orderAmountPaid = Utils.formatNumber(order.amountPaid)

  return (
    <div>
      <CardBody className="pt-0">
        <Row className="no-gutters">
          <Col lg={12}>
            <p
              className="card-section-header"
              style={{ padding: "0.75rem 1.25rem 0.75rem 0" }}
            >
              Order
            </p>
            {document.queryCommandSupported("copy") && (
              <div style={{ marginLeft: "16px" }}>
                Copy TX table for a bank{" "}
                <i
                  className="fas fa-copy"
                  style={copyStyle}
                  onClick={copyTxTable}
                  data-tableid="txTableForBank"
                ></i>{" "}
                <span style={{ paddingLeft: "16px" }}>Copy TX full table </span>
                <i
                  className="fas fa-copy"
                  style={copyStyle}
                  onClick={copyTxTable}
                  data-tableid="txFullTable"
                ></i>
                <br />
                <br />
              </div>
            )}
            <Col lg={12}>
              <table className="table-copy" id="txTableForBank">
                <tbody>
                  <tr>
                    <td>Order ID</td>
                    <td>{order.orderId}</td>
                  </tr>
                  <tr>
                    <td>Order number</td>
                    <td>{order.id}</td>
                  </tr>
                  <tr>
                    <td>issued</td>
                    <td>{orderIssued}</td>
                  </tr>
                  <tr>
                    <td>status</td>
                    <td>{orderStatus}</td>
                  </tr>
                  <tr>
                    <td>amount</td>
                    <td>{orderAmountPaid}</td>
                  </tr>
                  <tr>
                    <td>currency</td>
                    <td>{order.currency}</td>
                  </tr>
                  {!order.walletOrder && (
                    <tr>
                      <td>crypto_address</td>
                      <td>{order.cryptoAddress}</td>
                    </tr>
                  )}
                  <tr>
                    <td>crypto</td>
                    <td>{order.cryptoCurrency}</td>
                  </tr>
                  <tr>
                    <td>payment_channel</td>
                    <td>{order.channelName}</td>
                  </tr>
                  <tr>
                    <td>payer_phone</td>
                    <td>{order.customerPhone}</td>
                  </tr>
                  <tr>
                    <td>paid</td>
                    <td>{orderPaid}</td>
                  </tr>
                  <tr>
                    <td>completed</td>
                    <td>{orderCompleted}</td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
              <table className="table-copy" id="txFullTable">
                <tbody>
                  <tr>
                    <td>Order ID</td>
                    <td>{order.orderId}</td>
                  </tr>
                  <tr>
                    <td>Order number</td>
                    <td>{order.id}</td>
                  </tr>
                  <tr>
                    <td>issued</td>
                    <td>{orderIssued}</td>
                  </tr>
                  <tr>
                    <td>status</td>
                    <td>{orderStatus}</td>
                  </tr>
                  <tr>
                    <td>ip</td>
                    <td>{order.ip}</td>
                  </tr>
                  <tr>
                    <td>amount</td>
                    <td>{orderAmountPaid}</td>
                  </tr>
                  <tr>
                    <td>currency</td>
                    <td>{order.currency}</td>
                  </tr>
                  {!order.walletOrder && (
                    <tr>
                      <td>crypto_address</td>
                      <td>{order.cryptoAddress}</td>
                    </tr>
                  )}
                  <tr>
                    <td>crypto</td>
                    <td>{order.cryptoCurrency}</td>
                  </tr>
                  <tr>
                    <td>crypto_amount_sent</td>
                    <td>{order.cryptoAmount}</td>
                  </tr>
                  <tr>
                    <td>payment_channel</td>
                    <td>{order.channelName}</td>
                  </tr>
                  <tr>
                    <td>payer_name</td>
                    <td>{order.customerName}</td>
                  </tr>
                  <tr>
                    <td>payer_phone</td>
                    <td>{order.customerPhone}</td>
                  </tr>
                  <tr>
                    <td>paid</td>
                    <td>{orderPaid}</td>
                  </tr>
                  <tr>
                    <td>completed</td>
                    <td>{orderCompleted}</td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td className="details-header">Crypto:</td>
                    <td className="details-value" align="right">
                      {order.cryptoCurrency}
                    </td>
                  </tr>
                  {!order.walletOrder && (
                    <tr>
                      <td className="details-header">Crypto address:</td>
                      <td
                        className="details-value"
                        align="right"
                        style={{ wordBreak: "break-all" }}
                      >
                        <a
                          href={OrderUtils.getCryptoBlockExplorerUrl(
                            order.cryptoCurrency,
                            order.cryptoAddress,
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {order.cryptoAddress}
                        </a>{" "}
                        {document.queryCommandSupported("copy") && (
                          <i
                            className="fas fa-copy"
                            style={copyStyle}
                            data-copy={order.cryptoAddress}
                            onClick={copyToClipboard}
                          ></i>
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="details-header">Placed at:</td>
                    <td className="details-value" align="right">
                      {order.apiName || "Safello"}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Channel:</td>
                    <td className="details-value" align="right">
                      {order.channelName || "Safello"}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Order value:</td>
                    <td className="details-value" align="right">
                      {Utils.formatNumber(order.amount)} {order.currency}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Paid:</td>
                    <td className="details-value" align="right">
                      {Utils.formatNumber(order.amountPaid)} {order.paidCurrency}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Fees:</td>
                    <td className="details-value" align="right">
                      {Utils.formatNumber(order.feeAmount)} {order.currency}
                    </td>
                  </tr>
                  {discountFee && (
                    <tr>
                      <td className="details-header">Campaign:</td>
                      <td className="details-value" align="right">
                        {discountFee.name}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="details-header">Crypto value:</td>
                    <td className="details-value" align="right">
                      {Utils.formatNumber(order.purchaseValue)} {order.currency}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">
                      Value in {order.cryptoCurrency}:
                    </td>
                    {order.completed ? (
                      <td className="details-value" align="right">
                        {order.cryptoAmount.toLocaleString("sv-SE", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 10,
                        })}
                      </td>
                    ) : (
                      <td
                        className="details-value"
                        align="right"
                        style={{ color: "#e05c57" }}
                      >
                        Order still in process{" "}
                        {order.sentToPaymentGateway && (
                          <Button color="warning" onClick={bumpTransaction}>
                            Bump transaction
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
            </Col>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col lg={6}>
            <p
              className="card-section-header"
              style={{ padding: "0.75rem 1.25rem 0.75rem 0" }}
            >
              {"Safello's share"}
            </p>
            <Col className="pl-0 pr-0">
              <div
                style={{
                  position: "relative",
                  width: "80%",
                  height: "100px",
                  minWidth: "100px",
                }}
              >
                <ResponsiveContainer style={{ display: "inline-block" }}>
                  <PieChart>
                    <Pie
                      startAngle={90}
                      endAngle={470}
                      cx={50}
                      dataKey="value"
                      data={safelloSharePie.cells}
                      outerRadius="100%"
                      label={false}
                    >
                      {safelloSharePie.cells.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={item.color} />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      verticalAlign="middle"
                      align="right"
                      content={shareLegendRender}
                      payload={safelloSharePie.payload}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col>
            {partnerSharePie && (
              <div>
                <p
                  className="card-section-header"
                  style={{ padding: "0.75rem 1.25rem 0.75rem 0" }}
                >
                  {order.apiName} share
                </p>
                <Col className="pl-0 pr-0">
                  <div
                    style={{
                      position: "relative",
                      width: "80%",
                      height: "100px",
                      minWidth: "100px",
                    }}
                  >
                    <ResponsiveContainer style={{ display: "inline-block" }}>
                      <PieChart>
                        <Pie
                          startAngle={90}
                          endAngle={470}
                          cx={50}
                          dataKey="value"
                          data={partnerSharePie.cells}
                          outerRadius={"80%"}
                          label={false}
                        >
                          {partnerSharePie.cells.map((item, index) => (
                            <Cell key={`cell-${index}`} fill={item.color} />
                          ))}
                        </Pie>
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="right"
                          content={shareLegendRender}
                          payload={partnerSharePie.payload}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </div>
  )
}

export default BuyOrderDetailsSection
