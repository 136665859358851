// utils
import ToastUtils from "../components/Common/ToastUtils"
import BackOfficeApi from "../components/Api/BackOfficeApi"

export const fetchTags = async type => {
  try {
    const res = await BackOfficeApi.endpoints.getTags.getAll({
      //Temporarily set swap_order type to wallet_transaction, until BI has fixed support for swap_order
      type: type === "SWAP_ORDER" ? "WALLET_TRANSACTION" : type,
    })
    if (res.ok) {
      const newTags = await res.json()

      newTags.sort(function (a, b) {
        let tagA = a.name.toUpperCase()
        let tagB = b.name.toUpperCase()
        return tagA < tagB ? -1 : tagA > tagB ? 1 : 0
      })

      return newTags
    }
  } catch (e) {
    console.error(e)
    ToastUtils.toastCustomError("Error fetching tags")
  }
}
