import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import CustomerInfoArea from "./CustomerInfoArea"
import CustomerActionsArea from "./CustomerActionsArea"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import Utils from "../Common/Utils"
import CommentArea from "../Common/CommentArea"
import EditCustomerModal from "./EditCustomerModal"
import CustomerKycModal from "./CustomerKycModal"
import FreezeModal from "./FreezeModal"
import EditBadgesModal from "./EditBadgesModal"
import CustomerDocuments from "./CustomerDocuments"
import UploadModal from "./UploadModal"
import CustomerBankAccounts from "./CustomerBankAccounts"
import BankAccountModal from "./BankAccountModal"
import CreateOrderModal from "./CreateOrderModal"
import CustomerLogins from "./CustomerLogins"
import CustomerYearlyLimit from "./CustomerYearlyLimit"
import CustomerRecurringPayments from "./CustomerRecurringPayments"
import YearlyLimitModal from "./YearlyLimitModal"
import ReviewPepSanctionModal from "./ReviewPepSanctionModal"
import SellPofModal from "./SellPofModal"
import AdminFeeModal from "./AdminFeeModal"
import CustomerAccesses from "./CustomerAccesses"

class Customer extends Component {
  state = {
    customerId: Utils.getLastUrlSegment(this.props.location),
    editModal: false,
    kycModal: false,
    uploadModal: false,
    riskProfileModal: false,
    freezeModal: false,
    bankAccountModal: false,
    createOrderModal: false,
    yearlyLimitModal: false,
    reviewPepSanctionModal: false,
    sellPofModal: false,
    adminFeeModal: false,
    activeTab: "1",
    commentReloadTrigger: 0,
  }

  componentDidMount() {
    this.getCustomer(this.loadData)
  }

  loadData = () => {
    this.getCountries()
    this.getImages()
    this.getBankAccounts()
    this.getBanks()
    this.getYearlyLimit()
    this.getRecurringPayments()
    this.getTinkData()
    this.getLimits()
    this.getChannelLimits()
    this.getReviewStatus()
    this.getSellPofs()
    this.getFeeLevelData()
    this.getDiscounts()
    this.getWallets()
    this.getTickers()
    this.getPocLimits()
    this.getCustomerTags()
  }

  getCustomer = loadData => {
    this.setState({ isLoading: true })
    BackOfficeApi.endpoints.customerDetails
      .getOne({ id: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else if (response.status === 401) {
          ToastUtils.toastUnauthorized()
          this.setState({ isLoading: false })
        } else {
          throw Error()
        }
      })
      .then(data => {
        this.setState({ isLoading: false, customer: data })
        if (loadData && data) {
          loadData()
        }
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  getCountries = () => {
    BackOfficeApi.endpoints.countries
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          countries: data.slice(0),
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  getImages = () => {
    BackOfficeApi.endpoints.customerImages
      .getAll({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        let imgs = data.images
        imgs.sort((a, b) => {
          return b.timestamp - a.timestamp
        })

        this.setState({
          images: imgs,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getBankAccounts = () => {
    BackOfficeApi.endpoints.customerBankAccounts
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        let ba = data
        ba.sort((a, b) => {
          return b.timestamp - a.timestamp
        })

        this.setState({
          bankAccounts: ba,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getBanks = () => {
    BackOfficeApi.endpoints.getAllBanks
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        let ba = data
        ba.sort((a, b) => {
          return a.countryCode.localeCompare(b.countryCode)
        })

        this.setState({
          banks: ba,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getYearlyLimit = () => {
    BackOfficeApi.endpoints.getYearlyLimit
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          yearlyLimit: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }
  getRecurringPayments = () => {
    BackOfficeApi.endpoints.getRecurringPayments
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          recurringPayments: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getTinkData = () => {
    BackOfficeApi.endpoints.customerTinkData
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        let d = data
        d.tinkUsers.sort((a, b) => {
          return b.created - a.created
        })
        this.setState({
          tinkData: d,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getLimits = () => {
    BackOfficeApi.endpoints.customerLimits
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          limits: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getChannelLimits = () => {
    BackOfficeApi.endpoints.customerChannelLimits
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          channelLimits: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getReviewStatus = () => {
    BackOfficeApi.endpoints.customerReviewStatus
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          reviewStatus: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getSellPofs = () => {
    BackOfficeApi.endpoints.customerSellPofs
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          sellPofs: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getPocLimits = () => {
    BackOfficeApi.endpoints.pocLimits
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          pocLimits: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getFeeLevelData = () => {
    BackOfficeApi.endpoints.customerFeeLevelData
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          feeLevelData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getDiscounts = () => {
    BackOfficeApi.endpoints.getCustomerDiscounts
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          discounts: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getWallets = () => {
    BackOfficeApi.endpoints.getCustomerWallets
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          wallets: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getCustomerTags = () => {
    BackOfficeApi.endpoints.getCustomerTags
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          customerTags: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  getTickers = () => {
    BackOfficeApi.endpoints.getTickers
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          tickers: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  onActionPerformed = action => {
    switch (action) {
      case "gdpr_removal_request":
        this.gdprRemovalRequest(false)
        break
      case "gdpr_single_account_removal_request":
        this.gdprRemovalRequest(true)
        break
      case "remove_gdpr_removal_request":
        this.removeGdprRemovalRequest()
        break
      case "customer_freeze":
        this.toggleFreezeModal()
        break
      case "customer_unfreeze":
        this.toggleFreezeModal()
        break
      case "customer_edit":
        this.editCustomer()
        break
      case "customer_kyc":
        this.toggleKycModal()
        break
      case "customer_upload":
        this.toggleUploadModal()
        break
      case "customer_add_bank_account":
        this.toggleBankAccountModal()
        break
      case "customer_create_order":
        this.toggleCreateOrderModal()
        break
      case "customer_add_sell_pof":
        this.toggleSellPofModal()
        break
      case "customer_edit_yearly_limit":
        this.toggleYearlyLimitModal()
        break
      case "customer_create_update_intercom":
        this.createUpdateIntercomUser()
        break
      case "customer_offboard":
        this.offboardCustomer()
        break
      case "customer_review":
        this.reviewCustomer()
        break
      case "customer_corporate_approve":
        this.corporateApprove()
        break
      case "customer_corporate_reject":
        this.corporateReject()
        break
      case "change_risk_profile":
        this.toggleRiskProfileModal()
        break
      case "customer_enable_protected":
        this.changeProtectedStatus(true)
        break
      case "customer_remove_protected":
        this.changeProtectedStatus(false)
        break
      case "customer_bump_tier":
        this.bumpCustomerTier()
        break
      case "administration_fee":
        this.toggleAdminFeeModal()
        break
      case "customer_takeover":
        // refetch customer
        this.getCustomer()
        break
      default:
        break
    }
  }

  changeProtectedStatus = value => {
    let formData = new FormData()
    formData.append("value", value)

    BackOfficeApi.endpoints.customerProtected
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getCustomer()
        } else if (response.status === 401) {
          ToastUtils.toastUnauthorized()
        } else {
          ToastUtils.toastAPIError2()
        }
      })
  }

  gdprRemovalRequest = isSingleAccountRemoval => {
    this.setState({ isLoading: true })
    BackOfficeApi.endpoints.gdprRemovalRequest
      .post(
        {
          id: this.state.customerId,
        },
        {
          singleAccountRemoval: isSingleAccountRemoval,
        },
      )
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getCustomer()
        } else {
          ToastUtils.toastAPIError2()
        }
        this.setState({ isLoading: false })
      })
  }

  removeGdprRemovalRequest = () => {
    this.setState({ isLoading: true })
    BackOfficeApi.endpoints.removeGdprRemovalRequest
      .post({ id: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getCustomer()
        } else {
          ToastUtils.toastAPIError2()
        }
        this.setState({ isLoading: false })
      })
  }

  setDefaultBankAccount = bankAccount => {
    let formData = new FormData()
    formData.append("bankAccountId", bankAccount.id)

    BackOfficeApi.endpoints.selectDefaultBankAccount
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          this.getBankAccounts()
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  deleteBankAccount = bankAccount => {
    let formData = new FormData()
    formData.append("bankAccountId", bankAccount.id)

    BackOfficeApi.endpoints.deleteBankAccount
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          this.getBankAccounts()
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  hideBankAccount = bankAccount => {
    let formData = new FormData()
    formData.append("bankAccountId", bankAccount.id)

    BackOfficeApi.endpoints.hideBankAccount
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          this.getBankAccounts()
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  addBankAccount = bankAccount => {
    let formData = new FormData()
    formData.append("bankAccountType", bankAccount.bankAccountType)
    if (bankAccount.bank) {
      formData.append("bank", bankAccount.bank)
    }
    if (bankAccount.otherBankName && bankAccount.otherBankCountryCode) {
      formData.append("otherBankName", bankAccount.otherBankName)
      formData.append("otherBankCountryCode", bankAccount.otherBankCountryCode)
    }

    formData.append("currency", bankAccount.currency)
    formData.append("name", bankAccount.name)
    formData.append("routingAddress", bankAccount.routingAddress)
    formData.append("accountNumber", bankAccount.accountNumber)
    if (bankAccount.image) {
      formData.append("image", bankAccount.image)
    }

    BackOfficeApi.endpoints.addBankAccount
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          this.getBankAccounts()
          this.toggleBankAccountModal()
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  editBankAccount = bankAccount => {
    let formData = new FormData()
    formData.append("bankAccountId", bankAccount.id)
    formData.append("bankAccountType", bankAccount.bankAccountType)
    formData.append("bank", bankAccount.bank)
    formData.append("currency", bankAccount.currency)
    formData.append("name", bankAccount.name)
    formData.append("routingAddress", bankAccount.routingAddress)
    formData.append("accountNumber", bankAccount.accountNumber)
    if (bankAccount.image) {
      formData.append("image", bankAccount.image)
    }

    BackOfficeApi.endpoints.editBankAccount
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          this.getBankAccounts()
          this.toggleBankAccountModal()
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  addSellPof = sellPof => {
    let formData = new FormData()
    formData.append("crypto", sellPof.crypto)
    formData.append("amount", sellPof.amount)

    if (sellPof.comment) {
      formData.append("comment", sellPof.comment)
    }
    if (sellPof.image) {
      formData.append("image", sellPof.image)
    }

    BackOfficeApi.endpoints.customerAddSellPof
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          this.getSellPofs()
          this.toggleSellPofModal()
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  onExecuteAdministrationFee = data => {
    let formData = new FormData()
    formData.append("wallet", data.wallet)
    formData.append("currency", data.crypto)
    formData.append("amount", data.amount)
    formData.append("remittanceInfo", data.comment)

    BackOfficeApi.endpoints.adminFeeTransfer.create(formData).then(response => {
      if (response.ok) {
        ToastUtils.toastSaveSuccess2()
        this.toggleAdminFeeModal()
      } else {
        ToastUtils.toastSaveError2()
      }
    })
  }

  bumpCustomerTier = () => {
    this.setState({ isLoading: true })
    BackOfficeApi.endpoints.bumpCustomerTier
      .post({ id: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getCustomer()
        } else {
          ToastUtils.toastAPIError2()
        }
        this.setState({ isLoading: false })
      })
  }

  onEditBankAccount = bankAccount => {
    this.setState({
      bankAccountModal: !this.state.bankAccountModal,
      selectedBankAccount: bankAccount,
    })
  }

  uploadDone = () => {
    this.toggleUploadModal()
    this.getImages()
  }

  editCustomer = () => {
    this.toggleEditModal()
  }

  onCreateOrderDone = () => {
    this.setState({
      commentReloadTrigger: this.state.commentReloadTrigger + 1,
    })
    this.loadData()
  }

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal,
    })
  }

  toggleKycModal = () => {
    this.setState({
      kycModal: !this.state.kycModal,
    })
  }

  toggleUploadModal = () => {
    this.setState({
      uploadModal: !this.state.uploadModal,
    })
  }

  toggleRiskProfileModal = () => {
    this.setState({
      riskProfileModal: !this.state.riskProfileModal,
    })
  }

  toggleFreezeModal = () => {
    this.setState({
      freezeModal: !this.state.freezeModal,
    })
  }

  onCustomerDetailsUpdate = () => {
    this.getCustomer()
    ToastUtils.toastSaveSuccess2()
  }

  onCustomerDetailsUpdateError = error => {
    ToastUtils.toastCustomError(error)
  }

  editTags = () => {
    this.setState({
      editTagsModal: true,
    })
  }

  toggleEditTags = () => {
    this.setState({
      editTagsModal: !this.state.editTagsModal,
    })
  }

  toggleReviewPepSanction = () => {
    this.setState({
      reviewPepSanctionModal: !this.state.reviewPepSanctionModal,
    })
  }

  changeTab = tabId => {
    this.setState({
      activeTab: tabId,
    })
  }

  toggleBankAccountModal = () => {
    this.setState({
      bankAccountModal: !this.state.bankAccountModal,
      selectedBankAccount: null,
    })
  }

  toggleCreateOrderModal = () => {
    if (!Utils.isOperationsPermission()) {
      ToastUtils.toastCustomError(
        "Missing required user permission to create customer orders",
      )
      return
    }

    this.setState({
      createOrderModal: !this.state.createOrderModal,
    })
  }

  toggleSellPofModal = () => {
    this.setState({
      sellPofModal: !this.state.sellPofModal,
      selectedBankAccount: null,
    })
  }

  toggleAdminFeeModal = () => {
    this.setState({
      adminFeeModal: !this.state.adminFeeModal,
    })
  }

  toggleYearlyLimitModal = () => {
    this.setState({
      yearlyLimitModal: !this.state.yearlyLimitModal,
    })
  }

  onYearlyLimitChange = () => {
    this.setState({
      yearlyLimitModal: false,
    })

    this.getYearlyLimit()
  }

  createUpdateIntercomUser = () => {
    BackOfficeApi.endpoints.createUpdateIntercomUser
      .post({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  offboardCustomer = () => {
    BackOfficeApi.endpoints.offboardCustomer
      .post({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getCustomer()
        } else {
          ToastUtils.toastExecuteError2()
        }
      })
  }

  reviewCustomer = () => {
    BackOfficeApi.endpoints.customerReview
      .post({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getReviewStatus()
        } else {
          ToastUtils.toastExecuteError2()
        }
      })
  }

  corporateApprove = () => {
    BackOfficeApi.endpoints.approveCorporate
      .post({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getReviewStatus()
        } else {
          ToastUtils.toastExecuteError2()
        }
      })
  }

  corporateReject = () => {
    BackOfficeApi.endpoints.rejectCorporate
      .post({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getReviewStatus()
        } else {
          ToastUtils.toastExecuteError2()
        }
      })
  }

  onReviewPepSanction = verification => {
    this.setState({
      pepSanctionVerification: verification,
    })
    this.toggleReviewPepSanction()

    BackOfficeApi.endpoints.customerPepSanction
      .getOne({ customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          pepSanctionData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  onReviewPepSanctionVerification = (approved, comment) => {
    this.toggleReviewPepSanction()

    let formData = new FormData()
    formData.append("approved", approved)
    if (comment && comment !== "") {
      formData.append("comment", comment)
    }
    BackOfficeApi.endpoints.customerReviewPepSanction
      .create(formData, { customerId: this.state.customerId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          this.getCustomer()
        } else {
          throw Error()
        }
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  removeTag = tag => {
    let formData = new FormData()
    formData.append("tag", tag.id)

    BackOfficeApi.endpoints.removeCustomerTag
      .create(formData, { customerId: this.state.customer.id })
      .then(response => {
        if (response.ok) {
          this.onTagsChanged()
        }
      })
  }

  addTag = tag => {
    let formData = new FormData()
    formData.append("tag", tag.id)

    BackOfficeApi.endpoints.addCustomerTag
      .create(formData, { customerId: this.state.customer.id })
      .then(response => {
        if (response.ok) {
          this.onTagsChanged()
        }
      })
  }

  onTagsChanged = () => {
    this.setState({
      editTagsModal: false,
    })
    this.getCustomer()
  }

  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col lg={9}>
            <Row>
              <Col>
                <span className="header-area">
                  Customer
                  <span> {"#" + this.state.customerId}</span>
                </span>
              </Col>
              <Col>
                <Nav tabs className="float-right">
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "1"}
                      onClick={() => this.changeTab("1")}
                    >
                      Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "2"}
                      onClick={() => this.changeTab("2")}
                    >
                      Documents
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "3"}
                      onClick={() => this.changeTab("3")}
                    >
                      Bank accounts
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "4"}
                      onClick={() => this.changeTab("4")}
                    >
                      Logins
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "5"}
                      onClick={() => this.changeTab("5")}
                    >
                      Yearly limit
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={this.state.activeTab === "6"}
                      onClick={() => this.changeTab("6")}
                    >
                      Recurring payments
                    </NavLink>
                  </NavItem>
                  {this.state.customer &&
                    this.state.customer.type === "Corporate" && (
                      <NavItem>
                        <NavLink
                          active={this.state.activeTab === "7"}
                          onClick={() => this.changeTab("7")}
                        >
                          Accesses
                        </NavLink>
                      </NavItem>
                    )}
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <CustomerInfoArea
                      customer={this.state.customer}
                      discounts={this.state.discounts}
                      tinkData={this.state.tinkData}
                      limits={this.state.limits}
                      channelLimits={this.state.channelLimits}
                      reviewStatus={this.state.reviewStatus}
                      sellPofs={this.state.sellPofs}
                      feeLevelData={this.state.feeLevelData}
                      wallets={this.state.wallets}
                      tickers={this.state.tickers}
                      isLoading={this.state.isLoading}
                      pocLimits={this.state.pocLimits}
                      onEditTagClick={this.editTags}
                      onReviewPepSanction={this.onReviewPepSanction}
                      onCustomerChange={this.onCustomerDetailsUpdate}
                      refreshDiscounts={this.getDiscounts}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <CustomerDocuments
                      images={this.state.images}
                      isLoading={this.state.isLoading}
                      reloadImages={this.getImages}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <CustomerBankAccounts
                      bankAccounts={this.state.bankAccounts}
                      images={this.state.images}
                      isLoading={this.state.isLoading}
                      onSetDefaultBankAccount={this.setDefaultBankAccount}
                      onDeleteBankAccount={this.deleteBankAccount}
                      onEditBankAccount={this.onEditBankAccount}
                      onHideBankAccount={this.hideBankAccount}
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <CustomerLogins
                      customer={this.state.customer}
                      workaround={this.state.activeTab === "4"}
                      isLoading={this.state.isLoading}
                    />
                  </TabPane>
                  <TabPane tabId="5">
                    <CustomerYearlyLimit
                      customer={this.state.customer}
                      yearlyLimit={this.state.yearlyLimit}
                      isLoading={this.state.isLoading}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <CustomerRecurringPayments
                      customer={this.state.customer}
                      recurringPayments={this.state.recurringPayments}
                      isLoading={this.state.isLoading}
                    />
                  </TabPane>
                  <TabPane tabId="7">
                    <CustomerAccesses
                      customerId={this.state.customer?.id}
                      isLoading={this.state.isLoading}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
          <Col lg={3}>
            <Col className="pl-0 pr-0">
              <CustomerActionsArea
                customer={this.state.customer}
                isLoading={this.state.isLoading}
                onActionPerformed={this.onActionPerformed}
              />
            </Col>
            <Col className="pl-0 pr-0">
              <CommentArea
                id={this.state.customerId}
                type="CUSTOMER"
                reloadTrigger={this.state.commentReloadTrigger}
              />
            </Col>
          </Col>
        </Row>
        <EditCustomerModal
          open={this.state.editModal}
          toggle={this.toggleEditModal}
          customer={this.state.customer}
          countries={this.state.countries}
          save={this.onCustomerDetailsUpdate}
          error={this.onCustomerDetailsUpdateError}
        />
        <CustomerKycModal
          open={this.state.kycModal}
          toggle={this.toggleKycModal}
          customer={this.state.customer}
        />
        <UploadModal
          open={this.state.uploadModal}
          toggle={this.toggleUploadModal}
          customer={this.state.customer}
          onUploadDone={this.uploadDone}
        />
        <BankAccountModal
          bankAccount={this.state.selectedBankAccount}
          banks={this.state.banks}
          images={this.state.images}
          open={this.state.bankAccountModal}
          toggle={this.toggleBankAccountModal}
          onAdd={this.addBankAccount}
          onEdit={this.editBankAccount}
        />
        <CreateOrderModal
          open={this.state.createOrderModal}
          toggle={this.toggleCreateOrderModal}
          customerBankAccounts={this.state.bankAccounts}
          customerId={this.state.customer?.id}
          onDone={this.onCreateOrderDone}
          customerWallets={this.state.wallets}
        />
        <YearlyLimitModal
          customer={this.state.customer}
          open={this.state.yearlyLimitModal}
          toggle={this.toggleYearlyLimitModal}
          onChange={this.onYearlyLimitChange}
        />
        <EditBadgesModal
          activeData={this.state.customer ? this.state.customer.tags : null}
          allData={this.state.customerTags}
          open={this.state.editTagsModal}
          toggle={this.toggleEditTags}
          onChange={this.editTagsOnChange}
          onRemove={this.removeTag}
          onAdd={this.addTag}
          name={"tags"}
        />
        <ReviewPepSanctionModal
          open={this.state.reviewPepSanctionModal}
          toggle={this.toggleReviewPepSanction}
          pepSanctionData={this.state.pepSanctionData}
          verification={this.state.pepSanctionVerification}
          customer={this.state.customer}
          onReview={this.onReviewPepSanctionVerification}
        />
        <FreezeModal
          open={this.state.freezeModal}
          toggle={this.toggleFreezeModal}
          customer={this.state.customer}
          save={this.onCustomerDetailsUpdate}
          error={this.onCustomerDetailsUpdateError}
        />
        <SellPofModal
          images={this.state.images}
          open={this.state.sellPofModal}
          toggle={this.toggleSellPofModal}
          onAdd={this.addSellPof}
        />
        <AdminFeeModal
          wallets={this.state.wallets}
          open={this.state.adminFeeModal}
          toggle={this.toggleAdminFeeModal}
          onExecute={this.onExecuteAdministrationFee}
        />
      </ContentWrapper>
    )
  }
}

export default Customer
