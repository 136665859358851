import React, { Fragment, useEffect, useState, useMemo } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CryptoSelector from "../../Common/CryptoSelector"

const safelloUrls = [
  { url: "safello://home" },
  { url: "safello://account" },
  { url: "safello://wallet" },
  { url: "safello://wallet/transactions" },
  { url: "safello://market" },
  {
    url: "safello://market/detail",
    paramName: "base",
  },
  { url: "safello://exchange" },
  {
    url: "safello://exchange/buy",
    paramName: "crypto",
  },
  { url: "safello://exchange/sell", paramName: "crypto" },
  { url: "safello://exchange/swap", paramName: "from" },
  { url: "safello://account" },
  { url: "safello://account/info" },
  { url: "safello://account/declare" },
  { url: "safello://account/settings" },
  { url: "safello://account/discount-codes" },
  { url: "safello://account/kyc" },
  { url: "safello://account/bank-accounts" },
  { url: "safello://account/withdrawal-addresses" },
  { url: "safello://account/saved-cards" },
]

const notificationTypeOptions = [
  { label: "Price change", value: "PRICE_CHANGE" },
  { label: "Display graph", value: "DISPLAY_GRAPH" },
  { label: "Order update", value: "ORDER_UPDATE" },
]

const timePeriodOptions = [
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Quarterly", value: "QUARTERLY" },
  { label: "Yearly", value: "YEARLY" },
  { label: "Max", value: "MAX" },
]

const cryptoOptions = [
  { label: "Bitcoin (BTC)", value: "BTC" },
  { label: "Ether (ETH)", value: "ETH" },
]

const Mobile = () => {
  const [form, setForm] = useState({
    svTitle: "",
    svBody: "",
    enTitle: "",
    enBody: "",
    redirectPath: "",
    redirectParamValue: "",
    url: "",
    userId: "",
    notificationType: "PRICE_CHANGE",
    graphCryptoCurrency: "",
    iconCryptoCurrency: "",
    timePeriod: "DAILY",
  })
  const [latestNotifications, setLatestNotifications] = useState(null)
  const [isSending, setSending] = useState(false)
  const [isPushTested, setPushTested] = useState(false)

  const [isMultiLanguage, setIsMultiLanguage] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    getLatestNotifications()
  }, [])

  // Reset isPushTested when the form changes
  useEffect(() => {
    setPushTested(false)
  }, [form])
  const getLatestNotifications = () => {
    BackOfficeApi.endpoints.getLatestNotifications
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        setLatestNotifications(data.splice(0))
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  const onInputChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const renderLatestNotifications = () => {
    let n = []

    if (latestNotifications) {
      latestNotifications.forEach((noti, index) => {
        n.push(
          <tr key={index}>
            <td>{Utils.getUtcInLocalDateTime(noti.sent)}</td>
            <td>{noti.title}</td>
            <td>{noti.body}</td>
            <td>{noti.sentToCount}</td>
          </tr>,
        )
      })
    }

    return n
  }

  const handleSend =
    (sendToEveryone = false) =>
    async e => {
      if (e) {
        e.preventDefault()
      }

      const approved = await Utils.showSweetAlertAreYouSure(
        "Are you sure you want to send this notification " +
          (sendToEveryone ? "to EVERYONE?" : "to User " + form.userId + "?"),
        !sendToEveryone && isMultiLanguage
          ? "Sending a multi-language notification to a single user should only be used for testing purposes. The user will receive a notification for each available translation."
          : "",
        null,
      )
      if (!approved) return
      setSending(true)

      const translations = [
        {
          title: form.svTitle,
          body: form.svBody,
          locale: "sv",
        },
      ]
      if (isMultiLanguage) {
        translations.push({
          title: form.enTitle,
          body: form.enBody,
          locale: "en",
        })
      }

      const data = {
        translations,
        notificationType: form.notificationType,
      }

      if (!sendToEveryone) {
        data.userId = form.userId
      }
      if (form.url) {
        data.url = form.url
      }
      if (form.iconCryptoCurrency) {
        data.cryptoCurrency = form.iconCryptoCurrency
      }
      if (form.notificationType === "DISPLAY_GRAPH") {
        data.cryptoCurrency = form.graphCryptoCurrency
        data.timePeriod = form.timePeriod
      }
      if (form.redirectPath) {
        data.redirectPath = form.redirectPath
        if (redirectParamName && form.redirectParamValue) {
          data.redirectPath += `?${redirectParamName}=${form.redirectParamValue}`
        }
      }

      try {
        const response =
          await BackOfficeApi.endpoints.sendMobilePushNotificationV2.postJson({
            body: data,
          })
        if (response?.ok) {
          setPushTested(true)
          ToastUtils.toastExecuteSuccess2()
          getLatestNotifications()
        } else {
          ToastUtils.toastExecuteError2()
        }
      } catch (e) {
        console.error(e)
        ToastUtils.toastExecuteError2()
      } finally {
        setSending(false)
      }
    }

  const redirectParamName = useMemo(
    () =>
      form.redirectPath
        ? safelloUrls.find(path => path.url === form.redirectPath)?.paramName
        : null,
    [form.redirectPath],
  )

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>Mobile</div>
      </div>
      <Row>
        <Col xl={4}>
          <Card>
            <CardHeader>Notification form</CardHeader>
            <CardBody>
              <Form onSubmit={handleSend()}>
                <FormGroup>
                  <Label for="notificationType">Notification type</Label>
                  <Input
                    required
                    type="select"
                    name="notificationType"
                    id="notificationType"
                    onChange={onInputChange}
                    value={form.notificationType}
                  >
                    {notificationTypeOptions.map(o => (
                      <option value={o.value} key={o.value}>
                        {o.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                {form.notificationType === "DISPLAY_GRAPH" && (
                  <Fragment>
                    <FormGroup>
                      <Label for="graphCryptoCurrency">Cryptocurrency</Label>
                      <Input
                        required
                        type="select"
                        name="graphCryptoCurrency"
                        id="graphCryptoCurrency"
                        onChange={onInputChange}
                        value={form.graphCryptoCurrency}
                      >
                        {cryptoOptions.map(o => (
                          <option value={o.value} key={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label for="timePeriod">Time period</Label>
                      <Input
                        required
                        type="select"
                        name="timePeriod"
                        id="timePeriod"
                        onChange={onInputChange}
                        value={form.timePeriod}
                      >
                        {timePeriodOptions.map(o => (
                          <option value={o.value} key={o.value}>
                            {o.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Fragment>
                )}
                <FormGroup>
                  <Label for="userId">UserId</Label>
                  <Input
                    required
                    type="number"
                    name="userId"
                    id="userId"
                    onChange={onInputChange}
                    value={form.userId}
                  />
                </FormGroup>

                <FormGroup>
                  <Label check style={{ marginLeft: 20 }}>
                    <Input
                      type="checkbox"
                      onChange={() => setIsMultiLanguage(false)}
                      checked={!isMultiLanguage}
                    />
                    Single language
                  </Label>
                  {!isMultiLanguage && (
                    <>
                      {/* For single language notifications, the notification can be set to "swedish", but will be sent to all users */}
                      <FormGroup>
                        <Label for="svTitle">Title</Label>
                        <Input
                          required
                          type="text"
                          name="svTitle"
                          id="svTitle"
                          onChange={onInputChange}
                          value={form.svTitle}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="svBody">Body</Label>
                        <Input
                          required
                          rows="4"
                          type="textarea"
                          name="svBody"
                          id="svBody"
                          onChange={onInputChange}
                          value={form.svBody}
                        />
                      </FormGroup>
                    </>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label check style={{ marginLeft: 20 }}>
                    <Input
                      type="checkbox"
                      onChange={() => setIsMultiLanguage(true)}
                      checked={isMultiLanguage}
                    />
                    Multi language
                  </Label>
                  {isMultiLanguage && (
                    <>
                      <FormGroup>
                        <Label for="svTitle">Swedish Title</Label>
                        <Input
                          required
                          type="text"
                          name="svTitle"
                          id="svTitle"
                          onChange={onInputChange}
                          value={form.svTitle}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="svBody">Swedish Body</Label>
                        <Input
                          required
                          rows="4"
                          type="textarea"
                          name="svBody"
                          id="svBody"
                          onChange={onInputChange}
                          value={form.svBody}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="svTitle">English Title</Label>
                        <Input
                          required
                          type="text"
                          name="enTitle"
                          id="enTitle"
                          onChange={onInputChange}
                          value={form.enTitle}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="enBody">English Body</Label>
                        <Input
                          required
                          rows="4"
                          type="textarea"
                          name="enBody"
                          id="enBody"
                          onChange={onInputChange}
                          value={form.enBody}
                        />
                      </FormGroup>
                    </>
                  )}
                </FormGroup>
                <Label>Redirect (optional)</Label>
                <FormGroup>
                  <select
                    value={form.redirectPath}
                    name="redirectPath"
                    id="redirectPath"
                    className={"custom-select"}
                    onChange={onInputChange}
                  >
                    {<option value={null}>{"-- None selected --"}</option>}
                    {safelloUrls.map((url, i) => (
                      <option value={url.url} key={"redirect-" + i}>
                        {url.url}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                {redirectParamName && (
                  <>
                    <FormGroup>
                      <Label for="url">
                        Redirect Crypto Currency (optional)
                      </Label>
                      <CryptoSelector
                        value={form.redirectParamValue}
                        name="redirectParamValue"
                        id="redirectParamValue"
                        onChange={newSelectedCrypto =>
                          setForm({
                            ...form,
                            redirectParamValue: newSelectedCrypto,
                          })
                        }
                        style={{ width: "100%" }}
                        nullValue="-- None selected --"
                      />
                    </FormGroup>
                  </>
                )}
                <FormGroup>
                  <Label for="url">URL (for GIFs) (optional)</Label>
                  <Input
                    type="text"
                    name="url"
                    id="url"
                    onChange={onInputChange}
                    value={form.url}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="url">
                    Crypto Currency (for Icon image) (optional)
                  </Label>
                  <CryptoSelector
                    value={form.iconCryptoCurrency}
                    name="iconCryptoCurrency"
                    id="iconCryptoCurrency"
                    onChange={newSelectedCrypto =>
                      setForm({
                        ...form,
                        iconCryptoCurrency: newSelectedCrypto,
                      })
                    }
                    style={{ width: "100%" }}
                    nullValue="-- None selected --"
                  />
                </FormGroup>
                <Button type="submit" className="mr-3" disabled={isSending}>
                  Send to user
                </Button>
                <Button
                  onClick={
                    !isPushTested
                      ? () =>
                          ToastUtils.toastCustomError(
                            "You need to test the notification first by sending it to a single user.",
                          )
                      : handleSend(true)
                  }
                  disabled={isSending}
                  style={!isPushTested ? { opacity: 0.5 } : {}}
                >
                  Send to everyone
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col xl={8}>
          <Card style={{ maxHeight: "400px" }}>
            <CardHeader>Latest notifications</CardHeader>
            <CardBody style={{ overflow: "auto" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Sent</th>
                    <th>Title</th>
                    <th>Body</th>
                    <th>Sent to count</th>
                  </tr>
                </thead>
                <tbody>{renderLatestNotifications()}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default Mobile
