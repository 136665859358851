import React from "react"
import { Table, Card } from "reactstrap"
import { Button } from "reactstrap"
import styled from "styled-components"
// utils
import { APP_COLORS } from "../../../Common/constants"
import BackOfficeApi from "../../../Api/BackOfficeApi"
import ToastUtils from "../../../Common/ToastUtils"
import Utils from "../../../Common/Utils"

const FlagTable = ({ featuresFlagData, setLoading, refetchFeatureFlags }) => {
  const onButtonPressed = async flag => {
    const isEnableAction = !flag.enabled

    const confirm = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: ${isEnableAction ? "ENABLE" : "DISABLE"} feature flag \n
       - Flag: ${flag.flag}
      `,
    )

    if (confirm) {
      setLoading(true)
      const response = await BackOfficeApi.endpoints.enableFeatureFlag.post(
        {
          flagKey: flag.flag,
        },
        {
          enable: isEnableAction,
        },
      )
      if (response && response.ok) {
        ToastUtils.toastExecuteSuccess2()
        refetchFeatureFlags()
      } else {
        ToastUtils.toastExecuteError2()
      }
      setLoading(false)
    }
  }

  return (
    <Card style={{ maxWidth: 600 }}>
      <div style={{ overflowX: "auto" }}>
        <Table>
          <tbody>
            <tr style={{ backgroundColor: "lightgray" }}>
              <td>
                <strong>Flag Name</strong>
              </td>
              <td>
                <strong>Enabled for all</strong>
              </td>
              <td>
                <strong>Enable/Disable</strong>
              </td>
            </tr>
            {featuresFlagData.map((item, index) => (
              <tr key={index}>
                <td key={index}>{item.flag}</td>
                <td>{item.enabled ? "Yes" : "No"}</td>
                <td key={`change-flag-state-button-${index}`}>
                  <StyledButton
                    $isEnabled={item.enabled}
                    onClick={() => onButtonPressed(item)}
                  >
                    {item.enabled ? "Disable" : "Enable"}
                  </StyledButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  )
}

export default FlagTable

const StyledButton = styled(Button)`
  padding: 4px;
  background-color: ${props =>
    props.$isEnabled ? APP_COLORS.warning : APP_COLORS.yellow};
  color: ${APP_COLORS.primary_text_color};
`
