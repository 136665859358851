/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useContext, useMemo } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { CardBody, Col, Row, Table } from "reactstrap"
import TurnoverChart from "./TurnoverChart"
import BackOfficeApi from "../../Api/BackOfficeApi"
import moment from "moment"
import ToastUtils from "../../Common/ToastUtils"
import HotWallet from "./HotWallet"
import Bitstamp from "./Bitstamp"
import GeneralActions from "./GeneralActions"
import LiquidityProvider from "./LiquidityProvider"
import InProcessTransactions from "./InProcessTransactions"
import BalanceSummary from "./BalanceSummary"
import WalletBalancesChart from "./WalletBalancesChart"
import useTreasury from "./useTreasury"
import TreasuryContext from "./TreasuryContext"
import Utils from "../../Common/Utils"
import { CryptoTickerContext } from "../../../App"
import { NOT_SUPPORTED_CRYPTO_HOT_WALLETS } from "../../Common/constants"

const Treasury = () => {
  const [state, setState] = useState({
    buy1: "NA",
    buy7: "NA",
    buy30: "NA",
    sell1: "NA",
    sell7: "NA",
    sell30: "NA",
    btc7: "NA",
  })

  const treasury = useTreasury()

  const tickers = useContext(CryptoTickerContext)?.cryptoTickerData
  const hotWalletTickers = useMemo(
    () =>
      tickers?.filter(
        ticker =>
          ticker.symbol !== "BTC" &&
          ticker.symbol !== "ETH" &&
          !NOT_SUPPORTED_CRYPTO_HOT_WALLETS.includes(ticker.symbol),
      ),
    [tickers],
  )

  useEffect(() => {
    updateAverages()
    window.scrollTo(0, 0)
  }, [])

  const updateAverages = () => {
    let targetDate = moment().format("YYYY-MM-DD")
    BackOfficeApi.endpoints.treasuryAverages
      .getAll({ targetDate: targetDate })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        setState({
          buy1: data.buy1 * data.rate,
          buy7: data.buy7 * data.rate,
          buy30: data.buy30 * data.rate,
          sell1: data.sell1 * data.rate,
          sell7: data.sell7 * data.rate,
          sell30: data.sell30 * data.rate,
          btc7: data.btc7,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  return (
    <TreasuryContext.Provider value={treasury}>
      <ContentWrapper>
        <div className="content-heading">
          <div>Treasury</div>
        </div>
        <Row>
          <WalletBalancesChart />
        </Row>
        <Row>
          <Col xl={12}>
            <BalanceSummary />
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Row>
              <Col xl={12}>
                <HotWallet crypto="BTC" />
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <LiquidityProvider />
              </Col>
            </Row>
          </Col>
          <Col xl={6}>
            <HotWallet crypto="ETH" />
            {hotWalletTickers?.map(ticker => (
              <HotWallet
                crypto={ticker.symbol}
                key={`${ticker.symbol}-hotwallet`}
              />
            ))}
            <Bitstamp />
            <GeneralActions />
            <InProcessTransactions />
            <div className="card">
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Average daily order amount, EUR</th>
                      <th bgcolor="#dff0d8">Buy</th>
                      <th bgcolor="#f2dede">Sell</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Last 7 days</td>
                      <td bgcolor="#dff0d8">
                        {Utils.formatCurrency(state.buy7 / 7)}
                      </td>
                      <td bgcolor="#f2dede">
                        {Utils.formatCurrency(state.sell7 / 7)}
                      </td>
                    </tr>
                    <tr>
                      <td>Last 30 days</td>
                      <td bgcolor="#dff0d8">
                        {Utils.formatCurrency(state.buy30 / 30)}
                      </td>
                      <td bgcolor="#f2dede">
                        {Utils.formatCurrency(state.sell30 / 30)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </div>
          </Col>
        </Row>
        <Row>
          <TurnoverChart />
        </Row>
      </ContentWrapper>
    </TreasuryContext.Provider>
  )
}

export default Treasury
