import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

const DeleteDiscountModal = ({
  onDismiss,
  open,
  customerId,
  deleteDiscountId,
  deleteDiscountName,
  onDeleteDiscount,
}) => {
  const _onDeleteDiscount = async () => {
    if (!deleteDiscountId || !customerId) {
      alert("Something went wrong. Try again")
      return
    }
    try {
      let formData = new FormData()
      formData.append("discountFeeId", deleteDiscountId)

      const res =
        await BackOfficeApi.endpoints.deactivateCustomerDiscount.create(
          formData,
          { customerId: customerId },
        )
      if (res.ok) {
        ToastUtils.toastExecuteSuccess2()
        onDeleteDiscount()
        onDismiss()
      } else throw new Error()
    } catch (e) {
      console.error(e)
      ToastUtils.toastAPIError3()
    }
  }

  return (
    <Modal isOpen={open} toggle={onDismiss} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onDismiss}>
        Delete discount. Are you sure?
      </ModalHeader>
      <ModalBody>
        <p>
          Customer: <span style={{ fontWeight: "bold" }}>{customerId}</span>
        </p>
        <p>
          Discount name:{" "}
          <span style={{ fontWeight: "bold" }}>{deleteDiscountName}</span>
        </p>
        <p>
          Discount ID:{" "}
          <span style={{ fontWeight: "bold" }}>{deleteDiscountId}</span>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={_onDeleteDiscount}>
          Delete
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteDiscountModal
