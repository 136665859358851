import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import { Container, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import { Link } from "react-router-dom"
import DataGridEmpty from "../Common/DataGridEmpty"
import BackOfficeApi from "../Api/BackOfficeApi"
import CustomDropdownItem from "../Common/CustomDropdownItem"
import Badge from "../Common/Badge"
import { APP_COLORS } from "../Common/constants"
import ToastUtils from "../Common/ToastUtils"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"

class Users extends Component {
  state = {
    sortStatus: "active",
    sortStatusValue: "Active",
    dropdownStatus: false,
    rows: [],
  }
  constructor(props, context) {
    super(props, context)

    this._columns = [
      {
        key: "id",
        name: "ID",
        width: 60,
        formatter: this.IdFormatter,
      },
      {
        key: "username",
        name: "Username",
      },
      {
        key: "name",
        name: "Name",
        formatter: this.NameFormatter,
      },
      {
        key: "email",
        name: "Email",
      },
      {
        key: "title",
        name: "Title",
        width: 150,
      },
      {
        key: "role",
        name: "Role",
        width: 150,
      },
      {
        key: "status",
        name: "Status",
        width: 80,
        formatter: this.StatusFormatter,
      },
      {
        key: "created",
        name: "Created",
        formatter: DataGridLocalDateTimeFormatter,
        width: 130,
      },
    ]

    this.rowOffsetHeight = 0
  }

  toggleStatus = () => {
    this.setState(prevState => ({
      dropdownStatus: !prevState.dropdownStatus,
    }))
  }

  changeSortStatus = (value, label) => {
    this.setState({
      sortStatus: value,
      sortStatusValue: label,
    })
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.sortStatus !== prevState.sortStatus) {
      this.loadData()
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    })

    this.loadData()
  }

  loadData = () => {
    this.setState({
      isLoading: true,
    })

    BackOfficeApi.endpoints.adminUsers
      .getAll({ status: this.state.sortStatus })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          rows: data,
          isLoading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  rowGetter = i => {
    const row = this.state.rows[i]
    if (row) {
      row.status = {
        active: row.active,
      }

      row.name = {
        firstName: row.firstName,
        lastName: row.lastName,
      }
    }

    return row
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Users</div>
          <div className="ml-auto form-inline">
            <Dropdown
              isOpen={this.state.dropdownStatus}
              toggle={this.toggleStatus}
            >
              <DropdownToggle color="primary" caret>
                Status: {this.state.sortStatusValue}
              </DropdownToggle>
              <DropdownMenu>
                <CustomDropdownItem
                  value="all"
                  onSelect={this.changeSortStatus}
                >
                  All
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="active"
                  onSelect={this.changeSortStatus}
                >
                  Active
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="inactive"
                  onSelect={this.changeSortStatus}
                >
                  Inactive
                </CustomDropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <Container fluid>
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={200}
            rowHeight={40}
          />
        </Container>
      </ContentWrapper>
    )
  }

  IdFormatter = props => {
    return <Link to={"/admin/users/" + props.value}>{props.value}</Link>
  }

  StatusFormatter = props => {
    const badges = []

    if (props.value.active) {
      badges.push(
        <Badge key="active" color={APP_COLORS.status_active}>
          Active
        </Badge>,
      )
    } else {
      badges.push(
        <Badge key="inactive" color={APP_COLORS.status_inactive}>
          Inactive
        </Badge>,
      )
    }

    return <div>{badges}</div>
  }

  NameFormatter = props => {
    return props.value.firstName + " " + props.value.lastName
  }
}

export default Users
