import React, { useContext } from "react"
// utils
import { useMemo } from "react"
import DropdownList from "./DropdownList"
import { CryptoTickerContext } from "../../App"

/*
 Different dropdown look than V1.
*/
const CryptoSelectorV2 = ({ onChange, selectedCrypto }) => {
  const { cryptoTickerData: tickers } = useContext(CryptoTickerContext)

  const dropDownData = useMemo(() => {
    const data = [{ value: "All", text: "All" }, { divider: true }]

    const tickersData = tickers.map(ticker => {
      return { value: ticker.symbol, text: ticker.symbol }
    })
    data.push(...tickersData)
    return data
  }, [tickers])

  return (
    <DropdownList
      className="pr-2"
      label="Crypto"
      buttonColor="primary"
      value={selectedCrypto}
      onSelectItem={({ value }) => onChange(value)}
    >
      {dropDownData}
    </DropdownList>
  )
}
export default CryptoSelectorV2
