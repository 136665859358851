import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import {
  Button,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import DataGridEmpty from "../Common/DataGridEmpty"
import ReactDataGrid from "react-data-grid"
import BackOfficeApi from "../Api/BackOfficeApi"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"
import { Link } from "react-router-dom"
import CustomDropdownItem from "../Common/CustomDropdownItem"
import SearchBar from "../Common/SearchBar"
import ToastUtils from "../Common/ToastUtils"
import PaymentNotificationRefundModal from "./PaymentNotificationRefundModal"
import Utils from "../Common/Utils"

class PaymentNotifications extends Component {
  state = {
    isLoading: false,
    rows: [],
    status: "unmatched",
    statusValue: "Unmatched",
    searchText: "",
  }
  constructor(props) {
    super(props)

    this.fetchSize = 30

    this._columns = [
      {
        key: "id",
        name: "Id",
        width: 100,
        formatter: this.IdFormatter,
      },
      {
        key: "actions",
        name: "Actions",
        width: 80,
        formatter: this.ActionsFormatter,
      },
      {
        key: "orderId",
        name: "Order ref",
        width: 100,
      },
      {
        key: "payerName",
        name: "Name",
      },
      {
        key: "infoText",
        name: "Infotext",
      },
      {
        key: "amount",
        name: "Amount",
        width: 100,
      },
      {
        key: "currency",
        name: "Currency",
        width: 60,
      },
      {
        key: "channel",
        name: "Channel",
        width: 200,
      },
      {
        key: "reference",
        name: "Reference",
      },
      {
        key: "recieverBg",
        name: "Reciever BG",
      },
      {
        key: "timestamp",
        name: "Timestamp",
        width: 120,

        formatter: DataGridLocalDateTimeFormatter,
      },
      {
        key: "delete",
        name: "Delete",
        width: 80,
        formatter: this.DeleteFormatter,
      },
    ]

    this.searching = false
    this.noMoreData = false
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.status !== prevState.status) {
      this.loadData()
    }
  }

  loadData = (first = 0, append = false) => {
    this.setState({
      isLoading: true,
    })

    if (this.searching) {
      this.onSearch(first, append)
      return
    }

    BackOfficeApi.endpoints.listPaymentNotifications
      .getAll({
        status: this.state.status,
        first: first,
        limit: this.fetchSize,
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.addData(data, append)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  addData = (data, append) => {
    if (append) {
      if (data.length < this.fetchSize) {
        this.noMoreData = true
      }
      this.setState({
        originalRows: data,
        rows: this.state.rows.concat(data.slice(0)),
        isLoading: false,
      })
    } else {
      this.setState({
        originalRows: data,
        rows: data.slice(0),
        isLoading: false,
      })
      this.noMoreData = false
    }
  }

  onSearchChange = e => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout)
    }

    this.setState({
      searchText: e.target.value,
      isLoading: true,
      searchTimeout: setTimeout(() => {
        this.onSearch(0, false)
      }, 500),
    })
  }

  onSearch = (first = 0, append = false) => {
    if (this.state.searchText.length > 0) {
      this.searching = true

      BackOfficeApi.endpoints.searchPaymentNotifications
        .getAll({
          search: this.state.searchText,
          first: first,
          limit: this.fetchSize,
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
    } else {
      this.searching = false
      this.loadData()
    }
  }

  rowGetter = i => {
    let row = this.state.rows[i]
    if (row) {
      row.actions = {
        id: row.id,
        canRefund: !(row.orderId || row.refundedTimestamp),
      }
      row.delete = {
        id: row.id,
        isDeleted: row.deleted,
      }
    }

    return row
  }

  toggleStatus = () => {
    this.setState(prevState => ({
      dropdownStatus: !prevState.dropdownStatus,
    }))
  }

  changeStatus = (value, label) => {
    this.setState({
      status: value,
      statusValue: label,
      searchText: "",
    })

    this.searching = false
  }

  onScroll = e => {
    let halfWayVScroll = this.state.rows.length * this.rowOffsetHeight - 700
    let currentVScroll = e.scrollTop

    if (
      currentVScroll >= halfWayVScroll &&
      !this.state.isLoading &&
      !this.noMoreData
    ) {
      if (this.state.rows.length < 1000) {
        this.loadData(this.state.rows.length, true)
      }
    }
  }

  openRefundModal = pnId => {
    this.setState({
      refundModal: true,
      refundPnId: pnId,
    })
  }

  toggleRefundModal = () => {
    this.setState({
      refundModal: !this.state.refundModal,
    })
  }

  onRefund = (reason, date, adminFee) => {
    let formData = new FormData()
    formData.append("reason", reason)
    formData.append("refundDate", date)
    formData.append("adminFee", adminFee)

    BackOfficeApi.endpoints.refundPaymentNotification
      .create(formData, { id: this.state.refundPnId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }

        return new Error()
      })
      .then(json => {
        let refundReference = json.refundReference
        Utils.showSweetAlertOk(
          "Refund reference: " + refundReference,
          "Use the reference above when refunding in bank, if the refund is through the bank",
        ).then(() => {
          this.loadData()
        })
      })
      .catch(() => {
        ToastUtils.toastSaveError2()
        this.setState({ isLoading: false })
      })
  }

  onDeletePn = async pnId => {
    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: Delete payment notification with id "${pnId}"\n\n\n\nNote: If you need to restore deleted payment notification later, please contact a developer.`,
    )
    if (!confirmed) {
      return
    }

    const response =
      await BackOfficeApi.endpoints.deletePaymentNotification.post({
        id: pnId,
      })
    if (response?.ok) {
      ToastUtils.toastExecuteSuccess2()
      this.loadData()
    } else {
      try {
        const json = await response.json()
        if (json.error) {
          ToastUtils.toastCustomError(json.error)
        } else {
          throw new Error(response)
        }
      } catch (e) {
        console.error(e)
        ToastUtils.toastExecuteError2()
      }
    }
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Payment notifications</div>
          <div className="ml-auto position-relative">
            <SearchBar
              value={this.state.searchText}
              onChange={this.onSearchChange}
            >
              Search
            </SearchBar>
            <div
              className="ball-beat loader-position"
              hidden={!this.state.isLoading}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="ml-auto form-inline">
            <Dropdown
              className="pr-2"
              isOpen={this.state.dropdownStatus}
              toggle={this.toggleStatus}
            >
              <DropdownToggle color="secondary" caret>
                Status: {this.state.statusValue}
              </DropdownToggle>
              <DropdownMenu>
                <CustomDropdownItem value="all" onSelect={this.changeStatus}>
                  All
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="unmatched"
                  onSelect={this.changeStatus}
                >
                  Unmatched
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="matched"
                  onSelect={this.changeStatus}
                >
                  Matched
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="refunded"
                  onSelect={this.changeStatus}
                >
                  Refunded
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="deleted"
                  onSelect={this.changeStatus}
                >
                  Deleted
                </CustomDropdownItem>
                <CustomDropdownItem value="legacy" onSelect={this.changeStatus}>
                  Legacy
                </CustomDropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <Container fluid>
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={150}
            ref={element => {
              if (element !== null) {
                const base = element.base
                base.onScroll = this.onScroll
                this.rowOffsetHeight = element.getRowOffsetHeight()
              }
            }}
          />
        </Container>
        <PaymentNotificationRefundModal
          open={this.state.refundModal}
          toggle={this.toggleRefundModal}
          onRefund={this.onRefund}
        />
      </ContentWrapper>
    )
  }

  IdFormatter = props => {
    return (
      <Link to={"/transactions/paymentnotifications/" + props.value}>
        {props.value}
      </Link>
    )
  }

  ActionsFormatter = props => {
    if (props.value.canRefund) {
      return (
        <Container>
          <Row className="justify-content-center align-items-center">
            <Button
              size="sm"
              color="warning"
              className="pr-2 pl-2"
              onClick={() => this.openRefundModal(props.value.id)}
            >
              Refund
            </Button>
          </Row>
        </Container>
      )
    }
    return ""
  }
  DeleteFormatter = props => {
    return (
      <Container>
        <Row className="justify-content-center align-items-center">
          {!props.value.isDeleted ? (
            <Button
              size="sm"
              color="warning"
              className="pr-2 pl-2"
              onClick={() => this.onDeletePn(props.value.id)}
            >
              Delete
            </Button>
          ) : (
            <Button size="sm" className="pr-2 pl-2" disabled>
              Deleted
            </Button>
          )}
        </Row>
      </Container>
    )
  }
}
export default PaymentNotifications
