import React, { Component } from "react"
import { CardBody, CardHeader, Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import BackOfficeApi from "../Api/BackOfficeApi"
import Utils from "../Common/Utils"

class WalletSection extends Component {
  state = {
    isLoading: true,
    customerWalletBalance: null,
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.getCustomerWalletBalance()
    }
  }

  getCustomerWalletBalance() {
    BackOfficeApi.endpoints.getCustomerWalletBalance
      .getOne({ id: this.props.user })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          customerWalletBalance: data,
          isLoading: false,
        })
      })
  }

  getWalletsTotalBalances() {
    let balances = new Map()
    if (this.props.wallets) {
      this.props.wallets.forEach(w => {
        const arr = Object.entries(w.balances)
        arr.forEach(b => {
          if (!balances.has(b[0])) {
            balances.set(b[0], 0)
          }
          balances.set(b[0], balances.get(b[0]) + b[1])
        })
      })
    }
    return balances
  }

  renderTableHead(balances) {
    let ths = []
    ths.push(<th>Wallet</th>)

    balances.forEach((v, k) => {
      ths.push(<th>{k}</th>)
    })

    return ths
  }

  renderTableRows(wallets, totalBalances, totalWalletBalances) {
    let rows = []

    if (wallets) {
      //Row for each wallet balance
      wallets.forEach(w => {
        let balances = Object.entries(w.balances)
        let tds = []
        tds.push(
          <td>
            {w.id} ({w.name ? w.name : "unnamed"})
          </td>,
        )

        totalBalances.forEach((v, k) => {
          let b = balances.find(b => {
            return b[0] === k
          })
          if (b && this.props.tickers) {
            let t = this.props.tickers.find(ticker => {
              return ticker.cryptoCurrency.toUpperCase() === k
            })

            let fiatValue = b[1] * t.baseBid
            tds.push(
              <td>
                {Utils.formatCrypto(b[1])} ({Utils.formatNumber(fiatValue, 0)}{" "}
                SEK)
              </td>,
            )
          } else {
            tds.push(<td></td>)
          }
        })

        rows.push(<tr>{tds}</tr>)
      })
    }
    if (
      totalWalletBalances &&
      totalWalletBalances.cryptoBalances &&
      wallets &&
      wallets.length > 1
    ) {
      let totalTds = []
      totalTds.push(<td>Total</td>)
      totalTds.push(
        totalWalletBalances.cryptoBalances.map((balance, i) => (
          <td key={`crypto-balance-${i}`}>
            {Utils.formatCrypto(balance.amount)} (
            {Utils.formatNumber(balance.fiatAmount, 0)} SEK)
          </td>
        )),
      )
      rows.push(<tr>{totalTds}</tr>)
    }
    return rows
  }

  render() {
    let totalBalances = this.getWalletsTotalBalances()
    const balanceJson = this.state.customerWalletBalance

    return (
      <div>
        <CardHeader className="card-section-header">Wallet</CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col xl={12}>
              <div style={{ overflow: "auto" }}>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>{this.renderTableHead(totalBalances)}</tr>
                  </thead>

                  <tbody>
                    {this.renderTableRows(
                      this.props.wallets,
                      totalBalances,
                      balanceJson,
                    )}
                  </tbody>
                </table>
              </div>
              <br />
              {this.state.isLoading ? (
                <div>Loading...</div>
              ) : (
                <div>
                  {balanceJson ? (
                    <div>
                      <div className="font-weight-bold">
                        TOTAL VALUE:{" "}
                        <b className="details-header">
                          {Utils.formatNumber(balanceJson.totalAmount, 0)} SEK
                        </b>
                      </div>
                    </div>
                  ) : (
                    <span>no balance</span>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <Link
                className="btn btn-primary btn-sm safello-font mr-3"
                to={"/orders/all?customer=" + this.props.user}
              >
                View all transactions
              </Link>
              <Link
                className="btn btn-primary btn-sm safello-font mr-3"
                to={"/wallet/incoming?customer=" + this.props.user}
              >
                View all deposits
              </Link>
              <Link
                className="btn btn-primary btn-sm safello-font mr-3"
                to={"/wallet/outgoing?customer=" + this.props.user}
              >
                View all withdrawals
              </Link>
              <Link
                className="btn btn-primary btn-sm safello-font"
                to={"/wallet/swaps?customer=" + this.props.user}
              >
                View all swaps
              </Link>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
}

export default WalletSection
