import React, { Component } from "react"
import { Button, Card, Col, Row, Table } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ValidationImage from "../Common/ValidationImage"
import Utils from "../Common/Utils"

class CustomerBankAccounts extends Component {
  setDefaultBankAccount = bankAccount => {
    Utils.showSweetAlertYesCancel(
      "Set default to this bank account for customer?",
    ).then(confirmed => {
      if (confirmed) {
        if (this.props.onSetDefaultBankAccount) {
          this.props.onSetDefaultBankAccount(bankAccount)
        }
      }
    })
  }

  deleteBankAccount = bankAccount => {
    Utils.showSweetAlertYesCancel(
      "Are you sure you want to delete this bank account?",
    ).then(confirmed => {
      if (confirmed) {
        if (this.props.onDeleteBankAccount) {
          this.props.onDeleteBankAccount(bankAccount)
        }
      }
    })
  }

  hideBankAccount = bankAccount => {
    Utils.showSweetAlertYesCancel(
      "Are you sure you want to hide this bank account?",
    ).then(confirmed => {
      if (confirmed) {
        if (this.props.onHideBankAccount) {
          this.props.onHideBankAccount(bankAccount)
        }
      }
    })
  }

  editBankAccount = bankAccount => {
    if (this.props.onEditBankAccount) {
      this.props.onEditBankAccount(bankAccount)
    }
  }

  renderBankAccounts = () => {
    if (!this.props.bankAccounts) {
      return <tr />
    }

    return this.props.bankAccounts.map(b => {
      return (
        <tr key={b.id} style={{ backgroundColor: b.selected ? "#cdffdc" : "" }}>
          <td>
            <div className="d-flex justify-content-between">
              <Button
                title="Select default"
                color="info"
                size="sm"
                className="mr-1 fa icon-star"
                disabled={b.selected || !b.verified}
                onClick={() => {
                  this.setDefaultBankAccount(b)
                }}
              />
              <Button
                title="Edit"
                color="primary"
                size="sm"
                className="mr-1 fa icon-pencil"
                onClick={() => {
                  this.editBankAccount(b)
                }}
              />
              <Button
                title="Hide"
                color="warning"
                size="sm"
                className="mr-1 fa icon-mustache"
                onClick={() => {
                  this.hideBankAccount(b)
                }}
              />
              <Button
                title="Delete"
                color="danger"
                size="sm"
                className="mr-1 fa icon-trash"
                disabled={b.selected}
                onClick={() => {
                  this.deleteBankAccount(b)
                }}
              />
            </div>
          </td>
          <td>{b.id}</td>
          <td>{b.routingAddress}</td>
          <td>{b.accountNumber}</td>
          <td>{b.bank}</td>
          <td>{b.name}</td>
          <td>{b.currency}</td>
          <td>{this.getBankType(b.bankAccountType)}</td>
          <td>
            <ValidationImage valid={b.verified} />
          </td>
          <td>{b.apiAdded ? "Yes" : "No"}</td>
          <td>{Utils.getUtcInLocalDateTime(b.timestamp)}</td>
          <td>
            <a
              href={BackOfficeApi.url + "/image?id=" + b.imageId}
              target="_blank" rel="noreferrer"
            >
              {b.imageId}
            </a>
          </td>
        </tr>
      )
    })
  }

  getBankType = bankType => {
    if (bankType != null) {
      if (bankType === 0) {
        return "Foreign"
      } else if (bankType === 2) {
        return "Swedish"
      }
    }
    return ""
  }

  render() {
    return (
      <div>
        <Card
          className="card-area"
          style={{ minHeight: "300px", marginTop: "0" }}
        >
          <Row>
            <Col className="m-3">
              <Table striped bordered hover responsive={true}>
                <thead>
                  <tr>
                    <th>Actions</th>
                    <th>ID</th>
                    <th>Routing</th>
                    <th>Account No</th>
                    <th>Bank</th>
                    <th>Bank Account name</th>
                    <th>Currency</th>
                    <th>Type</th>
                    <th>Verified</th>
                    <th>Api Added</th>
                    <th>Timestamp</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>{this.renderBankAccounts()}</tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </div>
    )
  }
}
export default CustomerBankAccounts
