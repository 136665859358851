import React, { useEffect, useState, useMemo } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row, Col } from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import TransactionInfoArea from "./TransactionInfoArea"
import { swapTransactionToWalletTransaction } from "../../../utils/format"
import CommentArea from "../../Common/CommentArea"

const TransactionDetailSwap = ({ location }) => {
  const [isLoading, setLoading] = useState(false)
  const [transaction, setTransaction] = useState(null)

  useEffect(() => {
    getTransaction()
  }, [])

  const transactionId = Utils.getLastUrlSegment(location)

  const getTransaction = () => {
    setLoading(true)
    BackOfficeApi.endpoints.walletSwapTransaction
      .getOne({ id: transactionId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        setLoading(false)
        setTransaction(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setLoading(false)
      })
  }

  const formattedSwapTransaction = useMemo(() => {
    if (transaction) {
      return swapTransactionToWalletTransaction(transaction)
    }
  }, [transaction])

  return (
    <ContentWrapper>
      <Row>
        <Col lg={8}>
          <TransactionInfoArea
            transaction={formattedSwapTransaction}
            type={"SWAP"}
            isLoading={isLoading}
            getTransaction={getTransaction}
          />
        </Col>
        <Col lg={4}>
          <CommentArea
            id={transactionId}
            type="SWAP_ORDER"
            reloadTrigger={null}
          />
        </Col>
      </Row>
    </ContentWrapper>
  )
}

export default TransactionDetailSwap
