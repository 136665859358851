import React, { Component } from "react"
import { Card, Col, Row } from "reactstrap"
import Utils from "../Common/Utils"

class CustomerRecurringPayments extends Component {
  render() {
    const data = this.props.recurringPayments
    return (
      <Card
        className="card-area"
        style={{ minHeight: "300px", marginTop: "0" }}
      >
        <Row className="m-4">
          <Col xl={6}>
            <h3>Reccuring Payments</h3>
            {data && data.length > 0 ? (
              data.map(recurring => (
                <div key={recurring.id}>
                  <div className="card-section-divider" />
                  <div className="customer-recurring-container">
                    <div className="details-row">
                      <span className="details-header">Recurring ID:</span>
                      <span className="details-value">{recurring.id}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Account number:</span>
                      <span className="details-value">
                        {recurring.accountNumber}
                      </span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Amount:</span>
                      <span className="details-value">{recurring.amount}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Created:</span>
                      <span className="details-value">
                        {Utils.getUtcInLocalDateTime(recurring.created)}
                      </span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Crypto:</span>
                      <span className="details-value">
                        {recurring.cryptoCurrency}
                      </span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Fiat currency:</span>
                      <span className="details-value">
                        {recurring.currency}
                      </span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Day of execution:</span>
                      <span className="details-value">
                        {recurring.dayOfExecution}
                      </span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Frequency:</span>
                      <span className="details-value">
                        {recurring.frequency}
                      </span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">
                        Remittance information:
                      </span>
                      <span className="details-value">
                        {recurring.remittanceInformation}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h5>No recurring payments found</h5>
            )}
            <div className="card-section-divider" />
          </Col>
        </Row>
      </Card>
    )
  }
}
export default CustomerRecurringPayments
