import React, { useState, useEffect } from "react"
import { CardBody, CardHeader, Col, Row } from "reactstrap"
import ValidationImage from "../Common/ValidationImage"
import { Link } from "react-router-dom"
import RiskProfileBadge from "../Common/RiskProfileBadge"
import ProfileImage from "../Customers/ProfileImage"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import moment from "moment"

const isNullOrUndefined = value => value === null || value === undefined

const CustomerSection = ({
  customerId,
  identityVerified,
  eidVerified,
  bankAccountVerified,
}) => {
  const [customer, setCustomer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [viewProtected, setViewProtected] = useState(false)

  const getCustomer = () => {
    setIsLoading(true)

    BackOfficeApi.endpoints.customerDetails
      .getOne({ id: customerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else if (response.status === 401) {
          ToastUtils.toastUnauthorized()
          setIsLoading(false)
          setViewProtected(true)
        } else {
          throw new Error()
        }
      })
      .then(data => {
        setIsLoading(false)
        setCustomer(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setIsLoading(false)
      })
  }

  const renderTags = customerTags => {
    return customerTags.map(tag => (
      <div
        className="tag-badge"
        key={tag.id}
        style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
      >
        {tag.name}
      </div>
    ))
  }
  useEffect(() => {
    if (customerId) {
      getCustomer()
    }
  }, [customerId])

  if (!customer || isLoading) {
    return <div className="whirl" style={{ minHeight: "230px" }} />
  }

  if (viewProtected) {
    return (
      <div>
        <h1 className="ml-3">Protected</h1>
      </div>
    )
  }

  const customerName = customer?.companyName
    ? customer?.companyName
    : `${customer?.firstName} ${customer?.lastName}`

  return (
    <div>
      <CardHeader className="card-section-header">Customer</CardHeader>
      <CardBody className="pt-0">
        <Row className="no-gutters">
          <Col lg={8}>
            <Row className="no-gutters">
              <Col lg={2} className="pr-3">
                <ProfileImage className="img-fluid" customerId={customer.id} />
              </Col>
              <Col lg={10}>
                <div>
                  <div className="customer-name">
                    <Link to={"/customers/" + customer.id}>{customerName}</Link>
                  </div>
                  <p className="details-value details-row">{customer.email}</p>
                  <p className="details-value details-row">
                    {customer.phoneNumber}
                  </p>
                  <p className="details-value details-row">
                    Age: {moment().diff(moment(customer.dateOfBirth), "years")}
                  </p>
                  <div className="details-row">
                    <span
                      className="details-header"
                      style={{ display: "inline-block" }}
                    >
                      Risk profile:
                    </span>
                    <RiskProfileBadge customer={customer} />
                  </div>
                  <div className="details-row">
                    <span
                      className="details-header"
                      style={{ display: "inline-block" }}
                    >
                      Status:
                    </span>
                    <div
                      className="customer-risk-profile-container"
                      style={{
                        display: "inline-block",
                        backgroundColor: customer.frozen
                          ? "#e78435"
                          : "#fbc94c",
                      }}
                    >
                      <span className="customer-risk-profile-text">
                        {customer.frozen ? "Frozen" : "Active"}
                      </span>
                    </div>
                  </div>
                  <div className="details-row">
                    <span
                      className="details-header mb-2"
                      style={{ display: "block" }}
                    >
                      <u>Tags</u>
                    </span>
                    {renderTags(customer.tags)}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <p className="details-header details-row">Verifications</p>
            <div className="details-row">
              <ValidationImage
                style={{ float: "left", marginRight: "10px" }}
                valid={identityVerified}
              />
              <p className="details-value">Identity</p>
            </div>
            <div className="details-row">
              <ValidationImage
                style={{ float: "left", marginRight: "10px" }}
                valid={eidVerified}
              />
              <p className="details-value">eID</p>
            </div>
            {!isNullOrUndefined(bankAccountVerified) && (
              <div className="details-row">
                <ValidationImage
                  style={{ float: "left", marginRight: "10px" }}
                  valid={bankAccountVerified}
                />
                <p className="details-value">Bank account</p>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </div>
  )
}

export default CustomerSection
