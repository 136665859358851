import React, { Component } from "react"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"
import Utils from "../Common/Utils"

class CustomerPocLimitsSection extends Component {
  render() {
    const pocLimits =
      this.props.pocLimits &&
      Object.keys(this.props.pocLimits).map(key => {
        return {
          crypto: key,
          amount: this.props.pocLimits[key],
        }
      })
    if (pocLimits)
      pocLimits.sort((a, b) => {
        // Sort the array with the following order
        const order = ["BTC", "ETH", "USDC"]
        const aOrder = order.indexOf(a.crypto)
        const bOrder = order.indexOf(b.crypto)

        if (aOrder !== -1 && bOrder !== -1) {
          return aOrder - bOrder
        } else if (aOrder !== -1) {
          return -1
        } else if (bOrder !== -1) {
          return 1
        } else {
          return 0
        }
      })
    return (
      <Card>
        <CardHeader className="card-section-header">Sell limits</CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col>
              <Table striped bordered hover responsive={true}>
                <thead>
                  <tr>
                    {pocLimits &&
                      pocLimits.map(limit => (
                        <th key={`poc-limits-header-${limit.crypto}`}>
                          {limit.crypto}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {pocLimits &&
                      pocLimits.map(limit => (
                        <td key={`poc-limits-value-${limit.crypto}`}>
                          {Utils.formatCrypto(limit.amount)}
                        </td>
                      ))}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}
export default CustomerPocLimitsSection
