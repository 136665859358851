import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import {
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"
import DataGridEmpty from "../Common/DataGridEmpty"
import ReactDataGrid from "react-data-grid"
import BackOfficeApi from "../Api/BackOfficeApi"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"
import Utils from "../Common/Utils"
import moment from "moment"
import ToastUtils from "../Common/ToastUtils"

class MatchUnmatched extends Component {
  state = {
    id: Utils.getLastUrlSegment(this.props.location),
    rows: [],
    paymentNotification: null,
    matchModal: false,
  }
  constructor(props) {
    super(props)

    this._columns = [
      {
        key: "id",
        name: "Order ID",
        width: 100,
      },
      {
        key: "orderId",
        name: "ID",
        width: 100,
      },
      {
        key: "rating",
        name: "Rating",
        width: 50,
      },
      {
        key: "customerName",
        name: "Name",
      },
      {
        key: "amount",
        name: "Amount",
        width: 100,
      },
      {
        key: "currency",
        name: "Currency",
        width: 75,
      },
      {
        key: "channel",
        name: "Channel",
        width: 200,
      },
      {
        key: "reference",
        name: "Reference",
        width: 200,
      },
      {
        key: "completed",
        name: "Completed",
        width: 75,
      },
      {
        key: "issued",
        name: "Issued",
        formatter: DataGridLocalDateTimeFormatter,
      },
      {
        key: "action",
        name: "Action",
        formatter: this.ActionsFormatter,
        width: 100,
      },
    ]
  }

  componentDidMount() {
    this.loadPaymentNotification()
    this.loadMatchingOrders()
  }

  loadPaymentNotification = () => {
    BackOfficeApi.endpoints.paymentNotification
      .getOne({ id: this.state.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          paymentNotification: data,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
      })
  }

  loadMatchingOrders = () => {
    BackOfficeApi.endpoints.matchingOrders
      .getOne({ id: this.state.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          originalRows: data,
          rows: data.slice(0),
          isLoading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
      })
  }

  toggleMatchModal = () => {
    this.setState({
      matchModal: !this.state.matchModal,
    })
  }

  openMatchModal = row => {
    this.setState({
      matchModal: true,
      matchOrder: this.state.rows[row],
    })
  }

  connectPnToOrder = () => {
    this.toggleMatchModal()

    let formData = new FormData()
    formData.append("pnId", this.state.paymentNotification.id)
    formData.append("orderId", this.state.matchOrder.id)

    BackOfficeApi.endpoints.connectPnToOrder
      .create(formData)
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          let redirect = "/orders/buy/" + this.state.matchOrder.id
          return (window.location.href = redirect)
        }
        throw Error()
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
      })
  }

  rowGetter = i => {
    let row = this.state.rows[i]
    if (row) {
      row.action = i
    }

    return row
  }

  renderPaymentNotification() {
    if (!this.state.paymentNotification) {
      return ""
    }
    return (
      <table width="100%" className="table table-striped">
        <thead>
          <th>Name</th>
          <th>Infotext</th>
          <th>Amount</th>
          <th>Currency</th>
          <th>Channel</th>
          <th>Reference</th>
          <th>Timestamp</th>
        </thead>
        <tbody>
          <tr>
            <td>{this.state.paymentNotification.payerName}</td>
            <td>{this.state.paymentNotification.infoText}</td>
            <td>{this.state.paymentNotification.amount}</td>
            <td>{this.state.paymentNotification.currency}</td>
            <td>{this.state.paymentNotification.channel}</td>
            <td>{this.state.paymentNotification.reference}</td>
            <td>
              {moment
                .utc(this.state.paymentNotification.timestamp)
                .local()
                .format("YYYY-MM-DD HH:mm")}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  render() {
    const isDeleted = this.state.paymentNotification?.deleted
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>
            {isDeleted && <p style={{ color: "red" }}>DELETED</p>}
            Matching payment notification: {this.state.id}{" "}
          </div>
        </div>
        {this.renderPaymentNotification()}
        <Container fluid className="mt-4">
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={150}
          />
        </Container>
        <Modal
          style={{ maxWidth: "600px" }}
          isOpen={this.state.matchModal}
          toggle={this.toggleMatchModal}
        >
          <ModalHeader toggle={this.toggleMatchModal}>
            Connect PN: {this.state.id} with Order:{" "}
            {this.state.matchOrder ? this.state.matchOrder.id : 0}
          </ModalHeader>
          <ModalBody>
            {this.state.matchOrder && (
              <table width="100%" className="table table-striped">
                <thead>
                  <th></th>
                  <th>PN</th>
                  <th>Order</th>
                </thead>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{this.state.paymentNotification.payerName}</td>
                    <td>{this.state.matchOrder.customerName}</td>
                  </tr>
                  <tr>
                    <th>Amount</th>
                    <td>
                      {this.state.paymentNotification.amount}{" "}
                      {this.state.paymentNotification.currency}
                    </td>
                    <td>
                      {this.state.matchOrder.amount}{" "}
                      {this.state.matchOrder.currency}
                    </td>
                  </tr>
                  <tr>
                    <th>Channel</th>
                    <td>{this.state.paymentNotification.channel}</td>
                    <td>{this.state.matchOrder.channel}</td>
                  </tr>
                  <tr>
                    <th>Infotext</th>
                    <td>{this.state.paymentNotification.infoText}</td>
                    <td>{this.state.matchOrder.orderId}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td></td>
                    <td>{this.state.matchOrder.customerAddress}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td></td>
                    <td>
                      {this.state.matchOrder.customerCity} ,{" "}
                      {this.state.matchOrder.customerZip}
                    </td>
                  </tr>
                  <tr>
                    <th>Paid/Issued</th>
                    <td>
                      {moment
                        .utc(this.state.paymentNotification.timestamp)
                        .local()
                        .format("YYYY-MM-DD HH:mm")}
                    </td>
                    <td>
                      {moment
                        .utc(this.state.matchOrder.issued)
                        .local()
                        .format("YYYY-MM-DD HH:mm")}
                    </td>
                  </tr>
                  <tr>
                    <th>Reference</th>
                    <td>{this.state.paymentNotification.reference}</td>
                    <td>{this.state.matchOrder.reference}</td>
                  </tr>
                  <tr>
                    <th>E-mail</th>
                    <td></td>
                    <td>{this.state.matchOrder.customerEmail}</td>
                  </tr>
                  <tr>
                    <th>Telephone</th>
                    <td></td>
                    <td>{this.state.matchOrder.customerTelephone}</td>
                  </tr>
                  <tr>
                    <th>Customer join</th>
                    <td></td>
                    <td>
                      {moment
                        .utc(this.state.matchOrder.customerCreated)
                        .local()
                        .format("YYYY-MM-DD HH:mm")}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="mr-auto"
              onClick={this.connectPnToOrder}
            >
              Match
            </Button>
            <Button color="secondary" onClick={this.toggleMatchModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </ContentWrapper>
    )
  }

  ActionsFormatter = props => {
    return (
      <Button
        size="sm"
        color="warning"
        className="pr-2 pl-2"
        onClick={() => this.openMatchModal(props.value)}
      >
        Match
      </Button>
    )
  }
}
export default MatchUnmatched
