import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row } from "reactstrap"
import Cards from "./Cards"
import OrderChart from "./OrderChart"
import CryptoChart from "./CryptoChart"
import RegistrationsChart from "./RegistrationsChart"
import ActiveUsersChart from "./ActiveUsersChart"
import FirstTimeAndRecurring from "./FirstTimeAndRecurring"
import VerificationApproved from "./VerificationApproved"
import RegistrationsCumulativeChart from "./RegistrationsCumulativeChart"
import OrdersByCountryChart from "./OrdersByCountryChart"
import OrdersStoppedReasons from "./OrdersStoppedReasons"
import OrdersStoppedPercentage from "./OrdersStoppedPercentage"
import OrdersIssuedCompletedRefunded from "./OrdersIssuedCompletedRefunded"
import CryptoTxFeeChart from "./CryptoTxFeeChart"

class Metrics extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Metrics</div>
        </div>
        <Row>
          <Cards />
        </Row>
        <Row>
          <OrderChart />
        </Row>
        <Row>
          <CryptoChart />
        </Row>
        <Row>
          <CryptoTxFeeChart />
        </Row>
        <Row>
          <OrdersByCountryChart />
        </Row>
        <Row>
          <RegistrationsChart />
        </Row>
        <Row>
          <ActiveUsersChart />
        </Row>
        <Row>
          <FirstTimeAndRecurring />
        </Row>
        <Row>
          <VerificationApproved />
        </Row>
        <Row>
          <OrdersStoppedReasons />
        </Row>
        <Row>
          <OrdersStoppedPercentage />
        </Row>
        <Row>
          <OrdersIssuedCompletedRefunded />
        </Row>
        <Row>
          <RegistrationsCumulativeChart />
        </Row>
      </ContentWrapper>
    )
  }
}

export default Metrics
