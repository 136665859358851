import { createContext } from "react"

const TreasuryContext = createContext({
  gatewayStatusData: null,
  bitstampBalanceData: null,
  bitstampTickerData: null,
  orderSellStatsData: null,
  blockchainBalance: null,
  fireblocksBalance: null,
  walletLiabilities: null,
  inProcessTransactions: [],
  withdrawalDepositLiabilities: null,
  loading: true,
  error: null,
  refresh: async _endpointsToRefresh => {},
})

export default TreasuryContext
