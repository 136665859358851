import React, { Component } from "react"
import { Row, Col, Button } from "reactstrap"
import { CardHeader, CardBody } from "reactstrap"
import OrderUtils from "./OrderUtils"
import Utils from "../Common/Utils"

class OrderStatusSection extends Component {
  render() {
    if (!this.props.order) return <div />

    let statusBadge = !this.props.isSellOrder
      ? OrderUtils.getCurrentBuyOrderStatusBadge(this.props.order)
      : OrderUtils.getCurrentSellOrderStatusBadge(this.props.order)

    return (
      <div>
        <Row className="no-gutters">
          <Col xs={6}>
            <CardHeader className="card-section-header">
              Current status
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="no-gutters">{statusBadge}</Row>
            </CardBody>
          </Col>
          <Col xs={6}>
            <CardHeader className="card-section-header">
              Tags
              <Button
                size="xs"
                color="primary"
                className="float-right safello-font"
                onClick={this.props.onEditTagClick}
              >
                Edit tags
              </Button>
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="no-gutters">
                {Utils.getTags(this.props.order)}
              </Row>
            </CardBody>
          </Col>
        </Row>
        {this.props.order && this.props.order.walletOrder && (
          <Row className="no-gutters">
            <Col xs={12}>
              <CardHeader className="card-section-header">
                Wallet order
              </CardHeader>
              <CardBody className="pt-0">
                <div className="wallet-badge">wallet</div>
              </CardBody>
            </Col>
          </Row>
        )}
      </div>
    )
  }
}

export default OrderStatusSection
