import React, { Component } from "react"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

class Cards extends Component {
  state = {
    cardData: [],
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.statsWalletCards
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          cardData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    if (this.state.cardData && this.state.cardData.length > 0) {
      return this.state.cardData.map((card, i) => {
        let title
        let icon = "fa-2x fas fa-"
        if (card.name === "wallet_new_active") {
          title = "Total activated " + card.unit.substring(0, 3) + " wallets"
          icon += "users"
          card.unit = ""
        } else if (card.name === "wallet_assets_under_management") {
          title = card.unit.substring(0, 3) + " Wallet AUA"
          icon += "wallet"
          card.unit = "SEK"
          card.value = Math.round(card.value)
        }

        return (
          <BackOfficeCard
            icon={icon}
            title={title}
            value={Utils.formatNumber(card.value)}
            unit={card.unit}
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    } else {
      let placeholders = [0, 1, 2, 3]
      return placeholders.map((placeHolder, i) => {
        return (
          <BackOfficeCard
            icon=" "
            title="-"
            value="-"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    }
  }
}

export default Cards
