import React, { Component } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import Utils from "./Utils"

class ChainalysisTransactionExposureModal extends Component {
  alertsTable = () => {
    if (this.props.data) {
      let alerts = this.props.data.alerts || []

      return (
        <div>
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Severity</th>
                <th>Category</th>
                <th>Service name</th>
                <th>Timestamp</th>
                <th>Amount USD</th>
                <th>Exposure</th>
                <th>Direction</th>
                <th>Status</th>
                <th>State</th>
              </tr>
            </thead>
            <tbody>
              {alerts.map((row, i) => {
                return (
                  <tr key={i}>
                    <React.Fragment>
                      <td>{row.level}</td>
                      <td>{row.category}</td>
                      <td>{row.service}</td>
                      <td>{Utils.getUtcInLocalDateTime(row.createdAt)}</td>
                      <td>{row.alertAmountUsd}</td>
                      <td>{row.exposureType}</td>
                      <td>{row.direction}</td>
                      <td>{row.alertStatus}</td>
                      <td>{row.validity}</td>
                    </React.Fragment>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )
    }

    return ""
  }

  transactionData = () => {
    if (this.props.data && this.props.data.data) {
      let data = this.props.data.data

      return (
        <div>
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Asset</th>
                <th>Amount</th>
                <th>Amount USD</th>
                <th>Rating</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.asset}</td>
                <td>{data.amount}</td>
                <td>{data.amountUSD}</td>
                <td>{data.rating}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }

    return ""
  }

  render() {
    const data = this.props.data
    if (!data) return ""

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.onToggle}
        size="lg"
        style={{ maxWidth: "1500px" }}
      >
        <ModalHeader toggle={this.props.onToggle}>
          Chainalysis transaction details
        </ModalHeader>
        <ModalBody>
          <div>
            Data
            {this.transactionData()}
          </div>
          <div>
            Alerts
            {this.alertsTable()}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.onToggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default ChainalysisTransactionExposureModal
