import React, { Component } from "react"
import { Card } from "reactstrap"
import OrderStatusSection from "../OrderStatusSection"
import ChecksSection from "../ChecksSection"
import CustomerSection from "../CustomerSection"
import BuyOrderDetailsSection from "./BuyOrderDetailsSection"
import BuyTimeLineSection from "./BuyTimeLineSection"
import ChainalysisSection from "../../Common/ChainalysisSection"

class BuyOrderInfoArea extends Component {
  render() {
    let cardClass = "card-area"
    let cardStyle = {}
    if (this.props.isLoading) {
      cardClass += " whirl standard"
      cardStyle = { minHeight: "500px" }
    }

    const { order } = this.props

    return (
      <div>
        <span className="header-area">
          Buy order
          <span> {order ? "#" + order.orderId : ""}</span>
        </span>
        <Card className={cardClass} style={cardStyle}>
          <OrderStatusSection
            order={order}
            onEditTagClick={this.props.onEditTagClick}
          />
          <ChecksSection checks={order && order.checks} />
          <div className="card-section-divider" />
          <CustomerSection
            customerId={order && order.customerId}
            identityVerified={order && order.identityVerified}
            eidVerified={order && order.eidVerified}
          />
          <div className="card-section-divider" />
          {(!order || !order.walletOrder) && (
            <ChainalysisSection
              user={order ? order.customerId : null}
              data={order}
              isSellOrder={false}
            />
          )}
          <div className="card-section-divider" />
          <BuyOrderDetailsSection order={order} />
          <BuyTimeLineSection order={order} />
        </Card>
      </div>
    )
  }
}

export default BuyOrderInfoArea
