import React, { useState, useEffect } from "react"
import { Card, CardHeader, CardBody, FormGroup, Input } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import ButtonLoad from "../Common/ButtonLoad"
import Utils from "./Utils"
import styled from "styled-components"
import StorageUtils from "./StorageUtils"

/*
Comment data format:
[
  {
    comment: string,
    timestamp: number,
    date: string,
    time: string,
    userid: string,
    commentid: string,
    firstname: string,
    lastname: string,
    commentflag: string
  }
]
*/

const CommentArea = ({ id, type, reloadTrigger }) => {
  const [loggedInUser, setLoggedInUser] = useState(null)
  const [addNewButtonVisible, setAddNewButtonVisible] = useState(false)
  const [comments, setComments] = useState([])
  const [newCommentText, setNewCommentText] = useState("")
  const [isSavingNewComment, setIsSavingNewComment] = useState(false)

  useEffect(() => {
    getComments()
  }, [id, type, reloadTrigger])
  useEffect(() => {
    setLoggedInUser(StorageUtils.getUser())
  }, [])

  const getComments = async () => {
    const response = await BackOfficeApi.endpoints.comments.getOne(
      { id: id },
      { type: type },
    )
    if (response && response.ok) {
      const data = await response.json()
      setComments(data.slice(0).reverse())
    } else {
      ToastUtils.toastAPIError2()
    }
  }

  const onNewCommentTextChanged = event => {
    const text = event.target.value
    setNewCommentText(text)
    setAddNewButtonVisible(text.length > 0)
  }

  const onAddNewCommentClicked = async () => {
    setIsSavingNewComment(true)

    let formData = new FormData()
    formData.append("type", type)
    formData.append("comment", newCommentText)

    const response = await BackOfficeApi.endpoints.comment.create(formData, {
      id: id,
    })
    if (response && response.ok) {
      setIsSavingNewComment(false)
      setNewCommentText("")
      getComments()
    } else {
      ToastUtils.toastSaveError2()
      setIsSavingNewComment(false)
    }
  }

  const adjustTextAreaHeight = e => {
    e.target.style.height = "1px"
    e.target.style.height = 25 + e.target.scrollHeight + "px"
  }

  const archiveComment = async commentId => {
    const response = await BackOfficeApi.endpoints.archiveComment.post({
      commentId: commentId,
    })
    if (response && response.ok) {
      getComments()
      ToastUtils.toastExecuteSuccess2()
    } else {
      console.error(response)
      ToastUtils.toastCustomError("Could not archive comment")
    }
  }

  const onArchiveComment = async comment => {
    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: Archive ${type.toLowerCase()} comment \n
      - Comment ID: ${comment.commentid}\n
      - By: ${comment.firstname || ""} ${comment.lastname || ""}\n\n
      If you later want to revert an archived comment, please contact a developer.`,
    )
    if (confirmed) {
      archiveComment(comment.commentid)
    }
  }

  const getCommentTextAreaHeight = comment => {
    let rows = "2"
    if (comment.comment.length > 180) {
      rows = "8"
    } else if (comment.comment.length > 120) {
      rows = "6"
    } else if (comment.comment.length > 60) {
      rows = "4"
    }
    return rows
  }

  return (
    <div>
      <span className="header-area">Comments</span>
      <Card className="card-area">
        <CardBody>
          <FormGroup>
            <Input
              type="textarea"
              className="comment-add-text"
              placeholder="Add a comment..."
              rows="3"
              onChange={onNewCommentTextChanged}
              value={newCommentText}
              onKeyUp={adjustTextAreaHeight}
            />
          </FormGroup>
          {addNewButtonVisible && (
            <ButtonLoad
              isLoading={isSavingNewComment}
              onClicked={onAddNewCommentClicked}
              style={{ float: "right" }}
              buttonText="Add"
            />
          )}
        </CardBody>
      </Card>
      {comments &&
        comments.map((comment, i) => (
          <Card className="card-area" key={i}>
            <CardHeader>
              <span className="comment-header" style={{ float: "left" }}>
                {`${comment.firstname} ${comment.lastname || ""}`}
              </span>
              <span className="comment-timestamp" style={{ float: "right" }}>
                {Utils.getUtcInLocalDateTime(comment.timestamp)}
              </span>
            </CardHeader>
            <CardBody>
              <Input
                type="textarea"
                className="comment-text"
                value={comment.comment}
                rows={getCommentTextAreaHeight(comment)}
                readOnly
                disabled
              />
            </CardBody>
            {/* Only able to archive own comments */}
            {parseInt(comment.userid, 10) === loggedInUser.id && (
              <ArchiveButtonContainer onClick={() => onArchiveComment(comment)}>
                <em className={"icon-folder-alt"}></em>
              </ArchiveButtonContainer>
            )}
          </Card>
        ))}
    </div>
  )
}

export default CommentArea

const ArchiveButtonContainer = styled.div`
  position: absolute;
  bottom: 6px;
  left: 8px;
  cursor: pointer;
  transition: color 0.3s ease;
  &:hover {
    color: #e0e0e0;
  }
`
