import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import BuyOrdersStats from "./BuyOrdersStats"
import SellOrdersStats from "./SellOrdersStats"
import WalletOrdersStats from "./WalletOrdersStats"
import PaymentNotificationStats from "./PaymentNotificationStats"

class Support extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Buy Orders Statistics</div>
        </div>
        <BuyOrdersStats />
        <div className="content-heading">
          <div>Sell Orders Statistics</div>
        </div>
        <SellOrdersStats />
        <div className="content-heading">
          <div>Wallet Stuck Orders Statistics</div>
        </div>
        <WalletOrdersStats />
        <div className="content-heading">
          <div>Payment Notification Statistics</div>
        </div>
        <PaymentNotificationStats />
      </ContentWrapper>
    )
  }
}

export default Support
