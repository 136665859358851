import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"

class EditAppApiModal extends Component {
  state = {
    appName: "",
    redirectUrl: "",
    iframeDomain: "",
    webviewIntegration: false,
    mobileApp: false,
    primaryColor: "",
    secondaryColor: "",
    lockedChannels: ["None"],
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.appApi !== prevProps.appApi ||
      prevProps.open !== this.props.open
    ) {
      let a = this.props.appApi

      this.setState({
        appName: a.appName,
        redirectUrl: a.redirectUrl,
        iframeDomain: a.iframeDomain,
        webviewIntegration: a.webviewIntegration,
        mobileApp: a.isMobileApp,
        primaryColor: a.themeColorPrimary,
        secondaryColor: a.themeColorSecondary,
        lockedChannels: !a.lockedChannels ? ["None"] : a.lockedChannels,
      })
    }
  }

  onSave = () => {
    let formData = new FormData()
    formData.append("appName", this.getValue(this.state.appName))

    if (!this.state.lockedChannels.includes("None")) {
      let values = []
      this.state.lockedChannels.forEach(v => {
        values.push(
          this.props.channels.find(c => {
            return c.label === v
          }).id,
        )
      })

      formData.append("lockedChannels", values.join(", "))
    }

    formData.append("redirectUrl", this.getValue(this.state.redirectUrl))
    formData.append("iframeDomain", this.getValue(this.state.iframeDomain))
    formData.append("webviewIntegration", this.state.webviewIntegration)
    formData.append("mobileApp", this.state.mobileApp)
    formData.append("primaryColor", this.getValue(this.state.primaryColor))
    formData.append("secondaryColor", this.getValue(this.state.secondaryColor))

    BackOfficeApi.endpoints.editAppApi
      .create(formData, {
        id: this.props.partner.id,
        appId: this.props.appApi.id,
      })
      .then(response => {
        if (response.ok) {
          if (this.props.onSave) {
            this.props.onSave()
          }
        } else {
          response.text().then(text => {
            if (this.props.onError) {
              this.props.onError(text)
            }
          })
        }
      })
  }

  onToggle = () => {
    if (this.props.toggle) {
      this.props.toggle()
    }
  }

  getValue = value => {
    return value == null ? "" : value
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onSelectChange = e => {
    let options = e.target.options

    let values = []
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        values.push(options[i].value)
      }
    }
    this.setState({
      [e.target.name]: values,
    })
  }

  onCheckboxChange = e => {
    this.setState({
      [e.target.name]: e.target.checked,
    })
  }

  getChannelOptions = () => {
    if (this.props.channels) {
      let cs = []
      cs.push(
        <option key="-1" value="None">
          None
        </option>,
      )
      this.props.channels.forEach(c => {
        cs.push(
          <option key={c.id} value={c.label}>
            {c.label}
          </option>,
        )
      })
      return cs
    }

    return ""
  }

  render() {
    if (!this.props.appApi) return ""

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.onToggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.onToggle}>
          Edit app api {this.props.appApi.id}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="appName">
                App Name
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="appName"
                id="appName"
                value={this.state.appName}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="lockedChannels">
                Locked channels
              </Label>
              <div className="col-xl-0">
                <select
                  multiple
                  value={this.state.lockedChannels}
                  name="lockedChannels"
                  className="custom-select"
                  onChange={this.onSelectChange}
                >
                  {this.getChannelOptions()}
                </select>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="redirectUrl">
                Redirect URL
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="redirectUrl"
                id="redirectUrl"
                value={this.state.redirectUrl}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="iframeDomain">
                Iframe domain
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="iframeDomain"
                id="iframeDomain"
                value={this.state.iframeDomain}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup className="checkbox c-checkbox">
              <label className="mb-1">
                <Input
                  type="checkbox"
                  name="webviewIntegration"
                  id="webviewIntegration"
                  checked={this.state.webviewIntegration}
                  onChange={this.onCheckboxChange}
                />
                Webview integration <span className="fa fa-star"></span>
              </label>
            </FormGroup>
            <FormGroup className="checkbox c-checkbox">
              <label className="mb-1">
                <Input
                  type="checkbox"
                  name="mobileApp"
                  id="mobileApp"
                  checked={this.state.mobileApp}
                  onChange={this.onCheckboxChange}
                />
                Mobile app <span className="fa fa-heart"></span>
              </label>
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="primaryColor">
                Primary color
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="primaryColor"
                id="primaryColor"
                value={this.state.primaryColor}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="secondaryColor">
                Secondary color
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="secondaryColor"
                id="secondaryColor"
                value={this.state.secondaryColor}
                onChange={this.onChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onSave}>
            Save
          </Button>
          <Button color="secondary" onClick={this.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default EditAppApiModal
