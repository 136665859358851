import React, { useState, useEffect } from "react"
import { Row } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

const WalletOrdersStats = () => {
  const [stats, setStats] = useState(null)

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    BackOfficeApi.endpoints.walletOrdersStats
      .getOne()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        setStats(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  return stats &&
    stats.depositStuckBtc &&
    stats.withdrawalStuckBtc &&
    stats.depositStuckEth &&
    stats.withdrawalStuckEth ? (
    <Row>
      <BackOfficeCard
        icon="fa-2x fas fa-download"
        title={Utils.formatNumber(stats.depositStuckBtc.amountSum, 0) + " SEK"}
        value={"₿ deposits: " + stats.depositStuckBtc.count}
        url="/wallet/incoming?status=HALTED"
        unit=""
        key="1"
        {...CardUtils.getCardColorForColumn(0)}
      />
      <BackOfficeCard
        icon="fa-2x fas fa-upload"
        title={
          Utils.formatNumber(stats.withdrawalStuckBtc.amountSum, 0) + " SEK"
        }
        value={"₿ withdrawals: " + stats.withdrawalStuckBtc.count}
        url="/wallet/outgoing?status=HALTED"
        unit=""
        key="2"
        {...CardUtils.getCardColorForColumn(1)}
      />
      <BackOfficeCard
        icon="fa-2x fas fa-download"
        title={Utils.formatNumber(stats.depositStuckEth.amountSum, 0) + " SEK"}
        value={"Ξ deposits: " + stats.depositStuckEth.count}
        url="/wallet/incoming?status=HALTED"
        unit=""
        key="3"
        {...CardUtils.getCardColorForColumn(2)}
      />
      <BackOfficeCard
        icon="fa-2x fas fa-upload"
        title={
          Utils.formatNumber(stats.withdrawalStuckEth.amountSum, 0) + " SEK"
        }
        value={"Ξ withdrawals: " + stats.withdrawalStuckEth.count}
        url="/wallet/outgoing?status=HALTED"
        unit=""
        key="4"
        {...CardUtils.getCardColorForColumn(3)}
      />
    </Row>
  ) : (
    <Row>
      {Array(4)
        .fill(0)
        .map((_, i) => (
          <BackOfficeCard
            icon="fa-2x fas fa-question"
            title="*** *** SEK"
            value="Loading: ***"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        ))}
    </Row>
  )
}

export default WalletOrdersStats
