import React, { useState, useContext, useEffect } from "react"
import { Col, Row, CardBody, CardHeader } from "reactstrap"
import CardTool from "../../Common/CardTool"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import SellOnBitstamp from "./SellOnBitstamp"
import BuyOnBitstamp from "./BuyOnBitstamp"
import TreasuryContext from "./TreasuryContext"

const Bitstamp = () => {
  const [bitstampEurBalance, setBitstampEurBalance] = useState("...")
  const [bitstampBtcBalance, setBitstampBtcBalance] = useState("...")
  const [bitstampEthBalance, setBitstampEthBalance] = useState("...")
  const [bitstampError, setBitstampError] = useState(false)

  const [btcQuote, setBtcQuote] = useState(null)
  const [ethQuote, setEthQuote] = useState(null)

  const [isLoading, setLoading] = useState(false)

  const {
    refresh: refreshTreasury,
    error: balancesError,
    bitstampBalanceData,
  } = useContext(TreasuryContext)

  useEffect(() => {
    loadQuoteData().catch(() => ToastUtils.toastAPIError2())
  }, [])

  useEffect(() => {
    if (bitstampBalanceData) {
      setBitstampEurBalance(bitstampBalanceData["BTC"]?.EUR)
      setBitstampBtcBalance(bitstampBalanceData["BTC"]?.BTC)
      setBitstampEthBalance(bitstampBalanceData["ETH"]?.BTC)
    }
  }, [bitstampBalanceData])

  useEffect(() => {
    if (balancesError) {
      setBitstampError(balancesError)
    }
  }, [balancesError])

  const loadQuoteData = async () => {
    return Promise.all([
      BackOfficeApi.endpoints.getQuote
        .getAll({ crypto: "BTC", currency: "EUR" })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
        })
        .then(setBtcQuote),
      BackOfficeApi.endpoints.getQuote
        .getAll({ crypto: "ETH", currency: "EUR" })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
        })
        .then(setEthQuote),
    ]).catch(() => {
      ToastUtils.toastAPIError2()
    })
  }

  const handleRefreshClick = (_card, done) => {
    refreshAll().then(done)
  }

  const refreshAll = async () => {
    setLoading(true)
    try {
      await Promise.all([
        refreshTreasury(["btcBitstampBalance", "ethBitstampBalance"]),
        await loadQuoteData(),
      ])
    } catch (e) {
      ToastUtils.toastAPIError2()
    } finally {
      setLoading(false)
    }
  }

  const updateBitstampCallback = () => {
    //Wait 1 second to allow bitstamp to update
    setTimeout(() => refreshAll(), 1000)
  }

  let cardClass = "card mb-2"
  if (isLoading || !bitstampBalanceData) {
    cardClass += " whirl"
  }

  return (
    <div className={cardClass}>
      <CardHeader>
        <CardTool refresh onRefresh={handleRefreshClick} />
        <h4>
          <em className="fab fa-bitcoin mr-2" />
          Bitstamp
        </h4>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <h4>
              <em className="fa-fw fa fa-exclamation-circle mr-2" />
              Actions
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <SellOnBitstamp
              btcBalance={bitstampBtcBalance}
              ethBalance={bitstampEthBalance}
              btcQuote={btcQuote}
              ethQuote={ethQuote}
              disabled={!!bitstampError}
              updateBitstamp={updateBitstampCallback}
            />
            <BuyOnBitstamp
              eurBalance={bitstampEurBalance}
              btcQuote={btcQuote}
              ethQuote={ethQuote}
              disabled={!!bitstampError}
              updateBitstamp={updateBitstampCallback}
            />
          </Col>
        </Row>
      </CardBody>
    </div>
  )
}

export default Bitstamp
