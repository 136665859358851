import React from "react"
import { Row, CardHeader, CardBody } from "reactstrap"
import ValidationImage from "../Common/ValidationImage"

const ChecksSection = ({ checks }) => {
  if (!checks) return <div />

  return (
    <div>
      <CardHeader className="card-section-header">Checks</CardHeader>
      <CardBody className="pt-0">
        <Row className="no-gutters">
          {checks.map((checkItem, index) => {
            return (
              <div
                key={index}
                className={
                  checkItem.passed ? "order-check success" : "order-check fail"
                }
              >
                <ValidationImage
                  style={{
                    marginRight: "6px",
                    position: "absolute",
                    left: "8px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                  showBackground={false}
                  valid={checkItem.passed}
                  checkWidth={"16px"}
                  checkHeight={"12px"}
                />
                {checkItem.type}
              </div>
            )
          })}
        </Row>
      </CardBody>
    </div>
  )
}

export default ChecksSection
