import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import BackOfficeStackedBarChart from "../../Chart/BackOfficeStackedBarChart"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import BackOfficePieChart from "../../Chart/BackOfficePieChart"
import DropdownList from "../../Common/DropdownList"
import moment from "moment"

class Demographics extends Component {
  state = {
    isLoading: false,
    filter: "all",
    dateRange: "30days",
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
    data: [],
    countData: [],
    orderData: [],
    volumeData: [],
    avgData: [],
    pieData: [],
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getData()
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state.filter !== prevState.filter ||
      this.state.dateRange !== prevState.dateRange ||
      this.state.fromDate !== prevState.fromDate ||
      this.state.toDate !== prevState.toDate
    ) {
      this.getData()
    }
  }

  getData() {
    this.setState({
      isLoading: true,
    })

    let params = {}

    if (this.state.dateRange === "30days") {
      params.startTime = moment()
        .utc()
        .subtract(30, "days")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
    } else if (this.state.dateRange === "365days") {
      params.startTime = moment()
        .utc()
        .subtract(365, "days")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
    } else if (this.state.dateRange === "all") {
      params.startTime = "2000-01-01T00:00:00Z"
    } else if (this.state.dateRange === "custom") {
      params.startTime = moment(this.state.fromDate, "YYYY-MM-DD")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
      params.endTime = moment(this.state.toDate, "YYYY-MM-DD")
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss[Z]")
    }

    if (this.state.filter === "marketing") {
      params.marketingConsent = true
    } else if (this.state.filter === "premium") {
      params.premium = true
    }

    BackOfficeApi.endpoints.statsDemographic
      .getAll(params)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          isLoading: false,
          data: data,
          countData: this.getCountData(data),
          orderData: this.getOrderData(data),
          volumeData: this.getVolumeData(data),
          avgData: this.getAvgData(data),
          pieData: this.getPieData(data),
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({
          isLoading: false,
        })
      })
  }

  getPieData(data) {
    let male = 0
    let female = 0
    data.forEach(d => {
      if (d.gender === "Female") {
        female += d.customers
      } else {
        male += d.customers
      }
    })

    let arr = []
    arr.push({ name: "Male", value: male })
    arr.push({ name: "Female", value: female })

    return arr
  }

  getCountData(data) {
    let map = this.getAgeGroupMap(data)

    data.forEach(d => {
      if (d.gender === "Female") {
        map.get(d.ageGroup).female = d.customers
      } else {
        map.get(d.ageGroup).male = d.customers
      }
    })

    return this.mapToArr(map)
  }

  getOrderData(data) {
    let map = this.getAgeGroupMap(data)

    data.forEach(d => {
      if (d.gender === "Female") {
        map.get(d.ageGroup).female = d.totalOrders
      } else {
        map.get(d.ageGroup).male = d.totalOrders
      }
    })

    return this.mapToArr(map)
  }

  getVolumeData(data) {
    let map = this.getAgeGroupMap(data)

    data.forEach(d => {
      if (d.gender === "Female") {
        map.get(d.ageGroup).female = d.totalVolume
      } else {
        map.get(d.ageGroup).male = d.totalVolume
      }
    })

    return this.mapToArr(map)
  }

  getAvgData(data) {
    let map = this.getAgeGroupMap(data)

    data.forEach(d => {
      if (d.gender === "Female") {
        map.get(d.ageGroup).female = d.avgOrderSize
      } else {
        map.get(d.ageGroup).male = d.avgOrderSize
      }
    })

    return this.mapToArr(map)
  }

  mapToArr(map) {
    let arr = []
    map.forEach((v, k) => {
      arr.push({ name: k, female: v.female, male: v.male })
    })

    return arr
  }

  getAgeGroupMap(data) {
    let map = new Map()
    let ageGroups = this.getAgeGroups(data)
    ageGroups.forEach(a => {
      map.set(a, {})
    })

    return map
  }

  getAgeGroups(data) {
    return new Set(data.map(e => e.ageGroup))
  }

  changeFilter = item => {
    this.setState({
      filter: item.value,
    })
  }

  changeDateRange = item => {
    this.setState({
      dateRange: item.value,
    })
  }

  onFromDateChange = e => {
    this.setState({
      fromDate: e.target.value,
    })
  }

  onToDateChange = e => {
    this.setState({
      toDate: e.target.value,
    })
  }

  render() {
    let cardClass = ""
    if (this.state.isLoading) {
      cardClass = "whirl"
    }

    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Demographics</div>
        </div>
        <Row>
          <Col xl={6}>
            <Card>
              <CardHeader className="card-header">
                <div className="float-left">
                  <span>Settings</span>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xl={6}>
                    <DropdownList
                      value={this.state.filter}
                      onSelectItem={this.changeFilter}
                      buttonColor="secondary"
                      label="Filter"
                    >
                      {[
                        { value: "all", text: "All" },
                        { value: "marketing", text: "Marketing consent" },
                        { value: "premium", text: "Premium" },
                      ]}
                    </DropdownList>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xl={6}>
                    <DropdownList
                      value={this.state.dateRange}
                      onSelectItem={this.changeDateRange}
                      buttonColor="secondary"
                      label="Date range"
                    >
                      {[
                        { value: "30days", text: "Last 30 days" },
                        { value: "365days", text: "Last 365 days" },
                        { value: "all", text: "All Time" },
                        { value: "custom", text: "Custom" },
                      ]}
                    </DropdownList>
                  </Col>
                </Row>
                {this.state.dateRange === "custom" && (
                  <Row className="mt-4">
                    <Col>
                      From:{" "}
                      <input
                        type="date"
                        value={this.state.fromDate}
                        onChange={this.onFromDateChange}
                        className="form-control"
                      />
                    </Col>
                    <Col>
                      To:{" "}
                      <input
                        type="date"
                        onChange={this.onToDateChange}
                        value={this.state.toDate}
                        className="form-control"
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <Card className={cardClass} style={{ minHeight: "300px" }}>
              <CardBody>
                <BackOfficePieChart
                  headerLabel="Gender percentage"
                  cells={this.state.pieData}
                  height={300}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Card className={cardClass} style={{ minHeight: "300px" }}>
              <CardHeader className="card-header">
                <div className="float-left">
                  <span>Customers</span>
                </div>
              </CardHeader>
              <CardBody>
                <BackOfficeStackedBarChart
                  data={this.state.countData}
                  dataKey1="male"
                  dataKey2="female"
                />
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <Card className={cardClass} style={{ minHeight: "300px" }}>
              <CardHeader>
                <div className="float-left">
                  <span>Total orders</span>
                </div>
              </CardHeader>
              <CardBody>
                <BackOfficeStackedBarChart
                  data={this.state.orderData}
                  dataKey1="male"
                  dataKey2="female"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <Card className={cardClass} style={{ minHeight: "300px" }}>
              <CardHeader>
                <div className="float-left">
                  <span>Total volume</span>
                </div>
              </CardHeader>
              <CardBody className="card-body">
                <BackOfficeStackedBarChart
                  data={this.state.volumeData}
                  dataKey1="male"
                  dataKey2="female"
                  unit="SEK"
                />
              </CardBody>
            </Card>
          </Col>
          <Col xl={6}>
            <Card className={cardClass} style={{ minHeight: "300px" }}>
              <CardHeader>
                <div className="float-left">
                  <span>Average order size</span>
                </div>
              </CardHeader>
              <CardBody>
                <BackOfficeStackedBarChart
                  data={this.state.avgData}
                  dataKey1="male"
                  dataKey2="female"
                  unit="SEK"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}
export default Demographics
