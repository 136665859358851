import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import DropdownList from "../../Common/DropdownList"
import Utils from "../../Common/Utils"
import AccountingCard from "./AccountingCard"

class Accounting extends Component {
  state = {
    downloading: false,
    fromDate: "",
    toDate: "",
    walletStartDate: "",
    walletEndDate: "",
    customerId: "",
    activeCustomerInterval: "7",
    email: "",
    unmatchedReport: null,
    moveToExpense: [],
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const dateObj = new Date()
    const month = dateObj.getUTCMonth() + 1 //months from 1-12
    const day = dateObj.getUTCDate()
    const year = dateObj.getUTCFullYear()
    const date = year + "-" + month + "-" + day
    this.setState({
      fromDate: date,
      toDate: date,
      walletStartDate: date,
      walletEndDate: date,
    })
  }

  moveToExpenseClicked = () => {
    const moveToExpense = this.state.moveToExpense
    if (!moveToExpense) {
      alert("Select records which you want to move to expense!")
      return
    }

    this.setState({
      downloading: true,
    })
    const json = JSON.stringify(moveToExpense)
    const headers = {
      headers: { "Content-Type": "application/json" },
    }
    BackOfficeApi.endpoints.accountingMoveToExpense
      .create(json, null, headers)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(_ => {
        ToastUtils.toastExecuteSuccess2()
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  reportRecordSelected = e => {
    const checked = e.target.checked
    const checkValue = Number(e.target.value)
    let moveToExpense = this.state.moveToExpense
    const index = moveToExpense.indexOf(checkValue)

    if (checked) {
      if (index === -1) {
        moveToExpense.push(checkValue)
      }
    } else {
      if (index > -1) {
        moveToExpense.splice(index, 1)
      }
    }

    this.setState({
      moveToExpense,
    })
  }

  onShowUnmatchedReportClicked = () => {
    this.setState({
      downloading: true,
    })
    BackOfficeApi.endpoints.accountingUnmatchedReport
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          unmatchedReport: data.records,
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  onDownloadUnprocessedOrdersCsvClicked = () => {
    this.setState({
      downloading: true,
    })

    const end = this.state.unprocessedOrdersEndDate

    BackOfficeApi.endpoints.downloadAccountingUnprocessedOrders
      .getAll({ endDate: end })
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, "unprocessed_orders.csv")
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  onDownloadAccountingOrdersCsvClicked = () => {
    this.setState({
      downloading: true,
    })
    BackOfficeApi.endpoints.downloadAccountingOrders
      .getAll({ from: this.state.fromDate, to: this.state.toDate })
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, "accounting_orders.csv")
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  onDownloadAccountingCryptoOrdersCsvClicked = () => {
    this.setState({
      downloading: true,
    })
    BackOfficeApi.endpoints.downloadAccountingCryptoOrders
      .getAll({ from: this.state.fromDate, to: this.state.toDate })
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, "accounting_crypto_orders.csv")
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }
  onDownloadOutstandingWalletDebtCsvClicked = async () => {
    this.setState({
      downloading: true,
    })
    const start = this.state.walletStartDate
    const end = this.state.walletEndDate
    const customerId = this.state.customerId

    let res
    let outputFileName = "outstanding_wallet_debt.xlsx"
    try {
      if (customerId) {
        outputFileName = `outstanding_wallet_debt_customer_${customerId}.xlsx`
        res =
          await BackOfficeApi.endpoints.downloadCustomerOutstandingWalletDebt.getAll(
            {
              startDate: start,
              endDate: end,
              customerId: customerId,
            },
          )
      } else {
        res =
          await BackOfficeApi.endpoints.downloadOutstandingWalletDebt.getAll({
            startDate: start,
            endDate: end,
          })
      }
      if (res.ok) {
        const blob = await res.blob()
        Utils.downloadFile(blob, outputFileName)
        this.setState({
          downloading: false,
        })
      } else {
        throw new Error()
      }
    } catch (e) {
      ToastUtils.toastExecuteError2()
      this.setState({
        downloading: false,
      })
    }
  }

  onDownloadWithdrawalSummaryClicked = async () => {
    this.setState({
      downloading: true,
    })
    const start = this.state.reportStartDate
    const end = this.state.reportEndDate

    let res
    let outputFileName = "withdrawal_summary.pdf"
    try {
      res = await BackOfficeApi.endpoints.downloadWithdrawalSummary.getAll({
        from: start,
        to: end,
      })

      if (res.ok) {
        const blob = await res.blob()
        Utils.downloadFile(blob, outputFileName)
        this.setState({
          downloading: false,
        })
      } else {
        throw new Error()
      }
    } catch (e) {
      ToastUtils.toastExecuteError2()
      this.setState({
        downloading: false,
      })
    }
  }

  onDownloadAccountingCryptoActionsCsvClicked = () => {
    this.setState({
      downloading: true,
    })
    BackOfficeApi.endpoints.downloadAccountingCryptoActions
      .getAll({ from: this.state.fromDate, to: this.state.toDate })
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, "accounting_crypto_actions.csv")
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  onDownloadJournalVoucherPdfClicked = () => {
    this.setState({
      downloading: true,
    })
    BackOfficeApi.endpoints.downloadJournalVoucher
      .getOne({ from: this.state.fromDate, to: this.state.toDate })
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, "journalVoucher.pdf")
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  onDownloadInventoryReportPdfClicked = () => {
    this.setState({
      downloading: true,
    })
    let params = {}
    params.inventoryId = 1
    params.from = this.state.fromDate
    params.to = this.state.toDate
    if (this.state.email && this.state.email !== "") {
      params.optionalEmailAddress = this.state.email
    }

    BackOfficeApi.endpoints.downloadInventoryReport
      .getAll(params)
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, "inventory_report.pdf")
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  onDownloadActiveCustomersCsvClicked = () => {
    this.setState({
      downloading: true,
    })
    BackOfficeApi.endpoints.downloadActiveCustomersCsv
      .getOne({ days: this.state.activeCustomerInterval })
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, "active_customers.csv")
        this.setState({
          downloading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({
          downloading: false,
        })
      })
  }

  onFromDateChange = e => {
    this.setState({
      fromDate: e.target.value,
    })
  }

  onToDateChange = e => {
    this.setState({
      toDate: e.target.value,
    })
  }

  onEmailChange = e => {
    this.setState({
      email: e.target.value,
    })
  }

  onWalletStartDateChange = e => {
    this.setState({
      walletStartDate: e.target.value,
    })
  }

  onWalletEndDateChange = e => {
    this.setState({
      walletEndDate: e.target.value,
    })
  }

  onReportStartDateChange = e => {
    this.setState({
      reportStartDate: e.target.value,
    })
  }

  onUnprocessedOrdersDateChange = e => {
    this.setState({
      unprocessedOrdersEndDate: e.target.value,
    })
  }

  onReportEndDateChange = e => {
    this.setState({
      reportEndDate: e.target.value,
    })
  }

  onCustomerIdChange = e => {
    this.setState({
      customerId: e.target.value,
    })
  }

  changeActiveCustomerInterval = item => {
    this.setState({
      activeCustomerInterval: item.value,
    })
  }

  render() {
    const unmatchedReport = this.state.unmatchedReport

    return (
      <ContentWrapper>
        <div className="content-heading">
          Accounting
          <div style={{ marginLeft: "40px" }}>
            {this.state.downloading && (
              <div className="pacman">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </div>
        </div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>Actions</CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Button
                      size="lg"
                      color="primary"
                      onClick={this.onShowUnmatchedReportClicked}
                      disabled={this.state.downloading}
                    >
                      Show Accounting Unmatched Report
                    </Button>
                  </Col>
                  <Col>
                    <DropdownList
                      label="Interval"
                      value={this.state.activeCustomerInterval}
                      onSelectItem={this.changeActiveCustomerInterval}
                    >
                      {[
                        { value: "7", text: "7 Days" },
                        { value: "14", text: "2 Weeks" },
                        { value: "30", text: "1 Month" },
                        { value: "90", text: "3 Months" },
                      ]}
                    </DropdownList>
                    <Button
                      size="lg"
                      color="primary"
                      onClick={this.onDownloadActiveCustomersCsvClicked}
                      disabled={this.state.downloading}
                      style={{ marginLeft: "20px" }}
                    >
                      <em className="fa mr-2 fas fa-cloud-download-alt"></em>
                      Active Customers CSV
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <AccountingCard
            title="Unprocessed Orders"
            loading={this.state.downloading}
            inputs={[
              {
                title: "End date:",
                type: "date",
                value: this.state.unprocessedOrdersEndDate,
                onChange: this.onUnprocessedOrdersDateChange,
              },
            ]}
            buttons={[
              {
                title: "Unprocessed Orders (CSV)",
                onClick: this.onDownloadUnprocessedOrdersCsvClicked,
              },
            ]}
          />
          <AccountingCard
            title="Accounting Stuff"
            loading={this.state.downloading}
            inputs={[
              {
                title: "From (Inclusive):",
                type: "date",
                value: this.state.fromDate,
                onChange: this.onFromDateChange,
              },
              {
                title: "To (Exclusive):",
                type: "date",
                value: this.state.toDate,
                onChange: this.onToDateChange,
              },
              {
                title: "Email (Optional):",
                type: "text",
                value: this.state.email,
                onChange: this.onEmailChange,
              },
            ]}
            buttons={[
              {
                title: "Journal Voucher PDF",
                onClick: this.onDownloadJournalVoucherPdfClicked,
              },
              {
                title: "Inventory Report PDF",
                onClick: this.onDownloadInventoryReportPdfClicked,
              },
              {
                title: "Orders CSV",
                onClick: this.onDownloadAccountingOrdersCsvClicked,
              },
              {
                title: "Crypto Actions CSV",
                onClick: this.onDownloadAccountingCryptoActionsCsvClicked,
              },
              {
                title: "Crypto Orders CSV",
                onClick: this.onDownloadAccountingCryptoOrdersCsvClicked,
              },
            ]}
          />
          <AccountingCard
            title="Wallet Stuff"
            loading={this.state.downloading}
            inputs={[
              {
                title: "Start date (Inclusive):",
                type: "date",
                value: this.state.walletStartDate,
                onChange: this.onWalletStartDateChange,
              },
              {
                title: "End date (Inclusive):",
                type: "date",
                value: this.state.walletEndDate,
                onChange: this.onWalletEndDateChange,
              },
              {
                title: "Customer ID(Optional):",
                type: "text",
                value: this.state.customerId,
                onChange: this.onCustomerIdChange,
              },
            ]}
            buttons={[
              {
                title: "Outstanding Wallet Debt XLSX",
                onClick: this.onDownloadOutstandingWalletDebtCsvClicked,
              },
            ]}
          />
          <AccountingCard
            title="Reports"
            loading={this.state.downloading}
            inputs={[
              {
                title: "Start date (Inclusive):",
                type: "date",
                value: this.state.reportStartDate,
                onChange: this.onReportStartDateChange,
              },
              {
                title: "End date (Inclusive):",
                type: "date",
                value: this.state.reportEndDate,
                onChange: this.onReportEndDateChange,
              },
            ]}
            buttons={[
              {
                title: "Withdrawal summary (PDF)",
                onClick: this.onDownloadWithdrawalSummaryClicked,
              },
            ]}
          />
          {unmatchedReport && (
            <Col lg={12}>
              <Card>
                <CardHeader>Unmatched report</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="card-default card">
                        <div className="table-responsive">
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Journal Id</th>
                                <th>Account Id</th>
                                <th>Entry Id</th>
                                <th>Account</th>
                                <th>Amount</th>
                                <th>Asset</th>
                                <th>Reference</th>
                                <th>Statement date</th>
                                <th>Select</th>
                              </tr>
                            </thead>
                            <tbody>
                              {unmatchedReport.map(record => (
                                <tr key={record.journal_id}>
                                  <td>{record.journal_id}</td>
                                  <td>{record.account_id}</td>
                                  <td>{record.entry_id}</td>
                                  <td>{record.account}</td>
                                  <td>{record.amount}</td>
                                  <td>{record.asset}</td>
                                  <td>{record.reference}</td>
                                  <td>{record.statement_date}</td>
                                  <td>
                                    <div className="checkbox c-checkbox">
                                      <label>
                                        <input
                                          type="checkbox"
                                          value={record.entry_id}
                                          onClick={this.reportRecordSelected}
                                        />
                                        <span className="fa fa-check"></span>
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="card-footer">
                          <div className="d-flex">
                            <div className="ml-auto">
                              <div className="input-group float-right">
                                <select
                                  className="custom-select"
                                  id="recordsAction"
                                >
                                  <option value="0">Move to expense</option>
                                </select>
                                <div className="input-group-append">
                                  <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={this.moveToExpenseClicked}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </ContentWrapper>
    )
  }
}

export default Accounting
