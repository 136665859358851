import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Container, Dropdown } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import { Link } from "react-router-dom"
import DataGridEmpty from "../../Common/DataGridEmpty"
import BackOfficeApi from "../../Api/BackOfficeApi"
import SearchBar from "../../Common/SearchBar"
import ToastUtils from "../../Common/ToastUtils"
import Utils, { DataGridLocalDateTimeFormatter } from "../../Common/Utils"
import OrderUtils from "../OrderUtils"
import DropdownList from "../../Common/DropdownList"
import XmlReport from "../XmlReport"
import EditTags from "../../Common/EditTags"
import { amountFormatter } from "../../../utils/format"

class SellOrders extends Component {
  state = {
    sortStatus: "",
    sortStatusValue: "",
    dropdownStatus: false,
    rows: [],
    searchText: "",
    searchCriteria: "id",
    customer: Utils.getQueryVariable(this.props.location, "customer"),
    choosenIds: [],
    tags: [],
    payoutMethods: [],
  }

  constructor(props, context) {
    super(props, context)

    this.fetchSize = 30

    this._columns = [
      {
        key: "orderIdentifier",
        name: "ID",
        width: 100,
        formatter: this.IdFormatter,
      },
      {
        key: "orderId",
        name: "REF #",
        width: 75,
      },
      {
        key: "customer",
        name: "Customer",
        formatter: this.CustomerFormatter,
        width: 130,
      },
      {
        key: "receivedAmountData",
        name: "Received",
        formatter: ({ value }) =>
          amountFormatter(value?.amount, value?.currency),
        width: 120,
      },
      {
        key: "payoutAmountData",
        name: "Payout",
        formatter: ({ value }) =>
          amountFormatter(value?.amount, value?.currency),
        width: 100,
      },
      {
        key: "issued",
        name: "Issued",
        formatter: DataGridLocalDateTimeFormatter,
        width: 120,
      },
      {
        key: "issuer",
        name: "Issuer",
        width: 100,
      },
      {
        key: "status",
        name: "Status",
        width: 140,
      },
      {
        key: "walletOrder",
        name: "Wallet",
        width: 80,
        formatter: this.WalletFormatter,
      },
      {
        key: "payoutMethodName",
        name: "Payout Method",
        width: 120,
      },
      {
        key: "tags",
        name: "Tags",
        formatter: this.TagsFormatter,
      },
    ]

    this.rowOffsetHeight = 0
    this.searching = false
    this.noMoreData = false
  }

  handleIdCheckClick = e => {
    const checked = e.target.checked
    const checkValue = Number(e.target.value)
    let choosenIds = this.state.choosenIds
    const index = choosenIds.indexOf(checkValue)

    if (checked) {
      if (index === -1) {
        choosenIds.push(checkValue)
      }
    } else {
      if (index > -1) {
        choosenIds.splice(index, 1)
      }
    }

    this.setState({
      choosenIds,
    })
  }

  doHeaderAction = item => {
    if (item.value === "select_all") {
      const x = document.getElementsByClassName("custom-control-input")
      for (let i = 0; i <= x.length; i++) {
        if (x[i] && !x[i].checked) {
          x[i].click()
        }
      }
      return
    }

    if (!this.state.choosenIds || this.state.choosenIds.length === 0) {
      alert("Please choose some orders first.")
      return
    }
    switch (item.value) {
      case "report":
        this.toggleModal("reportXmlModal")
        break

      case "edit_tags":
        this.toggleModal("editTagsModal")
        break

      default:
      //do nothing
    }
  }

  toggleModal = modalName => {
    this.setState({
      [modalName]: !this.state[modalName],
    })
  }

  toggleStatus = () => {
    this.setState(prevState => ({
      dropdownStatus: !prevState.dropdownStatus,
    }))
  }

  changeSortStatus = item => {
    this.setState({
      sortStatus: item.value,
      searchText: "",
    })

    this.searching = false

    this.setQuery("status", item.value)
  }

  loadQueryParameters = () => {
    const savedStatus = this.getQuery("status", "sold")

    this.setState({
      sortStatus: savedStatus,
      customer: Utils.getQueryVariable(this.props.location, "customer"),
    })
  }

  loadPayoutMethods = async () => {
    BackOfficeApi.endpoints.getPayoutMethods
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          payoutMethods: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  setQuery = (key, value) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set(key, value)
    const url = searchParams.toString()
    this.props.history.push(`?${url}`)
  }

  getQuery = (key, defaultValue) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    return searchParams.get(key) || defaultValue
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sortStatus !== prevState.sortStatus) {
      this.loadData()
    } else if (this.props.location.search !== prevProps.location.search) {
      this.loadQueryParameters()
    }
  }

  componentDidMount() {
    this.loadQueryParameters()
    this.loadPayoutMethods()
    this.setState({
      isLoading: true,
      customer: Utils.getQueryVariable(this.props.location, "customer"),
    })
  }

  loadData = (first = 0, append = false) => {
    this.setState({
      isLoading: true,
    })

    if (this.state.tags.length === 0) {
      BackOfficeApi.endpoints.getTags
        .getAll({
          type: "ORDER",
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.setState({
            tags: data,
          })
        })
    }

    if (this.searching) {
      this.onSearch(first, append)
      return
    }

    if (this.state.customer) {
      BackOfficeApi.endpoints.customerSellOrders
        .getOne(
          { id: this.state.customer },
          { first: first, limit: this.fetchSize },
        )
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
    } else {
      BackOfficeApi.endpoints.sellOrders
        .getAll({
          first: first,
          limit: this.fetchSize,
          status: this.state.sortStatus,
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
    }
  }

  rowGetter = i => {
    const row = this.state.rows[i]
    if (row) {
      row.orderIdentifier = {
        id: row.id,
        orderMapId: row.orderMapId,
      }

      row.customer = {
        customerName: row.customerName,
        customerId: row.customerId,
        customerRiskProfile: row.customerRiskProfile,
      }

      row.receivedAmountData = {
        amount: row.receivedAmount,
        currency: row.cryptoCurrency,
      }

      row.payoutAmountData = {
        amount: row.payoutAmount,
        currency: row.currency,
      }

      row.issuer = row.apiName

      const options = { iconOnly: true }

      const badges = [OrderUtils.getCurrentSellOrderStatusBadge(row, options)]
      row.status = badges.concat(OrderUtils.getOrderTagBadges(row, options))

      const payoutMethod = this.state.payoutMethods.find(m => {
        return m.id === row.payoutMethod
      })
      row.payoutMethodName = payoutMethod ? payoutMethod.name : "UNKNOWN"
    }

    return row
  }

  onScroll = e => {
    let halfWayVScroll = this.state.rows.length * this.rowOffsetHeight - 700
    let currentVScroll = e.scrollTop

    if (
      currentVScroll >= halfWayVScroll &&
      !this.state.isLoading &&
      !this.noMoreData
    ) {
      if (this.state.rows.length < 1000) {
        this.loadData(this.state.rows.length, true)
      }
    }
  }

  onSearchChange = e => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout)
    }

    this.setState({
      searchText: e.target.value,
      isLoading: true,
      searchTimeout: setTimeout(() => {
        this.onSearch()
      }, 500),
    })
  }

  changeSearchCriteria = item => {
    this.setState({
      searchCriteria: item.value,
    })
  }

  onSearch = (first = 0, append = false) => {
    if (this.state.searchText.length > 0) {
      this.searching = true

      BackOfficeApi.endpoints.searchSellOrder
        .getAll({
          search: this.state.searchText,
          first: first,
          limit: this.fetchSize,
          criteria: this.state.searchCriteria,
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
    } else {
      this.searching = false
      this.loadData()
    }
  }

  addData = (data, append) => {
    if (append) {
      if (data.length < this.fetchSize) {
        this.noMoreData = true
      }
      this.setState({
        originalRows: data,
        rows: this.state.rows.concat(data.slice(0)),
        isLoading: false,
      })
    } else {
      this.setState({
        originalRows: data,
        rows: data.slice(0),
        isLoading: false,
      })
      this.noMoreData = false
    }
  }

  renderHeaderActions() {
    let list = [{ value: "edit_tags", text: "Edit tags" }]

    if (this.state.customer) {
      list.push({ value: "report", text: "Report" })
      list.push({ value: "select_all", text: "Select all" })
    }

    return (
      <DropdownList
        className="pr-2"
        buttonColor="secondary"
        label="Bulk action"
        onSelectItem={this.doHeaderAction}
      >
        {list}
      </DropdownList>
    )
  }

  renderSearch() {
    if (this.state.customer) {
      return ""
    }

    return (
      <div className="ml-auto position-relative form-inline">
        <DropdownList
          label="Criteria"
          buttonColor="green"
          value={this.state.searchCriteria}
          onSelectItem={this.changeSearchCriteria}
        >
          {[
            { value: "id", text: "Order ID/Ref" },
            { value: "crypto-address", text: "Crypto address" },
            { value: "crypto-tx", text: "Crypto TX" },
            { value: "name", text: "Name" },
          ]}
        </DropdownList>
        <SearchBar
          value={this.state.searchText}
          onChange={this.onSearchChange}
        ></SearchBar>
        <div
          className="ball-beat loader-position"
          hidden={!this.state.isLoading}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }

  getStatusList = () => {
    let statusList = []
    statusList.push(
      { value: "all", text: "All" },
      { divider: true },
      { value: "mempool", text: "Mempool" },
      { value: "received-sold", text: "Received & Sold" },
      { value: "received", text: "Received" },
      { value: "sold", text: "Sold" },
      { value: "Awaiting response", text: "Awaiting response" },
      { value: "in-payout", text: "In payout" },
      { value: "below-limit", text: "Below limit" },
      { divider: true },
      { value: "completed", text: "Completed" },
      { value: "refunded", text: "Refunded" },
      { value: "suspicious", text: "Suspicious" },
      { value: "deleted", text: "Lazy Deleted" },
      { value: "frozen", text: "Frozen" },
      { value: "legacy", text: "Legacy" },
      { divider: true },
    )

    if (this.state.tags) {
      this.state.tags.forEach(tag => {
        statusList.push({ value: tag.name, text: "Tag: " + tag.name })
      })
    }
    return statusList
  }

  renderFilters() {
    if (this.state.customer) {
      return ""
    }

    return (
      <div className="ml-auto form-inline">
        <DropdownList
          className="pr-2"
          label="Status"
          buttonColor="primary"
          value={this.state.sortStatus}
          onSelectItem={this.changeSortStatus}
        >
          {this.getStatusList()}
        </DropdownList>

        <Dropdown
          isOpen={this.state.dropdownStatus}
          toggle={this.toggleStatus}
        ></Dropdown>
      </div>
    )
  }

  tagsEditFinished = () => {
    this.setState({
      choosenIds: [],
    })

    const x = document.getElementsByClassName("custom-control-input")
    for (let i = 0; i <= x.length; i++) {
      if (x[i] && x[i].checked) {
        x[i].checked = false
      }
    }

    this.loadData()
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          {this.renderHeaderActions()}
          <div>
            Sell orders{" "}
            {this.state.customer ? "for customer: " + this.state.customer : ""}{" "}
          </div>
          {this.renderSearch()}
          {this.renderFilters()}
        </div>
        <Container fluid>
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={200}
            rowHeight={40}
            ref={element => {
              if (element !== null) {
                const base = element.base
                base.onScroll = this.onScroll
                this.rowOffsetHeight = element.getRowOffsetHeight()
              }
            }}
          />
        </Container>
        <XmlReport
          orderOrTransactionIds={this.state.choosenIds}
          customerId={this.state.customer}
          open={this.state.reportXmlModal}
          onToggle={() => this.toggleModal("reportXmlModal")}
        />
        <EditTags
          ids={this.state.choosenIds}
          open={this.state.editTagsModal}
          toggle={() => this.toggleModal("editTagsModal")}
          onChange={this.tagsEditFinished}
          type="ORDER"
        />
      </ContentWrapper>
    )
  }

  IdFormatter = props => {
    const { id, orderMapId } = props.value
    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={orderMapId}
          value={orderMapId}
          onChange={this.handleIdCheckClick}
          defaultChecked={this.state.choosenIds.includes(orderMapId)}
        />
        <label
          className="custom-control-label"
          htmlFor={orderMapId}
          style={{ lineHeight: "24px" }}
        >
          <Link to={"/orders/sell/" + id}>{id}</Link>
        </label>
      </div>
    )
  }

  CustomerFormatter = props => {
    return (
      <Link to={"/customers/" + props.value.customerId}>
        {OrderUtils.renderRiskProfile(props.value.customerRiskProfile)}{" "}
        {props.value.customerName}
      </Link>
    )
  }

  TagsFormatter = props => {
    let tags = props.value
    let badges = []

    tags.forEach(t => {
      badges.push(
        <div
          className="tag-badge"
          key={t.id}
          style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
        >
          {t.name}
        </div>,
      )
    })

    return badges
  }

  WalletFormatter = ({ value }) => {
    return (
      <span
        style={{
          textAlign: "center",
          display: "block",
        }}
      >
        {value ? <div className="wallet-badge">wallet</div> : null}
      </span>
    )
  }
}

export default SellOrders
