import React, { useState } from "react"
import { Card } from "reactstrap"
import ChecksSection from "../../Orders/ChecksSection"
import CustomerSection from "../../Orders/CustomerSection"
import TransactionDetailsSection from "./TransactionDetailsSection"
import TransactionStatusSection from "./TransactionStatusSection"
import EditTags from "../../Common/EditTags"
import ChainalysisSection from "../../Common/ChainalysisSection"

const TransactionInfoArea = ({
  transaction,
  type,
  isLoading,
  getTransaction,
}) => {
  const [editTagsModal, setEditTagsModal] = useState(false)
  const isSwap = type === "SWAP"
  let cardClass = "card-area"
  let cardStyle = {}

  if (isLoading) {
    cardClass += " whirl standard"
    cardStyle = { minHeight: "500px" }
  }

  const sectionTitle = () => {
    if (!transaction) return ""
    return type.substr(0, 1) + type.substr(1).toLowerCase()
  }

  const editTagsOnChange = () => {
    setEditTagsModal(!editTagsModal)
    getTransaction()
  }

  return (
    <div>
      <span className="header-area">
        {sectionTitle()}
        <span> {transaction ? "#" + transaction.id : ""}</span>
      </span>
      <Card className={cardClass} style={cardStyle}>
        <TransactionStatusSection
          transaction={transaction}
          onEditTagClick={() => setEditTagsModal(true)}
        />
        {transaction?.checks && (
          <ChecksSection checks={transaction && transaction.checks} />
        )}
        <div className="card-section-divider" />
        <CustomerSection customerId={transaction && transaction.customerId} />
        <div className="card-section-divider" />
        <ChainalysisSection
          user={transaction ? transaction.customerId : null}
          justUser={isSwap}
          data={transaction}
          isWalletTx={!isSwap}
        />
        <div className="card-section-divider" />
        <TransactionDetailsSection transaction={transaction} />
        {transaction?.tags && (
          <EditTags
            order={transaction}
            open={editTagsModal}
            toggle={() => setEditTagsModal(!editTagsModal)}
            onChange={editTagsOnChange}
            type={isSwap ? "SWAP_ORDER" : "WALLET_TRANSACTION"}
          />
        )}
      </Card>
    </div>
  )
}

export default TransactionInfoArea
