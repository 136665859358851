import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import Utils from "../Common/Utils"
import BackOfficeApi from "../Api/BackOfficeApi"

class EditPartnerModal extends Component {
  state = {
    email: "",
    orgName: "",
    orgNumber: "",
    orgVat: "",
    iban: "",
    orgAddress: "",
    orgCity: "",
    orgZip: "",
    orgUrl: "",
    country: "",
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.partner !== prevProps.partner ||
      prevProps.open !== this.props.open
    ) {
      let p = this.props.partner

      this.setState({
        email: p.email,
        orgName: p.orgName,
        orgNumber: p.orgNumber,
        orgVat: p.orgVat,
        iban: p.iban,
        orgAddress: p.orgAddress,
        orgCity: p.orgCity,
        orgZip: p.orgZip,
        orgUrl: p.orgUrl,
        country: p.country,
      })
    }
  }

  onEditPartner = () => {
    let formData = new FormData()
    formData.append("email", this.getValue(this.state.email))
    formData.append("orgName", this.getValue(this.state.orgName))
    formData.append("orgNumber", this.getValue(this.state.orgNumber))
    formData.append("orgVat", this.getValue(this.state.orgVat))
    formData.append("iban", this.getValue(this.state.iban))
    formData.append("orgAddress", this.getValue(this.state.orgAddress))
    formData.append("orgCity", this.getValue(this.state.orgCity))
    formData.append("orgZip", this.getValue(this.state.orgZip))
    formData.append("orgUrl", this.getValue(this.state.orgUrl))
    if (this.state.country && this.state.country !== "None") {
      formData.append(
        "country",
        Utils.getCountryId(this.props.countries, this.state.country),
      )
    }

    BackOfficeApi.endpoints.editPartner
      .create(formData, { id: this.props.partner.id })
      .then(response => {
        if (response.ok) {
          if (this.props.onSave) {
            this.props.onSave()
          }
        } else {
          response.text().then(text => {
            if (this.props.onError) {
              this.props.onError(text)
            }
          })
        }
      })
  }

  onToggle = () => {
    if (this.props.toggle) {
      this.props.toggle()
    }
  }

  getValue = value => {
    return value == null ? "" : value
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    if (!this.props.partner) {
      return ""
    }
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.onToggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.onToggle}>Edit Partner</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="email">
                Email
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="email"
                id="email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="orgName">
                Org Name
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="orgName"
                id="orgName"
                value={this.state.orgName}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="orgNumber">
                Org Number
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="orgNumber"
                id="orgNumber"
                value={this.state.orgNumber}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="orgVat">
                Org Vat
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="orgVat"
                id="orgVat"
                value={this.state.orgVat}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="iban">
                IBAN
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="iban"
                id="iban"
                value={this.state.iban}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="orgAddress">
                Org Address
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="orgAddress"
                id="orgAddress"
                value={this.state.orgAddress}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="orgCity">
                Org City
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="orgCity"
                id="orgCity"
                value={this.state.orgCity}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="orgZip">
                Org Zip
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="orgZip"
                id="orgZip"
                value={this.state.orgZip}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="orgUrl">
                Org Url
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="orgUrl"
                id="orgUrl"
                value={this.state.orgUrl}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="country">
                Org Country
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.country}
                  name="country"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  <option value="None">None</option>
                  {Utils.getCountriesOptions(this.props.countries)}
                </select>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onEditPartner}>
            Save
          </Button>
          <Button color="secondary" onClick={this.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default EditPartnerModal
