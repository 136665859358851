import React, { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import ImagePicker from "react-image-picker"
import BackOfficeApi from "../Api/BackOfficeApi"
import "react-image-picker/dist/index.css"
import CryptoSelector from "../Common/CryptoSelector"

const SellPofModal = ({ open, toggle, images, onAdd }) => {
  const [state, setState] = useState({
    crypto: "BTC",
    amount: null,
    comment: null,
    image: null,
  })

  const onChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const renderImages = () => {
    if (!images) {
      return ""
    }

    return (
      <ImagePicker
        images={images.map(i => ({
          src: BackOfficeApi.url + "/image?size=m&id=" + i.id,
          value: i.id,
        }))}
        onPick={onPick}
      />
    )
  }

  const onPick = image => {
    setState({
      ...state,
      image: image.value,
    })
  }

  const onSave = () => {
    let pof = {}
    pof.crypto = state.crypto
    pof.amount = state.amount
    pof.comment = state.comment
    pof.image = state.image

    if (onAdd) {
      onAdd(pof)
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={toggle}>Add Source Of Crypto</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="crypto">
              Crypto
            </Label>
            <CryptoSelector
              value={state.crypto}
              className={"float-right mr-3"}
              onChange={value => {
                setState({
                  ...state,
                  crypto: value,
                })
              }}
            />
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="amount">
              Amount
            </Label>
            <Input
              className="col-xl-8"
              type="number"
              name="amount"
              id="amount"
              value={state.amount}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="comment">
              Comment
            </Label>
            <Input
              className="col-xl-8"
              type="text"
              name="comment"
              id="comment"
              value={state.comment}
              onChange={onChange}
            />
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-12 col-form-label">
              Select image (optional)
            </Label>
            <div
              className="col-xl-12"
              style={{ maxHeight: "300px", overflow: "auto" }}
            >
              <div>{renderImages()}</div>
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="mr-auto" onClick={onSave}>
          {"Add"}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SellPofModal
