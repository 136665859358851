import React, { Component } from "react"
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"
import moment from "moment"
import ValidationImage from "../Common/ValidationImage"
import Utils from "../Common/Utils"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import { parseAsync } from "json2csv"

const saveCsv = (data, filename) => {
  const file = new Blob([data], { type: "text/csv" })
  const a = document.createElement("a")
  const url = URL.createObjectURL(file)
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

class CustomerTinkSection extends Component {
  state = {
    csvLoading: {},
  }

  handleDownloadTransactions = async tinkUser => {
    this.setState({
      csvLoading: {
        ...this.state.csvLoading,
        [tinkUser.tinkId]: true,
      },
    })
    try {
      const response =
        await BackOfficeApi.endpoints.customerTinkTransactions.getOne({
          customerId: this.props.customerId,
          tinkUserId: tinkUser.tinkId,
        })
      if (!response.ok) {
        throw Error()
      }
      const transactions = await response.json()

      if (!transactions || transactions.length === 0) {
        ToastUtils.toastCustomMessage("No transactions found")
        return
      }

      const csv = await parseAsync(transactions)
      await saveCsv(
        csv,
        `POF ${this.props.customerId} - ${tinkUser.tinkId}.csv`,
      )
    } catch (error) {
      ToastUtils.toastAPIError2()
      console.error(error)
    } finally {
      const { [tinkUser.tinkId]: _, ...newValue } = this.state.csvLoading
      this.setState({ csvLoading: newValue })
    }
  }

  renderTinkUsers = () => {
    let tinkRow = []

    if (!this.props.tinkData) {
      return tinkRow
    }

    let tabsSize = this.props.tinkData.tinkUsers.length
    for (let i = 0; i < tabsSize; i++) {
      const tinkUser = this.props.tinkData.tinkUsers[i]
      const tinkPof = tinkUser.tinkPof || {}
      let bank = ""

      for (let j = 0; j < tinkUser.tinkAccounts.length; j++) {
        if (tinkUser.tinkAccounts[j].bank) {
          bank = tinkUser.tinkAccounts[j].bank
          break
        }
      }

      const isLoading = this.state.csvLoading[tinkUser.tinkId]

      tinkRow.push(
        <tr key={tinkUser.created}>
          <td>
            {moment.utc(tinkUser.created).local().format("YYYY-MM-DD HH:mm")}
          </td>
          <td>{Utils.formatValueToSek(tinkPof.yearlySalaryAmount)}</td>
          <td>{Utils.formatValueToSek(tinkPof.yearlyAverageSavingsAmount)}</td>
          <td>{Utils.formatValueToSek(tinkPof.investmentsAverageAmount)}</td>
          <td>{Utils.formatValueToSek(tinkPof.newLimit)}</td>
          <td>
            {moment.utc(tinkPof.created).local().format("YYYY-MM-DD HH:mm")}
          </td>
          <td>{bank}</td>
          <td>
            <ValidationImage valid={tinkPof.approved} />
          </td>
          <td>
            <Button
              size="xs"
              color="primary"
              disabled={isLoading}
              className="safello-font"
              onClick={() => this.handleDownloadTransactions(tinkUser)}
            >
              {isLoading ? "Downloading..." : "Download CSV"}
            </Button>
          </td>
        </tr>,
      )
    }

    return tinkRow
  }

  render() {
    return (
      <Card>
        <CardHeader className="card-section-header">Source of Funds</CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col>
              <Table striped bordered hover responsive={true}>
                <thead>
                  <tr>
                    <th>Tink created</th>
                    <th>Yearly salary</th>
                    <th>Average yearly saving</th>
                    <th>Average Investments</th>
                    <th>New limit</th>
                    <th>Source of Funds created</th>
                    <th>Bank</th>
                    <th>Approved</th>
                    <th>Transactions</th>
                  </tr>
                </thead>
                <tbody>{this.renderTinkUsers()}</tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}
export default CustomerTinkSection
