/**
 * Base API class with CRUD operations.
 * Inspiration from https://github.com/FrancescoSaverioZuppichini/API-Class but with changes.
 */
import StorageUtils from "../Common/StorageUtils"
import ToastUtils from "../Common/ToastUtils"

const isDev = process.env.NODE_ENV === "development"
class API {
  constructor({ url }) {
    this.url = url
    this.endpoints = {}
  }

  createEntity(entity) {
    this.endpoints[entity.name] = this.createBasicCRUDEndpoints(entity)
  }

  createBasicCRUDEndpoints(entity) {
    let endpoints = {}

    const resourceURL = `${this.url}/${entity.path}`

    endpoints.url = resourceURL

    endpoints.getOne = (ids, query) => {
      return this.fetchOrRedirect(
        this.getUrlWithIds(resourceURL, ids) + this.getQueryParams(query),
        this.getDefaultConfigs("GET"),
      )
    }

    endpoints.getAll = query => {
      return this.fetchOrRedirect(
        resourceURL + this.getQueryParams(query),
        this.getDefaultConfigs("GET"),
      )
    }

    endpoints.create = (body, ids, headers, query = {}) => {
      return this.fetchOrRedirect(
        this.getUrlWithIds(resourceURL, ids) + this.getQueryParams(query),
        {
          ...this.getDefaultConfigs("POST"),
          body,
          headers,
        },
      )
    }

    endpoints.post = (ids, query = {}, headers = {}) => {
      return this.fetchOrRedirect(
        this.getUrlWithIds(resourceURL, ids) + this.getQueryParams(query),
        {
          ...this.getDefaultConfigs("POST"),
          headers,
        },
      )
    }

    endpoints.postJson = ({ body, query }) => {
      return this.fetchOrRedirect(
        this.getUrlWithIds(resourceURL) + this.getQueryParams(query),
        {
          ...this.getDefaultConfigs("POST"),
          body: JSON.stringify(body),
          headers: this.getJsonRequestHeaders().headers,
        },
      )
    }

    endpoints.delete = ids => {
      return this.fetchOrRedirect(
        this.getUrlWithIds(resourceURL, ids),
        this.getDefaultConfigs("DELETE"),
      )
    }

    endpoints.upload = (body, ids, headers) => {
      return this.fetchOrRedirect(
        this.getUrlWithIds(resourceURL, ids),
        Object.assign(this.getDefaultConfigs("POST"), { body: body }, headers),
      )
    }

    // For legacy back office endpoints with redirects. Remove this when Hal no longer rely on redirect endpoints.
    endpoints.createLegacyRedirect = (body, ids, headers) => {
      return this.fetchOrRedirect(
        this.getUrlWithIds(resourceURL, ids),
        Object.assign(
          this.getRedirectManualConfigs("POST"),
          { body: body },
          headers,
        ),
      )
    }

    return endpoints
  }

  /**
   * the Fetch function with redirect
   * @param url
   * @param data
   */
  fetchOrRedirect = (url, data) => {
    return fetch(url, data)
      .then(function (response) {
        if (response.status !== 200) {
          console.log(
            "Looks like there was a problem fetching: " +
              url +
              ", Status Code: " +
              response.status,
          )
          //return;
        }
        return response
      })
      .catch(function (err) {
        //redirect to login
        const currentLocation = window.location.href
        if (
          !currentLocation.includes("login") &&
          !currentLocation.includes("password-reset")
        ) {
          if (isDev) {
            console.log("Fetch Error :-S", err)
            ToastUtils.toastCustomError(
              "API fetch error encountered! Access-token might have expired.",
            )
          } else {
            StorageUtils.removeUser()
            window.location.href = "/login?redirectback=" + currentLocation
          }
        }
      })
  }

  /**
   * Returns common configs for a fetch request
   * @param requestType
   */
  getDefaultConfigs = requestType => {
    return { method: requestType, credentials: "include" }
  }

  /**
   * This is for legacy back office endpoints that responds with redirects.
   * These configs will allow redirect but wont follow it.
   * The downside is that you can't determine if the response was a success.
   * Remove this when Hal no longer rely on legacy redirect endpoints.
   * @param requestType
   * @returns {{method: *, credentials: string, redirect: string}}
   */
  getRedirectManualConfigs = requestType => {
    return { method: requestType, credentials: "include", redirect: "manual" }
  }

  /**
   * Replaces id parts of url (for example /user/{userId}) with the given ids
   * @param url
   * @param ids
   * @returns {*}
   */
  getUrlWithIds = (url, ids) => {
    if (!ids) return url
    let objectKeys = Object.keys(ids)
    objectKeys.forEach(key => {
      let keyToFind = "{" + key + "}"
      url = url.replace(keyToFind, ids[key])
    })
    return url
  }

  /**
   * Returns a query param string from an object
   * @param query
   * @returns {string}
   */
  getQueryParams = query => {
    if (!query || Object.keys(query).length === 0) return ""
    let queryParams = "?"
    let objectKeys = Object.keys(query)
    objectKeys.forEach((key, index) => {
      if (index > 0) queryParams += "&"
      queryParams += key + "=" + query[key]
    })
    return queryParams
  }

  /**
   * Returns common headers for a JSON request
   * @returns {{headers: {Accept: string, "Content-Type": string}}}
   */
  getJsonRequestHeaders() {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  }
}

export default API
