import React, { useState } from "react"
import { Tooltip as TooltipStrap } from "reactstrap"
import toolbarIcon from "../../assets/images/toolbarIcon.svg"
function Tooltip({ text, id, style }) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <span style={style}>
      <img
        alt=""
        src={toolbarIcon}
        id={"Tooltip-" + id}
        style={{ marginTop: -5 }}
      />
      <TooltipStrap
        placement={"top"}
        isOpen={tooltipOpen}
        target={"Tooltip-" + id}
        toggle={toggle}
      >
        {text}
      </TooltipStrap>
    </span>
  )
}

export default Tooltip
