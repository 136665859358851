import React, { Component } from "react"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"
import moment from "moment"
import BackOfficeApi from "../Api/BackOfficeApi"

class CustomerSellPofsSection extends Component {
  renderSellPofs = () => {
    let pofRow = []

    if (!this.props.sellPofs) {
      return pofRow
    }

    for (let i = 0; i < this.props.sellPofs.length; i++) {
      let pof = this.props.sellPofs[i]

      pofRow.push(
        <tr key={i}>
          <td>
            {moment.utc(pof.timestamp).local().format("YYYY-MM-DD HH:mm")}
          </td>
          <td>{pof.amount}</td>
          <td>{pof.crypto}</td>
          <td>{pof.comment}</td>
          <td>
            <a
              href={BackOfficeApi.url + "/image?id=" + pof.imageId}
              target="_blank" rel="noreferrer"
            >
              {pof.imageId}
            </a>
          </td>
        </tr>,
      )
    }

    return pofRow
  }

  render() {
    return (
      <Card>
        <CardHeader className="card-section-header">
          Source Of Crypto
        </CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col>
              <Table striped bordered hover responsive={true}>
                <thead>
                  <tr>
                    <th>Created</th>
                    <th>Amount</th>
                    <th>Crypto</th>
                    <th>Comment</th>
                    <th>Document</th>
                  </tr>
                </thead>
                <tbody>{this.renderSellPofs()}</tbody>
              </Table>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}
export default CustomerSellPofsSection
