import React, { Component } from "react"

class SearchBar extends Component {
  render() {
    return (
      <div className="form-inline">
        <span className="pr-2">{this.props.children}</span>
        <input
          type="text"
          onChange={this.props.onChange}
          value={this.props.value}
          placeholder=" search"
          style={{ width: "200px" }}
        />
      </div>
    )
  }
}

export default SearchBar
