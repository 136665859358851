import React from "react"
import { Row, Col, Button } from "reactstrap"
import { CardHeader, CardBody } from "reactstrap"
import WalletUtils from "../WalletUtils"
import Utils from "../../Common/Utils"

const TransactionStatusSection = ({ transaction, onEditTagClick }) => {
  if (!transaction) return <div />

  return (
    <div>
      <Row className="no-gutters">
        <Col xs={6}>
          <CardHeader className="card-section-header">
            Current status
          </CardHeader>
          <CardBody className="pt-0">
            <Row className="no-gutters">
              {WalletUtils.getTransactionStatusBadge(transaction)}
            </Row>
          </CardBody>
        </Col>
        {transaction?.tags && (
          <Col xs={6}>
            <CardHeader className="card-section-header">
              Tags
              <Button
                size="xs"
                color="primary"
                className="float-right safello-font"
                onClick={onEditTagClick}
              >
                Edit tags
              </Button>
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="no-gutters">{Utils.getTags(transaction)}</Row>
            </CardBody>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default TransactionStatusSection
