import React, { Component } from "react"
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import Utils from "../Common/Utils"

const PIE_COLORS = ["#56BAA5", "#29315E", "#F0603A", "#FFD754", "#DEB6C8"]

class BackOfficePieChart extends Component {
  /**
   * Renders cell labels. Recharts default render shows only cell value by default, this has support for more
   * customization.
   * @param labelProps
   * @returns {*}
   */
  renderCustomizedLabel = labelProps => {
    let label
    if (this.props.verboseLabel) {
      label =
        this.props.cells[labelProps.index].name +
        " (" +
        Utils.formatNumber(this.props.cells[labelProps.index].value) +
        ", " +
        Utils.formatNumber(Math.round(labelProps.percent * 100)) +
        "%)"
    } else if (this.props.nameValueLabel) {
      label =
        this.props.cells[labelProps.index].name +
        " (" +
        Utils.formatNumber(this.props.cells[labelProps.index].value) +
        ")"
    } else if (this.props.namePercentageLabel) {
      label =
        this.props.cells[labelProps.index].name +
        " (" +
        Utils.formatNumber(Math.round(labelProps.percent * 100)) +
        "%)"
    } else if (this.props.nameLabel) {
      label = this.props.cells[labelProps.index].name
    } else if (this.props.percentageLabel) {
      label = Utils.formatNumber(Math.round(labelProps.percent * 100)) + "%"
    } else {
      label = this.props.cells[labelProps.index].value
    }

    return (
      <text
        x={labelProps.x}
        y={labelProps.y}
        fill={labelProps.fill}
        textAnchor={labelProps.textAnchor}
      >
        {label}
      </text>
    )
  }

  render() {
    return (
      <div style={{ width: "100%", height: this.props.height || "300px" }}>
        {this.props.headerLabel && (
          <div className="text-center">
            <h5>{this.props.headerLabel}</h5>
          </div>
        )}
        <ResponsiveContainer>
          <PieChart>
            <Pie
              startAngle={this.props.startAngle || 0}
              endAngle={this.props.endAngle || 360}
              dataKey={this.props.dataKey}
              data={this.props.cells}
              outerRadius={this.props.outerRadius || "80%"}
              label={this.props.label ? this.renderCustomizedLabel : false}
            >
              {this.props.cells.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={PIE_COLORS[index]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

BackOfficePieChart.defaultProps = {
  cells: [],
  dataKey: "value",
  label: true,
  verboseLabel: true,
  nameValueLabel: false,
  namePercentageLabel: false,
  nameLabel: false,
  percentageLabel: false,
}

export default BackOfficePieChart
