import React, { Component } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

class YearlyLimitModal extends Component {
  state = {
    newLimit: null,
    comment: null,
    validTo: null,
  }

  onChangeNewLimit = e => {
    this.setState({
      newLimit: e.target.value,
    })
  }

  onChangeComment = e => {
    this.setState({
      comment: e.target.value,
    })
  }

  onChangeValidTo = e => {
    this.setState({
      validTo: e.target.value,
    })
  }

  onUpdate = () => {
    let formData = new FormData()
    formData.append("newLimit", this.state.newLimit)
    if (this.state.validTo) {
      formData.append("validTo", this.state.validTo)
    }
    formData.append("commentText", this.state.comment)

    BackOfficeApi.endpoints.updateYearlyLimit
      .create(formData, { customerId: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          if (this.props.onChange) {
            this.props.onChange()
          }
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  onReset = () => {
    BackOfficeApi.endpoints.resetYearlyLimit
      .post({ customerId: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastSaveSuccess2()
          if (this.props.onChange) {
            this.props.onChange()
          }
        } else {
          ToastUtils.toastSaveError2()
        }
      })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.props.toggle}>Yearly limit</ModalHeader>
        <ModalBody>
          <div>
            <div lang="en-US">
              <p>New limit</p>
              <input
                type="number"
                id="newLimit"
                style={{ width: "100%" }}
                value={this.state.newLimit}
                onChange={this.onChangeNewLimit}
              />
            </div>
            <div className="mt-2">
              <p>{"Valid to (don't change for ∞)"}</p>
              <input
                type="date"
                id="validTo"
                value={this.state.validTo}
                onChange={this.onChangeValidTo}
              />
            </div>
            <div className="mt-2">
              <p>Comment</p>
              <textarea
                id="comment"
                placeholder="some kind of reason..."
                rows="6"
                name="comment"
                style={{ width: "100%" }}
                value={this.state.comment}
                onChange={this.onChangeComment}
              ></textarea>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="mr-auto" onClick={this.onUpdate}>
            Update
          </Button>
          <Button color="danger" className="mr-auto" onClick={this.onReset}>
            Reset
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default YearlyLimitModal
