import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
} from "reactstrap"

// utils
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"
import { useEffect } from "react"
const CustomerTakeoverModal = ({
  isOpen,
  onClose,
  onProceed,
  customerId,
  oldPnr,
}) => {
  const [inputtedPnr, setInputtedPnr] = useState(oldPnr)
  const handlePnrChange = e => {
    setInputtedPnr(e.target.value)
  }
  const onButtonPress = async () => {
    if (!inputtedPnr) {
      ToastUtils.toastCustomError("Please fill in a personal number")
      return
    } else if (inputtedPnr === oldPnr) {
      ToastUtils.toastCustomError("Please give a new personal number")
      return
    }
    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: Customer takeover with personal number \n
      - Customer Id: #${customerId}\n
      - New personal number: ${inputtedPnr}\n\n
      If the chosen personal number is already allocated to another customer, it will be replaced with a new randomly generated personal number on the existing account.`,
    )
    if (confirmed) {
      onClose()
      onProceed(inputtedPnr)
    }
  }

  useEffect(() => {
    if (oldPnr && isOpen) {
      setInputtedPnr(oldPnr)
    }
  }, [oldPnr, isOpen])

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onClose}>Customer Takeover</ModalHeader>
      <ModalBody>
        {/* Customer ID */}
        <Label>Customer ID</Label>
        <Input
          type="text"
          name="customerId"
          id="customerId"
          value={customerId}
          disabled
          style={{ maxWidth: 240, marginBottom: 24 }}
        />

        {/* Personal number input */}
        <Label>New Personal Number</Label>
        <Input
          type="text"
          name="personalNumber"
          id="personalNumber"
          placeholder="Enter Personal Number"
          onChange={handlePnrChange}
          value={inputtedPnr}
          style={{ maxWidth: 240, marginBottom: 24 }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onButtonPress(false)}>
          Takeover Customer
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CustomerTakeoverModal
