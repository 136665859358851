import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import {
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Button,
} from "reactstrap"
import CustomDropdownItem from "../Common/CustomDropdownItem"
import ReactDataGrid from "react-data-grid"
import DataGridEmpty from "../Common/DataGridEmpty"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"
import Utils from "../Common/Utils"
import { Link } from "react-router-dom"

class Partners extends Component {
  state = {
    sortStatus: "active",
    sortStatusValue: "Active",
    dropdownStatus: false,
    rows: [],
  }
  constructor(props, context) {
    super(props, context)

    this._columns = [
      {
        key: "id",
        name: "Id",
        sortable: true,
        width: 30,
        formatter: this.IdFormatter,
      },
      {
        key: "appIds",
        name: "App ids",
        width: 75,
      },
      {
        key: "email",
        name: "E-mail",
        width: 300,
        sortable: true,
        resizable: true,
      },
      {
        key: "orgUrl",
        name: "Org url",
        sortable: true,
        resizable: true,
      },
      {
        key: "orgName",
        name: "Org name",
        width: 80,
        resizable: true,
      },
      {
        key: "country",
        name: "Country",
        width: 70,
      },
      {
        key: "orgNumber",
        name: "Org number",
        width: 110,
        resizable: true,
      },
      {
        key: "orderCount",
        name: "Orders",
        sortable: true,
        formatter: this.AmountFormatter,
        width: 60,
      },
      {
        key: "orderVolumeAndCurrency",
        name: "Volume",
        formatter: this.AmountCurrencyFormatter,
        width: 100,
      },
      {
        key: "latestOrderDate",
        name: "Last order",
        formatter: DataGridLocalDateTimeFormatter,
        width: 125,
      },
      {
        key: "created",
        name: "Created",
        sortable: true,
        formatter: DataGridLocalDateTimeFormatter,
        width: 125,
      },
      {
        key: "actions",
        name: "Actions",
        formatter: this.ActionsFormatter,
        width: 100,
      },
    ]
  }

  toggleStatus = () => {
    this.setState(prevState => ({
      dropdownStatus: !prevState.dropdownStatus,
    }))
  }

  changeSortStatus = (value, label) => {
    this.setState({
      sortStatus: value,
      sortStatusValue: label,
    })
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.sortStatus !== prevState.sortStatus) {
      this.loadPartners()
    }
  }

  activatePartner = id => {
    Utils.showSweetAlertAreYouSure(
      "Are you sure you want to activate partner with id " + id + "?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        BackOfficeApi.endpoints.activatePartner
          .post({ id: id })
          .then(response => {
            if (response.ok) {
              this.loadPartners()
            } else {
              ToastUtils.toastAPIError2()
            }
          })
      }
    })
  }

  deactivatePartner = id => {
    Utils.showSweetAlertAreYouSure(
      "Are you sure you want to deactivate partner with id " + id + "?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        BackOfficeApi.endpoints.deactivatePartner
          .post({ id: id })
          .then(response => {
            if (response.ok) {
              this.loadPartners()
            } else {
              ToastUtils.toastAPIError2()
            }
          })
      }
    })
  }

  loadPartners = () => {
    BackOfficeApi.endpoints.partners
      .getAll({ status: this.state.sortStatus })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          originalRows: data,
          rows: data.slice(0),
          isLoading: false,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    })

    this.loadPartners()
  }

  rowGetter = i => {
    const row = this.state.rows[i]
    if (row) {
      row.orderVolumeAndCurrency = {
        amount: row.orderVolume,
        currency: row.orderVolumeCurrency,
      }

      row.actions = {
        partnerId: row.id,
        active: row.active,
      }
    }
    return row
  }

  handleGridSort = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1
      }
    }

    const rows =
      sortDirection === "NONE"
        ? this.state.originalRows.slice(0)
        : this.state.rows.sort(comparer)

    this.setState({ rows })
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Partner list</div>
          <div className="ml-auto form-inline">
            <Dropdown
              isOpen={this.state.dropdownStatus}
              toggle={this.toggleStatus}
            >
              <DropdownToggle color="primary" caret>
                Status: {this.state.sortStatusValue}
              </DropdownToggle>
              <DropdownMenu>
                <CustomDropdownItem
                  value="all"
                  onSelect={this.changeSortStatus}
                >
                  All
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="active"
                  onSelect={this.changeSortStatus}
                >
                  Active
                </CustomDropdownItem>
                <CustomDropdownItem
                  value="inactive"
                  onSelect={this.changeSortStatus}
                >
                  Inactive
                </CustomDropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <Container fluid>
          <ReactDataGrid
            onGridSort={this.handleGridSort}
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={150}
          />
        </Container>
      </ContentWrapper>
    )
  }

  PercentageFormatter = props => {
    return props.value * 100 + "%"
  }

  AmountFormatter = props => {
    const style = {
      textAlign: "right",
      display: "inline-block",
      width: "100%",
    }
    let amountString = ""
    if (props.value) {
      amountString = Utils.formatNumber(props.value)
    } else {
      amountString = "0"
    }
    return <span style={style}>{amountString}</span>
  }

  AmountCurrencyFormatter = props => {
    const style = {
      textAlign: "right",
      display: "inline-block",
      width: "100%",
    }
    let amountString = ""
    if (props.value.amount) {
      amountString =
        Utils.formatNumber(props.value.amount) + " " + props.value.currency
    } else {
      amountString = "0 " + props.value.currency
    }
    return <span style={style}>{amountString}</span>
  }

  ActionsFormatter = props => {
    if (props.value.active) {
      return (
        <Button
          size="sm"
          color="danger"
          className="pr-2 pl-2"
          onClick={() => this.deactivatePartner(props.value.partnerId)}
        >
          Deactivate
        </Button>
      )
    } else {
      return (
        <Button
          size="sm"
          color="primary"
          className="pr-2 pl-2"
          onClick={() => this.activatePartner(props.value.partnerId)}
        >
          Activate
        </Button>
      )
    }
  }

  IdFormatter = props => {
    return <Link to={"/partners/partner/" + props.value}>{props.value}</Link>
  }
}

export default Partners
