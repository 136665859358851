import React, { Component } from "react"
import { Card, CardBody, Button } from "reactstrap"
import OrderUtils from "../OrderUtils"
import Utils from "../../Common/Utils"

class BuyOrderActionsArea extends Component {
  forwardAction = action => {
    if (this.props.onActionPerformed) {
      this.props.onActionPerformed(action)
    }
  }

  onBanlistAddressClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Banlist this order address?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        this.forwardAction("banlist_address")
      }
    })
  }

  onFreezeClicked = () => {
    Utils.showSweetAlertAreYouSure("Freeze this order?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_freeze")
        }
      },
    )
  }

  onUnfreezeClicked = () => {
    Utils.showSweetAlertAreYouSure("Unfreeze this order?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_unfreeze")
        }
      },
    )
  }

  onDeleteClicked = () => {
    Utils.showSweetAlertAreYouSure("Delete this order?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_delete")
        }
      },
    )
  }

  onUndeleteClicked = () => {
    Utils.showSweetAlertAreYouSure("Undelete this order?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_undelete")
        }
      },
    )
  }

  onSendCryptoClicked = () => {
    Utils.showSweetAlertAreYouSure("Send crypto?", "", null).then(confirmed => {
      if (confirmed) {
        this.forwardAction("order_send_crypto")
      }
    })
  }

  render() {
    let buttons = this.getButtonList()

    return (
      <div>
        <span className="header-area">Actions</span>
        <Card className="card-area">
          <CardBody
            className={this.getLoadingClass()}
            style={
              this.props.isLoading && buttons.length === 0
                ? { minHeight: "100px" }
                : {}
            }
          >
            <div style={{ textAlign: "center" }}>
              {buttons.map((item, i) => {
                let className = "action_button"
                if (i + 1 < buttons.length) className += " mb-2"
                return (
                  <Button
                    key={i}
                    className={className}
                    style={{ background: item.background }}
                    onClick={item.onClick}
                  >
                    <i className={"fas fa-" + item.icon}></i>
                    {item.title}
                  </Button>
                )
              })}
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  getLoadingClass = () => {
    if (!this.props.isLoading) return ""
    return "whirl standard"
  }

  getButtonList = () => {
    let buttons = []
    const order = this.props.order
    if (!order) return buttons

    if (OrderUtils.isCurrentBuyOrderStatusIssued(order)) {
      buttons.push({
        title: "Delete",
        background: "#f47f7f",
        icon: "trash",
        onClick: this.onDeleteClicked,
      })
    } else {
      if (OrderUtils.isCurrentBuyOrderStatusPaid(order)) {
        buttons.push({
          title: "Edit",
          background: "#5d9cec",
          icon: "edit",
          onClick: () => this.forwardAction("order_edit"),
        })
        buttons.push({
          title: "Trade now",
          background: "#37bc9b",
          icon: "exchange-alt",
          onClick: () => this.forwardAction("order_trade"),
        })
        buttons.push(this.getRefundButton())
      } else if (OrderUtils.isCurrentBuyOrderStatusFrozen(order)) {
        buttons.push(this.getRefundButton())
      } else if (OrderUtils.isCurrentBuyOrderStatusTradeCompleted(order)) {
        buttons.push({
          title: "Send crypto",
          background: "#27c24c",
          icon: "paper-plane",
          onClick: this.onSendCryptoClicked,
        })
      }

      if (order.deleted) {
        buttons.push({
          title: "Undelete",
          background: "#f47f7f",
          icon: "trash",
          onClick: this.onUndeleteClicked,
        })
      }

      buttons.push(
        {
          title: "Report (XML)",
          background: "#243948",
          icon: "flag",
          onClick: () => this.forwardAction("report_xml"),
        },
        {
          title: "Fast XML export",
          background: "#131e26",
          icon: "download",
          onClick: () => this.forwardAction("fast_xml_export"),
        },
      )
    }

    if (OrderUtils.isCurrentBuyOrderStatusFrozen(order)) {
      buttons.push({
        title: "Unfreeze",
        background: "#ff902b",
        icon: "snowflake",
        onClick: this.onUnfreezeClicked,
      })
    } else if (
      !OrderUtils.isCurrentBuyOrderStatusCompleted(order) &&
      !OrderUtils.isCurrentBuyOrderStatusRefunded(order)
    ) {
      buttons.push({
        title: "Freeze",
        background: "#ff902b",
        icon: "snowflake",
        onClick: this.onFreezeClicked,
      })
    }

    if (!order.walletOrder) {
      buttons.push({
        title: "Banlist address",
        background: "#000000",
        onClick: this.onBanlistAddressClicked,
      })
    }

    return buttons
  }

  getRefundButton = () => {
    return {
      title: "Refund",
      background: "#7266ba",
      icon: "undo",
      onClick: () => this.forwardAction("order_refund"),
    }
  }
}

export default BuyOrderActionsArea
