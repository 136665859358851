import React, { useState, useEffect } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
// utils
import { fetchTags } from "../../utils/fetch"
import ToastUtils from "./ToastUtils"

/*
type type = "WALLET_TRANSACTION" | "ORDER" | "SWAP_ORDER"
*/
const EditTags = ({
  type,
  preFetchedTags,
  order,
  ids,
  toggle,
  open,
  onChange,
  isAggregated,
}) => {
  const [tags, setTags] = useState(preFetchedTags || [])
  const [chosenTags, setChosenTags] = useState([])
  const getTags = async () => {
    const fetchedTags = await fetchTags(type)
    setTags(fetchedTags)
  }

  useEffect(() => {
    if (!preFetchedTags) getTags()
  }, [])

  const renderActiveTags = () => {
    let activeTags = []

    if (order) {
      order.tags.forEach(tag => {
        activeTags.push(
          <div
            className="tag-badge"
            key={`tag-id-${tag.id}`}
            style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
          >
            {tag.name}
            <Button
              size="xs"
              color="danger"
              className="ml-2"
              onClick={() => removeTag(tag)}
            >
              -
            </Button>
          </div>,
        )
      })

      if (activeTags?.length > 0) {
        activeTags.unshift(<div key="header">Remove tags</div>)
      }
    }
    return activeTags
  }

  const renderInactiveTags = () => {
    let inactiveTags = []
    if (order && tags?.length > 0) {
      let filterTags = tags.filter(tag => {
        return !order.tags.some(e => e.id === tag.id) && !tag.hidden
      })

      filterTags.forEach(tag => {
        inactiveTags.push(
          <div
            className="tag-badge"
            key={tag.id}
            style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
          >
            {tag.name}
            <Button
              size="xs"
              color="success"
              className="ml-2"
              onClick={() => addTag(tag)}
            >
              +
            </Button>
          </div>,
        )
      })
    }
    return inactiveTags
  }

  const renderAllTags = stateTags => {
    let renderTags = []
    if (stateTags && stateTags?.length > 0) {
      stateTags.forEach(tag => {
        if (!tag.hidden) {
          renderTags.push(
            <div
              className="tag-badge"
              key={tag.id}
              style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
            >
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={tag.id}
                  defaultChecked={chosenTags.includes(tag.id)}
                  onChange={handleTagClick}
                  value={tag.id}
                />
                <label
                  className="custom-control-label"
                  htmlFor={tag.id}
                  style={{ fontSize: "15px", lineHeight: "22px" }}
                >
                  {tag.name}
                </label>
              </div>
            </div>,
          )
        }
      })
      renderTags.unshift(<div key="header">Choose tags to add/remove</div>)
    }
    return renderTags
  }

  const handleTagClick = e => {
    const checked = e.target.checked
    const checkValue = Number(e.target.value)
    let newChosenTags = chosenTags
    const index = newChosenTags.indexOf(checkValue)

    if (checked) {
      if (index === -1) {
        newChosenTags.push(checkValue)
      }
    } else {
      if (index > -1) {
        newChosenTags.splice(index, 1)
      }
    }
    setChosenTags(newChosenTags)
  }

  const removeTag = tag => {
    let formData = new FormData()
    formData.append("type", type)
    formData.append("tagId", tag.id)

    const id = type === "ORDER" ? order.orderMapId : order.id

    BackOfficeApi.endpoints.removeTag
      .create(formData, { id })
      .then(response => {
        if (response.ok) {
          onChange()
        } else {
          ToastUtils.toastExecuteError2()
        }
      })
  }

  const addTag = tag => {
    let formData = new FormData()
    formData.append("type", type)
    formData.append("tagId", tag.id)

    const id = type === "ORDER" ? order.orderMapId : order.id

    BackOfficeApi.endpoints.addTag.create(formData, { id }).then(response => {
      if (response.ok) {
        onChange()
      } else {
        ToastUtils.toastExecuteError2()
      }
    })
  }

  const doMultiTagsAction = (action, type, orders) => {
    if (!orders || orders?.length === 0) return
    const tags = chosenTags
    let formData = new FormData()
    formData.append("type", type)
    formData.append("tagIds", tags)
    formData.append("ids", orders)

    toggle()

    if (action === "add") {
      BackOfficeApi.endpoints.batchAddTags.create(formData).then(response => {
        if (response.ok) {
          onChange()
        }
      })
    }

    if (action === "delete") {
      BackOfficeApi.endpoints.batchRemoveTags
        .create(formData)
        .then(response => {
          if (response.ok) {
            onChange()
          }
        })
    }
  }

  const separateOrderTypes = stringOrders => {
    let walletTransactions = []
    let orders = []
    let swapOrders = []
    stringOrders.forEach(order => {
      const [type, id] = order.split("-")
      if (type === "BUY" || type === "SELL") {
        orders.push(Number(id))
      } else if (type === "WITHDRAWAL" || type === "DEPOSIT") {
        walletTransactions.push(Number(id))
      } else if (type === "SWAP") {
        swapOrders.push(id)
      } else {
        console.error("Order type not supported for adding tags!")
      }
    })
    return { walletTransactions, orders, swapOrders }
  }

  const multiTags = action => {
    if (isAggregated) {
      const allOrders = separateOrderTypes(ids)
      doMultiTagsAction(
        action,
        "WALLET_TRANSACTION",
        allOrders.walletTransactions,
      )
      doMultiTagsAction(action, "ORDER", allOrders.orders)
      doMultiTagsAction(action, "SWAP_ORDER", allOrders.swapOrders)
    } else {
      doMultiTagsAction(action, type, ids)
    }
  }

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit tags</ModalHeader>
      <ModalBody>
        {order && (
          <div>
            <div>{renderActiveTags()}</div>
            <div key="header">Add tags</div>
            <div>{renderInactiveTags()}</div>
          </div>
        )}
        {ids && <div>{renderAllTags(tags)}</div>}
      </ModalBody>
      <ModalFooter>
        {ids && (
          <div>
            <Button color="success" onClick={() => multiTags("add")}>
              Add tags
            </Button>{" "}
            <Button color="danger" onClick={() => multiTags("delete")}>
              Delete tags
            </Button>
          </div>
        )}
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default EditTags
