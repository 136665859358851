import React from "react"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"

export default function AccountingCard({ title, loading, inputs, buttons }) {
  return (
    <Col lg={12}>
      <Card>
        <CardHeader>{title}</CardHeader>
        <CardBody className="text-center">
          <Row>
            {inputs.map((inputData, i) => (
              <Col key={i}>
                {inputData.title}
                <input
                  type={inputData.type}
                  value={inputData.value}
                  onChange={inputData.onChange}
                  className="form-control"
                />
              </Col>
            ))}
          </Row>
          <div className="mt-4">
            {buttons.map((buttonData, i) => (
              <Button
                className="ml-2"
                color="primary"
                onClick={buttonData.onClick}
                disabled={loading}
                key={i}
              >
                <em className="fa mr-2 fas fa-cloud-download-alt"></em>
                {buttonData.title}
              </Button>
            ))}
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}
