import React, { Component } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import Utils from "../Common/Utils"

class PartnerAppApi extends Component {
  state = {
    menuOpen: false,
  }

  menuToggle = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    })
  }

  onEditClick = () => {
    if (this.props.onEdit) {
      this.props.onEdit(this.props.appApi)
    }
  }

  onNewFeeRevisionClick = () => {
    if (this.props.onNewFeeRevision) {
      this.props.onNewFeeRevision(this.props.appApi)
    }
  }

  renderLockedChannels = () => {
    let a = this.props.appApi
    if (a.lockedChannels.length === 0) {
      return "None"
    } else {
      return a.lockedChannels.join(", ")
    }
  }

  render() {
    if (!this.props.appApi) return <div />

    let a = this.props.appApi

    return (
      <Card className="card shadow-sm border">
        <CardHeader>
          <div className="card-tool float-right">
            <Dropdown isOpen={this.state.menuOpen} toggle={this.menuToggle}>
              <DropdownToggle>
                <span className="icon-menu" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.onEditClick}>Edit</DropdownItem>
                <DropdownItem onClick={this.onNewFeeRevisionClick}>
                  New fee revision
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <h4>{a.appId}</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <div className="details-row">
                <span className="details-header">App ID:</span>
                <span className="details-value">{a.id}</span>
              </div>
              <div className="details-row">
                <span className="details-header">App Name:</span>
                <span className="details-value">{a.appName}</span>
              </div>
              <div className="details-row">
                <span className="details-header">Locked channels:</span>
                <span className="details-value">
                  {this.renderLockedChannels()}
                </span>
              </div>
              <div className="details-row">
                <span className="details-header">Created:</span>
                <span className="details-value">
                  {Utils.getUtcInLocalDateTime(a.created)}
                </span>
              </div>
            </Col>
            <Col>
              <div className="details-row">
                <span className="details-header">Redirect URL: </span>
                <span className="details-value">{a.redirectUrl}</span>
              </div>
              <div className="details-row">
                <span className="details-header">Iframe domain:</span>
                <span className="details-value">{a.iframeDomain}</span>
              </div>
              <div className="details-row">
                <span className="details-header">Webview integration:</span>
                <span className="details-value">
                  {a.webviewIntegration ? "Yes" : "No"}
                </span>
              </div>
              <div className="details-row">
                <span className="details-header">Mobile app:</span>
                <span className="details-value">
                  {a.isMobileApp ? "Yes" : "No"}
                </span>
              </div>
              <div className="details-row">
                <span className="details-header">Primary color:</span>
                <span className="details-value">{a.themeColorPrimary}</span>
              </div>
              <div className="details-row">
                <span className="details-header">Secondary color:</span>
                <span className="details-value">{a.themeColorSecondary}</span>
              </div>
            </Col>
            <Col>
              <div className="details-row">
                <span className="details-header">Internal buy fee:</span>
                <span className="details-value">
                  {a.currentFeeRevision.internalBuyFee * 100}%
                </span>
              </div>
              <div className="details-row">
                <span className="details-header">Internal sell fee:</span>
                <span className="details-value">
                  {a.currentFeeRevision.internalSellFee * 100}%
                </span>
              </div>
              <div className="details-row">
                <span className="details-header">External buy fee:</span>
                <span className="details-value">
                  {a.currentFeeRevision.externalBuyFee * 100}%
                </span>
              </div>
              <div className="details-row">
                <span className="details-header">External sell fee:</span>
                <span className="details-value">
                  {a.currentFeeRevision.externalSellFee * 100}%
                </span>
              </div>
              <div className="details-row">
                <span className="details-header">Fee revision date:</span>
                <span className="details-value">
                  {Utils.getUtcInLocalDateTime(a.currentFeeRevision.created)}
                </span>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}
export default PartnerAppApi
