import React, { useState, useEffect } from "react"
import { Row, Col, CardBody } from "reactstrap"
import Utils from "../../Common/Utils"
import { Cell, Pie, PieChart, ResponsiveContainer, Legend } from "recharts"
import OrderUtils from "../OrderUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"

const SellOrderDetailsSection = props => {
  const [discountFee, setDiscountFee] = useState(null)

  const order = props.order

  const copyToClipboard = e => {
    let copyText = document.createElement("input")
    document.body.appendChild(copyText)
    copyText.value = e.target.getAttribute("data-copy")
    copyText.select()
    document.execCommand("copy", false)
    //alert("Copied: " + copyText.value);
    copyText.remove()
    let target = e.target
    target.style.color = "rgb(231, 132, 53)"
    setTimeout(function () {
      target.style.color = "rgb(106, 191, 91)"
    }, 1000)
  }

  const getSafelloSharePieData = () => {
    let cells = [
      {
        name: "Safello",
        value: order.amountShareSafello,
        color: "#34b094",
      },
      {
        name: "Customer",
        value: order.amountShareCustomer,
        color: "#dcdcdc",
      },
    ]

    let amountShareApi = order.amountShareApi || 0
    let percentage =
      (order.amountShareSafello /
        (order.amountShareSafello +
          order.amountShareCustomer +
          amountShareApi)) *
      100
    let payload = [
      {
        value: roundShareValue(order.amountShareSafello),
        currency: order.currency,
        percent: roundShareValue(percentage),
        className: "safello-share-value",
      },
    ]

    return { cells: cells, payload: payload }
  }

  const getPartnerSharePieData = () => {
    if (!order.hasPartnerShares) return undefined

    let cells = [
      {
        name: order.appName,
        value: order.amountShareApi,
        color: "#1285e5",
      },
      {
        name: "Customer",
        value: order.amountShareCustomer,
        color: "#dcdcdc",
      },
    ]

    let percentage =
      (order.amountShareApi /
        (order.amountShareSafello +
          order.amountShareCustomer +
          order.amountShareApi)) *
      100
    let payload = [
      {
        value: roundShareValue(order.amountShareApi) || 0,
        currency: order.currency,
        percent: roundShareValue(percentage) || 0,
        className: "partner-share-value",
      },
    ]

    return { cells: cells, payload: payload }
  }

  const shareLegendRender = props => {
    const { payload } = props
    return payload.map((item, i) => {
      return (
        <p className={item.className} key={i} style={{ color: item.color }}>
          {Utils.formatNumber(item.value)} {item.currency} (
          {Utils.formatNumber(item.percent)}%)
        </p>
      )
    })
  }

  const roundShareValue = value => {
    return Math.round(value * 100) / 100
  }

  const getDiscount = async discountFeeId => {
    if (!discountFeeId) {
      return
    }
    try {
      const res = await BackOfficeApi.endpoints.getDiscount.getOne({
        discountFeeId: discountFeeId,
      })
      if (res.ok) {
        const data = await res.json()

        if (data) setDiscountFee(data)
      } else throw new Error()
    } catch (e) {
      console.error(e)
    }
  }

  const renderPayoutDetails = () => {
    if (!order.payoutDetails) {
      return ""
    }

    if (order.payoutMethod === 1) {
      return (
        <tr>
          <td className="details-header">Payout details:</td>
          <td className="details-value" align="right">
            {JSON.stringify(order.payoutDetails)}
          </td>
        </tr>
      )
    }
  }

  useEffect(() => {
    if (order && order.discountFee && discountFee === null) {
      getDiscount(order.discountFee)
    }
  }, [order])

  if (!order) return <div />

  let safelloSharePie = getSafelloSharePieData()
  let partnerSharePie = getPartnerSharePieData()

  let copyStyle = {
    color: "rgb(106, 191, 91)",
    cursor: "pointer",
  }

  let txHash = order.txid
  txHash = txHash
    ? txHash.substring(0, 10) + "..." + txHash.substr(txHash.length - 10)
    : null

  return (
    <div>
      <CardBody className="pt-0">
        <Row className="no-gutters">
          <Col lg={12}>
            <p
              className="card-section-header"
              style={{ padding: "0.75rem 1.25rem 0.75rem 0" }}
            >
              Order
            </p>
            <Col lg={12}>
              <table className="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td className="details-header">Crypto:</td>
                    <td className="details-value" align="right">
                      {order.cryptoCurrency}
                    </td>
                  </tr>
                  {txHash && (
                    <tr>
                      <td className="details-header">Transaction:</td>
                      <td className="details-value" align="right">
                        <a
                          href={OrderUtils.getCryptoBlockExplorerUrl(
                            order.cryptoCurrency,
                            order.txid,
                            "tx",
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {txHash}
                        </a>{" "}
                        {document.queryCommandSupported("copy") && (
                          <i
                            className="fas fa-copy"
                            style={copyStyle}
                            data-copy={order.txid}
                            onClick={copyToClipboard}
                          ></i>
                        )}
                      </td>
                    </tr>
                  )}
                  {!order.walletOrder && (
                    <tr>
                      <td className="details-header">Received to our:</td>
                      <td className="details-value" align="right">
                        <a
                          href={OrderUtils.getCryptoBlockExplorerUrl(
                            order.cryptoCurrency,
                            order.cryptoAddress,
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {order.cryptoAddress}
                        </a>{" "}
                        {document.queryCommandSupported("copy") && (
                          <i
                            className="fas fa-copy"
                            style={copyStyle}
                            data-copy={order.cryptoAddress}
                            onClick={copyToClipboard}
                          ></i>
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="details-header">Placed at:</td>
                    <td className="details-value" align="right">
                      {order.apiName || "Safello"}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Expected amount :</td>
                    <td className="details-value" align="right">
                      {order.amount.toLocaleString("sv-SE", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 10,
                      })}{" "}
                      {order.cryptoCurrency}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Received amount:</td>
                    <td className="details-value" align="right">
                      {order.receivedAmount
                        ? order.receivedAmount.toLocaleString("sv-SE", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 10,
                          })
                        : 0}{" "}
                      {order.cryptoCurrency}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Sold amount:</td>
                    <td className="details-value" align="right">
                      {Utils.formatNumber(order.soldAmount)} {order.currency}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Payout amount :</td>
                    <td className="details-value" align="right">
                      {Utils.formatNumber(order.payoutAmount)} {order.currency}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Fees:</td>
                    <td className="details-value" align="right">
                      {Utils.formatNumber(order.feeAmount)} {order.currency}
                    </td>
                  </tr>
                  {discountFee && (
                    <tr>
                      <td className="details-header">Campaign:</td>
                      <td className="details-value" align="right">
                        {discountFee.name}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="details-header">Bank info:</td>
                    <td className="details-value" align="right">
                      {order.routingAddress} - {order.accountNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Bank:</td>
                    <td className="details-value" align="right">
                      {order.bank}
                    </td>
                  </tr>
                  {renderPayoutDetails(order.payoutDetails)}
                  <tr>
                    <td className="details-header">Payout method:</td>
                    <td className="details-value" align="right">
                      {order.payoutMethod /*TODO map to name*/}
                    </td>
                  </tr>
                  <tr>
                    <td className="details-header">Payout channel:</td>
                    <td className="details-value" align="right">
                      {order.payoutChannel /*TODO map to name*/}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col lg={6}>
            <p
              className="card-section-header"
              style={{ padding: "0.75rem 1.25rem 0.75rem 0" }}
            >
              {"Safello's share"}
            </p>
            <Col className="pl-0 pr-0">
              <div
                style={{
                  position: "relative",
                  width: "80%",
                  height: "100px",
                  minWidth: "100px",
                }}
              >
                <ResponsiveContainer style={{ display: "inline-block" }}>
                  <PieChart>
                    <Pie
                      startAngle={90}
                      endAngle={470}
                      cx={50}
                      dataKey="value"
                      data={safelloSharePie.cells}
                      outerRadius="100%"
                      label={false}
                    >
                      {safelloSharePie.cells.map((item, index) => (
                        <Cell key={`cell-${index}`} fill={item.color} />
                      ))}
                    </Pie>
                    <Legend
                      layout="vertical"
                      verticalAlign="middle"
                      align="right"
                      content={shareLegendRender}
                      payload={safelloSharePie.payload}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col>
            {partnerSharePie && (
              <div>
                <p
                  className="card-section-header"
                  style={{ padding: "0.75rem 1.25rem 0.75rem 1rem" }}
                >
                  {order.apiName} share
                </p>
                <Col className="pl-0 pr-0">
                  <div
                    style={{
                      position: "relative",
                      width: "80%",
                      height: "100px",
                      minWidth: "100px",
                    }}
                  >
                    <ResponsiveContainer style={{ display: "inline-block" }}>
                      <PieChart>
                        <Pie
                          startAngle={90}
                          endAngle={470}
                          cx={50}
                          dataKey="value"
                          data={partnerSharePie.cells}
                          outerRadius={"80%"}
                          label={false}
                        >
                          {partnerSharePie.cells.map((item, index) => (
                            <Cell key={`cell-${index}`} fill={item.color} />
                          ))}
                        </Pie>
                        <Legend
                          layout="vertical"
                          verticalAlign="middle"
                          align="right"
                          content={shareLegendRender}
                          payload={partnerSharePie.payload}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </Col>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </div>
  )
}

export default SellOrderDetailsSection
