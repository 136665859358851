import React, { useEffect, useState } from "react"
import { Card, CardBody, Button } from "reactstrap"
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"
import BackOfficeApi from "../Api/BackOfficeApi"
// components
import MultipleAccountsGdprRemovalModal from "../Modals/MultipleAccountsGdprRemovalModal"
import CustomerTakeoverModal from "../Modals/CustomerTakeoverModal"

const CustomerActionsArea = ({ onActionPerformed, isLoading, customer }) => {
  const [buttons, setButtons] = useState([])
  const [customerAccesses, setCustomerAccesses] = useState([])
  const [isLoadingAccesses, setIsLoadingAccesses] = useState(true)

  const [showPopup, setShowPopup] = useState(false)
  const [
    showMultipleAccountsGdprRemovalModal,
    setShowMultipleAccountsGdprRemovalModal,
  ] = useState(false)
  const [showCustomerTakeoverModal, setShowCustomerTakeoverModal] =
    useState(false)
  const isProduction = Utils.isProduction()
  const isDeveloperPermission = Utils.isDeveloperPermission()
  const isCorporate = customer?.type === "Corporate"

  const forwardAction = action => {
    if (onActionPerformed) {
      onActionPerformed(action)
    }
  }

  const togglePopup = () => {
    setShowPopup(!showPopup)
  }

  const fetchCustomerAccesses = async () => {
    const response = await BackOfficeApi.endpoints.getCustomerAccesses.getOne({
      id: customer.id,
    })
    if (response && response.ok) {
      const fetchedCustomerAccesses = await response.json()
      setCustomerAccesses(fetchedCustomerAccesses)
    } else {
      const buttons = getButtonList()
      setIsLoadingAccesses(false)
      setButtons(buttons)
    }
  }

  const forwardGdprRemoveRequest = isSingleAccountRemoval => {
    if (isSingleAccountRemoval) {
      forwardAction("gdpr_single_account_removal_request")
    } else {
      forwardAction("gdpr_removal_request")
    }
  }

  const onGdprRemoveRequestClicked = () => {
    if (customerAccesses.length > 1) {
      setShowMultipleAccountsGdprRemovalModal(true)
    } else {
      Utils.showSweetAlertAreYouSure(
        "Are you sure you want to send the GDPR remove request?",
        "",
        null,
      ).then(confirmed => {
        if (confirmed) {
          forwardGdprRemoveRequest(false)
        }
      })
    }
  }

  const onCustomerTakeover = async pnr => {
    const response = await BackOfficeApi.endpoints.customerTakeover.post(
      {
        customerId: customer?.id,
      },
      {
        pnr,
      },
    )
    if (response?.ok) {
      ToastUtils.toastExecuteSuccess2()
      forwardAction("customer_takeover")
    } else {
      Utils.displayError(response)
    }
  }

  const onRemoveGdprRemoveRequestClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Are you sure you want to remove the GDPR remove request?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("remove_gdpr_removal_request")
      }
    })
  }

  const onOffboardClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Are you sure you want to soft offboard customer due to darknet usage",
      "This will freeze the customer and add a tag: darknet",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("customer_offboard")
      }
    })
  }

  const onReviewCustomer = () => {
    Utils.showSweetAlertAreYouSure("Mark customer as reviewed?", "", null).then(
      confirmed => {
        if (confirmed) {
          forwardAction("customer_review")
        }
      },
    )
  }

  const onApproveCustomer = () => {
    Utils.showSweetAlertAreYouSure(
      "Mark corporate customer as approved?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("customer_corporate_approve")
      }
    })
  }

  const onRejectCustomer = () => {
    Utils.showSweetAlertAreYouSure(
      "Mark corporate customer as rejected?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("customer_corporate_reject")
      }
    })
  }

  const onEnableProtectedClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Enable protected status for customer?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("customer_enable_protected")
      }
    })
  }

  const onRemoveProtectedClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Remove protected status for customer?",
      "",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("customer_remove_protected")
      }
    })
  }

  const onBumpTierClicked = () => {
    Utils.showSweetAlertAreYouSure(
      "Bump customer tier?",
      "Customer must have a KYC in order to be bumped to tier 3",
      null,
    ).then(confirmed => {
      if (confirmed) {
        forwardAction("customer_bump_tier")
      }
    })
  }

  const getLoadingClass = () => {
    if (isLoading || isLoadingAccesses) return "whirl standard"
    return ""
  }

  const getButtonList = () => {
    let buttons = []
    if (!customer) return buttons

    buttons.push({
      title: "Edit customer",
      background: "#7266ba",
      icon: "user-edit",
      onClick: () => forwardAction("customer_edit"),
    })

    buttons.push({
      title: "Show/Edit KYC",
      background: "#9289ca",
      icon: "id-card",
      onClick: () => forwardAction("customer_kyc"),
    })

    if (customer.tier !== 3) {
      buttons.push({
        title: "Bump tier",
        background: "#9f98c9",
        icon: "arrow-up",
        onClick: () => onBumpTierClicked(),
      })
    }

    buttons.push({
      title: "Upload document",
      background: "#5d9cec",
      icon: "upload",
      onClick: () => forwardAction("customer_upload"),
    })

    buttons.push({
      title: "Add bank account",
      background: "#8bb8f1",
      icon: "university",
      onClick: () => forwardAction("customer_add_bank_account"),
    })

    buttons.push({
      title: "Create order",
      background: "#8bb8f1",
      icon: "cart-plus",
      onClick: () => forwardAction("customer_create_order"),
    })

    buttons.push({
      title: "Add Source Of Crypto",
      background: "#8bb8f1",
      icon: "money-check-alt",
      onClick: () => forwardAction("customer_add_sell_pof"),
    })

    buttons.push({
      title: "Edit yearly limit",
      background: "#23b7e5",
      icon: "money-check-alt",
      onClick: () => forwardAction("customer_edit_yearly_limit"),
    })

    buttons.push({
      title: "Create/Update Intercom user",
      background: "#51c6ea",
      icon: "comment",
      onClick: () => forwardAction("customer_create_update_intercom"),
    })

    buttons.push({
      title: "Review customer",
      background: "#37bc9b",
      icon: "user-check",
      onClick: onReviewCustomer,
    })

    if (customer.type === "Corporate") {
      buttons.push({
        title: "Approve customer",
        background: "#37bc9b",
        icon: "briefcase",
        onClick: onApproveCustomer,
      })

      buttons.push({
        title: "Reject customer",
        background: "#47bc9b",
        icon: "briefcase",
        onClick: onRejectCustomer,
      })
    }

    buttons.push({
      title: "Export data",
      background: "#58ceb1",
      icon: "download",
      onClick: togglePopup,
    })

    if (customer.viewProtected) {
      buttons.push({
        title: "Remove protected status",
        background: "#fad732",
        icon: "id-card-alt",
        onClick: onRemoveProtectedClicked,
      })
    } else {
      buttons.push({
        title: "Enable protected status",
        background: "#fad732",
        icon: "id-card-alt",
        onClick: onEnableProtectedClicked,
      })
    }

    if (customer.frozen) {
      buttons.push({
        title: "Unfreeze",
        background: "#ffab5e",
        icon: "snowflake",
        onClick: () => forwardAction("customer_unfreeze"),
      })
    } else {
      buttons.push({
        title: "Freeze",
        background: "#ffab5e",
        icon: "snowflake",
        onClick: () => forwardAction("customer_freeze"),
      })
    }

    buttons.push({
      title: "Freeze (Darknet)",
      background: "#ff902b",
      icon: "user-secret",
      onClick: onOffboardClicked,
    })

    if (!customer.gdprRemovalRequestDate) {
      buttons.push({
        title: "GDPR remove request",
        background: "#f77600",
        icon: "trash",
        onClick: () => {
          onGdprRemoveRequestClicked()
        },
      })
    } else {
      buttons.push({
        title: "Remove GDPR remove request",
        background: "#f77600",
        onClick: onRemoveGdprRemoveRequestClicked,
      })
    }

    buttons.push({
      title: "Administration fee",
      background: "#f75f00",
      icon: "",
      onClick: () => forwardAction("administration_fee"),
    })

    if (!isProduction && isDeveloperPermission && !isCorporate) {
      buttons.push({
        title: "Customer takeover",
        background: "#964B00",
        icon: "user-ninja",
        onClick: () => setShowCustomerTakeoverModal(true),
      })
    }
    return buttons
  }

  useEffect(() => {
    if (customer) {
      fetchCustomerAccesses()
    }
  }, [customer])

  useEffect(() => {
    if (customerAccesses.length) {
      const buttons = getButtonList()
      setIsLoadingAccesses(false)
      setButtons(buttons)
    }
  }, [customerAccesses.length, customer])
  return (
    <div>
      <div>
        <span className="header-area">Actions</span>
        <Card className="card-area">
          <CardBody
            className={getLoadingClass()}
            style={
              isLoading || (isLoadingAccesses && buttons.length === 0)
                ? { minHeight: "100px" }
                : {}
            }
          >
            <div style={{ textAlign: "center" }}>
              {showPopup ? <ExportPopup closePopup={togglePopup} /> : null}
              {showPopup
                ? null
                : buttons.map((item, i) => {
                    let className = "action_button"
                    if (i + 1 < buttons.length) className += " mb-2"
                    return (
                      <Button
                        key={i}
                        className={className}
                        style={{ background: item.background }}
                        onClick={item.onClick}
                      >
                        <i className={"fas fa-" + item.icon}></i>
                        {item.title}
                      </Button>
                    )
                  })}
            </div>
          </CardBody>
        </Card>
      </div>
      <MultipleAccountsGdprRemovalModal
        customerAccesses={customerAccesses}
        isOpen={showMultipleAccountsGdprRemovalModal}
        onClose={() => setShowMultipleAccountsGdprRemovalModal(false)}
        onProceed={isSingleAccountRemoval =>
          forwardGdprRemoveRequest(isSingleAccountRemoval)
        }
      />
      <CustomerTakeoverModal
        isOpen={showCustomerTakeoverModal}
        onClose={() => setShowCustomerTakeoverModal(false)}
        customerId={customer?.id}
        oldPnr={customer?.personalNumber}
        onProceed={onCustomerTakeover}
      />
    </div>
  )
}

export default CustomerActionsArea

const ExportPopup = props => {
  const customerId = Utils.getLastUrlSegment(window.location)
  let submitUrl = BackOfficeApi.endpoints.exportCustomer.url
  submitUrl = submitUrl.replace("{id}", customerId)

  return (
    <div style={{ width: "80%", textAlign: "left", margin: "auto auto" }}>
      <h4 style={{ textAlign: "center" }}>User data</h4>
      <form action={submitUrl} method="get">
        <label>
          <input type="radio" name="type" value="gdpr" defaultChecked /> GDPR
        </label>
        <br />
        <label>
          <input type="radio" name="type" value="ler" /> LER
        </label>
        <br />
        <div style={{ textAlign: "center" }}>
          <button className="btn-pill-left btn btn-primary">Download</button>
          <button
            type="button"
            onClick={props.closePopup}
            className="btn-pill-right btn btn-inverse"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}
