import React, { Component } from "react"
import { Card } from "reactstrap"
import OrderStatusSection from "../OrderStatusSection"
import ChecksSection from "../ChecksSection"
import CustomerSection from "../CustomerSection"
import SellOrderDetailsSection from "./SellOrderDetailsSection"
import SellTimeLineSection from "./SellTimeLineSection"
import ChainalysisSection from "../../Common/ChainalysisSection"

class SellOrderInfoArea extends Component {
  render() {
    const { order, isLoading, onEditTagClick } = this.props

    let cardClass = "card-area"
    let cardStyle = {}
    if (isLoading) {
      cardClass += " whirl standard"
      cardStyle = { minHeight: "500px" }
    }

    return (
      <div>
        <span className="header-area">
          Sell order
          <span> {order ? "#" + order.orderId : ""}</span>
        </span>
        <Card className={cardClass} style={cardStyle}>
          <OrderStatusSection
            order={order}
            isSellOrder={true}
            onEditTagClick={onEditTagClick}
          />

          <ChecksSection checks={order && order.checks} />

          <div className="card-section-divider" />

          <CustomerSection
            customerId={order && order.customerId}
            identityVerified={order && order.identityVerified}
            eidVerified={order && order.eidVerified}
            bankAccountVerified={order && order.bankAccountVerified}
          />

          <div className="card-section-divider" />

          {(!order || !order.walletOrder) && (
            <ChainalysisSection
              user={order ? order.customerId : null}
              data={order}
              isSellOrder={true}
            />
          )}

          <div className="card-section-divider" />

          <SellOrderDetailsSection order={order} />

          <SellTimeLineSection order={order} />
        </Card>
      </div>
    )
  }
}

export default SellOrderInfoArea
