import React, { Component } from "react"

class TooltipWithTotal extends Component {
  round(value) {
    if (value > 1000) return Math.round(value)
    if (value > 10) return Math.round(value * 100) / 100
    if (value > 0.1) return Math.round(value * 10000) / 10000
    return Math.round(value * 100000000) / 100000000
  }

  render() {
    const { active } = this.props
    if (active) {
      const { payload } = this.props

      let list = []
      let total = 0
      let unit = ""

      payload.forEach(key => {
        total += key.value
        list.push(
          <div key={key.name} style={{ color: key.stroke }} className="pt-2">
            {key.name} : {this.round(key.value)}
            {key.unit}
          </div>,
        )
        unit = key.unit
      })

      if (list.length > 1) {
        list.push(
          <div key="total" style={{ color: "black" }} className="pt-2">
            Total : {total}
            {unit}
          </div>,
        )
      }

      return (
        <div
          style={{ backgroundColor: "white", border: "1px solid lightgray" }}
          className="p-3"
        >
          <span>{this.props.label}</span>
          <div>{list}</div>
        </div>
      )
    }
    return null
  }
}

export default TooltipWithTotal
