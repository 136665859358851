const CardUtils = {
  /**
   * Returns card color depending on its column
   * @returns {object}
   */
  getCardColorForColumn: function (column) {
    if (column % 2 === 0) {
      return {
        darkColor: "bg-primary-dark",
        lightColor: "bg-primary",
      }
    } else {
      return {
        darkColor: "bg-purple-dark",
        lightColor: "bg-purple",
      }
    }
  },
}

export default CardUtils
