import React, { Component } from "react"
import { Col } from "reactstrap"
import BackOfficeBarChart from "../../Chart/BackOfficeBarChart"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"

class AgeGroupSpendingChart extends Component {
  state = {}

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    BackOfficeApi.endpoints.statsStatisticsAgeGroupSpending
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          bars: data.bars,
          unit: data.unit,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    return (
      <Col xl={12}>
        <div className="card">
          <div className="card-header">
            <div className="float-left">
              <span>Age group average spending</span>
            </div>
          </div>
          <BackOfficeBarChart
            bars={this.state.bars}
            unit={this.state.unit}
            name="Amount"
          />
        </div>
      </Col>
    )
  }
}
export default AgeGroupSpendingChart
