import React, { useState, useEffect, useMemo } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"
import Utils from "../../Common/Utils"
import ToastUtils from "../../Common/ToastUtils"

// The payout method of a sell order can only be changed to one of these payout methods (SE_BANK_TRANSFER and SWISH)
const AVAILABLE_PAYOUT_METHOD_IDS = [1, 5]

const EditSellOrderModal = ({
  order,
  open,
  onSave,
  onToggle,
  customerBankAccounts = [],
  payoutMethods = [],
}) => {
  const [fee, setFee] = useState("")

  const [bankAccount, setBankAccount] = useState(null)
  const [swishNumber, setSwishNumber] = useState(null)
  const [payoutMethod, setPayoutMethod] = useState(null)

  // Payout methods available for changing to
  const availablePayoutMethods =
    useMemo(
      () =>
        payoutMethods.length > 0 &&
        payoutMethods.filter(
          payoutMethod =>
            AVAILABLE_PAYOUT_METHOD_IDS.includes(payoutMethod.id) ||
            payoutMethod.id == order?.payoutMethod,
        ),
      [payoutMethods, order],
    ) || []

  const onEdit = async () => {
    let formData = new FormData()
    let actions = []

    if (payoutMethod.id != order.payoutMethod) {
      formData.append("payoutMethodId", payoutMethod.id)
      actions.push("Change payout method")
    }
    if (
      bankAccount &&
      payoutMethod.id === 1 &&
      bankAccount.accountNumber != order.payoutDetails?.accountNumber
    ) {
      formData.append("customerBankAccountId", bankAccount.uuid)
      actions.push("Edit payout details")
    }
    if (
      swishNumber &&
      payoutMethod.id === 5 &&
      swishNumber != order.payoutDetails?.swishNumber
    ) {
      formData.append("swishNumber", swishNumber)
      actions.push("Edit payout details")
    }
    if (fee && fee != order.fee) {
      formData.append("fee", getValue(fee))
      actions.push("Edit fee")
    }

    if (actions.length === 0) {
      ToastUtils.toastCustomError("Please change sell order values")
      return
    }

    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Actions: ${actions.join(", ")}`,
    )

    if (!confirmed) {
      return
    }

    const response = await BackOfficeApi.endpoints.editSellOrder.create(
      formData,
      { id: order.id },
    )

    if (!response.ok) {
      Utils.displayError(response)
      return
    }
    onSave()
  }

  const toggle = () => {
    if (onToggle) {
      onToggle()
    }
  }

  const getValue = value => {
    return value == null ? "" : value
  }

  const onFeeChange = e => {
    setFee(e.target.value)
  }

  const onSwishNumberChange = e => {
    setSwishNumber(e.target.value)
  }

  const onPayoutMethodChange = e => {
    const newPayoutMethodId = e.target.value
    const newPayoutMethod = payoutMethods?.find(
      method => method?.id == newPayoutMethodId,
    )
    setPayoutMethod(newPayoutMethod)
  }

  const onBankAccountChange = e => {
    const newBankAccountId = e.target.value
    const newBankAccount = customerBankAccounts?.find(
      account => account?.id == newBankAccountId,
    )
    setBankAccount(newBankAccount)
  }

  useEffect(() => {
    // Set default values for inputs
    if (order && payoutMethods && customerBankAccounts) {
      setFee(order.fee)

      const payoutMethodId = order.payoutMethod
      const payoutMethod = payoutMethods?.find(
        payoutMethod => payoutMethod.id === payoutMethodId,
      )
      setPayoutMethod(payoutMethod)

      const bankAccountNumber = order.payoutDetails?.accountNumber
      if (bankAccountNumber) {
        const defaultBankAccount = customerBankAccounts.find(
          bankAccount => bankAccount?.accountNumber === bankAccountNumber,
        )
        setBankAccount(defaultBankAccount)
      } else {
        setBankAccount(customerBankAccounts[0])
      }

      const swishNumber = order.payoutDetails?.swishNumber
      if (swishNumber) {
        setSwishNumber(swishNumber)
      }
    }
  }, [order, open, payoutMethods, customerBankAccounts])

  if (!order) {
    return ""
  }

  return (
    <Modal isOpen={open} toggle={toggle} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={toggle}>Edit Sell Order</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="fee">
              Fee
            </Label>
            {order.apiInternal ? (
              <Input
                className="col-xl-8"
                type="number"
                name="fee"
                id="fee"
                value={fee}
                onChange={onFeeChange}
              />
            ) : (
              <span>{Math.round(fee * 10000) / 100}% (through a partner)</span>
            )}
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="payoutMethod">
              Payout Method
            </Label>
            <Input
              type="select"
              name="payoutMethod"
              id="payoutMethod"
              value={payoutMethod?.id}
              onChange={onPayoutMethodChange}
              style={{ maxWidth: 180, marginBottom: 12 }}
            >
              {availablePayoutMethods?.map(payoutMethod => (
                <option
                  key={`${payoutMethod.name}-payout-method-option`}
                  value={payoutMethod.id}
                >
                  {payoutMethod.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          {payoutMethod?.id === 1 && (
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="bankAccount">
                Customer Bank Account
              </Label>
              <Input
                type="select"
                name="bankAccount"
                id="bankAccount"
                value={bankAccount?.id}
                onChange={onBankAccountChange}
                style={{ maxWidth: 180, marginBottom: 12 }}
              >
                {customerBankAccounts?.map(customerBankAccount => (
                  <option
                    key={`${customerBankAccount.id}-customer-bank-account-option`}
                    value={customerBankAccount.id}
                  >
                    {(customerBankAccount.name || "Unnamed Account") +
                      " - " +
                      (customerBankAccount.bank || "Unnamed Bank")}
                  </option>
                ))}
              </Input>
            </FormGroup>
          )}
          {payoutMethod?.id === 5 && (
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="swishNumber">
                Swish number
              </Label>
              <Input
                className="col-xl-8"
                type="number"
                name="swishNumber"
                id="swishNumber"
                value={swishNumber}
                onChange={onSwishNumberChange}
              />
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onEdit}>
          Save
        </Button>
        <Button color="secondary" onClick={onToggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditSellOrderModal
