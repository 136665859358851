import React, { Component } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import moment from "moment"

class RefundModal extends Component {
  state = {
    refundReason: "",
    refundDate: moment().format("YYYY-MM-DD"),
    refundAddress: "",
    refundAmount: this.props.cryptoAmount,
  }

  componentDidUpdate(prevProps) {
    if (this.props.cryptoAmount !== prevProps.cryptoAmount) {
      this.setState({
        refundAmount: this.props.cryptoAmount,
      })
    }
  }

  changeReason = event => {
    this.setState({
      refundReason: event.target.value,
    })
  }

  changeDate = event => {
    this.setState({
      refundDate: event.target.value,
    })
  }

  changeAddress = event => {
    this.setState({
      refundAddress: event.target.value,
    })
  }

  changeAmount = event => {
    let amount = event.target.value

    if (amount > this.props.cryptoAmount) {
      amount = this.props.cryptoAmount
    } else if (amount < 0) {
      amount = 0
    } else {
      amount = event.target.amount
    }
    this.setState({
      refundAmount: amount,
    })
  }

  onRefund = () => {
    if (this.props.walletOrder) {
      this.props.onRefund(this.state.refundReason)
    } else {
      this.props.onRefund(
        this.state.refundReason,
        this.state.refundDate,
        this.state.refundAddress,
        this.state.refundAmount,
      )
    }
    this.props.toggleModal()
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={this.props.toggleModal}>
        <ModalHeader toggle={this.toggleRefundModal}>
          Refund sell order
        </ModalHeader>
        <ModalBody>
          <div>
            <p>Refund reason</p>
            <textarea
              id="refund-reason"
              placeholder="some kind of reason..."
              rows="6"
              name="reason"
              style={{ width: "100%" }}
              value={this.state.refundReason}
              onChange={this.changeReason}
            ></textarea>
          </div>
          {!this.props.walletOrder && (
            <React.Fragment>
              <div>
                <p>Refund date</p>
                <input
                  type="date"
                  id="refund-date"
                  value={this.state.refundDate}
                  onChange={this.changeDate}
                />
              </div>
              <div>
                <p>Refund crypto address</p>
                <input
                  type="text"
                  id="refund-address"
                  style={{ width: "100%" }}
                  value={this.state.refundAddress}
                  onChange={this.changeAddress}
                />
              </div>
              <div lang="en-US">
                <p>Refund crypto amount</p>
                <input
                  type="number"
                  id="refund-amount"
                  style={{ width: "100%" }}
                  value={this.state.refundAmount}
                  onChange={this.changeAmount}
                />
              </div>
            </React.Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onRefund}>
            Refund
          </Button>
          <Button color="secondary" onClick={this.props.toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default RefundModal
