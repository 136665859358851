import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row } from "reactstrap"
import MobileAppRevenueChart from "./MobileAppRevenueChart"
import MobileAppVolumeChart from "./MobileAppVolumeChart"
import MobileAppOrdersChart from "./MobileAppOrdersChart"
import MobileAppAvgAmountChart from "./MobileAppAvgAmountChart"
import Cards from "./Cards"

class MobileAppDashboard extends Component {
  state = {}

  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Mobile App</div>
        </div>
        <Row>
          <Cards />
        </Row>
        <Row>
          <MobileAppRevenueChart />
        </Row>
        <Row>
          <MobileAppVolumeChart />
        </Row>
        <Row>
          <MobileAppOrdersChart />
        </Row>
        <Row>
          <MobileAppAvgAmountChart />
        </Row>
      </ContentWrapper>
    )
  }
}
export default MobileAppDashboard
