import React from "react"
import Transfer from "../Media/Svg/transfer.svg"

const WalletUtils = {
  /**
   * Returns a badge for a given wallet transaction status
   * @param order
   * @returns {*}
   */
  getTransactionStatusBadge: function (transaction, options) {
    let statusData = {}
    switch (transaction?.status?.toUpperCase()) {
      case "COMPLETED":
        statusData = {
          title: "Completed",
          icon: Transfer,
          background: "#fbc94c",
          textColor: "#BD7618",
        }
        break
      default:
        // TODO: Implement more styles for known tags
        statusData = {
          title: transaction.status,
          background: "#eeeeee",
          textColor: "#666666",
        }
    }

    return this._renderStatusBadge(statusData, options)
  },

  /**
   * Returns badge layout
   * @param statusData
   * @returns {*}
   */
  _renderStatusBadge: function (statusData, options) {
    const showTitle =
      options && options.iconOnly && statusData.icon ? false : true
    const style = {
      backgroundColor: statusData.background,
      color: statusData.textColor,
    }

    if (!showTitle) {
      style.height = "25px"
      style.padding = "5px 5px 5px 26px"
    }

    return (
      <div
        key={statusData.title}
        className="status-badge"
        style={style}
        title={statusData.title}
      >
        {statusData.icon && <img src={statusData.icon} alt="" />}
        {showTitle ? statusData.title : ""}
      </div>
    )
  },
}

export default WalletUtils
