import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import { Container } from "reactstrap"
import DataGridEmpty from "../Common/DataGridEmpty"
import ReactDataGrid from "react-data-grid"
import { Link } from "react-router-dom"
import BackOfficeApi from "../Api/BackOfficeApi"
import Badge from "../Common/Badge"
import { APP_COLORS } from "../Common/constants"
import Utils from "../Common/Utils"

class CustomerReview extends Component {
  state = {
    page: 0,
    rows: [],
    isLoading: false,
  }
  constructor(props) {
    super(props)

    this._columns = [
      {
        key: "customerId",
        name: "Customer",
        formatter: this.CustomerFormatter,
      },
      {
        key: "score",
        name: "Score",
      },
      {
        key: "riskProfile",
        name: "Risk profile",
        formatter: this.RiskProfileFormatter,
      },
      {
        key: "reviewDaysOverdue",
        name: "Review days overdue",
        formatter: this.DaysOverdueFormatter,
      },
      {
        key: "volumeSinceLastReview",
        name: "Volume since last review",
        formatter: this.VolumeFormatter,
      },
      {
        key: "kycVolume",
        name: "KYC volume",
        formatter: this.VolumeFormatter,
      },
    ]
  }

  componentDidMount() {
    this.getData(0, 20)
  }

  getData = (first, limit) => {
    this.setState({
      isLoading: true,
    })

    BackOfficeApi.endpoints.getReviewList
      .getAll({ first: first, limit: limit })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          rows: data.slice(0),
          isLoading: false,
        })
      })
      .catch(() => {
        this.setState({
          error: "Failed to retrieve data",
          isLoading: false,
        })
      })
  }

  rowGetter = i => {
    const row = this.state.rows[i]
    return row
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Customer review list</div>
        </div>

        <Container fluid>
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={200}
            rowHeight={40}
          />
        </Container>
      </ContentWrapper>
    )
  }

  CustomerFormatter = props => {
    return <Link to={"/customers/" + props.value}>{props.value}</Link>
  }

  RiskProfileFormatter = props => {
    if (props.value === 1) {
      return <Badge color={APP_COLORS.risk_low}>Low</Badge>
    } else if (props.value === 2) {
      return <Badge color={APP_COLORS.risk_medium}>Medium</Badge>
    } else if (props.value === 3) {
      return <Badge color={APP_COLORS.risk_high}>High</Badge>
    }

    return ""
  }

  VolumeFormatter = props => {
    if (props.value) {
      return Utils.formatValueToSek(props.value)
    }

    return ""
  }

  DaysOverdueFormatter = props => {
    if (props.value) {
      return props.value + " days"
    }

    return "Never reviewed"
  }
}
export default CustomerReview
