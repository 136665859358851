import React, { Component } from "react"
import { Row } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

class Cards extends Component {
  state = {
    cardData: {},
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.sellOrdersStats
      .getOne()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          cardData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    if (this.state.cardData.waiting) {
      const stats = this.state.cardData
      return (
        <Row>
          <BackOfficeCard
            icon="fa-2x fas fa-cloud"
            title={Utils.formatNumber(stats.mempool.amountSum, 0) + " SEK"}
            value={"Mempool: " + stats.mempool.count}
            url="/orders/sell?status=mempool"
            unit=""
            key="1"
            {...CardUtils.getCardColorForColumn(0)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-piggy-bank"
            title={Utils.formatNumber(stats.received.amountSum, 0) + " SEK"}
            value={"Received: " + stats.received.count}
            url="/orders/sell?status=received"
            unit=""
            key="2"
            {...CardUtils.getCardColorForColumn(1)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-hand-holding-usd"
            title={Utils.formatNumber(stats.sold.amountSum, 0) + " SEK"}
            value={"Sold: " + stats.sold.count}
            url="/orders/sell?status=sold"
            unit=""
            key="3"
            {...CardUtils.getCardColorForColumn(2)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-clock"
            title={Utils.formatNumber(stats.waiting.amountSum, 0) + " SEK"}
            value={"Waiting: " + stats.waiting.count}
            url="/orders/sell?status=waiting"
            unit=""
            key="6"
            {...CardUtils.getCardColorForColumn(5)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-snowflake"
            title={Utils.formatNumber(stats.frozen.amountSum, 0) + " SEK"}
            value={"Frozen: " + stats.frozen.count}
            url="/orders/sell?status=frozen"
            unit=""
            key="7"
            {...CardUtils.getCardColorForColumn(6)}
          />
        </Row>
      )
    } else {
      let placeholders = [0, 1, 2, 3, 4, 5, 6]
      let placeholderNodes = placeholders.map((placeHolder, i) => {
        return (
          <BackOfficeCard
            icon="fa-2x fas fa-question"
            title="*** *** SEK"
            value="Loading: ***"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
      return <Row>{placeholderNodes}</Row>
    }
  }
}

export default Cards
