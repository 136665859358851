import React from "react"

const labelForValue = value => (value ? "sliced" : "not sliced")

const SliceToggle = ({ onChange = () => {}, value = true }) => {
  return (
    <button
      className="btn btn-secondary float-right mr-3"
      onClick={() => onChange(!value)}
    >
      {labelForValue(value)}
    </button>
  )
}

export default SliceToggle
