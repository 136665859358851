import React, { Component } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import ToastUtils from "../Common/ToastUtils"

class DropdownList extends Component {
  state = {
    open: false,
    selectedItem: null,
  }

  componentDidMount() {
    this.setActiveSelect()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.value !== prevProps.value ||
      this.props.children !== prevProps.children
    ) {
      this.setActiveSelect()
    }
  }

  onSelectItem = item => {
    this.setState({
      selectedItem: item,
    })

    if (this.props.onSelectItem) {
      this.props.onSelectItem(item)
    }
  }

  onToggle = () => {
    if (!this.props.disabled) {
      this.setState({
        open: !this.state.open,
      })
    } else if (this.props.disabledMessage) {
      ToastUtils.toastCustomMessage(this.props.disabledMessage)
    }
  }

  renderItems = () => {
    let items = this.props.children

    return items.map((item, i) => {
      if (item.divider) {
        return <DropdownItem key={i} divider />
      } else {
        return (
          <DropdownItem key={i} onClick={() => this.onSelectItem(item)}>
            {item.text}
          </DropdownItem>
        )
      }
    })
  }

  setActiveSelect = () => {
    let selectedValue = this.props.value
    let items = this.props.children

    let index = items.findIndex(i => i.value === selectedValue)

    this.setState({
      selectedItem: items[index],
    })
  }

  render() {
    const { disabled } = this.props

    return (
      <Dropdown
        className={this.props.className}
        isOpen={this.state.open && !disabled}
        toggle={this.onToggle}
        style={{ display: "inline-block" }}
      >
        <DropdownToggle color={this.props.buttonColor} caret>
          {this.props.label}:{" "}
          {this.state.selectedItem ? this.state.selectedItem.text : ""}
        </DropdownToggle>
        <DropdownMenu
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: data => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: "auto",
                    maxHeight: "500px",
                  },
                }
              },
            },
          }}
        >
          {this.renderItems()}
        </DropdownMenu>
      </Dropdown>
    )
  }
}
export default DropdownList
