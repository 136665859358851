import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"

export default function RiskProfileModal({
  customer,
  open = () => {},
  onDismiss = () => {},
  onSave = () => {},
  onError = _ => {},
}) {
  const [comment, setComment] = useState("")
  const [level, setLevel] = useState("")
  const [forceStop, setForceStop] = useState("")
  const [safeEnvironment, setSafeEnvironment] = useState()

  useEffect(() => {
    if (customer) {
      setLevel(customer.riskProfile)
      setForceStop(customer.forceStopOrders)
      setSafeEnvironment(customer.safeEnvironment)
    }
    setComment("")
  }, [customer, open])

  const handleLevelChanged = e => {
    setLevel(parseInt(e.target.value, 10))
  }

  const saveRiskProfile = async () => {
    let formData = new FormData()
    formData.append("riskProfile", level)
    formData.append("comment", comment)

    const response = await BackOfficeApi.endpoints.changeRiskProfile.create(
      formData,
      { id: customer.id },
    )
    if (!response.ok) {
      const error = await response.json()
      throw error
    }
  }

  const saveSafeEnvironment = async () => {
    let formData = new FormData()
    formData.append("value", safeEnvironment)

    const response =
      await BackOfficeApi.endpoints.customerSafeEnvironment.create(formData, {
        customerId: customer.id,
      })
    if (!response.ok) {
      const error = await response.json()
      throw error
    }
  }

  const saveForceStopOrders = async () => {
    let formData = new FormData()
    formData.append("value", forceStop)

    const response = await BackOfficeApi.endpoints.forceStopOrders.create(
      formData,
      { customerId: customer.id },
    )
    if (!response.ok) {
      const error = await response.json()
      throw error
    }
  }

  const handleSaveClicked = async () => {
    if (!comment) {
      alert("Please enter a comment why you're changing the risk level.")
      return
    }

    if (comment.length < 10) {
      alert(
        "Your comment seems too short, describe the reason in more details.",
      )
      return
    }

    try {
      await saveRiskProfile()
      await saveForceStopOrders()
      await saveSafeEnvironment()
    } catch (error) {
      onError(error)
    }

    onDismiss()
    onSave()
  }

  if (!customer) {
    return null
  }
  return (
    <Modal isOpen={open} toggle={onDismiss} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onDismiss}>
        {"Change customer's Risk profile settings"}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label">Risk level</Label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="level"
                id="inlineRadio1"
                value="1"
                onChange={handleLevelChanged}
                defaultChecked={level === 1}
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                1 - Low
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="level"
                id="inlineRadio2"
                value="2"
                onChange={handleLevelChanged}
                defaultChecked={level === 2}
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                2 - Medium
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="level"
                id="inlineRadio3"
                value="3"
                onChange={handleLevelChanged}
                defaultChecked={level === 3}
              />
              <label className="form-check-label" htmlFor="inlineRadio3">
                3 - High
              </label>
            </div>
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="forceStop">
              Force stop orders
            </Label>
            <input
              type="checkbox"
              name="forceStop"
              id="forceStop"
              checked={forceStop}
              onChange={e => setForceStop(e.target.checked)}
            />
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="safeEnvironment">
              Safe mode
            </Label>
            <input
              type="checkbox"
              name="safeEnvironment"
              id="safeEnvironment"
              checked={safeEnvironment}
              onChange={e => setSafeEnvironment(e.target.checked)}
            />
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="comment">
              Comment
            </Label>
            <textarea
              className="form-control col-xl-8"
              rows="3"
              type="text"
              name="comment"
              id="comment"
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveClicked}>
          Save
        </Button>
        <Button color="secondary" onClick={() => onDismiss()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
