import React, { useState, useEffect } from "react"
import { Row, Card, Col, Container, Button } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import DataGridEmpty from "../Common/DataGridEmpty"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import Utils from "../Common/Utils"

import AddAccessModal from "./AddAccessModal"

const CustomerAccesses = ({ customerId }) => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [showAddAccessModal, setShowAddAccessModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDisableAccess = async (data, customerId) => {
    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: Disable access for user with personal number "${data.personalNumber}" from customer "#${customerId}"\n\nNote: If you need to restore disabled access later, please contact a developer.`,
    )
    if (!confirmed) {
      return
    }

    setIsLoading(true)
    const response = await BackOfficeApi.endpoints.disableCustomerAccess.post(
      {
        id: customerId,
      },
      {
        accessId: data.id,
      },
    )
    if (response?.ok) {
      ToastUtils.toastExecuteSuccess2()
      fetchData()
    } else {
      try {
        const json = await response.json()
        if (json.error) {
          ToastUtils.toastCustomError(json.error)
        } else {
          throw new Error(response)
        }
      } catch (e) {
        console.error(e)
        ToastUtils.toastExecuteError2()
      }
    }
    setIsLoading(false)
  }

  const rowGetter = i => {
    return data[i]
  }
  const fetchData = async () => {
    setIsLoading(true)
    const response =
      await BackOfficeApi.endpoints.getCustomerAccessesCorporate.getOne({
        id: customerId,
      })

    if (response?.ok) {
      const json = await response?.json()
      setData(json)
    } else {
      ToastUtils.toastAPIError2()
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (customerId) {
      // Columns can only be set after customerId is received
      setColumns([
        {
          key: "personalNumber",
          name: "Personal number",
        },
        {
          key: "email",
          name: "Email",
        },
        {
          key: "permissions",
          name: "Permissions",
          formatter: data => {
            if (Array.isArray(data.value)) {
              return data.value.join(", ")
            } else {
              return String(data.value)
            }
          },
        },
        {
          key: "disable",
          name: "",
          formatter: data => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {data.row?.active ? (
                <Button
                  color="danger"
                  size="l"
                  className="mr-1 fa icon-trash"
                  onClick={() => handleDisableAccess(data.row, customerId)}
                />
              ) : (
                <div style={{ color: "red" }}>DISABLED</div>
              )}
            </div>
          ),
        },
      ])
      fetchData()
    }
  }, [customerId])

  return (
    <Card className="card-area" style={{ minHeight: "300px", marginTop: "0" }}>
      <Row>
        <Col>
          <Container fluid>
            <ReactDataGrid
              style={{ width: "100%", height: "100%" }}
              columns={columns}
              rowGetter={rowGetter}
              rowsCount={data.length}
              emptyRowsView={isLoading ? null : DataGridEmpty}
              minColumnWidth={200}
              rowHeight={40}
            />
          </Container>
          <div
            style={{
              padding: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              color="success"
              size="lg"
              className="icon-plus"
              onClick={() => setShowAddAccessModal(true)}
            />
          </div>
        </Col>
        <AddAccessModal
          open={showAddAccessModal}
          onDismiss={() => setShowAddAccessModal(!showAddAccessModal)}
          customerId={customerId}
          onSuccess={fetchData}
        />
      </Row>
    </Card>
  )
}

export default CustomerAccesses
