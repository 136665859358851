import React, { Component } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

class ReviewPepSanctionModal extends Component {
  state = {
    comment: "",
    matchDetailsModal: false,
  }

  onChangeComment = e => {
    this.setState({
      comment: e.target.value,
    })
  }

  onAction = approved => {
    if (this.props.onReview) {
      this.props.onReview(approved, this.state.comment)
    }
  }

  onViewDetails = () => {
    this.setState({
      matchDetailsModal: true,
    })

    BackOfficeApi.endpoints.customerPepSanctionMatchDetails
      .getOne({ customerId: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          matchDetails: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  closeMatchDetails = () => {
    this.setState({
      matchDetailsModal: false,
    })
  }

  render() {
    let loadClass = ""
    if (!this.props.pepSanctionData) {
      loadClass = "whirl traditional"
    }

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "1200px" }}
        className={loadClass}
      >
        <ModalHeader toggle={this.props.toggle}>
          {this.props.verification &&
          this.props.verification.verificationLevel === 0
            ? "Review Pep Sanction"
            : "View Pep Sanction"}
        </ModalHeader>
        <ModalBody>
          <pre style={{ maxHeight: "700px", overflow: "scroll" }}>
            {JSON.stringify(this.props.pepSanctionData, null, 2)}
          </pre>
          {this.props.verification &&
            this.props.verification.verificationLevel === 0 && (
              <div className="mt-2">
                <p>Comment</p>
                <textarea
                  id="comment"
                  placeholder="some kind of reason..."
                  rows="6"
                  name="comment"
                  style={{ width: "100%" }}
                  value={this.state.comment}
                  onChange={this.onChangeComment}
                ></textarea>
              </div>
            )}
        </ModalBody>
        <ModalFooter>
          {this.props.verification &&
            this.props.verification.verificationLevel === 0 && (
              <Button
                color="primary"
                className="mr-auto"
                onClick={() => this.onAction(true)}
              >
                Dismiss (false positive)
              </Button>
            )}
          {this.props.verification &&
            this.props.verification.verificationLevel === 0 && (
              <Button
                color="danger"
                className="mr-auto"
                onClick={() => this.onAction(false)}
              >
                Flag as risky
              </Button>
            )}
          <Button color="info" onClick={this.onViewDetails}>
            View match details
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
        <Modal
          isOpen={this.state.matchDetailsModal}
          toggle={this.closeMatchDetails}
          style={{ maxWidth: "1200px" }}
        >
          <ModalHeader toggle={this.closeMatchDetails}>
            Match details
          </ModalHeader>
          <ModalBody>
            <pre style={{ maxHeight: "700px", overflow: "scroll" }}>
              {JSON.stringify(this.state.matchDetails, null, 2)}
            </pre>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeMatchDetails}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Modal>
    )
  }
}
export default ReviewPepSanctionModal
