import React, { Component } from "react"
import { Row, Col, Button } from "reactstrap"
import { CardHeader, CardBody } from "reactstrap"
import Transfer from "../Media/Svg/transfer.svg"
import Freeze from "../Media/Svg/freeze.svg"
import Exchange from "../Media/Svg/exchange_white.svg"
import Bandit from "../Media/Svg/bandit_white.svg"
import CheckInvalid from "../Media/Svg/check_invalid.svg"
import CheckValid from "../Media/Svg/check_valid.svg"
import Utils from "../Common/Utils"

class CustomerStatusSection extends Component {
  getStatusBadge = statusData => {
    return (
      <div
        key={statusData.title}
        className="status-badge"
        style={{
          backgroundColor: statusData.background,
          color: statusData.textColor,
        }}
      >
        <img alt="" src={statusData.icon} />
        {statusData.title}
      </div>
    )
  }

  getTypeBadge = () => {
    return (
      <div
        key={this.props.customer.type}
        className="status-badge"
        style={{ backgroundColor: "#003690", color: "white" }}
      >
        <img alt="" src={Exchange} />
        {this.props.customer.type}
      </div>
    )
  }

  getProtectedBadge = () => {
    if (!this.props.customer.viewProtected) {
      return ""
    }

    return (
      <div
        key={this.props.customer.viewProtected}
        className="status-badge"
        style={{ backgroundColor: "#003690", color: "white" }}
      >
        <img alt="" src={Bandit} />
        Protected
      </div>
    )
  }

  renderTags = () => {
    let tags = []

    this.props.customer.tags.forEach(tag => {
      tags.push(
        <div
          className="tag-badge"
          key={tag.id}
          style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
        >
          {tag.name}
        </div>,
      )
    })

    return tags
  }

  renderReviewStatus = () => {
    let reviewStatus = this.props.reviewStatus
    if (reviewStatus.status === -1) {
      return (
        <div
          className="status-badge"
          style={{
            backgroundColor: "#e57a7a",
            color: "#FFFFFF",
            textAlign: "left",
          }}
        >
          <img alt="" src={CheckInvalid} />
          Never reviewed
        </div>
      )
    } else if (reviewStatus.status === 0) {
      return (
        <div
          className="status-badge"
          style={{
            backgroundColor: "#e78435",
            color: "#FFFFFF",
            textAlign: "left",
          }}
        >
          <img alt="" src={Freeze} />
          Review overdue
          <br />
          Last review:{" "}
          {Utils.getUtcInLocalDateTime(
            reviewStatus.latestReviewTimestamp,
          )} by {reviewStatus.user}
        </div>
      )
    } else if (reviewStatus.status === 1) {
      return (
        <div
          className="status-badge"
          style={{
            backgroundColor: "#b2e7bf",
            color: "#24773B",
            textAlign: "left",
          }}
        >
          <img alt="" src={CheckValid} />
          Reviewed
          <br />
          Last review:{" "}
          {Utils.getUtcInLocalDateTime(
            reviewStatus.latestReviewTimestamp,
          )} by {reviewStatus.user}
        </div>
      )
    }
  }

  render() {
    if (!this.props.customer || !this.props.reviewStatus) return <div />

    let statusData
    if (this.props.customer.frozen) {
      statusData = {
        title: "Frozen",
        icon: Freeze,
        background: "#e78435",
        textColor: "#FFFFFF",
      }
    } else {
      statusData = {
        title: "Active",
        icon: Transfer,
        background: "#fbc94c",
        textColor: "#BD7618",
      }
    }

    return (
      <div>
        <Row className="no-gutters">
          <Col xs={4}>
            <CardHeader className="card-section-header">
              Current status
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="no-gutters">
                {this.getStatusBadge(statusData)}
                {this.getTypeBadge()}
                {this.getProtectedBadge()}
              </Row>
            </CardBody>
          </Col>
          <Col xs={4}>
            <CardHeader className="card-section-header">
              Review status
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="no-gutters">{this.renderReviewStatus()}</Row>
            </CardBody>
          </Col>
          <Col xs={4}>
            <CardHeader className="card-section-header">
              Tags
              <Button
                size="xs"
                color="primary"
                className="float-right safello-font"
                onClick={this.props.onEditTagClick}
              >
                Edit tags
              </Button>
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="no-gutters">{this.renderTags()}</Row>
            </CardBody>
          </Col>
        </Row>
      </div>
    )
  }
}

export default CustomerStatusSection
