import React, { Component } from "react"

class BtcToggle extends Component {
  render() {
    const { onClick, value } = this.props
    const status = value ? "exc." : "inc."
    return (
      <button className="btn btn-secondary float-right mr-3" onClick={onClick}>
        {status} <i className="fab fa-btc"></i>
      </button>
    )
  }
}

export default BtcToggle
