import React, { useState } from "react"
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

const AddDiscountModal = ({ onDismiss, open, customerId, onAddDiscount }) => {
  const [discountFeeId, setDiscountFeeId] = useState("")

  const onAddCode = async () => {
    if (!discountFeeId) {
      alert("Please fill in the discount fee ID")
      return
    }
    try {
      let formData = new FormData()
      formData.append("discountFeeId", discountFeeId)

      const res = await BackOfficeApi.endpoints.addCustomerDiscount.create(
        formData,
        { customerId: customerId },
      )

      if (res.ok) {
        ToastUtils.toastExecuteSuccess2()
        onDismiss()
        onAddDiscount()
      } else throw new Error()
    } catch (e) {
      console.error(e)
      ToastUtils.toastAPIError3()
    }
  }

  const onChange = e => {
    setDiscountFeeId(e.target.value)
  }

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      onAddCode()
    }
  }

  return (
    <Modal isOpen={open} toggle={onDismiss} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onDismiss}>Add discount code</ModalHeader>
      <ModalBody>
        <div className="row">
          <Label className="col-xl-3 col-form-label">Campaign Fee ID</Label>
          <Input
            className="col-md-7"
            type="text"
            name="discountFeeId"
            value={discountFeeId}
            onChange={onChange}
            onKeyPress={handleKeyPress}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onAddCode}>
          Add
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddDiscountModal
