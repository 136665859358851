import React, { Component } from "react"
import { Card, Col, Row } from "reactstrap"
import Utils from "../Common/Utils"

class CustomerYearlyLimit extends Component {
  render() {
    return (
      <Card
        className="card-area"
        style={{ minHeight: "300px", marginTop: "0" }}
      >
        <Row className="m-4">
          <Col xl={6}>
            <h3>Remaining yearly limit</h3>
            <span style={{ fontSize: "20px" }}>
              {this.props.yearlyLimit
                ? Utils.formatCurrency(
                    this.props.yearlyLimit.remainingYearlyLimit,
                    "SEK",
                  )
                : "..."}
            </span>
          </Col>
          <Col xl={6}>
            <h3>Custom yearly limit</h3>
            {this.props.yearlyLimit && this.props.yearlyLimit.newLimit && (
              <table width="100%" style={{ fontSize: "20px" }}>
                <thead>
                  <tr>
                    <th>New limit</th>
                    <th>Valid from</th>
                    <th>Valid to</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {Utils.formatCurrency(
                        this.props.yearlyLimit.newLimit,
                        "SEK",
                      )}
                    </td>
                    <td>
                      {Utils.getUtcInLocalDateTime(
                        this.props.yearlyLimit.validFrom,
                      )}
                    </td>
                    <td>
                      {this.props.yearlyLimit.validTo
                        ? Utils.getUtcInLocalDateTime(
                            this.props.yearlyLimit.validTo,
                          )
                        : "∞"}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Col>
        </Row>
      </Card>
    )
  }
}
export default CustomerYearlyLimit
