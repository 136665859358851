import React, { Component } from "react"

class StackToggle extends Component {
  render() {
    const { onClick, value } = this.props
    const status = value ? "stacked" : "not stacked"
    return (
      <button className="btn btn-secondary float-right mr-3" onClick={onClick}>
        {status}
      </button>
    )
  }
}

export default StackToggle
