import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row } from "reactstrap"
import ActiveCustomerPie from "./ActiveCustomerPie"
import AverageSignupAgeChart from "./AverageSignupAgeChart"
import ChannelAverageTime from "./ChannelAverageTime"
import AgeGroupSpendingChart from "./AgeGroupSpendingChart"
import SwishDistributionChart from "./SwishDistributionChart"
import SwishErrorReasonsChart from "./SwishErrorReasonsChart"

class Statistics extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Statistics</div>
        </div>
        <Row>
          <ActiveCustomerPie />
        </Row>
        <Row>
          <AverageSignupAgeChart />
        </Row>
        <Row>
          <ChannelAverageTime />
        </Row>
        <Row>
          <AgeGroupSpendingChart />
        </Row>
        <Row>
          <SwishDistributionChart />
        </Row>
        <Row>
          <SwishErrorReasonsChart />
        </Row>
      </ContentWrapper>
    )
  }
}
export default Statistics
