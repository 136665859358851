/* eslint-disable react/jsx-no-bind */
import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Collapse, Badge } from "reactstrap"
import SidebarRun from "./Sidebar.run"
import { isMobile } from "react-device-detect"

import Menu from "../../Menu.js"
import BackOfficeApi from "../Api/BackOfficeApi"
import StorageUtils from "../Common/StorageUtils.js"

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>{item.heading}</span>
  </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive, userPermissions }) => (
  <li
    className={isActive ? "active" : ""}
    onClick={
      item.name !== "Log out"
        ? isMobile
          ? closeSidebar
          : null
        : onLogoutClicked
    }
    style={isMenuItemAllowed(item, userPermissions) ? {} : { display: "none" }}
  >
    <Link to={item.path ? item.path : ""} title={item.name}>
      {item.label && (
        <Badge tag="div" className="float-right" color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon}></em>}
      <span>{item.name}</span>
    </Link>
  </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({
  item,
  isActive,
  handler,
  children,
  isOpen,
  hasAccess,
}) => (
  <li
    className={isActive ? "active" : ""}
    style={hasAccess ? {} : { display: "none" }}
  >
    <div className="nav-item" onClick={handler}>
      {item.label && (
        <Badge tag="div" className="float-right" color={item.label.color}>
          {item.label.value}
        </Badge>
      )}
      {item.icon && <em className={item.icon}></em>}
      <span>{item.name}</span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.name}</li>
)

const closeSidebar = () => {
  let $ = require("jquery")
  let $body = $("body")
  $body.removeClass("aside-toggled")
}

/**
 * Determines if a menu item should be visible for a certain user
 * @param item
 * @param userPermissions
 * @returns {boolean}
 */
const isMenuItemAllowed = (item, userPermissions) => {
  if (!item.permission) return true
  return userPermissions.includes(item.permission)
}

const onLogoutClicked = () => {
  BackOfficeApi.endpoints.logout.create().then(() => {})
  StorageUtils.removeUser()
}

class Sidebar extends Component {
  state = {
    collapse: {},
  }

  componentDidMount() {
    // pass navigator to access router api
    SidebarRun(this.navigator.bind(this))
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList()
  }

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {}
    Menu.filter(({ heading }) => !heading).forEach(
      ({ name, path, submenu }) => {
        collapse[name] = this.routeActive(
          submenu ? submenu.map(({ path }) => path) : path,
        )
      },
    )
    this.setState({ collapse })
  }

  navigator(route) {
    this.props.history.push(route)
  }

  routeActive(paths) {
    paths = Array.isArray(paths) ? paths : [paths]
    if (paths.indexOf(this.props.location.pathname.replace("/", "")) > -1)
      return true
    return false
  }

  toggleItemCollapse(stateName) {
    for (let c in this.state.collapse) {
      if (this.state.collapse[c] === true && c !== stateName)
        this.setState({
          collapse: {
            [c]: false,
          },
        })
    }
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName],
      },
    })
  }

  getSubRoutes = item => item.submenu.map(({ path }) => path)

  /** map menu config to string to determine what elemetn to render */
  itemType = item => {
    if (item.heading) return "heading"
    if (!item.submenu) return "menu"
    if (item.submenu) return "submenu"
  }

  render() {
    let logoWidth = !isMobile ? "36px" : "28px"
    let logoHeight = !isMobile ? "57px" : "44px"
    let userPermissions = StorageUtils.getUserPermissions()

    return (
      <aside className="aside-container">
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav data-sidebar-anyclick-close="" className="sidebar">
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* Iterates over all sidebar items */}
              {Menu.map((item, i) => {
                // heading
                if (this.itemType(item) === "heading")
                  return <SidebarItemHeader item={item} key={i} />
                else {
                  if (this.itemType(item) === "menu")
                    return (
                      <SidebarItem
                        isActive={this.routeActive(item.path)}
                        item={item}
                        userPermissions={userPermissions}
                        key={i}
                      />
                    )
                  if (this.itemType(item) === "submenu") {
                    // If no sub items should be visible, hide parent
                    let hasAccess
                    for (let currentSubItem of item.submenu) {
                      hasAccess = isMenuItemAllowed(
                        currentSubItem,
                        userPermissions,
                      )
                      if (hasAccess) break
                    }

                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item.name)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        hasAccess={hasAccess}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subItem, i) => (
                          <SidebarItem
                            key={i}
                            item={subItem}
                            isActive={this.routeActive(subItem.path)}
                            userPermissions={userPermissions}
                          />
                        ))}
                      </SidebarSubItem>,
                    ]
                  }
                }
                return null // unrecognized item
              })}
            </ul>
            <div
              className="text-center"
              style={{ position: "absolute", bottom: "20px", width: "100%" }}
            >
              <img
                className="img-fluid"
                src="img/logo_icon_white.png"
                alt="Safello logo"
                style={{ width: logoWidth, height: logoHeight }}
              />
            </div>

            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    )
  }
}

export default withRouter(Sidebar)
