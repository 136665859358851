import React, { Component } from "react"

class BackOfficeCard extends Component {
  cardClicked(url) {
    if (url) {
      window.location = url
    }
  }

  render() {
    let darkColor = this.props.darkColor || "bg-primary-dark"
    let lightColor = this.props.lightColor || "bg-primary"
    let leftSideClass =
      "col-2 d-flex align-items-center justify-content-center rounded-left " +
      darkColor
    let rightSideClass = "col-10 py-3 rounded-right " + lightColor
    let icon = this.props.icon || "fa-3x far fa-money-bill-alt"

    const cardStyle = { height: "calc(100% - 20px)" }

    if (this.props.url) {
      cardStyle.cursor = "pointer"
    }

    return (
      <div className="col-xl-3 col-md-6">
        <div
          className="card flex-row align-items-center align-items-stretch border-0"
          style={cardStyle}
          onClick={() => {
            this.cardClicked(this.props.url)
          }}
        >
          <div className={leftSideClass}>
            <em className={icon} />
          </div>
          <div className={rightSideClass}>
            <div className="h3 mt-0">
              {this.props.value} {this.props.unit}
            </div>
            <div>{this.props.title}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default BackOfficeCard
