import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

let ID_ERROR_API = 0

const ToastUtils = {
  toastAPIError2: function () {
    if (!toast.isActive(ID_ERROR_API)) {
      ID_ERROR_API = toast("Failed to retrieve data", {
        type: toast.TYPE.ERROR,
        position: "top-right",
      })
    }
  },

  toastAPIError3: function () {
    if (!toast.isActive(ID_ERROR_API)) {
      ID_ERROR_API = toast("Failed to save data", {
        type: toast.TYPE.ERROR,
        position: "top-right",
      })
    }
  },

  toastSaveError2: function () {
    if (!toast.isActive(ID_ERROR_API)) {
      ID_ERROR_API = toast("Failed to save changes", {
        type: toast.TYPE.ERROR,
        position: "top-right",
      })
    }
  },

  toastSaveSuccess2: function () {
    toast("Your changes were saved!", {
      type: toast.TYPE.SUCCESS,
      position: "top-right",
      className: "toastify-success",
    })
  },

  toastExecuteError2: function () {
    toast("Execution failed!", {
      type: toast.TYPE.ERROR,
      position: "top-right",
    })
  },

  toastExecuteSuccess2: function () {
    toast("Execution succeeded", {
      type: toast.TYPE.SUCCESS,
      position: "top-right",
      className: "toastify-success",
    })
  },

  toastCustomMessage: function (message) {
    toast(message, {
      type: toast.TYPE.INFO,
      position: "top-right",
    })
  },

  toastCustomError: function (message) {
    toast(message, {
      type: toast.TYPE.ERROR,
      position: "top-right",
    })
  },

  toastCustomSuccess: function (message) {
    toast(message, {
      type: toast.TYPE.SUCCESS,
      position: "top-right",
    })
  },

  toastUnauthorized: function () {
    toast("Unauthorized", {
      type: toast.TYPE.ERROR,
      position: "top-right",
    })
  },
}

export default ToastUtils
