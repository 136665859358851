import React, { Component } from "react"
import { Col } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import ChartUtils from "../../Common/ChartUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeAreaChart from "../../Chart/BackOfficeAreaChart"

class SwishErrorReasonsChart extends Component {
  state = {}

  componentDidMount() {
    this.changePerspective(ChartUtils.getDefaultPerspective())
  }

  changePerspective = perspective => {
    BackOfficeApi.endpoints.statsStatisticsSwishErrorReasons
      .getAll({ perspective: perspective })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        data.lineData.map(
          dataNode =>
            (dataNode.xKey = ChartUtils.getPerspectiveXFormat(
              dataNode.xKey,
              perspective,
            )),
        )
        this.setState({
          lines: data.lineInfo,
          lineData: data.lineData,
          xInterval: ChartUtils.getPerspectiveXInterval(perspective),
          perspective: perspective,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    return (
      <Col xl={12}>
        <BackOfficeAreaChart
          lines={this.state.lines}
          lineData={this.state.lineData}
          xInterval={this.state.xInterval}
          stack={this.state.staked}
          title="Swish error reasons"
          onPerspectiveChange={this.changePerspective}
        />
      </Col>
    )
  }
}

export default SwishErrorReasonsChart
