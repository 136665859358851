import React from "react"
import { Card, Row } from "reactstrap"
import DocumentImage from "./DocumentImage"
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"
import BackOfficeApi from "../Api/BackOfficeApi"

const CustomerDocuments = ({ images, reloadImages }) => {
  const handleRemoveImage = async image => {
    const confirm = await Utils.showSweetAlertAreYouSure(
      "Remove document?",
      `Document ID: ${image.id}\n\nNote: All documents are recoverable within 5 weeks of deletion. After this period, the document will be permanently deleted and cannot be recovered.`,
      null,
    )
    if (confirm) {
      const response = await BackOfficeApi.endpoints.deleteDocument.delete({
        id: image.id,
      })
      if (response?.ok) {
        ToastUtils.toastExecuteSuccess2()
        reloadImages()
      } else {
        console.error(response?.error)
        ToastUtils.toastExecuteError2()
      }
    }
  }

  const renderImages = () => {
    if (images) {
      let arr = []
      images.forEach(i => {
        arr.push(
          <DocumentImage
            key={i.id}
            image={i}
            onRemoveImage={handleRemoveImage}
          />,
        )
      })

      return arr
    }

    return ""
  }

  return (
    <div>
      <Card
        className="card-area"
        style={{ minHeight: "300px", marginTop: "0" }}
      >
        <Row className="mx-0">{renderImages()}</Row>
      </Card>
    </div>
  )
}
export default CustomerDocuments
