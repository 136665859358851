import React, { useState, useEffect } from "react"
import { Label, Input, Button } from "reactstrap"
import BackOfficeApi from "../../../Api/BackOfficeApi"
import ToastUtils from "../../../Common/ToastUtils"
import Utils from "../../../Common/Utils"

const EAvailableActions = {
  ADD: "ADD",
  REMOVE: "REMOVE",
}

const AVAILABLE_ACTIONS = [EAvailableActions.ADD, EAvailableActions.REMOVE]

const FeatureFlagControls = ({
  featuresFlagData,
  loading,
  setLoading,
  refetchFeatureFlags,
}) => {
  const [selectedAction, setSelectedAction] = useState(AVAILABLE_ACTIONS[0])
  const [selectedFlag, setSelectedFlag] = useState(null)
  const [flagKeyInput, setFlagKeyInput] = useState("")
  const [flagNameInput, setFlagNameInput] = useState("")
  const [flagDescriptionInput, setFlagDescriptionInput] = useState("")

  const handleActionChange = e => {
    setSelectedAction(e.target.value)
  }

  const handleFlagChange = e => {
    setSelectedFlag(e.target.value)
  }

  const resetInputs = () => {
    setFlagKeyInput("")
    setFlagNameInput("")
    setFlagDescriptionInput("")
  }

  const onSubmit = async () => {
    const confirm = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: ${selectedAction} feature flag \n
      ${
        selectedAction === EAvailableActions.REMOVE
          ? `- Flag: ${selectedFlag}\n\n
          Please double check that the flag is not used anymore before removing it.
          `
          : `- Flag: ${flagKeyInput}\n
      - Flag name: ${flagNameInput}\n
      - Flag description: ${flagDescriptionInput}\n
      `
      }`,
    )
    if (confirm) {
      setLoading(true)
      if (selectedAction === EAvailableActions.ADD) {
        const payLoad = {
          flag: flagKeyInput,
          name: flagNameInput,
          description: flagDescriptionInput,
        }
        const response =
          await BackOfficeApi.endpoints.createFeatureFlag.postJson({
            body: payLoad,
          })
        if (response && response.ok) {
          ToastUtils.toastExecuteSuccess2()
          refetchFeatureFlags()
          resetInputs()
        } else {
          Utils.displayError(response)
        }
      } else {
        const response = await BackOfficeApi.endpoints.deleteFeatureFlag.delete(
          {
            flagKey: selectedFlag,
          },
        )
        if (response && response.ok) {
          ToastUtils.toastExecuteSuccess2()
          refetchFeatureFlags()
        } else {
          Utils.displayError(response)
        }
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (featuresFlagData?.length) {
      setSelectedFlag(featuresFlagData[0].flag)
    }
  }, [featuresFlagData])

  const capitalizedAction =
    selectedAction.charAt(0).toUpperCase() +
    selectedAction.slice(1).toLowerCase()

  const validInput =
    selectedAction === EAvailableActions.ADD
      ? flagDescriptionInput.length > 2 &&
        flagKeyInput.length > 2 &&
        flagNameInput.length > 2
      : true

  return (
    <>
      <div className="card-section-header" style={{ marginBottom: 10 }}>
        Add/Remove Feature Flag
      </div>

      {/* Action type selector */}
      <Label for="actionType">Action Type</Label>
      <Input
        type="select"
        name="actionType"
        id="actionType"
        value={selectedAction}
        onChange={handleActionChange}
        style={{ maxWidth: 120, marginBottom: 12 }}
      >
        {AVAILABLE_ACTIONS.map(action => (
          <option key={`${action}-action-option`} value={action}>
            {action}
          </option>
        ))}
      </Input>

      {/* Flag input */}
      <Label>Flag</Label>
      {selectedAction === EAvailableActions.REMOVE ? (
        <Input
          type="select"
          name="flagSelector"
          id="flagSelector"
          value={selectedFlag}
          onChange={handleFlagChange}
          style={{ maxWidth: 180, marginBottom: 12 }}
        >
          {featuresFlagData.map(flag => (
            <option key={`${flag.flag}-flag-option`} value={flag.flag}>
              {flag.flag}
            </option>
          ))}
        </Input>
      ) : (
        <>
          <Input
            type="text"
            name="flagKey"
            id="flagKey"
            value={flagKeyInput}
            onChange={e => setFlagKeyInput(e.target.value)}
            style={{ maxWidth: 180, marginBottom: 12 }}
          />
          <Label>Flag Name</Label>
          <Input
            type="text"
            name="flagName"
            id="flagName"
            value={flagNameInput}
            onChange={e => setFlagNameInput(e.target.value)}
            style={{ maxWidth: 180, marginBottom: 12 }}
          />
          <Label>Flag Description</Label>
          <Input
            type="text"
            name="flagDescription"
            id="flagDescription"
            value={flagDescriptionInput}
            onChange={e => setFlagDescriptionInput(e.target.value)}
            style={{ maxWidth: 180, marginBottom: 12 }}
          />
        </>
      )}

      <Button
        color="primary"
        disabled={!validInput || loading}
        onClick={onSubmit}
        loading={loading}
      >
        {capitalizedAction}
      </Button>
    </>
  )
}

export default FeatureFlagControls
