import React from "react"
import moment from "moment"
import { Row, Col, CardBody } from "reactstrap"
import Utils from "../../Common/Utils"
import OrderUtils from "../../Orders/OrderUtils"

const TransactionDetailsSection = ({ transaction }) => {
  if (!transaction) return <div />

  const copyToClipboard = e => {
    let copyText = document.createElement("input")
    document.body.appendChild(copyText)
    copyText.value = e.target.getAttribute("data-copy")
    copyText.select()
    document.execCommand("copy", false)
    //alert("Copied: " + copyText.value);
    copyText.remove()
    let target = e.target
    target.style.color = "rgb(231, 132, 53)"
    setTimeout(function () {
      target.style.color = "rgb(106, 191, 91)"
    }, 1000)
  }

  let copyStyle = {
    color: "rgb(106, 191, 91)",
    cursor: "pointer",
  }

  return (
    <div>
      <CardBody className="pt-0">
        <Row className="no-gutters">
          <Col lg={12}>
            <p
              className="card-section-header"
              style={{ padding: "0.75rem 1.25rem 0.75rem 0" }}
            >
              Transaction
            </p>
            <Col lg={12}>
              <table className="table table-bordered table-striped">
                <tbody>
                  {transaction.txHash && (
                    <tr>
                      <td className="details-header">Tx hash:</td>
                      <td className="details-value" align="right">
                        <a
                          href={OrderUtils.getCryptoBlockExplorerUrl(
                            transaction.currency,
                            transaction.txHash,
                            "tx",
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {transaction.txHash}
                        </a>{" "}
                        {document.queryCommandSupported("copy") && (
                          <i
                            className="fas fa-copy"
                            style={copyStyle}
                            data-copy={transaction.txHash}
                            onClick={copyToClipboard}
                          ></i>
                        )}
                      </td>
                    </tr>
                  )}
                  {transaction.txAddress && (
                    <tr>
                      <td className="details-header">Tx address:</td>
                      <td className="details-value" align="right">
                        <a
                          href={OrderUtils.getCryptoBlockExplorerUrl(
                            transaction.currency,
                            transaction.txAddress,
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {transaction.txAddress}
                        </a>{" "}
                        {document.queryCommandSupported("copy") && (
                          <i
                            className="fas fa-copy"
                            style={copyStyle}
                            data-copy={transaction.txAddress}
                            onClick={copyToClipboard}
                          ></i>
                        )}
                      </td>
                    </tr>
                  )}
                  {transaction.withdrawalAddressName && (
                    <tr>
                      <td className="details-header">Withdrawal name:</td>
                      <td className="details-value" align="right">
                        {transaction.withdrawalAddressName}
                      </td>
                    </tr>
                  )}
                  {transaction.withdrawalAddressCategory && (
                    <tr>
                      <td className="details-header">Withdrawal category:</td>
                      <td className="details-value" align="right">
                        {transaction.withdrawalAddressCategory}
                      </td>
                    </tr>
                  )}
                  {transaction.withdrawalAddressCategoryName && (
                    <tr>
                      <td className="details-header">
                        Withdrawal category name:
                      </td>
                      <td className="details-value" align="right">
                        {transaction.withdrawalAddressCategoryName}
                      </td>
                    </tr>
                  )}
                  {transaction.type && (
                    <tr>
                      <td className="details-header">Type:</td>
                      <td className="details-value" align="right">
                        {transaction.type}
                      </td>
                    </tr>
                  )}
                  {transaction.from && (
                    <tr>
                      <td className="details-header">From:</td>
                      {
                        <td className="details-value" align="right">
                          {transaction.from}
                        </td>
                      }
                    </tr>
                  )}
                  {transaction.to && (
                    <tr>
                      <td className="details-header">To:</td>
                      <td className="details-value" align="right">
                        {transaction.to}
                      </td>
                    </tr>
                  )}
                  {transaction.currency && (
                    <tr>
                      <td className="details-header">Crypto:</td>
                      <td className="details-value" align="right">
                        {transaction.currency}
                      </td>
                    </tr>
                  )}
                  {transaction.fiatAmount && (
                    <tr>
                      <td className="details-header">Fiat Amount:</td>
                      <td className="details-value" align="right">
                        {transaction.fiatAmount}
                      </td>
                    </tr>
                  )}
                  {(transaction.created || transaction.issued) && (
                    <tr>
                      <td className="details-header">Created on:</td>
                      <td className="details-value" align="right">
                        {moment(
                          transaction.created || transaction.issued,
                        ).format("lll")}
                      </td>
                    </tr>
                  )}
                  {transaction.completed && (
                    <tr>
                      <td className="details-header">Completed on:</td>
                      <td className="details-value" align="right">
                        {moment(transaction.completed).format("lll")}
                      </td>
                    </tr>
                  )}
                  {transaction.swapFee && (
                    <tr>
                      <td className="details-header">Fees:</td>
                      <td className="details-value" align="right">
                        {transaction.swapFee}
                      </td>
                    </tr>
                  )}
                  {transaction.fee && (
                    <tr>
                      <td className="details-header">Fees:</td>
                      <td className="details-value" align="right">
                        {Utils.formatCrypto(transaction.fee)}{" "}
                        {transaction.currency}
                      </td>
                    </tr>
                  )}
                  {transaction.amount && (
                    <tr>
                      <td className="details-header">Crypto value:</td>
                      <td className="details-value" align="right">
                        {Utils.formatCrypto(transaction.amount)}{" "}
                        {transaction.currency}
                      </td>
                    </tr>
                  )}
                  {transaction.fiatGrossAmount && transaction.fiatCurrency && (
                    <tr>
                      <td className="details-header">
                        Fiat value (at transaction time):
                      </td>
                      <td className="details-value" align="right">
                        {Utils.formatCurrency(
                          transaction.fiatGrossAmount,
                          transaction.fiatCurrency,
                        )}{" "}
                      </td>
                    </tr>
                  )}
                  {transaction.rejectReason && (
                    <tr>
                      <td className="details-header">Reject reason:</td>
                      <td className="details-value" align="right">
                        {transaction.rejectReason}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Col>
        </Row>
      </CardBody>
    </div>
  )
}

export default TransactionDetailsSection
