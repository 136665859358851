import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import { Container, Form, FormGroup, Label, Input, Button } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

class AddUser extends Component {
  state = {
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    title: "",
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  submit = () => {
    if (!this.state.username) {
      alert("Please fill in the username!")
      return
    }

    if (!this.state.firstName) {
      alert("Please fill in the first name!")
      return
    }

    if (!this.state.lastName) {
      alert("Please fill the last name!")
      return
    }

    if (!this.state.email) {
      alert("Please fill the user's email!")
      return
    }

    let formData = new FormData()
    formData.append("username", this.state.username)
    formData.append("email", this.state.email)
    formData.append("first_name", this.state.firstName)
    formData.append("last_name", this.state.lastName)
    if (this.state.title) {
      formData.append("title", this.state.title)
    }

    BackOfficeApi.endpoints.addUser
      .create(formData)
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          window.location.href = "/admin/users/"
        }
        throw new Error()
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Add a new user</div>
        </div>
        <Container fluid>
          <Form style={{ width: "93%", margin: "auto" }}>
            <FormGroup row>
              <Label className="col-xl-1 col-form-label" for="username">
                Username
              </Label>
              <Input
                className="col-md-3"
                type="text"
                name="username"
                value={this.username}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-1 col-form-label" for="email">
                E-mail
              </Label>
              <Input
                className="col-md-3"
                type="text"
                name="email"
                value={this.email}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-1 col-form-label" for="firstName">
                Name
              </Label>
              <Input
                className="col-md-3"
                type="text"
                name="firstName"
                value={this.firstName}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-1 col-form-label" for="lastName">
                Surname
              </Label>
              <Input
                className="col-md-3"
                type="text"
                name="lastName"
                value={this.lastName}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-1 col-form-label" for="title">
                Title
              </Label>
              <Input
                className="col-md-3"
                type="text"
                name="title"
                value={this.title}
                onChange={this.onChange}
              />
            </FormGroup>
            <Button color="primary" onClick={this.submit}>
              Submit
            </Button>
          </Form>
        </Container>
      </ContentWrapper>
    )
  }
}
export default AddUser
