import React, { Component } from "react"
import ChartUtils from "../Common/ChartUtils"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

class BackOfficeAreaPercentageChart extends Component {
  render() {
    let optionalAreaProps = {}
    if (this.props.unit) {
      optionalAreaProps.unit = " " + this.props.unit
    }

    return (
      <div
        className="card-body"
        style={{
          width: "100%",
          height: "500px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        {this.props.lineData && (
          <ResponsiveContainer>
            <AreaChart
              data={this.props.lineData}
              margin={{ top: 10, right: 22, left: 10, bottom: 5 }}
              stackOffset="expand"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="xKey" interval={this.props.xInterval} />
              <YAxis tickFormatter={toPercent} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={renderTooltipContent} />
              <Legend />
              {this.props.lines.map((item, i) => {
                return (
                  <Area
                    key={i}
                    type="monotone"
                    dataKey={item.name}
                    stroke={ChartUtils.getChartLineColor(i)}
                    fill={ChartUtils.getChartLineColor(i)}
                    strokeWidth={3}
                    activeDot={{ r: 8 }}
                    name={this.props.lineLabel || item.name}
                    stackId="1"
                    {...optionalAreaProps}
                  />
                )
              })}
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    )
  }
}

const toPercent = (decimal, fixed = 0) => {
  return `${(decimal * 100).toFixed(fixed)}%`
}

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0

  return toPercent(ratio, 2)
}

const renderTooltipContent = o => {
  const { payload, label } = o
  const total = payload.reduce((result, entry) => result + entry.value, 0)

  return (
    <div
      className="customized-tooltip-content"
      style={{ backgroundColor: "white", padding: "10px" }}
    >
      <p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value} ${
              entry.unit ? entry.unit : ""
            } (${getPercent(entry.value, total)})`}
          </li>
        ))}
      </ul>
    </div>
  )
}

BackOfficeAreaPercentageChart.defaultProps = {
  lines: [],
}

export default BackOfficeAreaPercentageChart
