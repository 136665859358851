import moment from "moment"
import React from "react"
import swal from "sweetalert"
import emojiFlags from "emoji-flags"
import ToastUtils from "./ToastUtils"
import StorageUtils from "./StorageUtils"
import { API_ID_STAGING, API_ID_PROD, IMPORTANT_WALLETS } from "./constants"

/**
 * If possible, displays error message from BI. Else displays general error
 * @param response
 */
export const displayError = async response => {
  try {
    if (response) {
      const json = await response?.json()
      const message = json?.error
      if (!message) {
        console.error(response)
        throw new Error()
      }
      ToastUtils.toastCustomError(message)
    }
  } catch {
    // Show general error
    ToastUtils.toastExecuteError2()
  }
}

const Utils = {
  getTags: function (order) {
    let tags = []

    order.tags.forEach(tag => {
      tags.push(
        <div
          className="tag-badge"
          key={tag.id}
          style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
        >
          {tag.name}
        </div>,
      )
    })

    return tags
  },

  downloadFile: function (blob, name) {
    const fileURL = URL.createObjectURL(blob)
    let link = document.createElement("a")
    link.href = fileURL
    link.download = name
    link.dispatchEvent(new MouseEvent("click"))
  },

  selectElementContents: function (el) {
    let body = document.body,
      range,
      sel
    if (document.createRange && window.getSelection) {
      range = document.createRange()
      sel = window.getSelection()
      sel.removeAllRanges()
      try {
        range.selectNodeContents(el)
        sel.addRange(range)
      } catch (e) {
        range.selectNode(el)
        sel.addRange(range)
      }
    } else if (body.createTextRange) {
      range = body.createTextRange()
      range.moveToElementText(el)
      range.select()
    }
  },

  formatValueToSek: function (v) {
    return typeof v === "number"
      ? v.toLocaleString("sv-SE", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "currency",
          currency: "SEK",
        })
      : "NA"
  },

  getCountriesOptions: function (countries) {
    if (countries) {
      let cs = []
      countries.forEach(c => {
        cs.push(
          <option key={c.id} value={c.name}>
            {c.name}
          </option>,
        )
      })
      return cs
    }

    return []
  },

  getCountryId: function (countries, countryName) {
    if (countries) {
      return countries.find(c => {
        return c.name === countryName
      }).id
    }
    return null
  },

  /**
   * Returns value of a specific query parameter
   * @param location Location object
   * @param variable
   * @returns {string}
   */
  getQueryVariable: function (location, variable) {
    let query = location.search.substring(1)
    let vars = query.split("&")
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=")
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1])
      }
    }
  },

  getQueryVariableFromURI: function (variable) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    if (!params) return null

    const value = params[variable]
    return value
  },

  isProduction: function () {
    let hostname = document.location.hostname
    return hostname.indexOf("safello.com") > -1
  },

  getCountryByIP: async function (ip) {
    if (!ip || ip === "::1") {
      return null
    }
    ip = ip.split(",")[0]

    try {
      const response = await fetch("https://geolocation-db.com/json/" + ip)
      let location = await response.json()
      const { emoji } = emojiFlags.countryCode(location.country_code)
      location.emoji = emoji
      return location
    } catch (error) {
      console.log(error)
      return null
    }
  },

  getLastUrlSegment: function (location) {
    return location.pathname.substr(location.pathname.lastIndexOf("/") + 1)
  },

  getUtcInLocalDateTime: function (dateTime) {
    if (!dateTime) return ""
    return moment.utc(dateTime).local().format("YYYY-MM-DD HH:mm")
  },

  getUtcInLocalDate: function (dateTime) {
    if (!dateTime) return ""
    return moment.utc(dateTime).local().format("YYYY-MM-DD")
  },

  getFirstDayOfCurrentMonth: function () {
    return moment().startOf("month").format()
  },

  getLastDayOfCurrentMonth: function () {
    return moment().endOf("month").format()
  },

  getCurrentDay: function () {
    return moment().format()
  },

  getFirstAndLastDayOfCurrentMonthQuery: function () {
    return {
      startTime: encodeURIComponent(this.getFirstDayOfCurrentMonth()),
      endTime: encodeURIComponent(this.getLastDayOfCurrentMonth()),
    }
  },

  getFirstAndCurrentDay: function () {
    return {
      startTime: encodeURIComponent(this.getFirstDayOfCurrentMonth()),
      endTime: encodeURIComponent(this.getCurrentDay()),
    }
  },
  /**
   * Formats number with separator
   * @param {string} numberString - The number to be formatted
   * @param {number} decimal - The number of decimal places to round to
   * @returns {string}
   */
  formatNumber: function (numberString, decimal = 8) {
    if (!numberString) return "0"
    // Convert the string to a float
    const number = parseFloat(numberString)
    return number.toLocaleString("sv-SE", {
      maximumFractionDigits: decimal,
    })
  },

  formatCurrency: (v, currency = "EUR") =>
    typeof v === "number"
      ? v.toLocaleString("sv-SE", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "currency",
          currency,
        })
      : "",

  formatDay: v =>
    typeof v === "number"
      ? v.toLocaleString("sv-SE", {
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
        })
      : "NA",

  formatCrypto: (v, decimals = 8) =>
    typeof v === "number"
      ? v.toLocaleString("sv-SE", {
          maximumFractionDigits: decimals,
          minimumFractionDigits: 0,
        })
      : "NA",

  /**
   * Default yes or no sweet alert
   * @param title
   * @param text
   * @param translate
   * @returns {{identifier, transformer}}
   */
  showSweetAlertAreYouSure: function (title, text) {
    return swal({
      title: title,
      text: text,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    })
  },

  showSweetAlertYesCancel: function (title, text) {
    return swal({
      title: title,
      text: text,
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    })
  },

  showSweetAlertOk: function (title, text) {
    return swal({
      title: title,
      text: text,
      icon: "warning",
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        confirm: {
          text: "Ok",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    })
  },
  removeCamelCase: function (str) {
    str = str.replace(/([A-Z])/g, " $1")
    // uppercase the first character
    str = str.replace(/^./, function (str) {
      return str.toUpperCase()
    })
    return str
  },
  getExposureCategoryColor: function (exposureCategory) {
    return exposureCategoryColorMap[exposureCategory] || "#4E5452"
  },

  getAppId: function () {
    if (this.isProduction()) {
      return API_ID_PROD
    } else {
      return API_ID_STAGING
    }
  },

  displayError: displayError,
  isDeveloperPermission: function () {
    const userPermissions = StorageUtils.getUserPermissions()
    return userPermissions?.includes("DEVELOPER")
  },

  isOperationsPermission: function () {
    const userPermissions = StorageUtils.getUserPermissions()
    return userPermissions?.includes("OPERATIONS")
  },

  getImportantWallets: function () {
    if (this.isProduction()) {
      return IMPORTANT_WALLETS.production
    } else {
      return IMPORTANT_WALLETS.staging
    }
  },
}

export const DataGridNumberFormatter = props => (
  <div>
    <span>{Utils.formatNumber(props.value)}</span>
  </div>
)

/**
 * DataGrid formatter for showing an UTC date in local time
 * @param props
 * @returns {*}
 * @constructor
 */
export const DataGridLocalDateTimeFormatter = props => (
  <div>
    <span>{Utils.getUtcInLocalDateTime(props.value)}</span>
  </div>
)

export default Utils

// Color map is based on the one used by Chainanalysis
const exposureCategoryColorMap = {
  exchange: "#5c6bbe",
  "unnamed service": "#455a63",
  gambling: "#ff5827",
  "token smart contract": "#4b6651",
  "p2p exchange": "#4a148c",
  "smart contract": "#aa6ad8",
  "decentralized exchange contract": "#7c609b",
  "high risk exchange": "#880d4f",
  other: "#a2e1dc",
  sanctions: "#e43b38",
  "mining pool": "#4ead51",
  mining: "#4ead51",
  "protocol privacy": "#e82463",
  scam: "#4f3731",
  mixing: "#e82463",
  atm: "#fadf86",
  "child abuse material": "#ff1c46",
  "darknet market": "#e43b38",
  "erc20 token": "#00e675",
  "ethereum contract": "#6bf0af",
  "fraud shop": "#ed810e",
  "high risk jurisdiction": "#880d4f",
  "hosted wallet": "#1976d2",
  ico: "#c4c9ea",
  "illicit actor-org": "#7c7403",
  "infrastructure as a service": "#13dddd",
  "lending contract": "#a5a3e0",
  "merchant services": "#ffbf10",
  ransomware: "#8b1b1b",
  "stolen ether": "#79564a",
  "stolen funds": "#79564a",
  "terrorist financing": "#421313",
}
