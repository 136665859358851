import React, { Component } from "react"
import { Container, Row, Col, CardHeader, CardBody } from "reactstrap"
import { Timeline, TimelineEvent } from "react-event-timeline"
import Utils from "../../Common/Utils"
import Cart from "../../Media/Svg/cart.svg"
import Cash from "../../Media/Svg/cash.svg"
import Exchange from "../../Media/Svg/exchange.svg"
import Transfer from "../../Media/Svg/transfer.svg"
import BackOfficeApi from "../../Api/BackOfficeApi"
import OrderUtils from "../OrderUtils"
import ReactDataGrid from "react-data-grid"
import CheckValid from "../../Media/Svg/check_valid.svg"

const commonBubbleStyles = {
  border: "none",
  width: "40px",
  height: "40px",
  paddingLeft: "4px",
  marginLeft: "-3px",
}

class SellTimeLineSection extends Component {
  state = {
    isLoading: true,
  }
  constructor(props, context) {
    super(props, context)

    this.bitstampTransactionColumns =
      OrderUtils.getBitstampTransactionTableColumn()
    this.blockchainTransactionColumns =
      OrderUtils.getBlockchainTransactionTableColumn()
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      this.getBitstampTransactions()
      this.getBlockchainTransaction()
    }
  }

  getBitstampTransactions = () => {
    BackOfficeApi.endpoints.sellOrderBitstampTransactions
      .getAll({ id: this.props.order.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({ bitstampTransactions: data })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  getBlockchainTransaction = () => {
    BackOfficeApi.endpoints.sellOrderBlockchainTransactions
      .getAll({ orderId: this.props.order.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({ blockchainTransaction: data })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    if (!this.props.order) return <div />

    let events = []
    if (this.props.order.issued) {
      events.push({
        title: "Order issued",
        createdAt: Utils.getUtcInLocalDateTime(this.props.order.issued),
        icon: Cart,
        bubbleStyle: { backgroundColor: "#f0f0f0", ...commonBubbleStyles },
      })
    }
    if (this.props.order.approved) {
      events.push({
        title: "Order approved",
        createdAt: Utils.getUtcInLocalDateTime(this.props.order.approved),
        icon: CheckValid,
        bubbleStyle: { backgroundColor: "#b1e5be", ...commonBubbleStyles },
      })
    }
    if (this.props.order.receivedMempool) {
      events.push({
        title: "Mempool received",
        createdAt: Utils.getUtcInLocalDateTime(
          this.props.order.receivedMempool,
        ),
        icon: Cash,
        bubbleStyle: { backgroundColor: "#d5d5d5", ...commonBubbleStyles },
      })
    }
    if (this.props.order.received) {
      events.push({
        title: "Crypto received",
        createdAt: Utils.getUtcInLocalDateTime(this.props.order.received),
        icon: Cash,
        bubbleStyle: { backgroundColor: "#b2e7bf", ...commonBubbleStyles },
      })
    }
    if (this.props.order.sold) {
      events.push(this.getSentToExchangeEvent())
    }
    if (this.props.order.refunded) {
      events.push({
        title: "Refunded",
        createdAt: Utils.getUtcInLocalDateTime(this.props.order.refunded),
        icon: Exchange,
        bubbleStyle: { backgroundColor: "#e3f2ff", ...commonBubbleStyles },
      })
    }
    if (this.props.order.sentToBank) {
      events.push(this.getSentToCustomerEvent())
    }

    return (
      <div>
        <CardHeader className="card-section-header">Timeline</CardHeader>
        <CardBody className="pt-0">
          <Row className="no-gutters">
            <Col>
              <div>
                <Timeline
                  lineColor="#edf1f2"
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                >
                  {events.map((item, i) => {
                    return (
                      <TimelineEvent
                        key={i}
                        createdAt={
                          <span className="timeline_time">
                            {item.createdAt}
                          </span>
                        }
                        title={
                          <span className="timeline_title">{item.title}</span>
                        }
                        icon={
                          <img
                            src={item.icon}
                            width="24px"
                            height="24px"
                            alt=""
                          />
                        }
                        style={{ marginTop: i > 0 ? "20px" : "0px" }}
                        bubbleStyle={item.bubbleStyle}
                        collapsible={item.collapsible}
                        showContent={true}
                        contentStyle={{ padding: "0px", boxShadow: "0" }}
                      >
                        {item.content}
                      </TimelineEvent>
                    )
                  })}
                </Timeline>
              </div>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }

  getSentToExchangeEvent = () => {
    let exchangeEvent = {
      title: "Sent to exchange",
      createdAt: Utils.getUtcInLocalDateTime(this.props.order.sold),
      icon: Exchange,
      bubbleStyle: { backgroundColor: "#e3f2ff", ...commonBubbleStyles },
    }

    if (
      this.state.bitstampTransactions &&
      this.state.bitstampTransactions.length > 0
    ) {
      let rowHeight = 40
      let tableHeight = (this.state.bitstampTransactions.length + 1) * rowHeight
      exchangeEvent.collapsible = true
      exchangeEvent.content = (
        <div>
          <h2 className="timeline_content_title">Bitstamp transactions</h2>
          <Container className="grid-no-v-scroll grid-no-border">
            <ReactDataGrid
              columns={this.bitstampTransactionColumns}
              rowGetter={this.rowGetterBitstampTransaction}
              rowsCount={this.state.bitstampTransactions.length}
              rowHeight={rowHeight}
              minHeight={tableHeight}
              enableCellAutoFocus={false}
            />
          </Container>
        </div>
      )
    } else if (this.state.blockchainTransaction) {
      let rowHeight = 40
      let tableHeight = 80
      exchangeEvent.collapsible = true
      exchangeEvent.content = (
        <div>
          <h2 className="timeline_content_title">Blockchain transaction</h2>
          <Container className="grid-no-v-scroll grid-no-border">
            <ReactDataGrid
              columns={this.blockchainTransactionColumns}
              rowGetter={this.rowGetterBlockchainTransaction}
              rowsCount={1}
              rowHeight={rowHeight}
              minHeight={tableHeight}
              enableCellAutoFocus={false}
            />
          </Container>
        </div>
      )
    }

    return exchangeEvent
  }

  getSentToCustomerEvent = () => {
    let transferredEvent = {
      title: "Fiat sent to customer",
      createdAt: Utils.getUtcInLocalDateTime(this.props.order.sentToBank),
      icon: Transfer,
      bubbleStyle: { backgroundColor: "#fbc94c", ...commonBubbleStyles },
    }

    if (this.props.order && this.props.order.cryptoAddress) {
      transferredEvent.collapsible = true
      transferredEvent.content = (
        <a
          href={OrderUtils.getCryptoBlockExplorerUrl(
            this.props.order.cryptoCurrency,
            this.props.order.cryptoAddress,
          )}
          target="_blank"
          rel="noreferrer"
        >
          {this.props.order.cryptoAddress}
        </a>
      )
    }

    return transferredEvent
  }

  rowGetterBitstampTransaction = i => {
    return OrderUtils.getBitstampTransactionDataRow(
      this.state.bitstampTransactions[i],
      true,
    )
  }

  rowGetterBlockchainTransaction = () => {
    let row = this.state.blockchainTransaction

    let outCurrency = row.currencyPair ? row.currencyPair.split("/")[1] : ""

    let inCurrency = row.currencyPair ? row.currencyPair.split("/")[0] : ""

    row.originalAmountData = {
      amount: row.originalAmount,
      currency: inCurrency,
    }

    row.inAmountData = {
      amount: row.baseAmount,
      currency: inCurrency,
    }

    row.outAmountData = {
      amount: row.counterAmount,
      currency: outCurrency,
    }

    return row
  }
}

export default SellTimeLineSection
