import React, { Component } from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import PartnerAppApi from "./PartnerAppApi"
import EditAppApiModal from "./EditAppApiModal"
import ToastUtils from "../Common/ToastUtils"
import NewFeeRevisionModal from "./NewFeeRevisionModal"

class PartnerAppApiSection extends Component {
  state = {
    editAppApi: null,
    editAppApiModal: false,
    newFeeRevisionModal: false,
  }

  renderAppApis = () => {
    let appApis = this.props.partner.apiApplications
    let renderAppApis = []

    appApis.forEach(a => {
      renderAppApis.push(
        <Row key={a.id}>
          <Col>
            <PartnerAppApi
              appApi={a}
              onEdit={this.onAppApiEditClick}
              onNewFeeRevision={this.onNewFeeRevisionClick}
            />
          </Col>
        </Row>,
      )
    })

    return renderAppApis
  }

  onAppApiEditClick = appApi => {
    this.setState({
      editAppApi: appApi,
    })
    this.toggleAppApiEditModal()
  }

  onNewFeeRevisionClick = appApi => {
    this.setState({
      editAppApi: appApi,
    })
    this.toggleNewFeeRevisionModal()
  }

  toggleAppApiEditModal = () => {
    this.setState({
      editAppApiModal: !this.state.editAppApiModal,
    })
  }

  toggleNewFeeRevisionModal = () => {
    this.setState({
      newFeeRevisionModal: !this.state.newFeeRevisionModal,
    })
  }

  onSave = () => {
    ToastUtils.toastSaveSuccess2()

    this.setState({
      editAppApiModal: false,
      newFeeRevisionModal: false,
    })

    if (this.props.onUpdate) {
      this.props.onUpdate()
    }
  }

  render() {
    if (!this.props.partner) return <div />

    return (
      <div>
        <Card>
          <CardHeader className="card-section-header">App Api:s</CardHeader>
          <CardBody className="pt-0">{this.renderAppApis()}</CardBody>
        </Card>
        <EditAppApiModal
          partner={this.props.partner}
          appApi={this.state.editAppApi}
          open={this.state.editAppApiModal}
          toggle={this.toggleAppApiEditModal}
          onSave={this.onSave}
          channels={this.props.channels}
        />
        <NewFeeRevisionModal
          partner={this.props.partner}
          appApi={this.state.editAppApi}
          open={this.state.newFeeRevisionModal}
          toggle={this.toggleNewFeeRevisionModal}
          onSave={this.onSave}
        />
      </div>
    )
  }
}
export default PartnerAppApiSection
