import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row } from "reactstrap"
import RevenueChart from "./RevenueChart"
import OrderChart from "./OrderChart"
import OrderAvgAmountChart from "./OrderAvgAmountChart"
import RegistrationsChart from "./RegistrationsChart"
import LiveChart from "./LiveChart"

class Partners extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Partners</div>
        </div>
        <Row>
          <RevenueChart />
        </Row>
        <Row>
          <OrderChart />
        </Row>
        <Row>
          <OrderAvgAmountChart />
        </Row>
        <Row>
          <RegistrationsChart />
        </Row>
        <Row>
          <LiveChart />
        </Row>
      </ContentWrapper>
    )
  }
}

export default Partners
