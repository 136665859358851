import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import ChartUtils from "../../Common/ChartUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeAreaChart from "../../Chart/BackOfficeAreaChart"
import CryptoSelector from "../../Common/CryptoSelector"

const Chart = () => {
  const [perspective, setPerspective] = useState(
    ChartUtils.getDefaultPerspective(),
  )
  const [cryptoCurrency, setCryptoCurrency] = useState("BTC")
  const [graphData, setGraphData] = useState(null)

  useEffect(() => {
    updateChart()
  }, [perspective, cryptoCurrency])

  const updateChart = async () => {
    try {
      const response =
        await BackOfficeApi.endpoints.statsWalletCountChart.getAll({
          perspective: perspective.toLowerCase(),
          cryptoCurrency,
        })
      if (!response.ok) {
        throw Error()
      }
      const data = await response.json()
      setGraphData({
        lines: data.lineInfo,
        lineData: data.lineData.map(dataNode => ({
          ...dataNode,
          xKey: ChartUtils.getPerspectiveXFormat(dataNode.xKey, perspective),
        })),
        xInterval: ChartUtils.getPerspectiveXInterval(perspective),
        perspective,
        unit: data.unit,
      })
    } catch (e) {
      console.error(e)
      ToastUtils.toastAPIError2()
    }
  }

  return (
    <Col xl={12}>
      {graphData && (
        <BackOfficeAreaChart
          lines={graphData.lines}
          lineData={graphData.lineData}
          xInterval={graphData.xInterval}
          unit=""
          showTotal={true}
          title="Daily activated wallets"
          stack
          perspective={perspective}
          onPerspectiveChange={setPerspective}
          controls={
            <React.Fragment>
              <CryptoSelector
                className={"float-right mr-3"}
                onChange={setCryptoCurrency}
                value={cryptoCurrency}
              />
            </React.Fragment>
          }
        />
      )}
    </Col>
  )
}

export default Chart
