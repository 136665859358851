import React, { useEffect, useRef, useState, useMemo } from "react"
import { Container } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import ContentWrapper from "../Layout/ContentWrapper"
import DataGridEmpty from "../Common/DataGridEmpty"
import BackOfficeApi from "../Api/BackOfficeApi"
// utils
import ToastUtils from "../Common/ToastUtils"
import Utils, { DataGridLocalDateTimeFormatter } from "../Common/Utils"
import useWindowDimensions from "../../utils/hooks/useWindowDimensions"
import { fetchTags } from "../../utils/fetch"
// components
import DropdownList from "../Common/DropdownList"
import EditTags from "../Common/EditTags"
import XmlReport from "../Orders/XmlReport"
import SearchBar from "../Common/SearchBar"
import {
  customerFormatter,
  orderIDFormatter,
  walletFormatter,
  amountFormatter,
  tagsFormatter,
  statusFormatter,
  flaggedFormatter,
  orderTypeFormatter,
  capitalizeString,
} from "../../utils/format"

const _fetchSize = 30

const TransactionList = ({ location, type = "DEPOSIT" }) => {
  const searchParams = new URLSearchParams(location.search)
  const userId = searchParams.get("customer")

  const [rowOffsetHeight, setRowOffsetHeight] = useState(0)
  const [filteredOrders, setFilteredOrders] = useState([])
  const [orders, setOrders] = useState([])
  const [chosenIds, setChosenIds] = useState([])
  const [noMoreData, setNoMoreData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editTagsModal, setEditTagsModal] = useState(false)
  const [xmlReportModal, setXmlReportModal] = useState(false)
  const [isSearching, setSearching] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [customerId, setCustomerId] = useState(null)
  const [statusFilterValue, setStatusFilterValue] = useState("")
  const [orderTypeFilterValue, setOrderTypeFilterValue] = useState("")
  const [tagsFilterValue, setTagsFilterValue] = useState(0)
  const [tags, setTags] = useState(null)

  const { height } = useWindowDimensions()

  const searchTextRef = useRef(searchText)
  searchTextRef.current = searchText
  const isAggregated = type === "AGGREGATED"

  const transactionColumns = [
    {
      key: "orderInfo",
      name: "ID",
      width: 100,
      formatter: props =>
        orderIDFormatter(props, chosenIds, handleIdCheckClick),
    },
    {
      key: "customer",
      name: "Customer",
      formatter: customerFormatter,
      width: 180,
    },
    {
      key: "amount",
      name: "Amount",
      width: 150,
    },
    {
      key: "issued",
      name: "Created",
      formatter: DataGridLocalDateTimeFormatter,
      width: 140,
    },
    {
      key: "status",
      name: "Status",
      width: 120,
      formatter: statusFormatter,
    },
    {
      key: "tags",
      name: "Tags",
      formatter: tagsFormatter,
    },
  ]

  const aggregatedColumns = [
    {
      key: "orderInfo",
      name: "ID",
      formatter: props =>
        orderIDFormatter(props, chosenIds, handleIdCheckClick),
      width: 100,
    },
    {
      key: "orderType",
      name: "Type",
      formatter: orderTypeFormatter,
      width: 50,
    },
    {
      key: "customer",
      name: "Customer",
      width: 120,
      formatter: customerFormatter,
    },
    {
      key: "from",
      name: "From",
      width: 120,
    },
    {
      key: "to",
      name: "To",
      width: 120,
    },
    {
      key: "asset",
      name: "Asset",
      width: 80,
    },
    {
      key: "issued",
      name: "Issued",
      formatter: DataGridLocalDateTimeFormatter,
      width: 120,
    },
    {
      key: "status",
      name: "Status",
      width: 120,
      formatter: statusFormatter,
    },
    {
      key: "ref",
      name: "REF #",
      width: 80,
    },
    {
      key: "issuer",
      name: "Issuer",
      width: 80,
    },
    {
      key: "channel",
      name: "Channel",
      width: 100,
    },
    {
      key: "wallet",
      name: "Wallet",
      formatter: walletFormatter,
      width: 80,
    },
    {
      key: "flagged",
      name: "Flagged",
      formatter: flaggedFormatter,
      width: 80,
    },
    {
      key: "tags",
      name: "Tags",
      formatter: tagsFormatter,
    },
  ]
  const columns = isAggregated ? aggregatedColumns : transactionColumns

  const doFiltering = () => {
    let newFilteredOrders = orders
    if (statusFilterValue) {
      const filteredStatus = newFilteredOrders.filter(order => {
        return order.status === statusFilterValue
      })
      newFilteredOrders = filteredStatus
    }
    if (orderTypeFilterValue) {
      const filteredOrderType = newFilteredOrders.filter(order => {
        return order.orderInfo.orderType === orderTypeFilterValue
      })
      newFilteredOrders = filteredOrderType
    }
    if (tagsFilterValue) {
      const filteredTag = newFilteredOrders.filter(order => {
        return order.tags.filter(tag => tag.id === tagsFilterValue).length > 0
      })
      newFilteredOrders = filteredTag
    }
    setFilteredOrders(newFilteredOrders)
  }

  const getTags = async () => {
    const fetchedTags = await fetchTags("WALLET_TRANSACTION")
    setTags(fetchedTags)
  }

  useEffect(() => {
    if (!isAggregated && statusFilterValue !== "") {
      loadTransactionData()
    }
  }, [statusFilterValue])

  useEffect(() => {
    if (isAggregated) {
      getTags()
      const customerId = Utils.getQueryVariableFromURI("customer")
      if (!customerId) {
        ToastUtils.toastCustomError("No customer ID was provided")
        window.location.href = "/"
      } else {
        setCustomerId(customerId)
      }
    } else {
      loadTransactionData()
    }
  }, [])

  useEffect(() => {
    if (customerId && isAggregated) fetchAggregatedOrders()
  }, [customerId])

  useEffect(() => {
    if (isAggregated && orders.length !== 0) {
      if (!orderTypeFilterValue && !statusFilterValue && !tagsFilterValue) {
        setFilteredOrders(orders)
      } else {
        doFiltering()
      }
    }
  }, [orderTypeFilterValue, statusFilterValue, tagsFilterValue, orders])

  const handleIdCheckClick = e => {
    const checked = e.target.checked
    const value = e.target.value
    let newChosenIds = chosenIds
    const index = newChosenIds.indexOf(value)

    if (checked) {
      if (index === -1) {
        newChosenIds.push(value)
      }
    } else {
      if (index > -1) {
        newChosenIds.splice(index, 1)
      }
    }
    setChosenIds(newChosenIds)
  }

  const loadTransactionData = (first = 0, append = false) => {
    setIsLoading(true)

    if (isSearching) {
      onSearch(first, append)
      return
    }

    let params = {
      first: first,
      limit: _fetchSize,
      type,
      ...(statusFilterValue ? { status: statusFilterValue } : {}),
    }

    if (userId) {
      params.userId = userId
    }

    BackOfficeApi.endpoints.walletTransactions
      .getAll(params)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        const formattedData = formatTransactionData(data)
        addData(formattedData, append)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onSearch = (first = 0, append = false) => {
    if (searchText.length <= 0) {
      setSearching(false)
      loadTransactionData()
    }

    setIsLoading(true)
    setSearching(true)

    let params = {
      first: first,
      limit: _fetchSize,
      type,
      criteria: searchTextRef.current,
    }

    BackOfficeApi.endpoints.searchWalletTransactions
      .getAll(params)
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        const formattedData = formatTransactionData(data)
        addData(formattedData, append)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onScroll = e => {
    const ordersSize = orders.length
    let halfWayVScroll = ordersSize * rowOffsetHeight - 800
    let currentVScroll = e.scrollTop
    if (
      currentVScroll >= halfWayVScroll &&
      !isLoading &&
      !noMoreData &&
      ordersSize < 1000
    ) {
      loadTransactionData(ordersSize, true)
    }
  }

  const addData = (data, append) => {
    if (append) {
      if (data.length < _fetchSize) {
        setNoMoreData(true)
      }
      setOrders(orders.concat(data.slice(0)))
    } else {
      setOrders(data.slice(0))
      setNoMoreData(false)
    }
  }

  const doHeaderAction = item => {
    if (item.value === "select_all") {
      const x = document.getElementsByClassName("custom-control-input")
      for (let i = 0; i <= x.length; i++) {
        if (x[i] && !x[i].checked) {
          x[i].click()
        }
      }
      return
    }

    if (!chosenIds || chosenIds.length === 0) {
      alert("Please choose some orders first.")
      return
    }

    switch (item.value) {
      case "edit_tags":
        setEditTagsModal(true)
        break
      case "report":
        setXmlReportModal(true)
        break
      case "banlist_addresses":
        Utils.showSweetAlertAreYouSure(
          "Banlist selected transactions addresses?",
          "",
          null,
        ).then(confirmed => {
          if (confirmed) {
            banlistAddresses()
          }
        })
        break
      default:
      //do nothing
    }
  }

  const tagsEditFinished = () => {
    clearSelectedCheckboxes()
    isAggregated ? fetchAggregatedOrders() : loadTransactionData()
  }

  const banlistAddresses = () => {
    const txs = getIdsFromChosenIds(chosenIds)
    let formData = new FormData()
    formData.append("ids", txs)

    BackOfficeApi.endpoints.banlistWalletTransactionsAddress
      .create(formData)
      .then(response => {
        if (response.ok) {
          clearSelectedCheckboxes()
          ToastUtils.toastExecuteSuccess2()
        }
      })
  }

  const fetchAggregatedOrders = async () => {
    setIsLoading(true)
    try {
      const res =
        await BackOfficeApi.endpoints.customerAggregatedTransactions.getOne({
          customerId,
        })
      if (!res || res.error) throw Error(res ? res.error : null)
      const json = await res.json()
      if (!json) throw Error()
      const formattedData = formatAggregatedData(json)
      setOrders(formattedData)
    } catch (e) {
      console.error(e)
      ToastUtils.toastCustomError("Error fetching user orders")
    }
    setIsLoading(false)
  }

  const clearSelectedCheckboxes = () => {
    setChosenIds(chosenIds.splice(0, chosenIds.length))

    const x = document.getElementsByClassName("custom-control-input")
    for (let i = 0; i <= x.length; i++) {
      if (x[i] && x[i].checked) {
        x[i].checked = false
      }
    }
  }

  const onSearchChange = e => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }

    setSearchText(e.target.value)
    setSearchTimeout(
      setTimeout(() => {
        onSearch()
      }, 500),
    )
  }

  const renderStatusFilters = () => {
    return (
      <div className="ml-auto form-inline">
        <DropdownList
          className="pr-2"
          label="Status"
          buttonColor="primary"
          value={statusFilterValue}
          onSelectItem={item => setStatusFilterValue(item.value)}
        >
          {[
            { value: "", text: "All" },
            { divider: true },
            { value: "PENDING", text: "Pending" },
            { value: "PROCESSING", text: "Processing" },
            { value: "HALTED", text: "Halted" },
            { value: "REQUIRE_SIGNING", text: "Require signing" },
            { value: "REJECTED", text: "Rejected" },
            { value: "COMPLETED", text: "Completed" },
            isAggregated && { value: "ISSUED", text: "Issued" },
            isAggregated && { value: "DELETED", text: "Deleted" },
          ]}
        </DropdownList>
      </div>
    )
  }

  const renderOrderTypeFilters = () => {
    return (
      <div className="ml-auto form-inline">
        <DropdownList
          className="pr-2"
          label="Order type"
          buttonColor="primary"
          value={orderTypeFilterValue}
          onSelectItem={item => setOrderTypeFilterValue(item.value)}
        >
          {[
            { value: "", text: "All" },
            { divider: true },
            { value: "BUY", text: "Buy" },
            { value: "SELL", text: "Sell" },
            { value: "DEPOSIT", text: "Deposit" },
            { value: "WITHDRAWAL", text: "Withdrawal" },
            { value: "SWAP", text: "Swap" },
          ]}
        </DropdownList>
      </div>
    )
  }

  const tagsFilter = useMemo(() => {
    let tagsList = [{ value: 0, text: "All" }, { divider: true }]

    if (tags) {
      tags.forEach(tag => tagsList.push({ value: tag.id, text: tag.name }))
    }

    return (
      <div className="ml-auto form-inline">
        <DropdownList
          className="pr-2"
          label="Tags"
          buttonColor="primary"
          value={tagsFilterValue}
          onSelectItem={item => setTagsFilterValue(item.value)}
        >
          {tagsList}
        </DropdownList>
      </div>
    )
  }, [tags])

  const renderFilters = () => {
    if (!isAggregated) return renderStatusFilters()
    return (
      <React.Fragment>
        {renderOrderTypeFilters()}
        {renderStatusFilters()}
        {tagsFilter}
      </React.Fragment>
    )
  }

  const renderHeaderActions = () => {
    let list = [{ value: "edit_tags", text: "Edit tags" }]

    if (userId) {
      list.push({ value: "report", text: "Report" })
      list.push({ value: "select_all", text: "Select all" })
    }
    if (type === "WITHDRAWAL") {
      list.push({ value: "banlist_addresses", text: "Banlist addresses" })
    }

    return (
      <DropdownList
        className="pr-2"
        buttonColor="secondary"
        label="Bulk action"
        onSelectItem={doHeaderAction}
      >
        {list}
      </DropdownList>
    )
  }

  const renderSearch = () => {
    if (userId) {
      return ""
    }

    return (
      <div className="ml-auto position-relative">
        <SearchBar value={searchText} onChange={onSearchChange}></SearchBar>
        <div className="ball-beat loader-position" hidden={!isLoading}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }

  const headerTitle = isAggregated
    ? `All orders for customer: ${customerId}`
    : `Wallet ${type.toLowerCase()}s`

  return (
    <ContentWrapper>
      <div className="content-heading">
        {renderHeaderActions()}
        <div>{headerTitle}</div>
        {!isAggregated && renderSearch()}
        {renderFilters()}
      </div>
      <Container fluid className={isLoading ? "whirl standard" : ""}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => (isAggregated ? filteredOrders[i] : orders[i])}
          rowsCount={isAggregated ? filteredOrders.length : orders.length}
          minHeight={height * 0.8}
          emptyRowsView={orders ? null : DataGridEmpty}
          onScroll={onScroll}
          ref={element => {
            if (!isAggregated && element !== null) {
              setRowOffsetHeight(element.getRowOffsetHeight())
            }
          }}
        />
      </Container>
      <XmlReport
        orderOrTransactionIds={
          isAggregated ? chosenIds : getIdsFromChosenIds(chosenIds)
        }
        customerId={userId}
        open={xmlReportModal}
        type={type}
        isTransaction={!isAggregated}
        onToggle={() => setXmlReportModal(!xmlReportModal)}
      />
      {(editTagsModal || !isAggregated) && (
        <EditTags
          ids={isAggregated ? chosenIds : getIdsFromChosenIds(chosenIds)}
          open={editTagsModal}
          toggle={() => setEditTagsModal(!editTagsModal)}
          onChange={tagsEditFinished}
          isAggregated={isAggregated}
          type={"WALLET_TRANSACTION"}
          preFetchedTags={tags}
        />
      )}
    </ContentWrapper>
  )
}

// Formats from example: "BUY-1823" => 1823
const getIdsFromChosenIds = chosenIds => {
  return chosenIds.map(value => Number(value.split("-")[1]))
}

const formatNumber = (amount, asset) => {
  if (!amount || !asset) {
    return "-"
  }
  return `${Utils.formatNumber(amount)} ${asset}`
}

const formatAggregatedData = ordersData => {
  const formattedOrders = ordersData.map(order => {
    const orderId = order.internalId
    const orderMapId = order.order && order.order.orderMapId

    const customer = order.customer ? order.customer : null
    const issued = order.created ? order.created : null
    const status = order.status ? order.status : null
    const ref = order.externalId ? order.externalId : null
    const issuer = order.order ? order.order.issuer : null
    const tags = order.tags
    const wallet =
      (!!order.order && !!order.order.walletOrder) ||
      order.mainType === "WALLET_TRANSACTION"
    const flagged = order.flagged
    const channel =
      order.order && order.order.buyOrder ? order.order.buyOrder.channel : null

    const from = formatNumber(
      order.fromAmount?.amount,
      order.fromAmount?.currency,
    )
    const to = formatNumber(order.toAmount?.amount, order.toAmount?.currency)

    const isSwap = order.subType === "SWAP"
    const asset = isSwap ? order.pair : order.cryptoCurrency

    return {
      orderInfo: { orderId, orderType: order.subType, orderMapId },
      orderType: capitalizeString(order.subType),
      customer: customer,
      from,
      to,
      asset,
      issued: issued,
      status: status,
      ref: ref,
      issuer: issuer,
      channel: channel,
      tags: tags,
      wallet: wallet,
      flagged: flagged,
    }
  })

  return formattedOrders.reverse()
}

const formatTransactionData = ordersData => {
  return ordersData.map(order => {
    const orderType = order.type
    const orderId = order.id

    const customer = {
      id: order.customerId,
      name: order.customerName,
      riskLevel: order.customerRiskProfile,
    }
    const issued = order.created
    const status = order.status
    const tags = order.tags

    const amount = order.amount
    const crypto = order.currency

    return {
      orderInfo: { orderId, orderType },
      orderType: capitalizeString(orderType),
      customer: customer,
      amount: amountFormatter(amount, crypto),
      issued: issued,
      status: status,
      tags: tags,
    }
  })
}

export default TransactionList
