import React, { Component } from "react"
import { Button, CardBody, CardHeader, Col, Row, Table } from "reactstrap"

class UserPermissionsSection extends Component {
  state = {
    activePermissions: [],
  }

  renderPermissions = () => {

    return this.props.allPermissions.map(permission =>
      <tr key={permission}>
        <th scope="row">{permission}</th>
        <td style={{ "text-align":"center", width:"40px" }}>
          <input
            type="checkbox"
            checked={this.state.activePermissions.includes(permission)}
            value={permission}
            name={permission}
            onChange={this.onCheckboxChange}
          />
        </td>
      </tr>
    )
  }

  onCheckboxChange = e => {
    let value = e.target.value
    let activePermissions = this.state.activePermissions

    if (activePermissions.includes(value)) {
      this.setState({
        activePermissions: activePermissions.filter(p => p !== value),
      })
    } else {
      activePermissions.push(value)
      this.setState({
        activePermissions: activePermissions,
      })
    }
  }

  onUpdatePermissions = () => {
    if (this.props.onUpdatePermissions) {
      this.props.onUpdatePermissions(this.state.activePermissions)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        activePermissions: this.props.user.permissions,
      })
    }
  }

  render() {
    if (!this.props.user || !this.props.allPermissions) return <div />

    return (
      <div>
        <CardHeader className="card-section-header">
          User permissions
        </CardHeader>
        <CardBody>
          <Row className="no-gutters">
            <Col xs="4">
              <Table striped bordered hover size="sm">
                <tbody>
                  {this.renderPermissions()}
                </tbody>
              </Table>
              <Button
                color="primary"
                className="float-right mt-2"
                onClick={this.onUpdatePermissions}
              >
                Update permissions
              </Button>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
}
export default UserPermissionsSection
