import React, { useState } from "react"
import { Button, Row, Col, CardHeader, CardBody } from "reactstrap"
import RiskProfileBadge from "../Common/RiskProfileBadge"
import Badge from "../Common/Badge"
import ToastUtils from "../Common/ToastUtils"
import RiskProfileModal from "./RiskProfileModal"

export default function RiskSettingsSection({ customer, onCustomerChange }) {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleSavingError = error => {
    console.error(error)
    ToastUtils.toastSaveError2()
  }

  const isLoading = !customer

  return (
    <div>
      <CardHeader className="card-section-header">Risk settings</CardHeader>
      <CardBody className={`pt-0 ${isLoading ? "whirl standard" : ""}`}>
        <Row>
          <Col xl={8}>
            <table
              style={{
                width: "100%",
                borderCollapse: "separate",
                borderSpacing: "0 5px",
              }}
            >
              <tbody>
                <tr>
                  <td className="details-header">Risk profile:</td>
                  <td className="details-info">
                    <RiskProfileBadge customer={customer} />
                  </td>
                </tr>
                <tr>
                  <td className="details-header">Force stop orders:</td>
                  <td className="details-info">
                    {customer && customer.forceStopOrders ? (
                      <Badge>on</Badge>
                    ) : (
                      "off"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="details-header">Safe mode:</td>
                  <td className="details-info">
                    {customer && customer.safeEnvironment ? (
                      <Badge>on</Badge>
                    ) : (
                      "off"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              color="primary"
              size="sm"
              className="safello-font mt-3"
              onClick={() => setModalOpen(true)}
            >
              Edit risk settings
            </Button>
          </Col>
        </Row>
        <RiskProfileModal
          open={isModalOpen}
          customer={customer}
          onDismiss={() => setModalOpen(!isModalOpen)}
          onSave={onCustomerChange}
          onError={handleSavingError}
        />
      </CardBody>
    </div>
  )
}
