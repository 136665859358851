import React from "react"
import { Table, Card, Button } from "reactstrap"
import { APP_COLORS } from "../../Common/constants"
// components

const CoinFunctionsTable = ({
  coinFunctionsData = [],
  onEditCryptoFunction,
}) => {
  return (
    <Card style={{ maxWidth: 600 }}>
      <div style={{ overflowX: "auto" }}>
        <Table>
          <tbody>
            <tr style={{ backgroundColor: "lightgray" }}>
              <td>
                <strong>Symbol</strong>
              </td>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Available actions</strong>
              </td>
            </tr>
            {coinFunctionsData.map((item, index) => (
              <tr key={index}>
                <td>{item.symbol}</td>
                <td>{item.displayName}</td>
                <td>{item.availableActions?.join(", ")}</td>
                <td style={{ textAlign: "center" }}>
                  <Button onClick={() => onEditCryptoFunction(item.symbol)}>
                    <span
                      className="fa fa-pen-to-square"
                      style={{ fontSize: "18px", color: APP_COLORS.green }}
                    ></span>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  )
}

export default CoinFunctionsTable
