import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

import CryptoSelector from "../Common/CryptoSelector"
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"
import BackOfficeApi from "../Api/BackOfficeApi"

const CreateOrderModal = ({
  open,
  toggle,
  customerBankAccounts,
  customerId,
  onDone,
  customerWallets,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [orderType, setOrderType] = useState("0")
  const [additionalComment, setAdditionalComment] = useState("")
  // Create sell order
  const [cryptoCurrency, setCryptoCurrency] = useState("BTC")
  const [cryptoAmount, setCryptoAmount] = useState("")
  const [skipLimitChecks, setSkipLimitChecks] = useState(false)
  const [bankAccount, setBankAccount] = useState(null)
  // Create internal transfer
  const [toWallet, setToWallet] = useState(null)
  const [remittanceInfo, setRemittanceInfo] = useState("")
  const [availableWallets, setAvailableWallets] = useState(null)

  const onCreate = async () => {
    setIsLoading(true)
    const isCreateSellOrder = orderType === "0"
    // Validate user input
    if (isCreateSellOrder) {
      if (
        !cryptoAmount ||
        !cryptoCurrency ||
        !bankAccount ||
        !additionalComment
      ) {
        ToastUtils.toastCustomError(
          !bankAccount
            ? "Customer is missing a valid bank account. Please create one first."
            : "Please fill in all required fields",
        )
        setIsLoading(false)
        return
      }
    } else if (
      !cryptoAmount ||
      !cryptoCurrency ||
      !toWallet ||
      !remittanceInfo ||
      !additionalComment
    ) {
      ToastUtils.toastCustomError("Please fill in all required fields")
      setIsLoading(false)
      return
    }

    if (!additionalComment.length >= 8) {
      ToastUtils.toastCustomError(
        "'Additional comment' needs to be 8 or more characters",
      )
      setIsLoading(false)
      return
    }

    // Confirm user action
    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: ${
        isCreateSellOrder
          ? `Create a sell order of ${cryptoAmount} ${cryptoCurrency} for customer #${customerId}`
          : `Create a internal-transfer of ${cryptoAmount} ${cryptoCurrency} from customer #${customerId} to ${toWallet.customerName}`
      }`,
    )
    if (!confirmed) {
      setIsLoading(false)
      return
    }

    // Create order
    let formData = new FormData()
    formData.append("customerId", customerId)
    formData.append("amount", cryptoAmount)
    formData.append("additionalComment", additionalComment)
    let response = null
    if (isCreateSellOrder) {
      formData.append("cryptoCurrency", cryptoCurrency)
      formData.append("appId", Utils.getAppId())
      formData.append("skipLimitChecks", skipLimitChecks)
      formData.append("bankAccountId", bankAccount?.uuid)
      response = await BackOfficeApi.endpoints.createSellOrder.create(formData)
    } else {
      formData.append("currency", cryptoCurrency)
      const customerDefaultWallet = customerWallets?.find(
        wallet => wallet.defaultWallet,
      )
      if (!customerDefaultWallet) {
        ToastUtils.toastCustomError("Customer is missing a default wallet")
        setIsLoading(false)
        return
      }
      formData.append("fromWallet", customerDefaultWallet.id)
      formData.append("toWallet", toWallet.walletId)
      formData.append("remittanceInfo", remittanceInfo)

      response =
        await BackOfficeApi.endpoints.createInternalTransfer.create(formData)
    }

    if (!response || !response.ok) {
      Utils.displayError(response)
      setIsLoading(false)
      return
    }

    toggle()
    ToastUtils.toastExecuteSuccess2()
    onDone()
    setIsLoading(false)
  }

  const onBankAccountChange = e => {
    const newBankAccountId = e.target.value
    const newBankAccount = customerBankAccounts?.find(
      account => account?.id == newBankAccountId,
    )
    setBankAccount(newBankAccount)
  }

  const onToWalletChange = e => {
    const newToWalletId = e.target.value
    const newToWallet = availableWallets?.find(
      wallet => wallet?.walletId == newToWalletId,
    )
    setToWallet(newToWallet)
  }

  useEffect(() => {
    if (customerBankAccounts) {
      setCryptoCurrency("BTC")
      setCryptoAmount("")
      setSkipLimitChecks(false)
      setAdditionalComment("")
      if (customerBankAccounts.length > 0) {
        const defaultBankAccount = customerBankAccounts.find(
          bankAccount => bankAccount.selected,
        )
        setBankAccount(defaultBankAccount)
      }
      const wallets = Utils.getImportantWallets()
      setToWallet(wallets[0])
      setAvailableWallets(wallets)
      setRemittanceInfo("")
    }
  }, [customerBankAccounts, open, orderType])

  return (
    <Modal isOpen={open} toggle={toggle} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={toggle}>Create Customer Order</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="orderType">
              Order type
            </Label>
            <div className="col-xl-0">
              <select
                value={orderType}
                name="orderType"
                className="custom-select"
                onChange={e => setOrderType(e.target.value)}
              >
                <option value="0">Sell order</option>
                <option value="1">Internal transfer</option>
              </select>
            </div>
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="cryptoCurrency">
              Crypto currency
            </Label>
            <CryptoSelector
              onChange={setCryptoCurrency}
              value={cryptoCurrency}
            />
          </FormGroup>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="cryptoAmount">
              Crypto amount
            </Label>
            <Input
              className="col-xl-8"
              type="text"
              name="cryptoAmount"
              id="cryptoAmount"
              value={cryptoAmount}
              onChange={e => setCryptoAmount(e.target.value)}
            />
          </FormGroup>
          {orderType === "0" && (
            <>
              <FormGroup row>
                <Label
                  className="col-xl-3 col-form-label"
                  for="skipLimitChecks"
                >
                  Skip limit checks
                </Label>
                <Input
                  className="col-xl-7"
                  type="checkbox"
                  name="emailActive"
                  id="emailActive"
                  style={{ marginTop: 10 }}
                  checked={skipLimitChecks}
                  onChange={() => setSkipLimitChecks(!skipLimitChecks)}
                />
              </FormGroup>
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="fiatCurrency">
                  Fiat currency
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="fiatCurrency"
                  id="fiatCurrency"
                  value={"SEK"}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="walletId">
                  Wallet Id
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="walletId"
                  id="walletId"
                  value={"Default customer wallet"}
                  disabled={true}
                />
              </FormGroup>
              {/* Endpoint only supports bank-transfer payout method */}
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="payoutMethod">
                  Payout Method
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="payoutMethod"
                  id="payoutMethod"
                  value={"SE_BANK_TRANSFER"}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="bankAccount">
                  Customer bank account
                </Label>
                <Input
                  type="select"
                  name="bankAccount"
                  id="bankAccount"
                  value={bankAccount?.id}
                  onChange={onBankAccountChange}
                  style={{ maxWidth: 240, marginBottom: 12 }}
                >
                  {customerBankAccounts?.map(customerBankAccount => (
                    <option
                      key={`${customerBankAccount.id}-customer-bank-account-option`}
                      value={customerBankAccount.id}
                    >
                      {(customerBankAccount.name || "Unnamed Account") +
                        " - " +
                        (customerBankAccount.bank || "Unnamed Bank")}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </>
          )}
          {orderType === "1" && (
            <>
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="fromWallet">
                  From wallet
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="fromWallet"
                  id="fromWallet"
                  value={"Default wallet of customer #" + customerId}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="toWallet">
                  To wallet
                </Label>
                <Input
                  type="select"
                  name="toWallet"
                  id="toWallet"
                  value={toWallet?.walletId}
                  onChange={onToWalletChange}
                  style={{ maxWidth: 240, marginBottom: 12 }}
                >
                  {availableWallets?.map((wallet, i) => (
                    <option key={`${i}-wallet-option`} value={wallet.walletId}>
                      {`#${wallet.customerId} - ${wallet.customerName}`}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="remittanceInfo">
                  Remittance info
                </Label>
                <Input
                  className="col-xl-8"
                  type="textarea"
                  name="remittanceInfo"
                  id="remittanceInfo"
                  placeholder="Safello reference or related ticket name. Please remember that the customer can see
                  this information."
                  value={remittanceInfo}
                  onChange={e => setRemittanceInfo(e.target.value)}
                />
              </FormGroup>
            </>
          )}
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="additionalComment">
              Additional comment
            </Label>
            <Input
              className="col-xl-8"
              type="textarea"
              name="additionalComment"
              id="additionalComment"
              placeholder="Some kind of reason..."
              value={additionalComment}
              onChange={e => setAdditionalComment(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="mr-auto"
          onClick={onCreate}
          disabled={isLoading}
        >
          Create
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CreateOrderModal
