import React, { Component } from "react"
import Utils from "../Common/Utils"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import ContentWrapper from "../Layout/ContentWrapper"
import { Col, Row } from "reactstrap"
import PartnerInfoArea from "./PartnerInfoArea"
import PartnerActionsArea from "./PartnerActionsArea"
import EditPartnerModal from "./EditPartnerModal"
import NewAppApiModal from "./NewAppApiModal"

class Partner extends Component {
  state = {
    partner: null,
    partnerId: Utils.getLastUrlSegment(this.props.location),
    isLoading: false,
    editModal: false,
    newAppApiModal: false,
    editAppApiModal: false,
    newFeeRevisionModal: false,
  }

  componentDidMount() {
    this.getPartner()
    this.getCountries()
    this.getChannels()
  }

  getPartner = () => {
    this.setState({ isLoading: true })

    BackOfficeApi.endpoints.getPartner
      .getOne({ id: this.state.partnerId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          isLoading: false,
          partner: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  getCountries = () => {
    BackOfficeApi.endpoints.countries
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          countries: data.slice(0),
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  getChannels = () => {
    BackOfficeApi.endpoints.channels
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          channels: data.slice(0),
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  onActionPerformed = action => {
    switch (action) {
      case "partner_edit":
        this.toggleEditModal()
        break
      case "partner_add_new_app_api":
        this.toggleNewAppApiModal()
        break
      case "partner_activate":
        this.activePartner()
        break
      case "partner_deactivate":
        this.deactivatePartner()
        break
      default:
        break
    }
  }

  activePartner = () => {
    BackOfficeApi.endpoints.activatePartner
      .post({ id: this.state.partner.id })
      .then(response => {
        if (response.ok) {
          this.getPartner()
        } else {
          ToastUtils.toastAPIError2()
        }
      })
  }

  deactivatePartner = () => {
    BackOfficeApi.endpoints.deactivatePartner
      .post({ id: this.state.partner.id })
      .then(response => {
        if (response.ok) {
          this.getPartner()
        } else {
          ToastUtils.toastAPIError2()
        }
      })
  }

  toggleEditModal = () => {
    this.setState({
      editModal: !this.state.editModal,
    })
  }

  toggleNewAppApiModal = () => {
    this.setState({
      newAppApiModal: !this.state.newAppApiModal,
    })
  }

  onEditPartner = () => {
    this.toggleEditModal()
    this.getPartner()
    ToastUtils.toastSaveSuccess2()
  }

  onNewAppApi = () => {
    this.toggleNewAppApiModal()
    this.getPartner()
    ToastUtils.toastSaveSuccess2()
  }

  onUpdate = () => {
    this.getPartner()
  }

  onError = error => {
    this.setState({
      editModal: false,
      newAppApiModal: false,
      editAppApiModal: false,
      newFeeRevisionModal: false,
    })
    ToastUtils.toastCustomMessage(error)
  }

  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col lg={8}>
            <PartnerInfoArea
              partner={this.state.partner}
              channels={this.state.channels}
              onUpdate={this.onUpdate}
            />
          </Col>
          <Col lg={4}>
            <Col className="pl-0 pr-0">
              <PartnerActionsArea
                partner={this.state.partner}
                isLoading={this.state.isLoading}
                onActionPerformed={this.onActionPerformed}
              />
            </Col>
            <Col className="pl-0 pr-0"></Col>
          </Col>
        </Row>
        <EditPartnerModal
          partner={this.state.partner}
          countries={this.state.countries}
          toggle={this.toggleEditModal}
          open={this.state.editModal}
          onSave={this.onEditPartner}
          onError={this.onError}
        />
        <NewAppApiModal
          partner={this.state.partner}
          channels={this.state.channels}
          toggle={this.toggleNewAppApiModal}
          open={this.state.newAppApiModal}
          onSave={this.onNewAppApi}
          onError={this.onError}
        />
      </ContentWrapper>
    )
  }
}
export default Partner
