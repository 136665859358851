import React, { Component } from "react"
import { Card } from "reactstrap"
import CustomerDetailsSection from "./CustomerDetailsSection"
import CustomerContactSection from "./CustomerContactSection"
import CustomerDiscountSection from "./CustomerDiscountSection"
import CustomerOrderSection from "./CustomerOrderSection"
import CustomerExposureSection from "./CustomerExposureSection"
import CustomerStatusSection from "./CustomerStatusSection"
import ChainalysisSection from "../Common/ChainalysisSection"
import CustomerPocLimitsSection from "./CustomerPocLimitsSection"
import CustomerTinkSection from "./CustomerTinkSection"
import CustomerLimitsSection from "./CustomerLimitsSection"
import CustomerSellPofsSection from "./CustomerSellPofsSection"
import CustomerChainalysisAlerts from "./CustomerChainalysisAlerts"
import CustomerFeatureFlagsSection from "./CustomerFeatureFlagsSection"

import WalletSection from "./WalletSection"
import RiskSettingsSection from "./RiskSettingsSection"

class CustomerInfoArea extends Component {
  render() {
    let cardClass = "card-area"
    let cardStyle = { marginTop: "0" }
    if (this.props.isLoading) {
      cardClass += " whirl standard"
      cardStyle = { minHeight: "500px", marginTop: "0" }
    }

    return (
      <div>
        <Card className={cardClass} style={cardStyle}>
          <CustomerStatusSection
            customer={this.props.customer}
            reviewStatus={this.props.reviewStatus}
            onEditTagClick={this.props.onEditTagClick}
          />

          <div className="card-section-divider" />

          <CustomerDetailsSection
            customer={this.props.customer}
            onReviewPepSanction={this.props.onReviewPepSanction}
            feeLevelData={this.props.feeLevelData}
          />

          <div className="card-section-divider" />

          <RiskSettingsSection
            onCustomerChange={this.props.onCustomerChange}
            customer={this.props.customer}
          />

          <div className="card-section-divider" />

          <ChainalysisSection
            user={this.props.customer ? this.props.customer.id : null}
            justUser={true}
          />

          <div className="card-section-divider" />

          <WalletSection
            user={this.props.customer ? this.props.customer.id : null}
            wallets={this.props.wallets}
            tickers={this.props.tickers}
          />

          <div className="card-section-divider" />

          <CustomerDiscountSection
            discounts={this.props.discounts}
            customerId={this.props.customer ? this.props.customer.id : null}
            refreshDiscount={this.props.refreshDiscounts}
          />

          <div className="card-section-divider" />

          <CustomerLimitsSection
            limits={this.props.limits}
            channelLimits={this.props.channelLimits}
          />
          <div className="card-section-divider" />

          <CustomerPocLimitsSection pocLimits={this.props.pocLimits} />
          <div className="card-section-divider" />

          {this.props.customer && (
            <CustomerTinkSection
              customerId={this.props.customer.id}
              tinkData={this.props.tinkData}
            />
          )}

          <div className="card-section-divider" />

          <CustomerSellPofsSection sellPofs={this.props.sellPofs} />

          <div className="card-section-divider" />

          <CustomerContactSection customer={this.props.customer} />

          <div className="card-section-divider" />

          <CustomerOrderSection customer={this.props.customer} />

          <div className="card-section-divider" />

          <CustomerExposureSection customer={this.props.customer} />

          <div className="card-section-divider" />

          <CustomerChainalysisAlerts
            user={this.props.customer ? this.props.customer.id : null}
          />
          <div className="card-section-divider" />

          <CustomerFeatureFlagsSection user={this.props.customer} />
        </Card>
      </div>
    )
  }
}

export default CustomerInfoArea
