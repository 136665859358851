import React, { Component } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import moment from "moment"
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"
import { KycAnswerStatus } from "../../constants/enums"

export default class CustomerKycModal extends Component {
  state = {
    kycData: null,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.loadKycData()
    }
  }

  loadKycData = () => {
    BackOfficeApi.endpoints.customerKyc
      .getOne({ id: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          kycData: data,
        })
      })
  }

  onDeleteKyc = kycIdObject => {
    Utils.showSweetAlertYesCancel("Delete Kyc answer?", "").then(confirmed => {
      if (confirmed) {
        BackOfficeApi.endpoints.deleteKyc
          .post({ customerId: this.props.customer.id }, kycIdObject, {
            "Content-Type": "text/plain",
          })
          .then(response => {
            if (response.ok) {
              ToastUtils.toastCustomMessage("Kyc answer's status changed!")
              this.loadKycData()
            }
          })
      }
    })
  }

  onDeleteAllKyc = () => {
    Utils.showSweetAlertYesCancel(
      "Delete ALL Kyc answers for this customer?",
      "",
    ).then(confirmed => {
      if (confirmed) {
        BackOfficeApi.endpoints.deleteAllKyc
          .post({ customerId: this.props.customer.id })
          .then(response => {
            if (response.ok) {
              ToastUtils.toastCustomMessage(
                "All Kyc answer for this customer removed!",
              )
              this.loadKycData()
              this.props.toggle()
            }
          })
      }
    })
  }

  renderKyc() {
    let kycData = this.state.kycData
    if (kycData && kycData.length > 0) {
      return kycData.map(row => {
        let withdrawnStyle = null
        if (row.status !== undefined) {
          if (row.status === KycAnswerStatus.INACTIVE) {
            withdrawnStyle = { backgroundColor: "#F3D9D3" }
          }
        }

        return (
          <tr key={JSON.stringify(row.id)} style={withdrawnStyle}>
            <td>{row.question}</td>
            <td>{row.alternative}</td>
            <td>{row.optionalTextAnswer}</td>
            <td>
              {moment.utc(row.timestamp).local().format("YYYY-MM-DD HH:mm")}
            </td>
            <td>{row.status}</td>
            <td>
              {row.status !== KycAnswerStatus.INACTIVE && (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => this.onDeleteKyc(row.id)}
                >
                  Delete
                </Button>
              )}
            </td>
          </tr>
        )
      })
    }
    return null
  }

  render() {
    const hasActiveAnswers =
      this.state.kycData &&
      this.state.kycData.length > 0 &&
      this.state.kycData.some(
        answer => answer.status !== KycAnswerStatus.INACTIVE,
      )
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "1000px" }}
      >
        <ModalHeader toggle={this.props.toggle}>
          Customer KYC answers
        </ModalHeader>
        <ModalBody>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Question</th>
                <th>Selected answer</th>
                <th>Optional text answer</th>
                <th>Timestamp</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>{this.renderKyc()}</tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          {hasActiveAnswers && (
            <Button
              color="danger"
              className="mr-auto"
              onClick={this.onDeleteAllKyc}
            >
              Delete all
            </Button>
          )}
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
