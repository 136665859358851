import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
// utils
import Utils from "../Common/Utils"
const MultipleAccountsGdprRemovalModal = ({
  isOpen,
  onClose,
  customerAccesses,
  onProceed,
}) => {
  const onButtonPress = async isSingleAccountRemoval => {
    const confirmed = await Utils.showSweetAlertAreYouSure(
      `Are you sure you want to send the ${
        isSingleAccountRemoval ? "single" : "multiple"
      } account GDPR remove request?`,
      "",
      null,
    )
    if (confirmed) {
      onClose()
      onProceed(isSingleAccountRemoval)
    }
  }

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onClose}>Multiple Accounts Exist</ModalHeader>
      <ModalBody>
        <div>
          <p>This customer has multiple Safello accounts</p>
          {customerAccesses.map((access, i) => {
            return (
              <p key={`customer-access-${i}`} style={{ fontWeight: "bold" }}>
                {`${access.type} - ${access.name}${
                  access.organizationNumber
                    ? `, ${access.organizationNumber}`
                    : ""
                }`}
              </p>
            )
          })}
          <p>
            You can ask the customer if they want to GDPR remove all Business
            and Private accounts, or just this account.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onButtonPress(false)}>
          GDPR remove all accounts
        </Button>
        <Button color="secondary" onClick={() => onButtonPress(true)}>
          GDPR just this account
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MultipleAccountsGdprRemovalModal
