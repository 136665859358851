import React, { Component } from "react"
import { Row } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

class Cards extends Component {
  state = {
    cardData: {},
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.paymentNotificationStats
      .getOne()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          cardData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    if (this.state.cardData.unmatched) {
      const stats = this.state.cardData
      return (
        <Row>
          <BackOfficeCard
            icon="fa-2x fas fa-not-equal"
            title={Utils.formatNumber(stats.unmatched.amountSum, 0) + " SEK"}
            value={"Unmatched: " + stats.unmatched.count}
            url="/transactions/paymentnotifications"
            unit=""
            key="1"
            {...CardUtils.getCardColorForColumn(0)}
          />
        </Row>
      )
    } else {
      let placeholders = [0]
      let placeholderNodes = placeholders.map((placeHolder, i) => {
        return (
          <BackOfficeCard
            icon="fa-2x fas fa-question"
            title="*** *** SEK"
            value="Loading: ***"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
      return <Row>{placeholderNodes}</Row>
    }
  }
}

export default Cards
