import React, { Component } from "react"
import { Input } from "reactstrap"

import FormValidator from "../Common/FormValidator.js"
import BackOfficeApi from "../Api/BackOfficeApi.js"
import FormData from "form-data"
import bodymovin from "bodymovin"
import logoAnimationData from "../Media/Json/logo_animation.json"

class PasswordReset extends Component {
  state = {
    resetForm: {
      password1: "",
      password2: "",
    },
  }

  componentDidMount() {
    const animationProperties = {
      container: this.animationContainer,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: logoAnimationData,
    }

    bodymovin.loadAnimation(animationProperties)
  }

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  validateOnChange = event => {
    const input = event.target
    const form = input.form
    const value = input.type === "checkbox" ? input.checked : input.value

    const result = FormValidator.validate(input)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result,
        },
      },
    })
  }

  onSubmit = e => {
    const form = e.target
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName),
    )
    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors,
      },
      error: "",
    })

    e.preventDefault()
    if (hasError) {
      return
    }

    if (this.state.resetForm.password1 !== this.state.resetForm.password2) {
      this.setState({ error: "The entered passwords are different :)" })
      return
    }

    var currentUrl = new URL(window.location.href)
    var code = currentUrl.searchParams.get("code")
    if (!code) {
      this.setState({ error: "Error: there is no code in the link" })
      return
    }

    let formData = new FormData()
    formData.append("password", this.state.resetForm.password1)
    formData.append("code", code)

    BackOfficeApi.endpoints.updatePasswordReset
      .create(formData)
      .then(response => {
        let errorMessage = "Failed to retrieve data"
        if (!response) {
          throw errorMessage
        } else if (response.ok) {
          window.location.href = "/login"
          return
        } else {
          return response.json()
        }
      })
      .then(err => {
        if (err && err.error) {
          this.setState({ error: err.error })
        } else {
          this.setState({ error: "Error occured" })
        }
      })
      .catch(err => {
        if (typeof err !== "object") {
          this.setState({ error: err })
        }
      })
  }

  /* Simplify error check */
  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    )
  }

  render() {
    return (
      <div className="block-center wd-xl" style={{ marginTop: "80px" }}>
        <div className="card card-flat">
          <div className="card-header text-center">
            <div>
              <div
                style={{ width: 60, height: 60, display: "inline-block" }}
                ref={animationDiv => {
                  this.animationContainer = animationDiv
                }}
              />
              <img
                style={{ display: "inline-block" }}
                src="/img/safellologo_text.png"
                alt="Logo"
              />
            </div>
          </div>
          <div className="card-body">
            <form
              className="mb-3"
              name="resetForm"
              onSubmit={this.onSubmit}
              method="post"
            >
              <div className="form-group">
                <h4>
                  <center>Password reset</center>
                </h4>
                <div className="input-group with-focus">
                  <Input
                    type="password"
                    name="password1"
                    className="border-right-0"
                    placeholder="Enter your new password"
                    invalid={this.hasError(
                      "resetForm",
                      "password1",
                      "required",
                    )}
                    onChange={this.validateOnChange}
                    data-validate='["required"]'
                    value={this.state.resetForm.password1}
                    autoFocus
                  />
                  <div className="input-group-append">
                    <span className="input-group-text text-muted bg-transparent border-left-0">
                      <em className="fa fa-lock" />
                    </span>
                  </div>
                  <span className="invalid-feedback">Field is required</span>
                </div>
              </div>
              <div className="form-group">
                <div className="input-group with-focus">
                  <Input
                    type="password"
                    id="id-password2"
                    name="password2"
                    className="border-right-0"
                    placeholder="Repeat your password"
                    invalid={this.hasError(
                      "resetForm",
                      "password2",
                      "required",
                    )}
                    onChange={this.validateOnChange}
                    data-validate='["required"]'
                    value={this.state.resetForm.password2}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text text-muted bg-transparent border-left-0">
                      <em className="fa fa-lock" />
                    </span>
                  </div>
                  <span className="invalid-feedback">Field is required</span>
                </div>
              </div>
              {this.state.error && (
                <div className="text-center text-danger">
                  <span>{this.state.error}</span>
                </div>
              )}
              <button className="btn btn-block btn-primary mt-3" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default PasswordReset
