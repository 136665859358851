import React, { useState } from "react"
import BackOfficeApi from "../../Api/BackOfficeApi"
import { Label, Input, Button } from "reactstrap"
import ContentWrapper from "../../Layout/ContentWrapper"
import Utils from "../../Common/Utils"
import ToastUtils from "../../Common/ToastUtils"

const CustomerTakeoverPage = () => {
  const [inputtedCustomerId, setInputtedCustomerId] = useState("")
  const [inputtedPnr, setInputtedPnt] = useState("")
  const [loading, setLoading] = useState(false)

  const isProduction = Utils.isProduction()

  const handleCustomerIdChange = e => {
    setInputtedCustomerId(e.target.value)
  }
  const handlePnrChange = e => {
    setInputtedPnt(e.target.value)
  }

  const reset = () => {
    setInputtedCustomerId("")
    setInputtedPnt("")
  }

  const onSubmit = async () => {
    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: Customer takeover with personal number \n
      - Customer Id: #${inputtedCustomerId}\n
      - New personal number: ${inputtedPnr}\n\n
      If the chosen personal number is already allocated to another customer, it will be replaced with a new randomly generated personal number on the existing account.`,
    )
    if (confirmed) {
      executeTakeover()
    }
  }

  const executeTakeover = async () => {
    setLoading(true)
    const response = await BackOfficeApi.endpoints.customerTakeover.post(
      {
        customerId: inputtedCustomerId,
      },
      {
        pnr: inputtedPnr,
      },
    )
    if (response?.ok) {
      ToastUtils.toastExecuteSuccess2()
    } else {
      Utils.displayError(response)
    }
    reset()
    setLoading(false)
  }

  if (isProduction) {
    // Disable customer takeover for prod env
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Customer Takeover Dashboard</div>
        </div>
        <Label>This function is not available in production environment</Label>
      </ContentWrapper>
    )
  }
  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>Customer Takeover Dashboard</div>
      </div>

      {/* Customer ID input */}
      <Label>Customer ID</Label>
      <Input
        type="text"
        name="customerId"
        id="customerId"
        placeholder="Enter Customer ID"
        onChange={handleCustomerIdChange}
        value={inputtedCustomerId}
        style={{ maxWidth: 240, marginBottom: 24 }}
      />

      {/* Personal number input */}
      <Label>New Personal Number</Label>
      <Input
        type="text"
        name="personalNumber"
        id="personalNumber"
        placeholder="Enter Personal Number"
        onChange={handlePnrChange}
        value={inputtedPnr}
        style={{ maxWidth: 240, marginBottom: 24 }}
      />
      <Button
        color="primary"
        disabled={!inputtedCustomerId || !inputtedPnr || loading}
        onClick={onSubmit}
        loading={loading}
      >
        {"Takeover Customer"}
      </Button>
    </ContentWrapper>
  )
}

export default CustomerTakeoverPage
