import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row, Col } from "reactstrap"
import BuyOrderInfoArea from "./BuyOrderInfoArea"
import BuyOrderActionsArea from "./BuyOrderActionsArea"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CommentArea from "../../Common/CommentArea"
import StorageUtils from "../../Common/StorageUtils"
import EditTags from "../../Common/EditTags"
import TradeModal from "../TradeModal"
import BuyRefundModal from "./BuyRefundModal"
import EditBuyOrderModal from "./EditBuyOrderModal"
import XmlReport from "../XmlReport"

class BuyOrder extends Component {
  state = {
    orderId: Utils.getLastUrlSegment(this.props.location),
    tradeModal: false,
    refundModal: false,
    editModal: false,
    reportXmlModal: false,
  }
  constructor(props, context) {
    super(props, context)

    this.autoRefundChannels = [51, 52]
  }

  componentDidMount() {
    this.getOrder()
  }

  getOrder = () => {
    this.setState({ isLoading: true })
    BackOfficeApi.endpoints.buyOrderDetails
      .getOne({ id: this.state.orderId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({ isLoading: false, order: data })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  onActionPerformed = action => {
    switch (action) {
      case "order_trade":
        this.toggleModal("tradeModal")
        break
      case "order_send_crypto":
        this.sendCrypto()
        break
      case "order_freeze":
        this.freezeOrder()
        break
      case "order_unfreeze":
        this.unfreezeOrder()
        break
      case "order_refund":
        this.toggleModal("refundModal")
        break
      case "order_delete":
        this.deleteOrder()
        break
      case "order_undelete":
        this.undeleteOrder()
        break
      case "order_edit":
        this.toggleModal("editModal")
        break
      case "report_xml":
        this.setState({
          fastXmlExport: false,
        })
        this.toggleModal("reportXmlModal")
        break
      case "fast_xml_export":
        this.toggleModal("fastXmlExport")
        break
      case "banlist_address":
        this.banlistAddress()
        break
      default:
        break
    }
  }

  tradeOrder = provider => {
    this.toggleModal("tradeModal")

    let formData = new FormData()
    formData.append("orderId", this.state.orderId)
    formData.append("provider", provider)

    BackOfficeApi.endpoints.tradeBuyOrder
      .create(formData)
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getOrder()

          return
        }
        throw Error()
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
        this.setState({ isLoading: false })
      })
  }

  sendCrypto = () => {
    this.createAction(
      BackOfficeApi.endpoints.sendToPaymentGateway,
      {},
      { orderId: this.state.order.orderId },
    )
  }

  freezeOrder = () => {
    this.createAction(
      BackOfficeApi.endpoints.freezeBuyOrder,
      {},
      { id: this.state.orderId },
    )
  }

  unfreezeOrder = () => {
    this.createAction(
      BackOfficeApi.endpoints.unfreezeBuyOrder,
      {},
      { id: this.state.orderId },
    )
  }

  refundOrder = (reason, date, adminFee) => {
    let formData = new FormData()
    formData.append("reason", reason)
    formData.append("refundDate", date)
    formData.append("adminFee", adminFee)

    BackOfficeApi.endpoints.refundBuyOrder
      .create(formData, { id: this.state.orderId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }

        throw new Error()
      })
      .then(json => {
        if (this.autoRefundChannels.includes(this.state.order.channel)) {
          Utils.showSweetAlertOk("This payment is automatically refunded").then(
            () => {
              this.getOrder()
            },
          )
        } else {
          let refundReference = json.refundReference
          Utils.showSweetAlertOk(
            "Refund reference: " + refundReference,
            "Use the reference above when refunding in bank, if the refund is through the bank",
          ).then(() => {
            this.getOrder()
          })
        }
      })
      .catch(() => {
        ToastUtils.toastSaveError2()
        this.setState({ isLoading: false })
      })
  }

  deleteOrder = () => {
    let formData = new FormData()
    formData.append("userId", StorageUtils.getUserId())
    formData.append("issellorder", "false")
    formData.append("orderid", this.state.orderId)
    formData.append("lazydelete", "true")
    this.createAction(BackOfficeApi.endpoints.deleteOrder, formData)
  }

  undeleteOrder = () => {
    let formData = new FormData()
    formData.append("userId", StorageUtils.getUserId())
    formData.append("issellorder", "false")
    formData.append("orderid", this.state.orderId)
    formData.append("lazydelete", "false")
    this.createAction(BackOfficeApi.endpoints.deleteOrder, formData)
  }

  banlistAddress = () => {
    let formData = new FormData()
    formData.append("ids", this.state.order.orderMapId)
    this.createAction(BackOfficeApi.endpoints.banlistOrdersAddress, formData)
  }

  toggleModal = modalName => {
    this.setState({
      [modalName]: !this.state[modalName],
    })
  }

  editTagsOnChange = () => {
    this.setState({
      editTagsModal: false,
    })
    this.getOrder()
  }

  onEditSave = () => {
    ToastUtils.toastSaveSuccess2()

    this.setState({
      editModal: false,
    })

    this.getOrder()
  }

  createAction = (endpoint, formData, ids) => {
    endpoint
      .create(formData, ids)
      .then(response => {
        if (!response.ok) {
          throw new Error()
        } else {
          ToastUtils.toastExecuteSuccess2()
        }
      })
      .then(() => {
        this.getOrder()
      })
      .catch(() => {
        ToastUtils.toastSaveError2()
        this.setState({ isLoading: false })
      })
  }

  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col lg={8}>
            <BuyOrderInfoArea
              order={this.state.order}
              isLoading={this.state.isLoading}
              onEditTagClick={() => this.toggleModal("editTagsModal")}
            />
          </Col>
          <Col lg={4}>
            <Col className="pl-0 pr-0">
              <BuyOrderActionsArea
                order={this.state.order}
                isLoading={this.state.isLoading}
                onActionPerformed={this.onActionPerformed}
              />
            </Col>
            <Col className="pl-0 pr-0">
              {this.state.order && (
                <CommentArea id={this.state.order.orderMapId} type="ORDER" />
              )}
            </Col>
          </Col>
        </Row>
        <EditTags
          order={this.state.order}
          open={this.state.editTagsModal}
          toggle={() => this.toggleModal("editTagsModal")}
          onChange={this.editTagsOnChange}
          type="ORDER"
        />
        <BuyRefundModal
          open={this.state.refundModal}
          toggle={() => this.toggleModal("refundModal")}
          onRefund={this.refundOrder}
        />
        <TradeModal
          order={this.state.order}
          open={this.state.tradeModal}
          onToggle={() => this.toggleModal("tradeModal")}
          onTrade={this.tradeOrder}
        />
        <EditBuyOrderModal
          order={this.state.order}
          open={this.state.editModal}
          onToggle={() => this.toggleModal("editModal")}
          onSave={this.onEditSave}
        />
        {this.state.order && (
          <XmlReport
            orderOrTransactionIds={this.state.order.orderMapId}
            customerId={this.state.order.customerId}
            open={this.state.reportXmlModal}
            onToggle={() => this.toggleModal("reportXmlModal")}
            fastXmlExport={this.state.fastXmlExport}
          />
        )}
      </ContentWrapper>
    )
  }
}

export default BuyOrder
