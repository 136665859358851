/* eslint-disable react/no-unknown-property */
import React, { useContext, useEffect, useState, useMemo } from "react"
import { Card, CardBody, CardHeader, Table } from "reactstrap"
import CardTool from "../../Common/CardTool"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import TreasuryContext from "./TreasuryContext"
import { CryptoTickerContext } from "../../../App"
import useWindowDimensions from "../../../utils/hooks/useWindowDimensions"
import { NOT_SUPPORTED_CRYPTO_BALANCER } from "../../Common/constants"

const BalanceSummary = () => {
  const {
    loading,
    error,
    refresh,
    gatewayStatusData,
    bitstampBalanceData,
    orderSellStatsData,
    bitstampTickerData,
    blockchainBalance,
    fireblocksBalance,
    walletLiabilities,
    inProcessTransactions,
    withdrawalDepositLiabilities,
    sekToEurRate,
  } = useContext(TreasuryContext)
  const tickers = useContext(CryptoTickerContext)?.cryptoTickerData
  const filteredTickers = useMemo(
    () =>
      tickers?.filter(
        ticker => !NOT_SUPPORTED_CRYPTO_BALANCER.includes(ticker.symbol),
      ),
    [tickers],
  )
  const [balances, setBalances] = useState(null)
  const [totalCryptoInEurData, setTotalCryptoInEurData] = useState(null)
  const { height } = useWindowDimensions()

  useEffect(() => {
    if (!loading && bitstampBalanceData && bitstampTickerData) {
      const tickersWithEur = [...filteredTickers, { symbol: "EUR" }]

      const warmWalletBalances = {}
      tickersWithEur.map(({ symbol }) => {
        warmWalletBalances[symbol] =
          fireblocksBalance[symbol] || fireblocksBalance[`${symbol}_TEST`] || 0
      })

      // In-process transactions for total, withdrawal and deposit
      const formattedProcessTransactions = {}
      const formattedInProcessWithdrawalTransactions = {}
      const formattedInProcessDepositTransactions = {}
      tickersWithEur.forEach(({ symbol }) => {
        inProcessTransactions.forEach(transaction => {
          if (transaction.currency === symbol) {
            if (transaction.type === "WITHDRAWAL") {
              formattedInProcessWithdrawalTransactions[symbol] =
                (formattedInProcessWithdrawalTransactions[symbol] || 0) +
                (transaction.amount || 0)
            } else if (transaction.type === "DEPOSIT") {
              formattedInProcessDepositTransactions[symbol] =
                (formattedInProcessDepositTransactions[symbol] || 0) +
                (transaction.amount || 0)
            }
            formattedProcessTransactions[symbol] =
              (formattedProcessTransactions[symbol] || 0) +
              (transaction.amount || 0)
          }
        })
      })

      const totalAssets = {}
      tickersWithEur.map(({ symbol }) => {
        totalAssets[symbol] =
          (gatewayStatusData[symbol]?.balance ?? 0) +
          (bitstampBalanceData[symbol]?.BTC ?? 0) +
          (blockchainBalance[symbol] ?? 0) +
          (formattedProcessTransactions[symbol] ?? 0) +
          (warmWalletBalances[symbol] ?? 0)
      })

      const brokerLiabilities = {}
      tickersWithEur.map(({ symbol }) => {
        brokerLiabilities[symbol] =
          gatewayStatusData[symbol]?.ordersAmount ||
          0 + orderSellStatsData[symbol]?.cryptoSum ||
          0
      })

      const totalLiabilities = {}
      tickersWithEur.map(({ symbol }) => {
        totalLiabilities[symbol] =
          (walletLiabilities[symbol] || 0) +
            (withdrawalDepositLiabilities[symbol]?.withdrawal?.cryptoSum || 0) +
            (withdrawalDepositLiabilities[symbol]?.deposit?.cryptoSum || 0) +
            (brokerLiabilities[symbol] || 0) || 0
      })

      bitstampTickerData["EUR"] = { bid: 1 }
      const newBalances = {}
      tickersWithEur.map(({ symbol }) => {
        newBalances[symbol] = {
          hotwallet: gatewayStatusData[symbol]?.balance || 0,
          bitstamp: bitstampBalanceData[symbol] || 0,
          blockchain: blockchainBalance[symbol] || 0,
          warmwallet: warmWalletBalances[symbol] || 0,
          inProcessTransactions: formattedProcessTransactions[symbol] || 0,
          inProcessWithdrawalTransactions:
            formattedInProcessWithdrawalTransactions[symbol] || 0,
          inProcessDepositTransactions:
            formattedInProcessDepositTransactions[symbol] || 0,
          totalAssets: totalAssets[symbol],
          brokerLiabilities: brokerLiabilities[symbol],
          walletLiabilities: walletLiabilities[symbol] || 0,
          totalLiabilities: totalLiabilities[symbol],
          balance: totalAssets[symbol] - totalLiabilities[symbol],
          withdrawalLiabilities:
            withdrawalDepositLiabilities[symbol]?.withdrawal?.cryptoSum || 0,
          depositLiabilities:
            withdrawalDepositLiabilities[symbol]?.deposit?.cryptoSum || 0,
          currentMarketValue: bitstampTickerData[symbol].bid * sekToEurRate,
          totalBalanceInSek:
            (totalAssets[symbol] - totalLiabilities[symbol]) *
            bitstampTickerData[symbol].bid *
            sekToEurRate,
        }
      })

      const totalCryptoInEur = {
        hotwallet: 0,
        bitstamp: 0,
        blockchain: 0,
        warmwallet: 0,
        inProcessTransactions: 0,
        inProcessWithdrawalTransactions: 0,
        inProcessDepositTransactions: 0,
        totalAssets: 0,
        brokerLiabilities: 0,
        walletLiabilities: 0,
        totalLiabilities: 0,
        balance: 0,
        withdrawalLiabilities: 0,
        depositLiabilities: 0,
        currentMarketValue: 0,
        totalBalanceInSek: 0,
      }
      // Don't include EUR holdings
      filteredTickers.map(({ symbol }) => {
        totalCryptoInEur.hotwallet +=
          newBalances[symbol].hotwallet * bitstampTickerData[symbol].bid
        totalCryptoInEur.bitstamp +=
          newBalances[symbol].bitstamp?.BTC * bitstampTickerData[symbol].bid
        totalCryptoInEur.blockchain +=
          newBalances[symbol].blockchain * bitstampTickerData[symbol].bid
        totalCryptoInEur.warmwallet +=
          newBalances[symbol].warmwallet * bitstampTickerData[symbol].bid
        totalCryptoInEur.inProcessTransactions +=
          newBalances[symbol].inProcessTransactions *
          bitstampTickerData[symbol].bid
        totalCryptoInEur.inProcessWithdrawalTransactions +=
          newBalances[symbol].inProcessWithdrawalTransactions *
          bitstampTickerData[symbol].bid
        totalCryptoInEur.inProcessDepositTransactions +=
          newBalances[symbol].inProcessDepositTransactions *
          bitstampTickerData[symbol].bid
        totalCryptoInEur.totalAssets +=
          newBalances[symbol].totalAssets * bitstampTickerData[symbol].bid
        totalCryptoInEur.brokerLiabilities +=
          newBalances[symbol].brokerLiabilities * bitstampTickerData[symbol].bid
        totalCryptoInEur.walletLiabilities +=
          newBalances[symbol].walletLiabilities * bitstampTickerData[symbol].bid
        totalCryptoInEur.totalLiabilities +=
          newBalances[symbol].totalLiabilities * bitstampTickerData[symbol].bid
        totalCryptoInEur.balance +=
          newBalances[symbol].balance * bitstampTickerData[symbol].bid
        totalCryptoInEur.withdrawalLiabilities +=
          newBalances[symbol].withdrawalLiabilities *
          bitstampTickerData[symbol].bid
        totalCryptoInEur.depositLiabilities +=
          newBalances[symbol].depositLiabilities *
          bitstampTickerData[symbol].bid
        totalCryptoInEur.totalBalanceInSek +=
          newBalances[symbol].totalBalanceInSek
      })
      setTotalCryptoInEurData(totalCryptoInEur)
      setBalances(newBalances)
    }
  }, [loading])
  useEffect(() => {
    if (error) {
      ToastUtils.toastAPIError2()
    }
  }, [error])

  const handleRefreshClick = (_card, done) => {
    refresh().then(done)
  }
  return (
    <Card>
      <CardHeader>
        <CardTool refresh onRefresh={handleRefreshClick} />
        <h4>
          <i className="fa fw fa-info-circle mr-2" />
          Balance summary
        </h4>
      </CardHeader>
      <CardBody>
        {loading && (
          <div className="ball-beat loader-position" hidden={!loading}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        {balances && !loading && (
          <div
            style={{
              maxHeight: height * 0.8,
              overflow: "auto",
            }}
          >
            <Table bordered hover>
              <thead>
                <tr
                  style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#d9d9d9",
                    color: "red",
                  }}
                >
                  <th />
                  <th>Balance</th>
                  <th>WW - (WL + DepositsL)</th>
                  <th>Hotwallet</th>
                  <th>Bitstamp</th>
                  <th>Blockchain</th>
                  <th>Warmwallet</th>
                  <th>In-process Withdrawal</th>
                  <th>In-process Deposit</th>
                  <th>Total Assets</th>
                  <th>Buy/Sell liabilities</th>
                  <th>Wallet liabilities</th>
                  <th>Withdrawal liabilities</th>
                  <th>Deposit liabilities</th>
                  <th>Total liabilities</th>
                  <th>Current Market Value in SEK</th>
                  <th>Total Balance in SEK</th>
                </tr>
              </thead>
              <tbody>
                {/* EUR fiat Row */}
                <tr>
                  <th
                    scope="row"
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "#efefef",
                    }}
                  >
                    EUR fiat
                  </th>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#d8ece6" }}
                  >
                    {Utils.formatCrypto(balances["EUR"].balance, 2)}
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(balances["EUR"].bitstamp?.EUR, 2)}
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(balances["EUR"].blockchain, 2)}
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    -
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatNumber(sekToEurRate, 2)}
                  </td>
                  <td
                    className="text-right text-bold"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(balances["EUR"].totalBalanceInSek, 2)}
                  </td>
                </tr>
                {/* Total Crypto in EUR Row */}
                <tr>
                  <th
                    scope="row"
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "#efefef",
                    }}
                  >
                    Total Crypto in Euro
                  </th>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#d8ece6" }}
                  >
                    {Utils.formatCrypto(totalCryptoInEurData.balance, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.warmwallet -
                        (totalCryptoInEurData.walletLiabilities +
                          totalCryptoInEurData.depositLiabilities),
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(totalCryptoInEurData.hotwallet, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(totalCryptoInEurData.bitstamp, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(totalCryptoInEurData.blockchain, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatNumber(totalCryptoInEurData.warmwallet, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.inProcessWithdrawalTransactions,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.inProcessDepositTransactions,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(totalCryptoInEurData.totalAssets, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData?.brokerLiabilities,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.walletLiabilities,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData?.withdrawalLiabilities,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData?.depositLiabilities,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.totalLiabilities,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatNumber(sekToEurRate, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.totalBalanceInSek,
                      2,
                    )}
                  </td>
                </tr>
                {/* All crypto coin rows */}
                {filteredTickers.map(({ symbol }) => (
                  <tr key={`${symbol}-row`}>
                    <th
                      scope="row"
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "#fff",
                      }}
                    >
                      {symbol}
                    </th>
                    <td
                      className="text-right"
                      style={{ backgroundColor: "#d8ece6" }}
                    >
                      {Utils.formatCrypto(balances[symbol].balance, 6)}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(
                        balances[symbol].warmwallet -
                          (balances[symbol].walletLiabilities +
                            balances[symbol].depositLiabilities),
                        6,
                      )}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(balances[symbol].hotwallet, 6)}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(balances[symbol].bitstamp?.BTC, 6)}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(balances[symbol].blockchain, 6)}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(balances[symbol].warmwallet, 6)}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(
                        balances[symbol].inProcessWithdrawalTransactions,
                        6,
                      )}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(
                        balances[symbol].inProcessDepositTransactions,
                        6,
                      )}
                    </td>
                    <td
                      className="text-right"
                      style={{ backgroundColor: "#efefef" }}
                    >
                      {Utils.formatCrypto(balances[symbol].totalAssets, 6)}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(
                        balances[symbol]?.brokerLiabilities,
                        6,
                      )}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(
                        balances[symbol].walletLiabilities,
                        6,
                      )}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(
                        balances[symbol]?.withdrawalLiabilities,
                        6,
                      )}
                    </td>
                    <td className="text-right">
                      {Utils.formatCrypto(
                        balances[symbol]?.depositLiabilities,
                        6,
                      )}
                    </td>
                    <td
                      className="text-right"
                      style={{ backgroundColor: "#efefef" }}
                    >
                      {Utils.formatCrypto(balances[symbol].totalLiabilities, 6)}
                    </td>
                    <td className="text-right">
                      {Utils.formatNumber(
                        balances[symbol].currentMarketValue,
                        2,
                      )}
                    </td>
                    <td className="text-right">
                      {Utils.formatNumber(
                        balances[symbol].totalBalanceInSek,
                        2,
                      )}
                    </td>
                  </tr>
                ))}
                {/* Total Crypto in SEK Row */}
                <tr>
                  <th
                    scope="row"
                    style={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      backgroundColor: "#efefef",
                    }}
                  >
                    Total Crypto in Sek
                  </th>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#d8ece6" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.balance * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      (totalCryptoInEurData.warmwallet -
                        totalCryptoInEurData.walletLiabilities) *
                        sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.hotwallet * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.bitstamp * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.blockchain * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatNumber(
                      totalCryptoInEurData.warmwallet * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.inProcessWithdrawalTransactions *
                        sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.inProcessDepositTransactions *
                        sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.totalAssets * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData?.brokerLiabilities * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.walletLiabilities * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData?.withdrawalLiabilities *
                        sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData?.depositLiabilities * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.totalLiabilities * sekToEurRate,
                      2,
                    )}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatNumber(1, 2)}
                  </td>
                  <td
                    className="text-right"
                    style={{ backgroundColor: "#efefef" }}
                  >
                    {Utils.formatCrypto(
                      totalCryptoInEurData.totalBalanceInSek,
                      2,
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

export default BalanceSummary
