import React, { Component } from "react"
import { Container, Row, Col, CardHeader, CardBody } from "reactstrap"
import { Timeline, TimelineEvent } from "react-event-timeline"
import Utils from "../../Common/Utils"
import Cart from "../../Media/Svg/cart.svg"
import Cash from "../../Media/Svg/cash.svg"
import ArrowUp from "../../Media/Svg/arrow_up.svg"
import Exchange from "../../Media/Svg/exchange.svg"
import Transfer from "../../Media/Svg/transfer.svg"
import CheckValid from "../../Media/Svg/check_valid.svg"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import OrderUtils from "../OrderUtils"
import ReactDataGrid from "react-data-grid"

const commonBubbleStyles = {
  border: "none",
  width: "40px",
  height: "40px",
  paddingLeft: "4px",
  marginLeft: "-3px",
}

class BuyTimeLineSection extends Component {
  state = {
    isLoading: true,
    location: null,
  }
  constructor(props, context) {
    super(props, context)

    this.bitstampTransactionColumns =
      OrderUtils.getBitstampTransactionTableColumn()

    this.enigmaTransactionColumns = OrderUtils.getEnigmaTransactionTableColumn()

    this.blockchainTransactionColumns =
      OrderUtils.getBlockchainTransactionTableColumn()

    this.paymentNotificationColumns = [
      {
        key: "reference",
        name: "Reference",
        width: 260,
      },
      {
        key: "senderName",
        name: "Payer name",
        width: 230,
      },
      {
        key: "senderAddress",
        name: "Payer address",
        width: 230,
      },
      {
        key: "date",
        name: "Date",
        formatter: this.DateFormatter,
      },
      {
        key: "channel",
        name: "Channel",
      },
      {
        key: "phoneNumber",
        name: "Phone number",
        width: 200,
      },
    ]

    this.cardTransactionColumns = [
      {
        key: "holder",
        name: "Holder",
        width: 225,
      },
      {
        key: "timestamp",
        name: "Date",
        width: 225,
        formatter: this.DateFormatter,
      },
      {
        key: "cardName",
        name: "Card",
        width: 120,
      },
      {
        key: "obfuscatedCardNumber",
        name: "Card Number",
        width: 160,
      },
      {
        key: "status",
        name: "Status",
      },
      {
        key: "reference",
        name: "External Reference",
        width: 160,
      },
    ]
  }

  componentDidUpdate(prevProps) {
    if (this.props.order !== prevProps.order) {
      this.getCountryByIP(this.props.order.ip)
      if (this.props.order.channel === 130) {
        this.getCardTransactions()
      }
      if (this.props.order.paid) {
        this.getPaymentNotification()
      }
      if (this.props.order.tradeCompleted) {
        this.getBitstampTransactions()
        this.getEnigmaTransaction()
        this.getBlockchainTransaction()
      }
    }
  }

  DateFormatter = props => {
    return Utils.getUtcInLocalDateTime(props.value)
  }

  getPaymentNotification = () => {
    BackOfficeApi.endpoints.buyOrderPaymentNotification
      .getOne({ id: this.props.order.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          paymentNotification: data,
        })
      })
  }

  getCardTransactions = () => {
    BackOfficeApi.endpoints.orderCardTransactions
      .getOne({
        orderId: this.props.order.orderId,
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({ cardTransaction: data })
      })
      .catch(err => {
        ToastUtils.toastAPIError2()
        console.log(err)
        this.setState({ isLoading: false })
      })
  }

  getBitstampTransactions = () => {
    BackOfficeApi.endpoints.buyOrderBitstampTransactions
      .getAll({ id: this.props.order.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({ bitstampTransactions: data })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  getEnigmaTransaction = () => {
    BackOfficeApi.endpoints.buyOrderEnigmaTransaction
      .getAll({ orderId: this.props.order.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          enigmaTransaction: data,
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  getBlockchainTransaction = () => {
    BackOfficeApi.endpoints.buyOrderBlockchainTransaction
      .getAll({ orderId: this.props.order.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          blockchainTransaction: data,
        })
      })
      .catch(() => {
        this.setState({ isLoading: false })
      })
  }

  getCountryByIP = async ip => {
    const location = await Utils.getCountryByIP(ip)
    this.setState({ location })
  }

  render() {
    if (!this.props.order) return <div />

    let events = []
    if (this.props.order.issued) {
      let locationDesc = ""
      if (this.state.location) {
        const { country_name, city, state, emoji } = this.state.location
        locationDesc = " | " + emoji + " " + country_name
        if (state && state !== "Not found") {
          locationDesc += ", " + state
        }
        if (city && city !== "Not found" && city !== state) {
          locationDesc += ", " + city
        }
      }

      events.push({
        title: "Order issued",
        createdAt: Utils.getUtcInLocalDateTime(this.props.order.issued),
        icon: Cart,
        bubbleStyle: { backgroundColor: "#f0f0f0", ...commonBubbleStyles },
        content: (
          <div>
            <div>
              IP: {this.props.order.ip} {locationDesc}
            </div>
            <div>User agent: {this.props.order.userAgent}</div>
          </div>
        ),
        contentStyle: {
          fontWeight: "bold",
          padding: "0px",
          boxShadow: "0",
          overflow: "hidden",
          overflowWrap: "normal",
          whiteSpace: "nowrap",
          textOverflow: "clip",
        },
      })
    }
    if (this.props.order.approved) {
      events.push(this.getApprovedEvent())
    }
    if (this.props.order.paid) {
      events.push(this.getPaidTimeEvent())
    }
    if (this.props.order.refunded) {
      events.push({
        title: "Refunded",
        createdAt: Utils.getUtcInLocalDateTime(this.props.order.refunded),
        icon: Exchange,
        content: (
          <div>
            Refund reference: REF
            {this.state.paymentNotification
              ? this.state.paymentNotification.id
              : ""}
          </div>
        ),
        bubbleStyle: { backgroundColor: "#e3f2ff", ...commonBubbleStyles },
      })
    }
    if (this.props.order.sentToExchange) {
      events.push(this.getSentToExchangeEvent())
    }
    if (this.props.order.tradeCompleted) {
      events.push(this.getTradeCompletedEvent())
    }
    if (this.props.order.sentToPaymentGateway) {
      events.push(this.getDeliveredEvent())
    }
    if (this.props.order.delivered) {
      events.push(this.getTransferredEvent())
    }

    return (
      <div>
        <CardHeader className="card-section-header">Timeline</CardHeader>
        <CardBody className="pt-0">
          <Row className="no-gutters">
            <Col>
              <div>
                <Timeline
                  lineColor="#edf1f2"
                  style={{ paddingTop: "0px", paddingBottom: "0px" }}
                >
                  {events.map((item, i) => {
                    return (
                      <TimelineEvent
                        key={i}
                        createdAt={
                          <span className="timeline_time">
                            {item.createdAt}
                          </span>
                        }
                        title={
                          <span className="timeline_title">{item.title}</span>
                        }
                        icon={
                          <img
                            src={item.icon}
                            width="24px"
                            height="24px"
                            alt=""
                          />
                        }
                        style={{ marginTop: i > 0 ? "20px" : "0px" }}
                        bubbleStyle={item.bubbleStyle}
                        collapsible={item.collapsible}
                        showContent={true}
                        contentStyle={
                          item.contentStyle || {
                            padding: "0px",
                            boxShadow: "0",
                          }
                        }
                      >
                        {item.content}
                      </TimelineEvent>
                    )
                  })}
                </Timeline>
              </div>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
  getApprovedEvent = () => {
    let approvedEvent = {
      title: "Order approved",
      createdAt: Utils.getUtcInLocalDateTime(this.props.order.approved),
      icon: CheckValid,
      bubbleStyle: { backgroundColor: "#b1e5be", ...commonBubbleStyles },
    }

    approvedEvent.collapsible = this.state.cardTransaction

    let contentParts = []
    if (this.state.cardTransaction) {
      contentParts.push(
        <div key="cardTransactions">
          <div className="timeline_content_title">Card transaction</div>
          <Container fluid className="grid-no-v-scroll grid-no-border">
            <ReactDataGrid
              columns={this.cardTransactionColumns}
              rowGetter={this.rowGetterCardTransaction}
              rowsCount={1}
              minHeight={80}
              enableCellAutoFocus={false}
            />
          </Container>
        </div>,
      )
    }

    approvedEvent.content = <div>{contentParts}</div>

    return approvedEvent
  }
  getPaidTimeEvent = () => {
    let paidEvent = {
      title: "Payment received",
      createdAt: Utils.getUtcInLocalDateTime(this.props.order.paid),
      icon: Cash,
      bubbleStyle: { backgroundColor: "#b2e7bf", ...commonBubbleStyles },
    }
    paidEvent.collapsible = !!this.props.order.channelName
    let contentParts = []
    if (this.state.paymentNotification) {
      let rowHeight = 40
      let tableHeight = 100

      contentParts.push(
        <div key="paymentNotification">
          <Container fluid className="grid-no-v-scroll grid-no-border">
            <ReactDataGrid
              columns={this.paymentNotificationColumns}
              rowGetter={this.rowGetterPaymentNotification}
              rowsCount={1}
              rowHeight={rowHeight}
              minHeight={tableHeight}
              enableCellAutoFocus={false}
            />
          </Container>
        </div>,
      )
    }

    paidEvent.content = <div>{contentParts}</div>

    return paidEvent
  }

  getSentToExchangeEvent = () => {
    let exchangeEvent = {
      title: "Sent to exchange",
      createdAt: Utils.getUtcInLocalDateTime(this.props.order.sentToExchange),
      icon: Exchange,
      bubbleStyle: { backgroundColor: "#e3f2ff", ...commonBubbleStyles },
    }

    return exchangeEvent
  }

  getTradeCompletedEvent = () => {
    let tradeCompletedEvent = {
      title: "Trade completed",
      createdAt: Utils.getUtcInLocalDateTime(this.props.order.tradeCompleted),
      icon: Exchange,
      bubbleStyle: { backgroundColor: "#e3f2ff", ...commonBubbleStyles },
    }

    if (
      this.state.bitstampTransactions &&
      this.state.bitstampTransactions.length > 0
    ) {
      let rowHeight = 40
      let tableHeight = (this.state.bitstampTransactions.length + 1) * rowHeight
      tradeCompletedEvent.collapsible = true
      tradeCompletedEvent.content = (
        <div>
          <h2 className="timeline_content_title">Bitstamp transactions</h2>
          <Container className="grid-no-v-scroll grid-no-border">
            <ReactDataGrid
              columns={this.bitstampTransactionColumns}
              rowGetter={this.rowGetterBitstampTransaction}
              rowsCount={this.state.bitstampTransactions.length}
              rowHeight={rowHeight}
              minHeight={tableHeight}
              enableCellAutoFocus={false}
            />
          </Container>
        </div>
      )
    } else if (this.state.enigmaTransaction) {
      let rowHeight = 40
      let tableHeight = 80
      tradeCompletedEvent.collapsible = true
      tradeCompletedEvent.content = (
        <div>
          <h2 className="timeline_content_title">Enigma transaction</h2>
          <Container className="grid-no-v-scroll grid-no-border">
            <ReactDataGrid
              columns={this.enigmaTransactionColumns}
              rowGetter={this.rowGetterEnigmaTransaction}
              rowsCount={1}
              rowHeight={rowHeight}
              minHeight={tableHeight}
              enableCellAutoFocus={false}
            />
          </Container>
        </div>
      )
    } else if (this.state.blockchainTransaction) {
      let rowHeight = 40
      let tableHeight = 80
      tradeCompletedEvent.collapsible = true
      tradeCompletedEvent.content = (
        <div>
          <h2 className="timeline_content_title">Blockchain transaction</h2>
          <Container className="grid-no-v-scroll grid-no-border">
            <ReactDataGrid
              columns={this.blockchainTransactionColumns}
              rowGetter={this.rowGetterBlockchainTransaction}
              rowsCount={1}
              rowHeight={rowHeight}
              minHeight={tableHeight}
              enableCellAutoFocus={false}
            />
          </Container>
        </div>
      )
    }

    return tradeCompletedEvent
  }

  getTransferredEvent = () => {
    let transferredEvent = {
      title: "Completed",
      createdAt: Utils.getUtcInLocalDateTime(this.props.order.delivered),
      icon: Transfer,
      bubbleStyle: { backgroundColor: "#fbc94c", ...commonBubbleStyles },
    }

    return transferredEvent
  }

  getDeliveredEvent = () => {
    let deliveredEvent = {
      title: "Crypto delivered",
      createdAt: Utils.getUtcInLocalDateTime(
        this.props.order.sentToPaymentGateway,
      ),
      icon: ArrowUp,
      bubbleStyle: { backgroundColor: "#f0f3e3", ...commonBubbleStyles },
    }

    if (this.props.order && this.props.order.cryptoAddress) {
      deliveredEvent.collapsible = true
      deliveredEvent.content = (
        <a
          href={OrderUtils.getCryptoBlockExplorerUrl(
            this.props.order.cryptoCurrency,
            this.props.order.cryptoAddress,
          )}
          target="_blank"
          rel="noreferrer"
        >
          {this.props.order.cryptoAddress}
        </a>
      )
    }

    return deliveredEvent
  }
  rowGetterCardTransaction = () => {
    return this.state.cardTransaction
  }
  rowGetterPaymentNotification = () => {
    return this.state.paymentNotification
  }

  rowGetterBitstampTransaction = i => {
    return OrderUtils.getBitstampTransactionDataRow(
      this.state.bitstampTransactions[i],
      false,
    )
  }

  rowGetterEnigmaTransaction = () => {
    let row = this.state.enigmaTransaction

    let inCurrency = row.currencyPair ? row.currencyPair.split("/")[1] : ""

    row.originalAmountData = {
      amount: row.originalAmount,
      currency: inCurrency,
    }

    row.inAmountData = {
      amount: row.counterAmount,
      currency: inCurrency,
    }

    let outCurrency = row.currencyPair ? row.currencyPair.split("/")[0] : ""
    row.outAmountData = {
      amount: row.baseAmount,
      currency: outCurrency,
    }

    return row
  }

  rowGetterBlockchainTransaction = () => {
    let row = this.state.blockchainTransaction

    let inCurrency = row.currencyPair ? row.currencyPair.split("/")[1] : ""

    row.originalAmountData = {
      amount: row.originalAmount,
      currency: inCurrency,
    }

    row.inAmountData = {
      amount: row.counterAmount,
      currency: inCurrency,
    }

    let outCurrency = row.currencyPair ? row.currencyPair.split("/")[0] : ""

    row.outAmountData = {
      amount: row.baseAmount,
      currency: outCurrency,
    }

    return row
  }

  CardTransactionCardFormatter = props => {
    return <span>{props.value.cardbin + ".." + props.value.last4Digits}</span>
  }
}

export default BuyTimeLineSection
