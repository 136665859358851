import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row } from "reactstrap"
import OrderAvgChannelChart from "./OrderAvgChannelChart"
import Cards from "./Cards"
import TurnoverChart from "./TurnoverChart"
import AverageOrderAmount from "./AverageOrderAmount"
import RevenueChart from "./RevenueChart"
import CumulativeRevenueChart from "./CumulativeRevenueChart"
import CumulativeTurnoverChart from "./CumulativeTurnoverChart"
import AverageOrderAmountAll from "./AverageOrderAmountAll"
import RevenueTypeChart from "./RevenueTypeChart"
import TurnoverTypeChart from "./TurnoverTypeChart"
import ChannelVolumeChart from "./ChannelVolumeChart"

class Financial extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Financial</div>
        </div>
        <Row>
          <Cards />
        </Row>
        <Row>
          <RevenueChart />
        </Row>
        <Row>
          <RevenueTypeChart />
        </Row>
        <Row>
          <TurnoverChart />
        </Row>
        <Row>
          <TurnoverTypeChart />
        </Row>
        <Row>
          <AverageOrderAmount />
        </Row>
        <Row>
          <AverageOrderAmountAll />
        </Row>
        <Row>
          <ChannelVolumeChart />
        </Row>
        <Row>
          <OrderAvgChannelChart />
        </Row>
        <Row>
          <CumulativeRevenueChart />
        </Row>
        <Row>
          <CumulativeTurnoverChart />
        </Row>
      </ContentWrapper>
    )
  }
}

export default Financial
