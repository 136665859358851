import React, { Component } from "react"
import { Container } from "reactstrap"
import DataGridEmpty from "../Common/DataGridEmpty"
import ReactDataGrid from "react-data-grid"
import BackOfficeApi from "../Api/BackOfficeApi"
import Utils from "../Common/Utils"

class CustomerChainalysisAlerts extends Component {
  state = {
    alerts: [],
    loading: false,
    total: null,
  }
  constructor(props) {
    super(props)

    this._columns = [
      {
        key: "index",
        name: "N",
        width: 30,
      },
      {
        key: "level",
        name: "Severity",
        width: 70,
      },
      {
        key: "category",
        name: "Category",
        width: 100,
      },
      {
        key: "service",
        name: "Service name",
        width: 155,
      },
      {
        key: "timestamp",
        name: "Timestamp",
        width: 110,
        formatter: this.TimestampFormatter,
      },
      {
        key: "alertAmountUsd",
        name: "Amount USD",
        width: 90,
      },
      {
        key: "exposureType",
        name: "Exposure",
        width: 70,
      },
      {
        key: "direction",
        name: "Direction",
        width: 70,
      },
      {
        key: "alertStatus",
        name: "Status",
        width: 80,
      },
      {
        key: "validity",
        name: "State",
        width: 80,
      },
    ]
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.getData()
    }
  }

  getData = () => {
    this.setState({
      loading: true,
    })

    BackOfficeApi.endpoints.chainalysisGetAlerts
      .getAll({ userId: this.props.user })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          alerts: data.data.slice(0),
          total: data.total,
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          error: "Failed to retrieve data",
          loading: false,
        })
      })
  }

  rowGetter = i => {
    let row = this.state.alerts[i]
    if (row) row.index = i + 1
    return row
  }

  render() {
    return (
      <Container>
        <br />
        <p className="details-header ml-3">
          Chainalysis Alerts: ({this.state.total || 0})
        </p>
        {!!this.state.total && (
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.alerts.length}
            minHeight={400}
            emptyRowsView={this.state.loading ? null : DataGridEmpty}
            minColumnWidth={50}
            rowHeight={30}
          />
        )}
      </Container>
    )
  }

  TimestampFormatter = props => {
    if (props.value) {
      return Utils.getUtcInLocalDateTime(props.value)
    }
    return ""
  }
}

export default CustomerChainalysisAlerts
