import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import DropdownList from "../Common/DropdownList"
import DataGridEmpty from "../Common/DataGridEmpty"
import ReactDataGrid from "react-data-grid"
import BackOfficeApi from "../Api/BackOfficeApi"
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"

class CustomerReview extends Component {
  state = {
    blacklistedCryptoAddresses: [],
    cryptoAddressesLoading: false,
    cryptoAddressesToBan: "",
    ourCryptoAddress: "",
    crypto: "BTC",
  }
  constructor(props) {
    super(props)

    this._columnsCryptoAddresses = [
      {
        key: "id",
        name: "ID",
        width: 30,
      },
      {
        key: "added",
        name: "Added",
        formatter: this.TimestampFormatter,
        width: 120,
      },
      {
        key: "address",
        name: "Crypto address",
      },
    ]
  }

  componentDidMount() {
    this.getData()
  }

  onAddToBanList = () => {
    if (!this.state.cryptoAddressesToBan) {
      alert(
        "Please enter one or more crypto addresses you want to add to the banlist.",
      )
      return
    }
    const cryptoAddressesList = this.state.cryptoAddressesToBan
      .trim()
      .split("\n")
      .filter(line => line.trim() !== "")
      .map(address => address.trim())

    const fetchPromises = cryptoAddressesList.map(address => {
      const formData = new FormData()
      formData.append("address", address)
      return BackOfficeApi.endpoints.toolsAddCryptoAddressToBlacklist
        .create(formData)
        .then(response => {
          if (response.ok) {
            return response.blob()
          }
          throw new Error()
        })
    })

    Promise.all(fetchPromises)
      .then(() => {
        const successMessage =
          cryptoAddressesList.length > 1
            ? "Multiple crypto addresses added to banlist"
            : "A single crypto address added to banlist"
        ToastUtils.toastCustomSuccess(successMessage)
        this.getData("cryptoAddresses")
      })
      .catch(e => {
        console.error(e)
        ToastUtils.toastExecuteError2()
      })
  }

  onIsOurCryptoAddressClicked = () => {
    if (!this.state.ourCryptoAddress) {
      alert("Please enter a crypto address you want to check.")
      return
    }

    BackOfficeApi.endpoints.toolsIsOurCryptoAddress
      .getOne({
        crypto: this.state.crypto,
        cryptoAddress: this.state.ourCryptoAddress,
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw new Error()
      })
      .then(data => {
        if (!data) {
          alert("error, no data received.")
          return
        }
        if (data.hotwalletAddress === true) {
          alert(
            this.state.ourCryptoAddress + " is Safello's hotwallet address.",
          )
        } else if (data.hotwalletAddress === false) {
          alert(
            this.state.ourCryptoAddress +
              " is NOT Safello's hotwallet address.",
          )
        } else {
          alert(JSON.stringify(data))
        }
      })
      .catch(() => {
        ToastUtils.toastExecuteError2()
      })
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onCryptoSelect = item => {
    this.setState({
      crypto: item.value,
    })
  }

  getData = request => {
    if (!request || request === "cryptoAddresses") {
      this.setState({
        cryptoAddressesLoading: true,
      })

      BackOfficeApi.endpoints.toolsGetBlacklistedCryptoAddresses
        .getAll()
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.setState({
            blacklistedCryptoAddresses: data.slice(0),
            cryptoAddressesLoading: false,
          })
        })
        .catch(() => {
          this.setState({
            error: "Failed to retrieve crypto addresses",
            cryptoAddressesLoading: false,
          })
        })
    }
  }

  rowCryptoAddressesGetter = i => {
    const row = this.state.blacklistedCryptoAddresses[i]
    return row
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Tools</div>
        </div>

        <Container fluid>
          <Form>
            <FormGroup row>
              <Label
                className="col-form-label ml-3 mr-2"
                for="ourCryptoAddress"
              >
                Crypto address:
              </Label>
              <Input
                className="col-md-4 mr-2"
                type="text"
                name="ourCryptoAddress"
                value={this.ourCryptoAddress}
                onChange={this.onChange}
              />
              <DropdownList
                label="Crypto currency"
                value={this.state.crypto}
                onSelectItem={this.onCryptoSelect}
                className="mr-2"
              >
                {[
                  { value: "BTC", text: "BTC" },
                  { value: "ETH", text: "ETH" },
                  { value: "XRP", text: "XRP" },
                  { value: "BCH", text: "BCH" },
                ]}
              </DropdownList>
              <Button
                color="primary"
                onClick={this.onIsOurCryptoAddressClicked}
              >
                Check if this address belongs to Safello
              </Button>
            </FormGroup>
          </Form>
        </Container>

        <hr />

        <Row>
          <Col>
            <div className="content-heading">
              <div>Banlisted Crypto addresses</div>
            </div>

            <Container fluid>
              <Form>
                <FormGroup row>
                  <Label
                    className="col-form-label ml-3 mr-2"
                    for="cryptoAddressesToBan"
                  >
                    Crypto addresses:
                  </Label>
                  <Input
                    className="col-md-6 mr-2"
                    type="textarea"
                    name="cryptoAddressesToBan"
                    value={this.cryptoAddressesToBan}
                    onChange={this.onChange}
                    style={{ height: "auto", minHeight: "100px" }} // CSS for auto-expanding
                  />
                  <Button
                    color="primary"
                    onClick={this.onAddToBanList}
                    style={{
                      maxHeight: 36,
                    }}
                  >
                    Add to the Banlist
                  </Button>
                </FormGroup>
              </Form>
            </Container>

            <Container fluid>
              <ReactDataGrid
                columns={this._columnsCryptoAddresses}
                rowGetter={this.rowCryptoAddressesGetter}
                rowsCount={this.state.blacklistedCryptoAddresses.length}
                minHeight={400}
                emptyRowsView={
                  this.state.cryptoAddressesLoading ? null : DataGridEmpty
                }
                minColumnWidth={50}
                rowHeight={30}
              />
            </Container>
          </Col>
        </Row>
      </ContentWrapper>
    )
  }

  TimestampFormatter = props => {
    if (props.value) {
      return Utils.getUtcInLocalDateTime(props.value)
    }
    return ""
  }
}
export default CustomerReview
