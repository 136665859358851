import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import ChartUtils from "../../Common/ChartUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeAreaChart from "../../Chart/BackOfficeAreaChart"
import CryptoSelector from "../../Common/CryptoSelector"

const WalletBalancesView = {
  INDIVIDUAL: "INDIVIDUAL",
  TOTAL_BALANCE: "TOTAL_BALANCE",
  TOTAL_ASSET: "TOTAL_ASSET",
  TOTAL_LIABILITIES: "TOTAL_LIABILITIES",
}

const WalletBalancesChart = () => {
  const [perspective, setPerspective] = useState(
    ChartUtils.getHourlyPerspective(),
  )
  const [cryptoCurrency, setCryptoCurrency] = useState("BTC")
  const [graphData, setGraphData] = useState(null)
  const [view, setView] = useState(WalletBalancesView.TOTAL_BALANCE)

  useEffect(() => {
    updateChart()
  }, [perspective, cryptoCurrency, view])

  const updateChart = async () => {
    try {
      const response =
        await BackOfficeApi.endpoints.statsTreasuryWalletBalances.getAll({
          perspective: perspective.toLowerCase(),
          cryptoCurrency,
          view,
        })
      if (!response.ok) {
        throw Error()
      }
      const data = await response.json()
      setGraphData({
        lines: data.lineInfo,
        lineData: data.lineData.map(dataNode => ({
          ...dataNode,
          xKey: ChartUtils.getPerspectiveXFormat(dataNode.xKey, perspective),
        })),
        xInterval: ChartUtils.getPerspectiveXInterval(perspective),
        perspective,
        unit: data.unit,
      })
    } catch (e) {
      console.error(e)
      ToastUtils.toastAPIError2()
    }
  }

  return (
    <Col xl={12}>
      {graphData && (
        <BackOfficeAreaChart
          lines={graphData.lines}
          lineData={graphData.lineData}
          xInterval={graphData.xInterval}
          unit={graphData.unit}
          showTotal={true}
          title="Wallet balances"
          stack
          perspective={perspective}
          onPerspectiveChange={setPerspective}
          perspectives={ChartUtils.getPerspectives(
            ChartUtils.getHourlyPerspective(),
            ChartUtils.getDefaultPerspective(),
          )}
          controls={
            <React.Fragment>
              <CryptoSelector
                className={"float-right mr-3"}
                onChange={setCryptoCurrency}
                value={cryptoCurrency}
              />
              <BalancesViewSelector onChange={setView} value={view} />
            </React.Fragment>
          }
        />
      )}
    </Col>
  )
}

const balancesViewSelectorOptions = [
  { label: "Individual", value: WalletBalancesView.INDIVIDUAL },
  { label: "Balance", value: WalletBalancesView.TOTAL_BALANCE },
  { label: "Assets", value: WalletBalancesView.TOTAL_ASSET },
  { label: "Liabilities", value: WalletBalancesView.TOTAL_LIABILITIES },
]

const BalancesViewSelector = ({ onChange, value }) => {
  const handleSelectChange = e => {
    onChange(e.target.value)
  }

  return (
    <select
      value={value}
      name="view"
      className="custom-select float-right mr-3 crypto-selector"
      onChange={handleSelectChange}
    >
      {balancesViewSelectorOptions.map(c => (
        <option value={c.value} key={c.value}>
          {c.label}
        </option>
      ))}
    </select>
  )
}

export default WalletBalancesChart
