import React, { Component } from "react"
import { Button, CardBody, Col, Row } from "reactstrap"
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"
import moment from "moment"
import BackOfficeApi from "../Api/BackOfficeApi"
import ProfileImage from "./ProfileImage"
import VerificationImage from "../Common/VerificationImage"
import Premium from "../Media/Svg/premium.svg"
import Business from "../Media/Svg/business.svg"
import Tooltip from "../Common/Tooltip"
class CustomerDetailsSection extends Component {
  state = {
    verifications: null,
    verificationsLoaded: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      this.loadVerifications()
    }
  }

  loadVerifications() {
    BackOfficeApi.endpoints.customerVerifications
      .getOne({ id: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          verifications: data.slice(0),
          verificationsLoaded: true,
        })
      })
  }

  onRunPepSanction() {
    BackOfficeApi.endpoints.customerRunPepSanction
      .post({ customerId: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        let message = "Customer doesn't have a match."
        if (data.hasMatch) {
          message = "Customer has a Match!"
        }
        if (data.matchChanges) {
          message += " There are changes from the last check!"
        } else {
          message += " No changes from the last check."
        }
        ToastUtils.toastCustomMessage(message)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  renderDetailsInfo() {
    let customer = this.props.customer
    let feeLevelData = this.props.feeLevelData

    if (!customer || !feeLevelData) {
      return ""
    }

    let intercomId = "czp26nbb"
    if (Utils.isProduction()) {
      intercomId = "zmttdu0m"
    }
    let intercomLink =
      "https://app.eu.intercom.com/apps/" +
      intercomId +
      "/users/show?user_id=" +
      customer.id
    let fraudSightLink = `https://fraudsight.fisglobal.com/incidents/email/${encodeURIComponent(
      customer.email,
    )}/edit`

    const corporate = customer.type === "Corporate"
    const levelFee =
      feeLevelData.currentLevel +
      " (" +
      Utils.formatNumber(feeLevelData.feePercentage * 100, 1) +
      "%)"

    return (
      <div>
        {corporate ? (
          <div>
            <p className="customer-name details-row">{customer.companyName}</p>
            <div className="details-row">
              <span className="details-header">Org number:</span>
              <span className="details-value">{customer.orgNumber}</span>
            </div>
          </div>
        ) : (
          <div>
            <p className="customer-name details-row">
              {customer.firstName} {customer.lastName}
            </p>
            <div className="details-row">
              <span className="details-header">Personal number:</span>
              <span className="details-value">{customer.personalNumber}</span>
            </div>
            <div className="details-row">
              <span className="details-header">Search on:</span>
              <span className="details-value">
                <a
                  href={
                    "https://mrkoll.se/resultat?n=" + customer.personalNumber
                  }
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Mrkoll
                </a>
                ,{" "}
                <a
                  href={
                    "https://www.lexbase.se/personsok?&Search%5Bpersonal_id%5D=" +
                    customer.personalNumber
                  }
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Lexbase
                </a>
              </span>
            </div>

            <div className="details-row">
              <span className="details-header">Date of birth:</span>
              <span className="details-value">
                {moment.utc(customer.dateOfBirth).local().format("YYYY-MM-DD")}
              </span>
            </div>
            <div className="details-row">
              <span className="details-header">Age:</span>
              <span className="details-value">
                {moment().diff(moment(customer.dateOfBirth), "years")}
              </span>
            </div>
          </div>
        )}
        <div className="details-row">
          <span className="details-header">Member since:</span>
          <span className="details-value">
            {Utils.getUtcInLocalDateTime(customer.created)}
          </span>
        </div>
        <div className="details-row">
          <span className="details-header">Tier:</span>
          <span className="details-value">{customer.tier}</span>
        </div>
        <div className="details-row">
          <span className="details-header">Level:</span>
          <span className="details-value">{levelFee}</span>
        </div>
        <div className="details-row" style={{ position: "relative" }}>
          <span className="details-header">Yearly volume:</span>
          <span className="details-value">
            {Utils.formatNumber(feeLevelData.yearlyVolume, 0)} SEK
          </span>
          <Tooltip
            id={"yearly-volume-tooltip"}
            text={"Based on Buy and Sell volumes only"}
            style={{ marginLeft: 8, marginTop: -10 }}
          />
        </div>
        <div className="details-row">
          <span className="details-header">Next volume level:</span>
          <span className="details-value">
            {feeLevelData.nextLevelVolume
              ? Utils.formatNumber(feeLevelData.nextLevelVolume, 0) + " SEK"
              : "None"}{" "}
          </span>
        </div>
        <div className="details-row">
          <span className="details-header">Links:</span>
          <span className="details-value">
            <a href={intercomLink} target="_blank" rel="noopener noreferrer">
              Intercom
            </a>
            ,{" "}
            <a href={fraudSightLink} target="_blank" rel="noopener noreferrer">
              FraudSight
            </a>
          </span>
        </div>

        {customer.gdprRemovalRequestDate && (
          <div>
            <div className="details-row">
              <span className="details-header">GDPR request:</span>
              <span className="details-value">
                {Utils.getUtcInLocalDateTime(customer.gdprRemovalRequestDate)}
              </span>
            </div>
            <div className="details-row">
              <span className="details-header">GDPR removal:</span>
              <span className="details-value">
                {Utils.getUtcInLocalDateTime(customer.gdprRemovalProcessDate)}
              </span>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderVerifications() {
    let verifications = []

    if (!this.state.verificationsLoaded) {
      verifications.push(
        <div key="loading">
          <p className="customer-name details-row">Verifications:</p>
          <div className="details-row">
            <b>Loading... Please wait!</b>
          </div>
        </div>,
      )
      return verifications
    }

    const runPepSanctionButton = () => {
      verifications.push(
        <div key="pepsanctionbutton">
          <hr />
          <Button
            size="xs"
            color="primary"
            onClick={() => this.onRunPepSanction()}
          >
            Run PEP & Sanctions check
          </Button>
        </div>,
      )
    }

    if (!this.state.verifications) {
      verifications.push(
        <p className="customer-name details-row" key="title">
          NO verifications
        </p>,
      )
      runPepSanctionButton()
      return verifications
    }

    verifications.push(
      <p className="customer-name details-row" key="title">
        Verifications:
      </p>,
    )

    let havePepSanctionCheck = false

    this.state.verifications.forEach(v => {
      if (v.verificationType === "PEP and Sanctions check") {
        havePepSanctionCheck = true
      }
      verifications.push(
        <div className="details-row" key={v.id}>
          <VerificationImage
            style={{ float: "left", marginRight: "10px" }}
            verificationLevel={v.verificationLevel}
          />
          <div className="details-value">
            {v.verificationType === "PEP and Sanctions check" ? (
              <span>
                PEP&sanctions{" "}
                <Button
                  size="xs"
                  color="primary"
                  onClick={() => this.onReviewPepSanction(v)}
                >
                  {v.verificationLevel === 0 ? "Review" : "View"}
                </Button>{" "}
                <Button
                  size="xs"
                  color="primary"
                  onClick={() => this.onRunPepSanction()}
                >
                  Re-run
                </Button>
              </span>
            ) : (
              <span>
                {v.verificationType === "ZignSec"
                  ? "BankID/NemID (ZignSec)"
                  : v.verificationType}
              </span>
            )}
          </div>
        </div>,
      )
    })

    if (!havePepSanctionCheck) {
      runPepSanctionButton()
    }

    return verifications
  }

  onReviewPepSanction = verification => {
    if (this.props.onReviewPepSanction) {
      this.props.onReviewPepSanction(verification)
    }
  }

  render() {
    if (!this.props.customer) return <div />
    const corporate = this.props.customer.type === "Corporate"
    const premium = this.props.customer.level >= 2
    const iconStyle = { width: "170px", height: "220px", marginRight: "20px" }

    return (
      <div>
        <CardBody>
          <Row className="no-gutters">
            <Col>
              <Row className="no-gutters">
                {corporate ? (
                  <img alt="" src={Business} style={iconStyle} />
                ) : (
                  <span>
                    {premium ? (
                      <img alt="" src={Premium} style={iconStyle} />
                    ) : (
                      <ProfileImage
                        className="img-fluid mr-3"
                        customerId={this.props.customer.id}
                      />
                    )}
                  </span>
                )}
                <Col>{this.renderDetailsInfo()}</Col>
                <Col>{this.renderVerifications()}</Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
}

export default CustomerDetailsSection
