import React, { Component } from "react"
import { CardBody, CardHeader, Col, Row } from "reactstrap"

class PartnerStatusSection extends Component {
  getStatusBadge = () => {
    if (this.props.partner.active) {
      return (
        <div
          className="status-badge"
          style={{ backgroundColor: "#fbc94c", color: "#BD7618" }}
        >
          Active
        </div>
      )
    } else {
      return (
        <div
          className="status-badge"
          style={{ backgroundColor: "#c10007", color: "#fffcef" }}
        >
          Deactivated
        </div>
      )
    }
  }

  render() {
    if (!this.props.partner) return <div />

    return (
      <div>
        <Row className="no-gutters">
          <Col xs={6}>
            <CardHeader className="card-section-header">
              Current status
            </CardHeader>
            <CardBody className="pt-0">
              <Row className="no-gutters">{this.getStatusBadge()}</Row>
            </CardBody>
          </Col>
        </Row>
      </div>
    )
  }
}
export default PartnerStatusSection
