import React, { Component } from "react"

class Badge extends Component {
  render() {
    return (
      <div
        className="badge"
        style={{
          backgroundColor: this.props.color,
          color: this.props.textColor,
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

Badge.defaultProps = {
  textColor: "white",
  color: "#f89406",
}

export default Badge
