import React, { Component } from "react"

import "loaders.css/loaders.css"
import "spinkit/css/spinkit.css"
import { APP_COLORS } from "./constants"

/**
 * Button that shows spinner when pressed. States are determined by parent via props
 */
class ButtonLoad extends Component {
  state = {
    loadingContainerWidth: "0px",
    loadingContainerHeight: "0px",
  }

  constructor(props) {
    super(props)
    this.buttonRef = React.createRef()
  }

  componentDidMount() {
    // If loading container dimensions hasn't been set before, set it now when we know button size
    let buttonRef = this.buttonRef
    this.setState({
      loadingContainerWidth: buttonRef.clientWidth + "px",
      loadingContainerHeight: buttonRef.clientHeight + "px",
    })
  }

  onButtonClick = event => {
    if (this.props.isLoading) {
      event.preventDefault()
      return
    }

    if (this.props.onClicked) {
      this.props.onClicked()
    }
  }

  render() {
    let buttonText = this.props.buttonText || "Save"

    return (
      <button
        onClick={this.onButtonClick}
        type="submit"
        className="btn btn-primary btn-no-glow"
        style={{
          backgroundColor: this.props.isActive
            ? APP_COLORS.washed_green
            : APP_COLORS.gray,
          borderColor: this.props.isActive
            ? APP_COLORS.washed_green
            : APP_COLORS.gray,
          color: "#FFFFFF",
          boxShadow: "none!important",
          outline: "0",
          minWidth: this.state.loadingContainerWidth,
          minHeight: this.state.loadingContainerHeight,
          ...this.props.style,
        }}
        ref={this.buttonRef}
        disabled={this.props.isActive ? "" : "disabled"}
      >
        {!this.props.isLoading && buttonText}
        {this.props.isLoading && (
          <div className="text-center">
            <div
              className="ball-clip-rotate-pulse button-loader"
              style={{ display: "inline-block" }}
            >
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </button>
    )
  }
}

ButtonLoad.defaultProps = {
  isActive: true,
}

export default ButtonLoad
