import ContentWrapper from "../../Layout/ContentWrapper"
import { Button, Container } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import DataGridEmpty from "../../Common/DataGridEmpty"
import React, { useEffect, useState } from "react"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import EditOrderLimitsModal from "./EditOrderLimitsModal"

// Channels that can not be processed/rejected through UI

const OrderLimitsPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [buyLimitData, setBuyLimitData] = useState([])
  const [selectedLimitToEdit, setSelectedLimitToEdit] = useState(null)
  const [showEditLimitModal, setShowEditLimitModal] = useState(null)

  const [sellLimitData, setSellLimitData] = useState([])

  const columns = [
    {
      key: "name",
      name: "Name",
      width: 150,
    },
    {
      key: "min",
      name: "min",
      width: 80,
    },
    {
      key: "max",
      name: "max",
      width: 80,
    },
  ]
  const buyColumns = [
    ...columns,
    {
      key: "edit",
      name: "",
      width: 150,
      formatter: props => (
        <Button
          title="Edit"
          color="primary"
          size="sm"
          className="mr-1 fa icon-pencil d-flex align-items-center justify-content-center w-100"
          onClick={() => onOpenLimitModal(props, "buy")}
        />
      ),
    },
  ]
  const sellColumns = [
    ...columns,
    {
      key: "edit",
      name: "",
      width: 150,
      formatter: props => (
        <Button
          title="Edit"
          color="primary"
          size="sm"
          className="mr-1 fa icon-pencil d-flex align-items-center justify-content-center w-100"
          onClick={() => onOpenLimitModal(props, "sell")}
        />
      ),
    },
  ]

  const onOpenLimitModal = ({ row }, type) => {
    setSelectedLimitToEdit({ ...row, type })
    setShowEditLimitModal(true)
  }
  const onEditLimit = async ({ type, min, max, id }) => {
    setIsLoading(true)
    let formData = new FormData()
    if (type === "buy") {
      formData.append("buyLimitId", id)
    } else if (type === "sell") {
      formData.append("sellLimitId", id)
    }
    if (min) {
      formData.append("newMinLimit", min)
    }
    if (max) {
      formData.append("newMaxLimit", max)
    }
    const response =
      await BackOfficeApi.endpoints.editOrderLimits.create(formData)
    if (response?.ok) {
      await loadLimits()
      ToastUtils.toastExecuteSuccess2()
    } else {
      ToastUtils.toastAPIError3()
      setIsLoading(false)
    }
  }

  const loadLimits = async () => {
    setIsLoading(true)
    const response = await BackOfficeApi.endpoints.orderLimits.getAll()
    if (response?.ok) {
      const json = await response.json()
      setBuyLimitData(json.buy)
      setSellLimitData(json.sell)
      setIsLoading(false)
    } else {
      ToastUtils.toastAPIError2()
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadLimits()
  }, [])
  return (
    <ContentWrapper>
      <div className="content-heading">Order Limits</div>
      <Container fluid className={isLoading ? "whirl standard" : ""}>
        <span className="details-header">Buy limits:</span>
        <ReactDataGrid
          columns={buyColumns}
          rowGetter={i => buyLimitData[i]}
          rowsCount={buyLimitData.length}
          emptyRowsView={buyLimitData ? null : DataGridEmpty}
        />
        <div style={{ height: 30 }} />
        <span className="details-header">Sell limits:</span>
        <ReactDataGrid
          columns={sellColumns}
          rowGetter={i => sellLimitData[i]}
          rowsCount={sellLimitData.length}
          emptyRowsView={sellLimitData ? null : DataGridEmpty}
        />
      </Container>
      <EditOrderLimitsModal
        isOpen={showEditLimitModal}
        onDismiss={() => setShowEditLimitModal(false)}
        orderLimitData={selectedLimitToEdit}
        onEdit={onEditLimit}
      />
    </ContentWrapper>
  )
}

export default OrderLimitsPage
