import React from "react"
import BackOfficeApi from "../Api/BackOfficeApi"
import moment from "moment"
import { Button } from "reactstrap"

const DocumentImage = ({ image, onRemoveImage }) => {
  if (!image) return <div></div>

  return (
    <div className="m-2">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <a
          href={BackOfficeApi.url + "/image?id=" + image.id}
          target="_blank"
          rel="noreferrer"
        >
          <p>
            {moment.utc(image.timestamp).local().format("YYYY-MM-DD HH:mm")}
          </p>
        </a>
        <Button
          color="danger"
          size="l"
          className="mr-1 fa icon-trash"
          onClick={() => onRemoveImage(image)}
        />
      </div>
      <a
        href={BackOfficeApi.url + "/image?id=" + image.id}
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={BackOfficeApi.url + "/image?size=m&id=" + image.id}
          alt="uploaded images"
          style={{ border: "solid 1px #000" }}
          onError={e => {
            e.target.onerror = null
            e.target.src = "/img/user_placeholder.png"
          }}
        />
      </a>
    </div>
  )
}

export default DocumentImage
