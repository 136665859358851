import React, { Component } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

class ChainalysisModal extends Component {
  exposureDetails = (data, name) => {
    let rows = data.exposureDetails
    if (!rows) return ""

    let hasRecords = false
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].cluster.category === name) {
        hasRecords = true
      }
    }

    if (!hasRecords) return ""

    return (
      <div key={name}>
        <center>
          <h4>{name}</h4>
        </center>
        <table className="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Received direct</th>
              <th>Sent direct</th>
              <th>Received indirect</th>
              <th>Sent indirect</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => {
              return (
                <tr key={i}>
                  {rows[i].cluster.category === name && (
                    <React.Fragment>
                      <td>
                        <b>{rows[i].cluster.name}</b>
                      </td>
                      <td>{rows[i].receivedDirectExposure}</td>
                      <td>{rows[i].sentDirectExposure}</td>
                      <td>{rows[i].receivedIndirectExposure}</td>
                      <td>{rows[i].sentIndirectExposure}</td>
                    </React.Fragment>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const data = this.props.data
    if (!data) return ""

    let lastUpdate = null
    if (data.scoreUpdatedDate) {
      lastUpdate = new Date(data.scoreUpdatedDate)
      lastUpdate = lastUpdate.toUTCString()
      lastUpdate = lastUpdate.slice(5, -13)
    }

    const categories = [
      "terrorist financing",
      "child abuse material",
      "child abuse",
      "darknet market",
      "fraud shop",
      "scam",
      "illicit actor-org",
      "stolen bitcoins",
      "stolen funds",
      "ransomeware",
      "sanctions",
      "mixing",
      "gambling",
      "p2p exchange",
      "high risk exchange",
      "high risk jurisdiction",
      "exchange",
      "infrastructure as a service",
      "lending contract",
      "decentralized exchange contract",
      "smart contract",
      "token smart contract",
      "personal wallet",
      "hosted wallet",
      "merchant services",
      "mining",
      "mining pool",
      "other",
      "unknown",
      "address",
      "uncategorised",
      "unnamed service",
    ]

    let exposureTable = []
    for (let i = 0; i < categories.length; i++) {
      exposureTable.push(this.exposureDetails(data, categories[i]))
    }

    return (
      <Modal isOpen={this.props.open} toggle={this.props.onToggle} size="lg">
        <ModalHeader toggle={this.props.onToggle}>
          Chainalysis{" "}
          {lastUpdate && (
            <span>
              (Last update: <u>{lastUpdate}</u>)
            </span>
          )}
        </ModalHeader>
        <ModalBody>{exposureTable}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.onToggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default ChainalysisModal
