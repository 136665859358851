import React, { Component } from "react"
import { Row, Col, CardBody } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import { Link } from "react-router-dom"
import Utils from "../Common/Utils"

class CustomerOrderSection extends Component {
  state = {
    orderData: null,
    isLoading: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      this.loadData()
    }
  }

  loadData() {
    BackOfficeApi.endpoints.customerOrderStats
      .getOne({ id: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          orderData: data,
        })
      })
  }

  toggleCategory(e) {
    let el = e.target.closest("tr")
    let name = el.getAttribute("id")
    let subCategories = document.getElementsByClassName(name)
    for (var i = 0; i < subCategories.length; i++) {
      subCategories[i].classList.toggle("d-none")
    }
  }

  getTotalBuySum() {
    if (this.state.orderData) {
      let total = 0
      let bStats = this.state.orderData.buyOrderStats
      for (let i = 0; i < bStats.length; i++) {
        total += bStats[i].fiatSekAmount
      }
      return Math.round(total)
    }
    return "..."
  }

  getTotalSellSum() {
    if (this.state.orderData) {
      let total = 0
      let sStats = this.state.orderData.sellOrderStats
      for (let i = 0; i < sStats.length; i++) {
        total += sStats[i].fiatSekAmount
      }
      return Math.round(total)
    }
    return "..."
  }

  getTotalDepositSum() {
    if (this.state.orderData) {
      let total = 0
      let dStats = this.state.orderData.depositStats
      if (dStats) {
        for (let i = 0; i < dStats.length; i++) {
          total += dStats[i].fiatSekAmount
        }
        return Math.round(total)
      }
    }
    return "..."
  }

  getTotalWithdrawalSum() {
    if (this.state.orderData) {
      let total = 0
      let wStats = this.state.orderData.withdrawalStats
      if (wStats) {
        for (let i = 0; i < wStats.length; i++) {
          total += wStats[i].fiatSekAmount
        }
        return Math.round(total)
      }
    }
    return "..."
  }

  renderExposureStats(options) {
    if (!options || !options.type) return
    let stats = []

    if (this.state.orderData) {
      let orderStats
      if (options.type === "buy") {
        orderStats = this.state.orderData.buyOrderStats
      } else if (options.type === "sell") {
        orderStats = this.state.orderData.sellOrderStats
      } else if (options.type === "deposit") {
        orderStats = this.state.orderData.depositStats
      } else if (options.type === "withdrawal") {
        orderStats = this.state.orderData.withdrawalStats
      }
      if (orderStats) {
        orderStats.forEach(data => {
          stats.push(
            <tr key={data.cryptoCurrency + data.fiatCurrency}>
              <td>
                <b>{data.orders}</b> ORDERS
              </td>
              <td>
                <b>
                  {data.cryptoTotal} {data.cryptoCurrency}{" "}
                </b>
              </td>
              <td>
                <b>
                  {data.fiatAmount} {data.fiatCurrency}
                </b>
              </td>
            </tr>,
          )
        })
      }
    }

    return stats
  }

  render() {
    if (!this.props.customer) return <div />

    const cardStyle = { height: "calc(100% - 20px)" }
    const tableStyle = { width: "100%", maxWidth: "400px" }

    return (
      <div>
        <CardBody className="pt-0">
          <div
            className="details-header"
            style={{ padding: "1.5rem 1.25rem 0.75rem 0" }}
          >
            <p>Statistics:</p>
          </div>
          <Row>
            <Col lg={6} className="pr-3">
              <div className="card shadow-sm border" style={cardStyle}>
                <CardBody>
                  <Link
                    className="float-right btn btn-primary btn-sm safello-font"
                    to={"/orders/buy?customer=" + this.props.customer.id}
                  >
                    View all
                  </Link>
                  <h4>BUY Orders</h4>
                  <div>
                    Total:
                    <h2 className="safello-font">
                      {Utils.formatNumber(this.getTotalBuySum())} SEK
                    </h2>
                    <table style={tableStyle}>
                      <tbody>{this.renderExposureStats({ type: "buy" })}</tbody>
                    </table>
                  </div>
                </CardBody>
              </div>
            </Col>
            <Col lg={6} className="pl-3">
              <div className="card shadow-sm border" style={cardStyle}>
                <CardBody>
                  <Link
                    className="float-right btn btn-primary btn-sm safello-font"
                    to={"/orders/sell?customer=" + this.props.customer.id}
                  >
                    View all
                  </Link>
                  <h4>SELL Orders</h4>
                  Total:
                  <h2 className="safello-font">
                    {Utils.formatNumber(this.getTotalSellSum())} SEK{" "}
                  </h2>
                  <table style={tableStyle}>
                    <tbody>{this.renderExposureStats({ type: "sell" })}</tbody>
                  </table>
                </CardBody>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="pr-3">
              <div className="card shadow-sm border" style={cardStyle}>
                <CardBody>
                  <Link
                    className="float-right btn btn-primary btn-sm safello-font"
                    to={"/wallet/outgoing?customer=" + this.props.customer.id}
                  >
                    View all
                  </Link>
                  <h4>WITHDRAWAL Orders</h4>
                  Total:
                  <h2 className="safello-font">
                    {Utils.formatNumber(this.getTotalWithdrawalSum())} SEK{" "}
                  </h2>
                  <table style={tableStyle}>
                    <tbody>
                      {this.renderExposureStats({ type: "withdrawal" })}
                    </tbody>
                  </table>
                </CardBody>
              </div>
            </Col>
            <Col lg={6} className="pl-3">
              <div className="card shadow-sm border" style={cardStyle}>
                <CardBody>
                  <Link
                    className="float-right btn btn-primary btn-sm safello-font"
                    to={"/wallet/incoming?customer=" + this.props.customer.id}
                  >
                    View all
                  </Link>
                  <h4>DEPOSIT Orders</h4>
                  <div>
                    Total:
                    <h2 className="safello-font">
                      {Utils.formatNumber(this.getTotalDepositSum())} SEK
                    </h2>
                    <table style={tableStyle}>
                      <tbody>
                        {this.renderExposureStats({ type: "deposit" })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </div>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
}

export default CustomerOrderSection
