import React, { Component } from "react"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

class Cards extends Component {
  state = {
    cardData: [],
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.statsComplianceCards
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          cardData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    if (this.state.cardData && this.state.cardData.length > 0) {
      return this.state.cardData.map((card, i) => {
        let title
        let icon = "fa-2x fas fa-"
        if (card.name === "compliance_frozen_orders") {
          title = "Total frozen orders"
          icon += "snowflake"
        } else if (card.name === "compliance_frozen_orders_amount") {
          title = "Total frozen order amount"
          icon += "snowflake"
        } else if (card.name === "compliance_suspicious_orders") {
          title = "Total suspicious orders"
          icon += "user-secret"
        } else if (card.name === "compliance_suspicious_orders_amount") {
          title = "Total suspicious order amount"
          icon += "user-secret"
        } else if (card.name === "compliance_paid_orders_amount") {
          title = "Total unprocessed buy order amount"
          icon += "coins"
        } else if (card.name === "compliance_sold_orders_amount") {
          title = "Total unprocessed sell order amount"
          icon += "coins"
        } else if (card.name === "compliance_paid_orders") {
          title = "Total unprocessed buy orders"
          icon += "receipt"
        } else if (card.name === "compliance_sold_orders") {
          title = "Total unprocessed sell orders"
          icon += "receipt"
        }
        return (
          <BackOfficeCard
            icon={icon}
            title={title}
            value={Utils.formatNumber(card.value)}
            unit={card.unit}
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    } else {
      let placeholders = [0, 1, 2, 3, 4, 5, 6, 7]
      return placeholders.map((placeHolder, i) => {
        return (
          <BackOfficeCard
            icon=" "
            title="-"
            value="-"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    }
  }
}

export default Cards
