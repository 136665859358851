import React, { Component } from "react"
import Utils from "../Common/Utils"
import ContentWrapper from "../Layout/ContentWrapper"
import { Col, Row } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import UserInfoArea from "./UserInfoArea"

class User extends Component {
  state = {
    user: null,
    userId: Utils.getLastUrlSegment(this.props.location),
    allPermissions: null,
  }

  componentDidMount() {
    this.getUser()
    this.getAllPermissions()
  }

  getUser = () => {
    this.setState({ isLoading: true })
    BackOfficeApi.endpoints.getUser
      .getOne({ id: this.state.userId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({ isLoading: false, user: data })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  getAllPermissions = () => {
    BackOfficeApi.endpoints.getAllPermissions
      .getOne()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({ isLoading: false, allPermissions: data })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        this.setState({ isLoading: false })
      })
  }

  onUpdatePermissions = permissions => {
    let formData = new FormData()
    formData.append("permissions", permissions.join())

    BackOfficeApi.endpoints.updatePermissions
      .create(formData, { id: this.state.userId })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          this.getUser()
        } else {
          ToastUtils.toastAPIError2()
        }
      })
  }

  render() {
    return (
      <ContentWrapper>
        <Row>
          <Col lg={8}>
            <UserInfoArea
              user={this.state.user}
              allPermissions={this.state.allPermissions}
              onUpdatePermissions={this.onUpdatePermissions}
            />
          </Col>
          <Col lg={4}>
            <Col className="pl-0 pr-0"></Col>
            <Col className="pl-0 pr-0"></Col>
          </Col>
        </Row>
      </ContentWrapper>
    )
  }
}
export default User
