import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"

class FreezeUnfreeze extends Component {
  state = {
    comment: "",
  }
  freezeUpdate = () => {
    const comment = this.state.comment

    if (!comment) {
      alert("Please enter a comment why you're changing the risk level.")
      return
    }

    if (comment.length < 10) {
      alert(
        "Your comment seems too short, describe the reason in more details.",
      )
      return
    }

    let formData = new FormData()
    formData.append("freeze", !this.props.customer.frozen)
    formData.append("comment", comment)

    BackOfficeApi.endpoints.freezeReview
      .create(formData, { id: this.props.customer.id })
      .then(response => {
        this.props.toggle()
        if (response.ok) {
          this.props.save()
        } else {
          response.text().then(text => {
            this.props.error(text)
          })
        }
      })
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    if (!this.props.customer) {
      return ""
    }

    const actionName = this.props.customer.frozen ? "Unfreeze" : "Freeze"

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.props.toggle}>
          {actionName} customer
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="comment">
                Comment
              </Label>
              <textarea
                className="form-control col-xl-8"
                rows="3"
                type="text"
                name="comment"
                id="comment"
                value={this.state.comment}
                onChange={this.onChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.freezeUpdate}>
            {actionName}
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default FreezeUnfreeze
