import React from "react"
import BackOfficeApi from "../Api/BackOfficeApi"

export default props => (
  <img
    className={props.className}
    src={BackOfficeApi.url + "/image/profile/customer/" + props.customerId}
    alt="Customer"
    style={{ border: "solid 1px #000", width: "170px", maxHeight: "170px" }}
    onError={e => {
      e.target.onerror = null
      e.target.src = "/img/user_placeholder.png"
    }}
  />
)
