import React, { Component } from "react"
import ToastUtils from "../../Common/ToastUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficePieChart from "../../Chart/BackOfficePieChart"
import { Col, Row } from "reactstrap"

class ActiveCustomerPie extends Component {
  state = {
    pies: [],
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.statsStatisticsPies
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          pies: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    return (
      <Col xl={12}>
        <Row>
          <Col xl={6}>
            <div className="card" style={{ minHeight: "300px" }}>
              <div className="card-body">
                {this.state.pies.length > 0 && (
                  <BackOfficePieChart
                    headerLabel="Customer order stats"
                    cells={this.state.pies[0].cells}
                    height={300}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col xl={6}>
            <div className="card" style={{ minHeight: "300px" }}>
              <div className="card-body">
                {this.state.pies.length > 0 && (
                  <BackOfficePieChart
                    headerLabel="Customer age groups"
                    cells={this.state.pies[1].cells}
                    height={300}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}
export default ActiveCustomerPie
