import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import { Container } from "reactstrap"
import DataGridEmpty from "../Common/DataGridEmpty"
import ReactDataGrid from "react-data-grid"
import BackOfficeApi from "../Api/BackOfficeApi"
import SearchBar from "../Common/SearchBar"
import { Link } from "react-router-dom"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"

class CommentSearch extends Component {
  state = {
    searchText: "",
    rows: [],
    isLoading: false,
  }
  constructor(props) {
    super(props)

    this._columns = [
      {
        key: "buyOrderId",
        name: "Buy Order",
        width: 80,
        formatter: this.BuyOrderFormatter,
      },
      {
        key: "sellOrderId",
        name: "Sell order",
        width: 80,
        formatter: this.SellOrderFormatter,
      },
      {
        key: "customerId",
        name: "Customer",
        width: 80,
        formatter: this.CustomerFormatter,
      },
      {
        key: "timestamp",
        name: "Timestamp",
        width: 150,
        formatter: DataGridLocalDateTimeFormatter,
      },
      {
        key: "comment",
        name: "Comment",
        formatter: this.CommentFormatter,
      },
    ]
  }

  onSearchChange = e => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout)
    }

    this.setState({
      searchText: e.target.value,
      searchTimeout: setTimeout(() => {
        this.onSearch()
      }, 1000),
    })
  }

  onSearch = () => {
    if (this.state.searchText.length > 2) {
      this.setState({
        isLoading: true,
      })

      BackOfficeApi.endpoints.searchComments
        .getAll({ search: this.state.searchText })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.setState({
            rows: data.slice(0),
            isLoading: false,
          })
        })
        .catch(() => {
          this.setState({
            error: "Failed to retrieve data",
            isLoading: false,
          })
        })
    }
  }

  rowGetter = i => {
    const row = this.state.rows[i]
    return row
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Comment search</div>
          <div className="m-auto position-relative">
            <SearchBar
              value={this.state.searchText}
              onChange={this.onSearchChange}
            ></SearchBar>
            <div
              className="ball-beat loader-position"
              hidden={!this.state.isLoading}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <Container fluid>
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={200}
            rowHeight={40}
          />
        </Container>
      </ContentWrapper>
    )
  }

  BuyOrderFormatter = props => {
    return <Link to={"/orders/buy/" + props.value}>{props.value}</Link>
  }
  SellOrderFormatter = props => {
    return <Link to={"/orders/sell/" + props.value}>{props.value}</Link>
  }
  CustomerFormatter = props => {
    return <Link to={"/customers/" + props.value}>{props.value}</Link>
  }
  CommentFormatter = props => {
    return <div>{props.value}</div>
  }
}
export default CommentSearch
