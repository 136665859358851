import React, { useState } from "react"
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap"
import Select from "react-select"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

const permissionOptions = [
  { value: "SIGNER", label: "Signer" },
  { value: "EDITOR", label: "Editor" },
  // Add more options as needed
]

const AddAccessModal = ({ onDismiss, open, customerId, onSuccess }) => {
  const [personalNumber, setPersonalNumber] = useState("")
  const [selectedPermissions, setSelectedPermissions] = useState([])

  const onAddAccess = async () => {
    if (!personalNumber || selectedPermissions.length === 0) {
      alert("Please fill in all required fields")
      return
    }
    try {
      const res = await BackOfficeApi.endpoints.addCustomerAccess.post(
        { id: customerId },
        {
          personalNumber,
          permissions: selectedPermissions
            .map(option => option.value)
            .join(","),
        },
      )

      if (res.ok) {
        ToastUtils.toastExecuteSuccess2()
        onDismiss()
        onSuccess()
      } else {
        const json = await res.json()
        if (json.error) {
          ToastUtils.toastCustomError(json.error)
        } else {
          throw new Error(res)
        }
      }
    } catch (e) {
      console.error(e)
      ToastUtils.toastAPIError3()
    }
  }

  return (
    <Modal isOpen={open} toggle={onDismiss} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onDismiss}>
        Add access to corporate account
      </ModalHeader>
      <ModalBody>
        <div className="row" style={{ marginBottom: 24 }}>
          <Label className="col-xl-3 col-form-label">Personal number</Label>
          <Input
            className="col-md-7"
            type="text"
            name="discountFeeId"
            value={personalNumber}
            onChange={e => setPersonalNumber(e.target.value)}
          />
        </div>
        <div className="row">
          <Label className="col-xl-3 col-form-label">Permissions</Label>
          <Select
            className="col-md-7"
            isMulti
            options={permissionOptions}
            value={selectedPermissions}
            onChange={selectedOptions =>
              setSelectedPermissions(selectedOptions)
            }
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onAddAccess}>
          Add
        </Button>
        <Button color="secondary" onClick={onDismiss}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AddAccessModal
