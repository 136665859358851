import React, { Component } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

class UploadModal extends Component {
  state = {
    imageType: 0,
    file: null,
  }

  onChange = e => {
    this.setState({
      imageType: e.target.value,
    })
  }

  onFileChange = e => {
    this.setState({
      file: e.target.files[0],
    })
  }

  onUpload = () => {
    let formData = new FormData()
    formData.append("customer_id", this.props.customer.id)
    formData.append("image_type", this.state.imageType)
    formData.append("image", this.state.file)

    this.setState({
      isUploading: true,
    })

    BackOfficeApi.endpoints.uploadDocument
      .upload(formData, {}, { "Content-Type": "multipart/form-data" })
      .then(response => {
        this.setState({
          isUploading: false,
        })

        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
        } else {
          ToastUtils.toastAPIError2()
        }

        if (this.props.onUploadDone) {
          this.props.onUploadDone()
        }
      })
  }

  render() {
    let loadClass = ""
    if (this.state.isUploading) {
      loadClass = "whirl traditional"
    }

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "600px" }}
        className={loadClass}
      >
        <ModalHeader toggle={this.props.toggle}>Upload document</ModalHeader>
        <ModalBody>
          <div>
            <input
              className="mb-4"
              type="file"
              name="file"
              onChange={this.onFileChange}
            />
            <select
              value={this.state.imageType}
              name="imageType"
              className="custom-select"
              onChange={this.onChange}
            >
              <option value="0">Not defined</option>
              <option value="1">Identification</option>
              <option value="2">Utility bill</option>
              <option value="3">Bank account</option>
              <option value="4">Profile</option>
              <option value="5">Wallpaper</option>
              <option value="10">Miscellaneous</option>
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            className="mr-auto"
            onClick={this.onUpload}
            disabled={!this.state.file}
          >
            Upload
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default UploadModal
