import React from "react"
import { Link } from "react-router-dom"
import OrderUtils from "../components/Orders/OrderUtils"
import Utils from "../components/Common/Utils"
// assets
import Cart from "../components/Media/Svg/cart.svg"
import Trash from "../components/Media/Svg/trash_white.svg"
import Cash from "../components/Media/Svg/cash.svg"
import Freeze from "../components/Media/Svg/freeze.svg"
import ExchangeWhite from "../components/Media/Svg/exchange_white.svg"
import Exchange from "../components/Media/Svg/exchange.svg"
import ArrowUp from "../components/Media/Svg/arrow_up.svg"
import Transfer from "../components/Media/Svg/transfer.svg"
import BuyIcon from "../components/Media/Svg/buy.svg"
import SellIcon from "../components/Media/Svg/sell.svg"
import WithdrawalIcon from "../components/Media/Svg/withdrawal.svg"
import DepositIcon from "../components/Media/Svg/deposit.svg"
import SwapIcon from "../components/Media/Svg/swap.svg"

export const customerFormatter = props => {
  const customer = props.value
  if (!customer) return
  return (
    <Link to={"/customers/" + customer.id}>
      {OrderUtils.renderRiskProfile(customer.riskLevel)} {customer.name}
    </Link>
  )
}

export const customerFormatter2 = customerId => {
  if (!customerId) return
  return <Link to={"/customers/" + customerId}>{customerId}</Link>
}

export const orderIDFormatter = (
  props,
  chosenIds = null,
  handleIdCheckClick = null,
) => {
  const { orderId, orderType, orderMapId } = props.value
  const isSwap = orderType === "SWAP"
  const url =
    orderType === "BUY" || orderType === "SELL"
      ? `/orders/${orderType.toLowerCase()}/${orderId}`
      : isSwap
      ? `/wallet/transaction/swap/${orderId}`
      : `/wallet/transaction/${orderId}`
  const id = orderMapId ? orderMapId : orderId
  const value = `${orderType}-${id}`
  if (chosenIds)
    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={value}
          value={value}
          onChange={handleIdCheckClick}
          defaultChecked={chosenIds.includes(value)}
        />
        <label
          className="custom-control-label"
          htmlFor={value}
          style={{ lineHeight: "24px" }}
        >
          <Link to={url}>{orderId}</Link>
        </label>
      </div>
    )

  return (
    <div>
      <Link to={url}>{orderId}</Link>
    </div>
  )
}

export const walletFormatter = ({ value }) => {
  return (
    <span
      style={{
        textAlign: "center",
        display: "block",
      }}
    >
      {value ? <div className="wallet-badge">wallet</div> : null}
    </span>
  )
}

export const flaggedFormatter = ({ value }) => {
  return (
    <span
      style={{
        textAlign: "center",
        display: "block",
      }}
    >
      {value ? <div className="flagged-badge">flagged</div> : null}
    </span>
  )
}

export const amountFormatter = (amount, currency) => {
  const style = {
    textAlign: "right",
    display: "inline-block",
    width: "100%",
  }

  let amountString = ""
  if (amount) {
    amountString = Utils.formatNumber(amount) + " " + currency
  }
  return <span style={style}>{amountString || "-"}</span>
}

export const cryptoAmountFormatter = (amount, crypto) => {
  const style = {
    textAlign: "right",
    display: "inline-block",
    width: "100%",
  }
  let amountString = ""
  if (amount) {
    amountString = Utils.formatNumber(amount) + " " + crypto
  }
  return <span style={style}>{amountString || "-"}</span>
}

export const tagsFormatter = props => {
  let tags = props.value
  let badges = []

  tags.forEach(t => {
    badges.push(
      <div
        className="tag-badge"
        key={t.id}
        style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
      >
        {t.name}
      </div>,
    )
  })

  return badges
}

export const orderTypeFormatter = ({ value }) => {
  const orderType = value
  let orderTypeData = {}

  switch (orderType) {
    case "BUY":
      orderTypeData = {
        icon: BuyIcon,
        background: "white",
      }
      break
    case "SELL":
      orderTypeData = {
        icon: SellIcon,
        background: "white",
      }
      break
    case "WITHDRAWAL":
      orderTypeData = {
        icon: WithdrawalIcon,
        background: "white",
      }
      break
    case "DEPOSIT":
      orderTypeData = {
        icon: DepositIcon,
        background: "white",
      }
      break
    case "SWAP":
      orderTypeData = {
        icon: SwapIcon,
        background: "white",
      }
      break
    default:
      orderTypeData = {
        title: orderType,
        background: "#eeeeee",
        textColor: "#666666",
      }
  }
  const options = orderTypeData.icon ? { iconOnly: true } : { iconOnly: false }
  return getBadge(orderTypeData, options)
}

export const statusFormatter = ({ value }) => {
  const status = value.toUpperCase()
  let statusData = {}
  switch (status) {
    case "COMPLETED":
      statusData = {
        title: "Completed",
        icon: Transfer,
        background: "#fbc94c",
        textColor: "#BD7618",
      }
      break
    case "ISSUED":
      statusData = {
        title: "Issued",
        icon: Cart,
        background: "#f0f0f0",
        textColor: "#D16161",
      }
      break
    case "PAID":
      statusData = {
        title: "Paid",
        icon: Cash,
        background: "#b2e7bf",
        textColor: "#24773B",
      }
      break
    case "FROZEN":
      statusData = {
        title: "Frozen",
        icon: Freeze,
        background: "#e78435",
        textColor: "#FFFFFF",
      }
      break
    case "REFUNDED":
      statusData = {
        title: "Refunded",
        icon: ExchangeWhite,
        background: "#1285e5",
        textColor: "#FFFFFF",
      }
      break
    case "EXCHANGED":
      statusData = {
        title: "Exchanged",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
      break
    case "TRADECOMPLETED" || "TRADE_COMPLETED":
      statusData = {
        title: "Trade completed",
        icon: Exchange,
        background: "#e3f2ff",
        textColor: "#1285E5",
      }
      break
    case "CRYPTODELIVERED" || "CRYPTO_DELIVERED":
      statusData = {
        title: "Crypto delivered",
        icon: ArrowUp,
        background: "#f0f3e3",
        textColor: "#159542",
      }
      break
    case "PROCESSING":
      statusData = {
        title: "Processing",
        background: "yellow",
        textColor: "#159542",
      }
      break
    case "DELETED":
      statusData = {
        title: "Deleted",
        icon: Trash,
        background: "#e05c57",
        textColor: "#FFFFFF",
      }
      break
    default:
      statusData = {
        title: status,
        background: "#eeeeee",
        textColor: "#666666",
      }
  }

  const options = statusData.icon ? { iconOnly: true } : { iconOnly: false }

  return getBadge(statusData, options)
}

const getBadge = (data, options) => {
  const showTitle = options && options.iconOnly ? false : true
  const style = {
    backgroundColor: data.background,
    color: data.textColor,
    padding: 5,
  }

  if (!showTitle) {
    style.height = "25px"
    style.padding = "5px 5px 5px 26px"
  }

  return (
    <div
      key={data.title}
      className="status-badge"
      style={style}
      title={data.title}
    >
      {data.icon && <img src={data.icon} alt="" />}
      {showTitle ? data.title : ""}
    </div>
  )
}

export const capitalizeString = string => {
  if (!string || string.length < 2) return string
  return string[0].toUpperCase() + string.slice(1).toUpperCase()
}

/**
 * Converts a swap transaction object from the format received from the API
 * to a format suitable for the wallet transactions display.
 *
 * @param {Object} swapTransaction - The swap transaction object from the getSwapOrder.
 * @returns {Object} - Transformed object with necessary information for wallet transactions.
 */
export const swapTransactionToWalletTransaction = swapTransaction => {
  const orderId = swapTransaction.id

  const customerId = swapTransaction.customerId
  const issued = formatDate(swapTransaction.created)
  const status = swapTransaction.status

  const baseAmount = swapTransaction.trade.amount
  const counterAmount = swapTransaction.trade.counterAmount
  const assetPair = swapTransaction.trade.pair
  const assetPairSplit = assetPair.split("_")
  const side = swapTransaction.trade.side
  const baseAsset =
    side.toLowerCase() === "buy" ? assetPairSplit[1] : assetPairSplit[0]
  const counterAsset =
    side.toLowerCase() === "buy" ? assetPairSplit[0] : assetPairSplit[1]
  const swapFrom = amountFormatter(
    baseAmount || swapTransaction.orderAmount?.amount || "0",
    baseAsset,
  )

  const swapTo = amountFormatter(counterAmount || "0", counterAsset)

  const fiatAmount = amountFormatter(
    swapTransaction?.trade?.fiatAmount?.amount,
    swapTransaction?.trade?.fiatAmount?.currency,
  )

  const fee = amountFormatter(
    swapTransaction?.fee?.amount,
    swapTransaction?.fee?.currency,
  )

  const tags = swapTransaction?.tags || []

  const type = "SWAP"

  const rejectReason = swapTransaction?.rejectReason

  return {
    orderInfo: { orderId, orderType: type },
    type,
    id: orderId,
    customerId: customerId,
    from: swapFrom,
    to: swapTo,
    issued: issued,
    status: status,
    swapFee: fee,
    rejectReason,
    fiatAmount,
    tags,
  }
}

// Make date from getSwapOrders endpoint work with utils
const formatDate = date => {
  return date * 1000
}
