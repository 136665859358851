import React, { useContext } from "react"
import { CryptoTickerContext } from "../../App"

const CryptoSelector = ({
  onChange,
  value,
  style = {},
  nullValue,
  className,
  filteredTickers,
}) => {
  const tickers = filteredTickers
    ? filteredTickers
    : useContext(CryptoTickerContext)?.cryptoTickerData

  const handleSelectChange = e => {
    onChange(e.target.value)
  }

  return (
    <select
      value={value}
      name="currency"
      className={"custom-select crypto-selector " + className}
      style={style}
      onChange={handleSelectChange}
    >
      {nullValue && <option value={null}>{nullValue}</option>}
      {tickers.map(c => (
        <option value={c.symbol} key={c.symbol}>
          {c.symbol}
        </option>
      ))}
    </select>
  )
}

export default CryptoSelector
