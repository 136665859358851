import React, { Component } from "react"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"

export default class SellOnBitstamp extends Component {
  state = {
    modalOpen: false,
    amount: 0,
    crypto: "BTC",
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      amount: 0,
    })
  }

  getCryptoBalance = crypto => {
    switch (crypto) {
      case "BTC":
        return this.props.btcBalance
      case "ETH":
        return this.props.ethBalance
      case "XRP":
        return this.props.xrpBalance
      default:
        return 0
    }
  }

  getCryptoQuote = crypto => {
    let cryptoQuote

    switch (crypto) {
      case "BTC":
        cryptoQuote = this.props.btcQuote
        break
      case "ETH":
        cryptoQuote = this.props.ethQuote
        break
      case "XRP":
        cryptoQuote = this.props.xrpQuote
        break
      default:
        return 0
    }
    if (cryptoQuote) {
      return cryptoQuote.baseAsk
    }
  }

  convertCryptoToEuro = () => {
    let amount = this.state.amount
    let cryptoQuote = this.getCryptoQuote(this.state.crypto)

    return Math.round(amount * cryptoQuote)
  }

  onAmountChange = event => {
    let newAmount = event.target.value
    if (isNaN(newAmount)) {
      newAmount = 0
    }

    let max = this.getCryptoBalance(this.state.crypto)
    newAmount = Math.min(Math.max(newAmount, 0), max)

    this.setState({
      amount: newAmount,
    })
  }

  onCryptoChange = event => {
    this.setState({
      crypto: event.target.value,
      amount: 0,
    })
  }

  executeSellOnBitstamp = () => {
    this.toggleModal()

    let formData = new FormData()
    formData.append("amount", this.state.amount)
    formData.append("cryptoCurrency", this.state.crypto)

    BackOfficeApi.endpoints.sellOnBitstamp.create(formData).then(response => {
      if (response.ok) {
        ToastUtils.toastExecuteSuccess2()
      } else {
        ToastUtils.toastExecuteError2()
      }
    })

    this.props.updateBitstamp()
  }

  render() {
    return (
      <div className="mr-2 mb-2 d-inline-block">
        <Button
          color="primary"
          onClick={this.toggleModal}
          disabled={this.props.disabled}
        >
          Sell crypto on Bitstamp
        </Button>

        <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>
            Sell crypto on Bitstamp
          </ModalHeader>
          <ModalBody>
            <p>Crypto to sell</p>
            <select
              name="sellCrypto"
              value={this.state.crypto}
              className="custom-select mb-2"
              onChange={this.onCryptoChange}
            >
              <option value="BTC">BTC</option>
              <option value="ETH">ETH</option>
            </select>
            <p>Enter the amount of {this.state.crypto} you want to sell</p>
            <div lang="en-US">
              <Input
                type="number"
                placeholder="Enter amount here"
                onChange={this.onAmountChange}
                value={this.state.amount}
              />
            </div>
            <p>
              {this.state.amount} {this.state.crypto} is about{" "}
              {this.convertCryptoToEuro()} €
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.executeSellOnBitstamp}>
              Sell
            </Button>
            <Button color="secondary" onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}
