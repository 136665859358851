import React, { Component } from "react"
import { Card } from "reactstrap"
import PartnerStatusSection from "./PartnerStatusSection"
import PartnerDetailsSection from "./PartnerDetailsSection"
import PartnerAppApiSection from "./PartnerAppApiSection"

class PartnerInfoArea extends Component {
  render() {
    let cardClass = "card-area"
    let cardStyle = {}
    if (this.props.isLoading) {
      cardClass += " whirl standard"
      cardStyle = { minHeight: "500px" }
    }

    return (
      <div>
        <span className="header-area">
          Partner
          <span> {this.props.partner ? "#" + this.props.partner.id : ""}</span>
        </span>
        <Card className={cardClass} style={cardStyle}>
          <PartnerStatusSection partner={this.props.partner} />

          <div className="card-section-divider" />

          <PartnerDetailsSection partner={this.props.partner} />

          <div className="card-section-divider" />

          <PartnerAppApiSection
            partner={this.props.partner}
            channels={this.props.channels}
            onUpdate={this.props.onUpdate}
          />
        </Card>
      </div>
    )
  }
}
export default PartnerInfoArea
