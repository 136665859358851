import React, { useState, useEffect } from "react"
import { CardBody, CardHeader, Table } from "reactstrap"
import CardTool from "../../Common/CardTool"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"

const InProcessTransactions = () => {
  const [isLoading, setLoading] = useState(true)
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    loadData()
  }

  const loadData = async () => {
    setLoading(true)
    try {
      const response =
        await BackOfficeApi.endpoints.inProcessTransactions.getAll()
      if (!response.ok) {
        throw Error()
      }
      const data = await response.json()
      setTransactions(data.splice(0))
    } catch (_error) {
      ToastUtils.toastAPIError2()
    } finally {
      setLoading(false)
    }
  }

  const renderTransactions = () => {
    let t = transactions
    if (t && t.length > 0) {
      return t.map(row => {
        return (
          <tr key={row.id}>
            <td>{row.exchange}</td>
            <td>{row.type}</td>
            <td>
              {row.amount} {row.currency}
            </td>
            <td>{Utils.getUtcInLocalDateTime(row.timestamp)}</td>
          </tr>
        )
      })
    }
    return null
  }

  let cardClass = "card mb-2"
  if (isLoading) {
    cardClass += " whirl"
  }
  return (
    <div className={cardClass}>
      <CardHeader>
        <CardTool refresh onRefresh={refresh} />
        <h4>
          <em className="fab fa-bitcoin mr-2" />
          In-process transactions
        </h4>
      </CardHeader>
      <CardBody>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Exchange</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Timestamp</th>
            </tr>
          </thead>
          {transactions.length > 0 && <tbody>{renderTransactions()}</tbody>}
        </Table>
      </CardBody>
    </div>
  )
}

export default InProcessTransactions
