import React, { useState, useEffect } from "react"
import { Label, Input, Button } from "reactstrap"
import BackOfficeApi from "../../../Api/BackOfficeApi"
import ToastUtils from "../../../Common/ToastUtils"
import Utils from "../../../Common/Utils"

const AVAILABLE_ACTIONS = ["ADD", "REMOVE"]

const CustomerFeatureFlagControls = ({
  featuresFlagData,
  loading,
  setLoading,
}) => {
  const [selectedAction, setSelectedAction] = useState(AVAILABLE_ACTIONS[0])
  const [selectedFlag, setSelectedFlag] = useState(null)
  const [inputtedCustomerId, setInputtedCustomerId] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState(null)

  const handleActionChange = e => {
    setSelectedAction(e.target.value)
  }

  const handleFlagChange = e => {
    setSelectedFlag(e.target.value)
  }

  const handleCustomerId = e => {
    setInputtedCustomerId(e.target.value)
  }

  const reset = () => {
    setInputtedCustomerId("")
    setSelectedCustomer(null)
  }

  const getCustomer = async () => {
    const response = await BackOfficeApi.endpoints.customerDetails.getOne({
      id: inputtedCustomerId,
    })

    if (response.ok) {
      const customer = await response.json()
      if (customer.id) {
        setSelectedCustomer(customer)
        return true
      }
    }
    ToastUtils.toastCustomError("Customer not found")
    return false
  }

  const onSubmit = async () => {
    setLoading(true)
    await getCustomer()
    setLoading(false)
  }

  const addCustomerFeatureFlag = async () => {
    const response = await BackOfficeApi.endpoints.addCustomerFeatureFlag.post({
      customerId: inputtedCustomerId,
      flagKey: selectedFlag,
    })
    if (response.ok) {
      ToastUtils.toastExecuteSuccess2()
    } else {
      Utils.displayError(response)
    }
  }

  const removeCustomerFeatureFlag = async () => {
    const response =
      await BackOfficeApi.endpoints.removeCustomerFeatureFlag.post({
        customerId: inputtedCustomerId,
        flagKey: selectedFlag,
      })
    if (response && response.ok) {
      ToastUtils.toastExecuteSuccess2()
    } else {
      if (response && response.status === 404) {
        ToastUtils.toastCustomError("Customer is missing this feature flag")
      } else {
        Utils.displayError(response)
      }
    }
  }

  const confirmSubmission = async () => {
    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: ${selectedAction} customer feature flag \n
      - Flag: ${selectedFlag}\n
      - User ID: #${inputtedCustomerId}\n
      - Name: ${selectedCustomer.firstName || selectedCustomer.companyName} ${
        selectedCustomer.lastName || ""
      }\n\n\n 
      Please double check the Customer ID before proceeding.`,
    )
    if (confirmed) {
      if (selectedAction === AVAILABLE_ACTIONS[0]) {
        await addCustomerFeatureFlag()
      } else {
        await removeCustomerFeatureFlag()
      }
    }
    reset()
  }

  useEffect(() => {
    if (selectedCustomer) {
      confirmSubmission()
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (featuresFlagData?.length) {
      setSelectedFlag(featuresFlagData[0].flag)
    }
  }, [featuresFlagData])

  const capitalizedAction =
    selectedAction.charAt(0).toUpperCase() +
    selectedAction.slice(1).toLowerCase()

  return (
    <>
      <div className="card-section-header" style={{ marginBottom: 10 }}>
        Add/Remove Customer Feature Flag
      </div>

      {/* Action type selector */}
      <Label for="actionType">Action Type</Label>
      <Input
        type="select"
        name="actionType"
        id="actionType"
        value={selectedAction}
        onChange={handleActionChange}
        style={{ maxWidth: 120, marginBottom: 12 }}
      >
        {AVAILABLE_ACTIONS.map(action => (
          <option key={`${action}-action-option`} value={action}>
            {action}
          </option>
        ))}
      </Input>

      {/* Flag selector */}
      <Label>Flag</Label>
      <Input
        type="select"
        name="actionType"
        id="actionType"
        value={selectedFlag}
        onChange={handleFlagChange}
        style={{ maxWidth: 180, marginBottom: 12 }}
      >
        {featuresFlagData.map(flag => (
          <option key={`${flag.flag}-flag-option`} value={flag.flag}>
            {flag.flag}
          </option>
        ))}
      </Input>

      {/* Customer ID input */}
      <Label>Customer ID</Label>
      <Input
        type="text"
        name="customerId"
        id="customerId"
        placeholder="Enter Customer ID"
        onChange={handleCustomerId}
        value={inputtedCustomerId}
        style={{ maxWidth: 240, marginBottom: 24 }}
      />
      <Button
        color="primary"
        disabled={!inputtedCustomerId || loading}
        onClick={onSubmit}
        loading={loading}
      >
        {capitalizedAction}
      </Button>
    </>
  )
}

export default CustomerFeatureFlagControls
