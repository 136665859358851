import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"
import Utils from "../Common/Utils"

class XmlReport extends Component {
  state = {
    orderOrTransactionIds: null,
    reason: "",
    action: "The customer account is frozen.",
    entityReference: "STR-",
    signatureComment: "",
    reportIndicators: [],
    isTransaction: false,
    type: null,
    isAggregated: false,
  }

  componentDidUpdate(prevProps) {
    if (!this.props.orderOrTransactionIds || !this.props.customerId) {
      return
    }

    const signatureComment =
      "Customer number at Safello: " + this.props.customerId

    if (
      this.props.fastXmlExport &&
      this.props.fastXmlExport !== prevProps.fastXmlExport
    ) {
      this.setState(
        {
          reason: "fast export",
          entityReference: "STR-0",
          reportIndicators: ["GEO04"],
          signatureComment,
          orderOrTransactionIds: this.props.orderOrTransactionIds,
          isAggregated: this.props.type === "AGGREGATED",
          type: this.props.type,
          isTransaction: this.props.isTransaction,
        },
        this.generate,
      )
      return
    }

    if (
      this.props.orderOrTransactionIds !== prevProps.orderOrTransactionIds ||
      prevProps.open !== this.props.open
    ) {
      this.setState({
        orderOrTransactionIds: this.props.orderOrTransactionIds,
        isAggregated: this.props.type === "AGGREGATED",
        type: this.props.type,
        isTransaction: this.props.isTransaction,
        signatureComment,
        reason: "",
        entityReference: "STR-",
        reportIndicators: [],
      })
    }
  }

  onToggle = () => {
    if (this.props.onToggle) {
      this.props.onToggle()
    }
    this.setState({
      xmlText: "",
      reportIndicators: [],
    })
  }

  generate = () => {
    if (this.state.entityReference === "STR-" || !this.state.entityReference) {
      alert("Please fill in the Safello reference!")
      return
    }

    if (!this.state.reason) {
      alert("Please fill in the Reason!")
      return
    }

    if (!this.state.action) {
      alert("Please fill in the action!")
      return
    }

    if (!this.state.signatureComment) {
      alert("Please fill the signature comment!")
      return
    }

    if (this.state.reportIndicators.length === 0) {
      alert("Please choose report indicators!")
      return
    }

    let formData = new FormData()
    formData.append("entityReference", this.state.entityReference)
    formData.append("reason", this.state.reason)
    formData.append("action", this.state.action)
    formData.append("signatoryComments", this.state.signatureComment)
    formData.append("reportIndicators", this.state.reportIndicators.join())

    let outputFileName = "transaction_report.xml"
    if (this.state.isAggregated) {
      let orderIds = []
      let swapOrderIds = []
      let transactionIds = []
      this.state.orderOrTransactionIds.forEach(idValue => {
        const split = idValue.split("-")
        const type = split[0]
        const id = split[1]
        if (type === "BUY" || type === "SELL") {
          orderIds.push(Number(id))
        } else if (type === "SWAP") {
          swapOrderIds.push(id)
        } else {
          transactionIds.push(Number(id))
        }
      })
      formData.append("walletTransactionsIds", transactionIds)
      formData.append("orderIds", orderIds)
      formData.append("swapIds", swapOrderIds)
    } else if (this.state.type === "SWAP") {
      formData.append("swapIds", this.state.orderOrTransactionIds)
      outputFileName = "transaction_report.xml"
    } else if (this.state.isTransaction) {
      formData.append("walletTransactionsIds", this.state.orderOrTransactionIds)
    } else {
      formData.append("orderIds", this.state.orderOrTransactionIds)
      outputFileName = "order_report.xml"
    }
    BackOfficeApi.endpoints.generatePoliceReport
      .create(formData)
      .then(response => {
        if (response.ok) {
          return response.blob()
        }
        throw new Error()
      })
      .then(blob => {
        Utils.downloadFile(blob, outputFileName)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSelectChange = e => {
    const checked = e.target.checked
    const checkValue = e.target.value
    let reportIndicators = this.state.reportIndicators
    const index = reportIndicators.indexOf(checkValue)

    if (checked) {
      if (index === -1) {
        reportIndicators.push(checkValue)
      }
    } else {
      if (index > -1) {
        reportIndicators.splice(index, 1)
      }
    }

    this.setState({
      reportIndicators,
    })
  }

  render() {
    if (!this.props.orderOrTransactionIds) {
      return ""
    }

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.onToggle}
        style={{ maxWidth: "1200px" }}
      >
        <ModalHeader toggle={this.onToggle}>Generate XML report</ModalHeader>
        <ModalBody>
          <Form style={{ width: "93%", margin: "auto" }}>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="entityReference">
                Safello reference
              </Label>
              <Input
                className="col-md-12"
                type="text"
                name="entityReference"
                id="entityReference"
                value={this.state.entityReference}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="reason">
                Reason for reporting
              </Label>
              <textarea
                className="form-control col-md-12"
                rows="3"
                name="reason"
                id="reason"
                value={this.state.reason}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="action">
                Actions performed by Safello
              </Label>
              <Input
                className="col-md-12"
                type="text"
                name="action"
                id="action"
                value={this.state.action}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="signatureComment">
                Comments about the customer
              </Label>
              <Input
                className="col-md-12"
                type="text"
                name="signatureComment"
                id="signatureComment"
                value={this.state.signatureComment}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label">
                <b>Report indicators [obligatory]</b>
              </Label>
              <div className="col-md-10">
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="BMTTF"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    BMTTF - Financing terrorism
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="AMTPT"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    AMTPT - Money laundering
                  </label>
                </div>
              </div>

              <Label className="col-xl-3 col-form-label">
                <b>Distribution channels</b>
              </Label>

              <div className="col-md-10">
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KAN01"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    {`KAN01 - Distribution channels: Business relationships or
                    transactions take place remotely, without the use of methods
                    that can reliably secure the customer's identity
                    (anonymization)`}
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KAN03"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KAN03 - Distribution channels: Other distribution channel
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KAN02"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KAN02 - Distribution channels: Payment of goods or services
                    is made by someone who is unknown or has no connection with
                    the customer
                  </label>
                </div>
              </div>

              <Label className="col-xl-3 col-form-label">
                <b>Geographical areas</b>
              </Label>

              <div className="col-md-10">
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="GEO04"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    GEO04 - Geographical areas: Other geographical area
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="GEO03"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    GEO03 - Geographical areas: Area in which a state finances
                    or supports terrorist activities or where terrorist
                    organizations operate
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="GEO02"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    GEO02 - Geographical areas: Area or state with significant
                    corruption and other relevant crime
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="GEO01"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    GEO01 - Geographical areas: An area or state that does not
                    have effective anti-money laundering or terrorist financing
                    systems
                  </label>
                </div>
              </div>

              <Label className="col-xl-3 col-form-label">
                <b>Information</b>
              </Label>

              <div className="col-md-10">
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="INF06"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    INF06 - Info: Concrete information on probable crime
                    exchange
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="INF05"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    INF05 - Information: Other information
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="INF02"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    INF02 - Information: Concrete information from another
                    operator or source
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="INF04"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    INF04 - Information: Concrete information from law
                    enforcement
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="INF03"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    INF03 - Information: Sanctions, embargoes or similar
                    measures
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="INF01"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    INF01 - Information: Approaches, mode and trends, national
                    risk assessments etc.
                  </label>
                </div>
              </div>

              <Label className="col-xl-3 col-form-label">
                <b>Customer awareness</b>
              </Label>

              <div className="col-md-10">
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KDK07"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KDK07 - Customer awareness: Other about customer
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KDK02"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KDK02 - Customer awareness: Customer is assumed to act on
                    behalf of another
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KDK01"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KDK01 - Customer knowledge: Customer is assumed to submit no
                    / incorrect information
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KDK06"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KDK06 - Customer knowledge: Customer uses product / service
                    or transacts in a different way compared to others in the
                    same risk profile / category
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KDK05"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KDK05 - Customer knowledge: The customer uses a product /
                    service or carries out a transaction in a way that is
                    different for the customer
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KDK04"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    KDK04 - Customer Knowledge: Customer is new or changes its
                    business which has great impact on customer knowledge
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="KDK03"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    {`KDK03 - Customer knowledge: The customer's information
                    appears to be unusual or too complicated for its operations`}
                  </label>
                </div>
              </div>

              <Label className="col-xl-3 col-form-label">
                <b>Products and services and transactions</b>
              </Label>

              <div className="col-md-10">
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="PTT06"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    PTT06 - Products and services and transactions: Other ways
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="PTT04"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    PTT04 - Products and services and transactions: Different
                    number of counterparties
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="PTT02"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    PTT02 - Products and services and transactions: Different
                    turnover
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="PTT03"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    PTT03 - Products and services as well as transactions:
                    Different transaction rates
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="PTT01"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    PTT01 - Products and services and transactions: Change of
                    payment method
                  </label>
                </div>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value="PTT05"
                      onChange={this.handleSelectChange}
                    />
                    <span className="fa fa-check"></span>
                    PTT05 - Products and services and transactions: Transaction
                    can take place on site, refer to certain property or other
                    that can be presumed to be part of a crime
                  </label>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.generate}>
            Generate
          </Button>
          <Button color="secondary" onClick={this.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default XmlReport
