import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import moment from "moment"
import BackOfficeApi from "../Api/BackOfficeApi"

class EditCustomerModal extends Component {
  state = {
    email: "",
    emailActive: false,
    firstName: "",
    lastName: "",
    companyName: "",
    personalNumber: "",
    orgNumber: "",
    address: "",
    zip: "",
    city: "",
    country: "",
    phoneNumber: "",
    level: "",
    dateOfBirth: "",
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.customer !== this.props.customer ||
      prevProps.open !== this.props.open
    ) {
      this.setState({
        email: this.props.customer.email,
        emailActive: this.props.customer.emailActive,
        firstName: this.props.customer.firstName,
        lastName: this.props.customer.lastName,
        companyName: this.props.customer.companyName,
        personalNumber: this.props.customer.personalNumber,
        orgNumber: this.props.customer.orgNumber,
        address: this.props.customer.address,
        zip: this.props.customer.zip,
        city: this.props.customer.city,
        country:
          this.props.customer.addressCountry || this.props.customer.country,
        phoneNumber: this.props.customer.phoneNumber,
        level: this.props.customer.level,
        dateOfBirth: moment
          .utc(this.props.customer.dateOfBirth)
          .local()
          .format("YYYY-MM-DD"),
      })
    }
  }

  getValue = value => {
    return value == null ? "" : value
  }

  onEditCustomer = () => {
    let formData = new FormData()
    formData.append("email", this.getValue(this.state.email))
    formData.append("emailActive", this.getValue(this.state.emailActive))
    formData.append("address", this.getValue(this.state.address))
    formData.append("zip", this.getValue(this.state.zip))
    formData.append("city", this.getValue(this.state.city))
    formData.append(
      "country",
      this.getValue(
        this.props.countries.find(c => {
          return c.name === this.state.country
        }).id,
      ),
    )
    formData.append("phoneNumber", this.getValue(this.state.phoneNumber))
    formData.append("level", this.getValue(this.state.level))

    if (this.props.customer.type === "Private") {
      formData.append("firstName", this.getValue(this.state.firstName))
      formData.append("lastName", this.getValue(this.state.lastName))
      formData.append(
        "personalNumber",
        this.getValue(this.state.personalNumber),
      )
      formData.append("dateOfBirth", this.getValue(this.state.dateOfBirth))
    } else {
      formData.append("companyName", this.getValue(this.state.companyName))
      formData.append("orgNumber", this.getValue(this.state.orgNumber))
    }

    BackOfficeApi.endpoints.editCustomer
      .create(formData, { id: this.props.customer.id })
      .then(response => {
        this.props.toggle()
        if (response.ok) {
          this.props.save()
        } else {
          response.json().then(json => {
            this.props.error(json.message || json.error_code)
          })
        }
      })
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onCheckboxChange = e => {
    this.setState({
      [e.target.name]: e.target.checked,
    })
  }

  renderCountries() {
    if (this.props.countries) {
      let cs = []
      this.props.countries.forEach(c => {
        cs.push(
          <option key={c.id} value={c.name}>
            {c.name}
          </option>,
        )
      })
      return cs
    }

    return null
  }

  render() {
    if (!this.props.customer) {
      return ""
    }
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.props.toggle}>Edit Customer</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="email">
                Email
              </Label>
              <Input
                className="col-xl-8"
                type="email"
                name="email"
                id="email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="level">
                Level
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.level}
                  name="level"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  <option key="1" value="1">
                    1
                  </option>
                  <option key="2" value="2">
                    2
                  </option>
                  <option key="3" value="3">
                    3
                  </option>
                </select>
              </div>
            </FormGroup>
            <FormGroup className="checkbox c-checkbox">
              <Label className="col-xl-3" style={{ paddingLeft: 0 }}>
                Email active
              </Label>
              <Label className="mb-1">
                <Input
                  type="checkbox"
                  name="emailActive"
                  id="emailActive"
                  checked={this.state.emailActive}
                  onChange={this.onCheckboxChange}
                />
                <span className="fa fa-check"></span>
              </Label>
            </FormGroup>

            {this.props.customer.type === "Private" ? (
              <div>
                <FormGroup row>
                  <Label className="col-xl-3 col-form-label" for="firstName">
                    First Name
                  </Label>
                  <Input
                    className="col-xl-8"
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={this.state.firstName}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup row>
                  <Label className="col-xl-3 col-form-label" for="lastName">
                    Last Name
                  </Label>
                  <Input
                    className="col-xl-8"
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={this.state.lastName}
                    onChange={this.onChange}
                  />
                </FormGroup>
              </div>
            ) : (
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="companyName">
                  Company Name
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="companyName"
                  id="companyName"
                  value={this.state.companyName}
                  onChange={this.onChange}
                />
              </FormGroup>
            )}
            {this.props.customer.type === "Private" ? (
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="personalNumber">
                  Personal Number
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="personalNumber"
                  id="personalNumber"
                  value={this.state.personalNumber}
                  disabled
                />
              </FormGroup>
            ) : (
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="orgNumber">
                  Org Number
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="orgNumber"
                  id="orgNumber"
                  value={this.state.orgNumber}
                  onChange={this.onChange}
                />
              </FormGroup>
            )}
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="address">
                Address
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="address"
                id="address"
                value={this.state.address}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="zip">
                Zip
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="zip"
                id="zip"
                value={this.state.zip}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="city">
                City
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="city"
                id="city"
                value={this.state.city}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="phoneNumber">
                Phone Number
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={this.state.phoneNumber}
                onChange={this.onChange}
              />
            </FormGroup>
            {this.props.customer.type === "Private" && (
              <FormGroup row>
                <Label className="col-xl-3 col-form-label" for="dateOfBirth">
                  Date Of Birth
                </Label>
                <Input
                  className="col-xl-8"
                  type="text"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  value={this.state.dateOfBirth}
                  onChange={this.onChange}
                />
              </FormGroup>
            )}
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="country">
                Country
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.country}
                  name="country"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  {this.renderCountries()}
                </select>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onEditCustomer}>
            Save
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default EditCustomerModal
