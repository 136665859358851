import React, { Component } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

class BackOfficeStackedBarChart extends Component {
  render() {
    let optionalProps = {}
    if (this.props.unit) {
      optionalProps.unit = " " + this.props.unit
    }

    let data = null
    if (this.props.data) {
      data = this.props.data.slice()
    }

    return (
      <div
        className="card-body"
        style={{
          width: "100%",
          height: "500px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        {data && (
          <ResponsiveContainer>
            <BarChart
              data={data}
              margin={{ top: 10, right: 22, left: 10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Bar
                dataKey={this.props.dataKey1}
                stackId="a"
                fill="#56BAA5"
                unit={optionalProps.unit}
              />
              <Bar
                dataKey={this.props.dataKey2}
                stackId="a"
                fill="#29315E"
                unit={optionalProps.unit}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    )
  }
}

BackOfficeStackedBarChart.defaultProps = {
  data: [],
}

export default BackOfficeStackedBarChart
