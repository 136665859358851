import React, { useEffect, useState } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row, Col } from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import TransactionInfoArea from "./TransactionInfoArea"
import CommentArea from "../../Common/CommentArea"
import ActionsArea from "./ActionsArea"
import XmlReport from "../../Orders/XmlReport"

const TransactionDetail = ({ location }) => {
  const [isLoading, setLoading] = useState(false)
  const [transaction, setTransaction] = useState(null)
  const [activeModal, setActiveModal] = useState("")
  const [commentReloadTrigger, setCommentReloadTrigger] = useState(0)

  useEffect(() => {
    getTransaction()
  }, [])

  const transactionId = Utils.getLastUrlSegment(location)

  const getTransaction = () => {
    setLoading(true)
    BackOfficeApi.endpoints.walletTransactionDetails
      .getOne({ id: transactionId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        setLoading(false)
        setTransaction(data)
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setLoading(false)
      })
  }

  const handleTx = (action, comment) => {
    setLoading(true)
    let formData = new FormData()
    formData.append("action", action)
    formData.append("comment", comment)

    BackOfficeApi.endpoints.walletHandleTransaction
      .create(formData, { id: transactionId })
      .then(response => {
        if (response.ok) {
          setLoading(false)
          getTransaction()
          ToastUtils.toastExecuteSuccess2()
          setCommentReloadTrigger(commentReloadTrigger + 1)
          return
        }
        throw Error()
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setLoading(false)
      })
  }

  const banlistAddress = () => {
    setLoading(true)
    let formData = new FormData()
    formData.append("ids", transactionId)

    BackOfficeApi.endpoints.banlistWalletTransactionsAddress
      .create(formData)
      .then(response => {
        if (response.ok) {
          setLoading(false)
          getTransaction()
          return
        }
        throw Error()
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
        setLoading(false)
      })
  }

  const bumpTransaction = () => {
    setLoading(true)

    BackOfficeApi.endpoints.bumpWalletTransaction
      .post({ id: transactionId })
      .then(response => {
        if (response?.ok) {
          setLoading(false)
          getTransaction()
          ToastUtils.toastExecuteSuccess2()
          return
        } else {
          Utils.displayError(response)
          setLoading(false)
        }
      })
  }

  const onActionPerformed = (action, data) => {
    switch (action) {
      case "tx_process":
        handleTx("PROCESS", data?.comment)
        break
      case "tx_reject":
        handleTx("REJECT", data?.comment)
        break
      case "report_xml":
        setActiveModal("report_xml")
        break
      case "banlist_address":
        banlistAddress()
        break
      case "bump_transaction":
        bumpTransaction()
        break
      default:
        break
    }
  }

  const toggleModal = modal => {
    if (activeModal === modal) {
      setActiveModal("")
    } else {
      setActiveModal(modal)
    }
  }
  return (
    <ContentWrapper>
      <Row>
        <Col lg={8}>
          <TransactionInfoArea
            transaction={transaction}
            type={transaction?.type}
            isLoading={isLoading}
            getTransaction={getTransaction}
          />
        </Col>
        <Col lg={4}>
          <ActionsArea
            transaction={transaction}
            isLoading={isLoading}
            onActionPerformed={onActionPerformed}
          />
          <CommentArea
            id={transactionId}
            type="WALLET_TRANSACTION"
            reloadTrigger={commentReloadTrigger}
          />
        </Col>
      </Row>
      {transaction && (
        <XmlReport
          customerId={transaction.customerId}
          orderOrTransactionIds={transaction.id}
          type={transaction?.type}
          isTransaction
          open={activeModal === "report_xml"}
          onToggle={() => toggleModal("report_xml")}
        />
      )}
    </ContentWrapper>
  )
}

export default TransactionDetail
