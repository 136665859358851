import React, { Component } from "react"
import { Button, Card, CardBody } from "reactstrap"
import Utils from "../Common/Utils"

class PartnerActionsArea extends Component {
  getLoadingClass = () => {
    if (!this.props.isLoading) return ""
    return "whirl standard"
  }

  getButtonList = () => {
    let buttons = []
    if (!this.props.partner) return buttons

    buttons.push({
      title: "Edit partner",
      background: "#e78435",
      onClick: this.onEditClicked,
    })

    buttons.push({
      title: "Add new app api",
      background: "#7996e7",
      onClick: this.onAddNewAppApi,
    })

    if (this.props.partner.active) {
      buttons.push({
        title: "Deactivate partner",
        background: "#e05c57",
        onClick: this.onDeactivateClicked,
      })
    } else {
      buttons.push({
        title: "Activate partner",
        background: "#e05c57",
        onClick: this.onActivateClicked,
      })
    }

    return buttons
  }

  onActivateClicked = () => {
    Utils.showSweetAlertAreYouSure("Activate partner?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("partner_activate")
        }
      },
    )
  }

  onDeactivateClicked = () => {
    Utils.showSweetAlertAreYouSure("Deactivate partner?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("partner_deactivate")
        }
      },
    )
  }

  onAddNewAppApi = () => {
    this.forwardAction("partner_add_new_app_api")
  }

  onEditClicked = () => {
    this.forwardAction("partner_edit")
  }

  forwardAction = action => {
    if (this.props.onActionPerformed) {
      this.props.onActionPerformed(action)
    }
  }

  render() {
    let buttons = this.getButtonList()

    return (
      <div>
        <div>
          <span className="header-area">Actions</span>
          <Card className="card-area">
            <CardBody
              className={this.getLoadingClass()}
              style={
                this.props.isLoading && buttons.length === 0
                  ? { minHeight: "100px" }
                  : {}
              }
            >
              <div style={{ textAlign: "center" }}>
                {buttons.map((item, i) => {
                  let className = "action_button"
                  if (i + 1 < buttons.length) className += " mb-3"
                  return (
                    <Button
                      key={i}
                      className={className}
                      style={{ background: item.background }}
                      onClick={item.onClick}
                    >
                      <img alt="" src={item.icon} />
                      {item.title}
                    </Button>
                  )
                })}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}
export default PartnerActionsArea
