import React, { Component } from "react"
import CheckValid from "../Media/Svg/check_valid.svg"
import CheckInvalid from "../Media/Svg/check_invalid.svg"
import Freeze from "../Media/Svg/freeze.svg"

class VerificationImage extends Component {
  render() {
    let color = "#e8fcd9"
    let image = CheckValid
    if (this.props.verificationLevel === -1) {
      color = "#fde8e7"
      image = CheckInvalid
    } else if (this.props.verificationLevel === 0) {
      color = "#ffc769"
      image = Freeze
    }

    return (
      <div
        className="validation-image"
        style={{
          backgroundColor: color,
          ...this.props.style,
        }}
      >
        <img
          alt=""
          src={image}
          style={{
            width: this.props.checkWidth,
            height: this.props.checkHeight,
          }}
        />
      </div>
    )
  }
}

VerificationImage.defaultProps = {
  verificationLevel: 0,
  checkWidth: "10px",
  checkHeight: "8px",
}

export default VerificationImage
