import { Component } from "react"
import React from "react"
import { APP_COLORS } from "./constants"

class RiskProfileBadge extends Component {
  render() {
    if (!this.props.customer) {
      return <div></div>
    }

    let color = APP_COLORS.risk_low
    let text = "Low"
    if (
      this.props.customer.frozen &&
      !this.props.customer.gdprRemovalRequestDate
    ) {
      color = APP_COLORS.risk_extreme
      text = "Extreme"
    } else if (this.props.customer.riskProfile === 2) {
      color = APP_COLORS.risk_medium
      text = "Medium"
    } else if (this.props.customer.riskProfile === 3) {
      color = APP_COLORS.risk_high
      text = "High"
    }

    return (
      <div
        className="customer-risk-profile-container"
        style={{ display: "inline-block", backgroundColor: color }}
      >
        <span className="customer-risk-profile-text">{text}</span>
      </div>
    )
  }
}

export default RiskProfileBadge
