import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import { Container, Table, Badge } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

class Permissions extends Component {
  state = {
    isLoading: true,
    permissions: [],
  }

  async componentDidMount() {
    try {
      const [ users, permissions ] = await Promise.all([
        (async () => {
          const response = await BackOfficeApi.endpoints.adminUsers.getAll()
          const data = await response.json()
          const usersPromises = data.map(async user => {
            const response = await BackOfficeApi.endpoints.getUser.getOne({
              id: user.id,
            })
            const user_details = await response.json()
            user.permissions = user_details.permissions
            return user
          })
          const users = await Promise.all(usersPromises)
          return users
        })(),
        (async () => {
          const response = await BackOfficeApi.endpoints.getAllPermissions.getAll()
          const data = await response.json()
          return data
        })()])

      const permissionsData = permissions.map(permission => {
        return {
          id: permission,
          users: users.filter(user => user.permissions.includes(permission))
        }
      })

      this.setState({
        isLoading: false,
        permissions: permissionsData,
      })
    } catch (error) {
      console.log(error)
      ToastUtils.toastAPIError2()
    }
  }
  render() {
    let content
    if (this.state.isLoading == false) {
      content = this.state.permissions.map(row => {
        let users = row.users.map(user => {
          return (
            <Badge
              key={user.id}
              color={user.active ? "primary" : "secondary"}
              href={"/admin/users/" + user.id}
            >
              {user.username}
            </Badge>
          )
        })
        return (
          <tr key={row.id}>
            <td>{row.id}</td>
            <td>{users}</td>
          </tr>
        )
      })
    }
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Permissions</div>
        </div>
        <Container fluid>
          <Table striped borderless size="sm">
            <thead>
              <tr>
                <th style={{ width: 150 }}>Permission</th>
                <th>Users</th>
              </tr>
            </thead>
            <tbody>{content}</tbody>
          </Table>
        </Container>
      </ContentWrapper>
    )
  }
}

export default Permissions
