import React, { useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

const CommentBeforeActionModal = ({ actionName, isOpen, onClose, onDone }) => {
  const [comment, setComment] = useState("")

  const onCommentChange = e => {
    setComment(e.target.value)
  }

  const onProceed = async () => {
    if (comment.length < 10) {
      alert(
        "Your comment seems too short, describe the reason in more details.",
      )
      setComment("")
      return
    }
    onDone(comment)
  }

  return (
    <Modal isOpen={isOpen} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onClose}>{actionName}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Label className="col-xl-3 col-form-label" for="comment">
              Comment
            </Label>
            <textarea
              className="form-control col-xl-8"
              rows="3"
              type="text"
              name="comment"
              id="comment"
              value={comment}
              onChange={onCommentChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onProceed} disabled={!comment}>
          {actionName}
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default CommentBeforeActionModal
