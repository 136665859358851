import React, { Component } from "react"
import ChartUtils from "../Common/ChartUtils"
import {
  Line,
  LineChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import ChartPerspectives from "./ChartPerspectives"
import SliceToggle from "./SliceToggle"

class BackOfficeLineChart extends Component {
  state = {
    sliced: true,
  }

  render() {
    let optionalProps = {}
    if (this.props.unit) {
      optionalProps.unit = " " + this.props.unit
    }

    let showData = null
    if (this.props.lineData) {
      let lineData = this.props.lineData
      showData = lineData.slice()
      if (this.state.sliced) {
        showData.splice(-1, 1)
      }
    }

    return (
      <div className="card">
        <div className="card-header">
          {this.props.title && (
            <div className="float-left">
              <span className="chart-tooltip">
                <h4>
                  {this.props.icon && (
                    <i className={`fa fa-fw fa-${this.props.icon}`} />
                  )}
                  {this.props.title}
                </h4>
                {this.props.tooltip && (
                  <span className="chart-tooltip-text">
                    {this.props.tooltip}
                  </span>
                )}
              </span>
            </div>
          )}
          <ChartPerspectives
            onChangePerspective={this.props.onPerspectiveChange}
            perspectives={this.props.perspectives}
          />
          {this.props.controls}
          <SliceToggle
            onChange={newValue => this.setState({ sliced: newValue })}
            value={this.state.sliced}
          />
        </div>
        <div
          className="card-body"
          style={{
            width: "100%",
            height: "500px",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          {showData && (
            <ResponsiveContainer>
              <LineChart
                data={showData}
                margin={{ top: 10, right: 22, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="xKey" interval={this.props.xInterval} />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                {this.props.lines.map((item, i) => {
                  return (
                    <Line
                      key={i}
                      type="monotone"
                      dataKey={item.name}
                      stroke={ChartUtils.getChartLineColor(i)}
                      fill={ChartUtils.getChartLineColor(i)}
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                      name={this.props.lineLabel || item.name}
                      isAnimationActive={false}
                      {...optionalProps}
                    />
                  )
                })}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    )
  }
}

BackOfficeLineChart.defaultProps = {
  lines: [],
  title: "",
  icon: "",
  tooltip: "",
  controls: null,
  onPerspectiveChange: () => {},
}

export default BackOfficeLineChart
