import React, { Component } from "react"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

class Cards extends Component {
  state = {
    cardData: [],
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.statsFinancialCards
      .getAll(Utils.getFirstAndCurrentDay())
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          cardData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    const d = new Date()
    const month = " (" + monthNames[d.getMonth()] + ".)"

    if (this.state.cardData && this.state.cardData.length > 0) {
      return this.state.cardData.map((card, i) => {
        let title
        let icon = "fa-2x fas fa-"
        if (card.name === "financial_net_revenue") {
          title = "Net Revenue" + month
          icon += "piggy-bank"
        } else if (card.name === "financial_net_revenue_projection") {
          title = "Net Revenue projection" + month
          icon += "chart-line"
        } else if (card.name === "financial_net_revenue_total") {
          title = "Total Net Revenue"
          icon += "coins"
        } else if (card.name === "financial_turnover_total") {
          title = "Total Turnover"
          icon += "exchange-alt"
        } else if (card.name === "financial_revenue") {
          title = "Revenue" + month
          icon += "piggy-bank"
        } else if (card.name === "financial_revenue_projection") {
          title = "Revenue projection" + month
          icon += "chart-line"
        } else if (card.name === "financial_revenue_total") {
          title = "Total Revenue"
          icon += "coins"
        }
        return (
          <BackOfficeCard
            icon={icon}
            title={title}
            value={Utils.formatNumber(card.value)}
            unit={card.unit}
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    } else {
      let placeholders = [0, 1, 2, 3]
      return placeholders.map((placeHolder, i) => {
        return (
          <BackOfficeCard
            icon=" "
            title="-"
            value="-"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    }
  }
}

export default Cards
