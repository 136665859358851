import React, { Component } from "react"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap"
import Utils from "../Common/Utils"

class CustomerLimitsSection extends Component {
  render() {
    return (
      <Card>
        <CardHeader className="card-section-header">Buy limits</CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col>
              {this.props.limits && this.props.channelLimits && (
                <Table striped bordered hover responsive={true}>
                  <thead>
                    <tr>
                      <th>Min</th>
                      <th>Max</th>
                      <th>Weekly</th>
                      <th>Yearly</th>
                      <th>Buy remaining</th>
                      <th>Sell remaining</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {Utils.formatValueToSek(this.props.channelLimits.min)}
                      </td>
                      <td>
                        {Utils.formatValueToSek(this.props.channelLimits.max)}
                      </td>
                      <td>
                        {Utils.formatValueToSek(
                          this.props.channelLimits.weekly,
                        )}
                      </td>
                      <td>
                        {Utils.formatValueToSek(
                          this.props.channelLimits.yearly,
                        )}
                      </td>
                      <td>
                        {Utils.formatValueToSek(this.props.limits.buyLimit)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}
export default CustomerLimitsSection
