import React, { Component } from "react"
import { Col } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import ChartUtils from "../../Common/ChartUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ChartPerspectives from "../../Chart/ChartPerspectives"
import BackOfficeAreaPercentageChart from "../../Chart/BackOfficeAreaPercentageChart"

class OrdersByCountryChart extends Component {
  state = {}

  componentDidMount() {
    this.changePerspective(ChartUtils.getDefaultPerspective())
  }

  changePerspective = perspective => {
    BackOfficeApi.endpoints.statsMetricsOrdersByCountry
      .getAll({ perspective: perspective })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        data.lineData.map(
          dataNode =>
            (dataNode.xKey = ChartUtils.getPerspectiveXFormat(
              dataNode.xKey,
              perspective,
            )),
        )

        // Always put sweden line last for the sake of layout
        let swedenLineIndex = data.lineInfo.map(o => o.id).indexOf("1")
        let lastIndex = data.lineInfo.length - 1
        if (swedenLineIndex > -1 && swedenLineIndex !== lastIndex) {
          let swedenLine = data.lineInfo[swedenLineIndex]
          data.lineInfo[swedenLineIndex] = data.lineInfo[lastIndex]
          data.lineInfo[lastIndex] = swedenLine
        }

        this.setState({
          lines: data.lineInfo,
          lineData: data.lineData,
          xInterval: ChartUtils.getPerspectiveXInterval(perspective),
          perspective: perspective,
          unit: data.unit,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    return (
      <Col xl={12}>
        <div className="card">
          <div className="card-header">
            <div className="float-left">
              <span>Orders by country</span>
            </div>
            <ChartPerspectives
              onChangePerspective={perspective =>
                this.changePerspective(perspective)
              }
            />
          </div>
          <BackOfficeAreaPercentageChart
            lines={this.state.lines}
            lineData={this.state.lineData}
            xInterval={this.state.xInterval}
            stack={true}
            unit={this.state.unit}
          />
        </div>
      </Col>
    )
  }
}

export default OrdersByCountryChart
