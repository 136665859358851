import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import { Container } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import { Link } from "react-router-dom"
import DataGridEmpty from "../Common/DataGridEmpty"
import BackOfficeApi from "../Api/BackOfficeApi"
import Badge from "../Common/Badge"
import SearchBar from "../Common/SearchBar"
import { APP_COLORS } from "../Common/constants"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"
import DropdownList from "../Common/DropdownList"
import Premium from "../Media/Svg/premium.svg"
import Business from "../Media/Svg/business.svg"
import RiskProfileBadge from "../Common/RiskProfileBadge"

class Customers extends Component {
  state = {
    sortStatus: "",
    type: "",
    tag: "",
    tags: [],
    rows: [],
    searchText: "",
  }
  constructor(props, context) {
    super(props, context)

    this.fetchSize = 30

    this._columns = [
      {
        key: "id",
        name: "ID",
        width: 60,
        formatter: this.IdFormatter,
      },
      {
        key: "name",
        name: "Name",
        width: 300,
        formatter: this.NameFormatter,
      },
      {
        key: "country",
        name: "Country",
        width: 100,
      },
      {
        key: "type",
        name: "Type",
        width: 100,
      },
      {
        key: "level",
        name: "Level",
        width: 50,
      },
      {
        key: "riskProfile",
        name: "Risk profile",
        formatter: this.RiskProfileFormatter,
        width: 100,
      },
      {
        key: "created",
        name: "Created",
        formatter: DataGridLocalDateTimeFormatter,
        width: 150,
      },
      {
        key: "status",
        name: "Status",
        formatter: this.StatusFormatter,
        width: 100,
      },
      {
        key: "tags",
        name: "Tags",
        formatter: this.TagsFormatter,
      },
    ]

    this.rowOffsetHeight = 0

    this.searching = false
    this.noMoreData = false
  }

  changeSortStatus = item => {
    this.setState({
      sortStatus: item.value,
      searchText: "",
    })

    this.searching = false

    this.setQuery("status", item.value)
  }

  changeType = item => {
    this.setState({
      type: item.value,
      searchText: "",
    })

    this.searching = false

    this.setQuery("type", item.value)
  }

  changeTag = item => {
    this.setState({
      tag: item.value,
      searchText: "",
    })

    this.searching = false

    this.setQuery("tag", item.value)
  }

  getQuery = (key, defaultValue) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    return searchParams.get(key) || defaultValue
  }

  setQuery = (key, value) => {
    const searchParams = new URLSearchParams(this.props.location.search)
    searchParams.set(key, value)
    const url = searchParams.toString()
    this.props.history.push(`?${url}`)
  }

  loadQueryParameters = () => {
    const savedStatus = this.getQuery("status", "all")
    const savedType = this.getQuery("type", "all")
    const savedTag = this.getQuery("tag", "any")

    this.setState({
      sortStatus: savedStatus,
      type: savedType,
      tag: savedTag,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.sortStatus !== prevState.sortStatus ||
      this.state.type !== prevState.type ||
      this.state.tag !== prevState.tag
    ) {
      this.loadData()
    } else if (this.props.location.search !== prevProps.location.search) {
      this.loadQueryParameters()
    }
  }

  componentDidMount() {
    this.loadQueryParameters()
  }

  loadData = (first = 0, append = false) => {
    this.setState({
      isLoading: true,
    })

    if (this.state.tags.length === 0) {
      BackOfficeApi.endpoints.getTags
        .getAll({
          type: "CUSTOMER",
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.setState({
            tags: data,
          })
        })
    }

    if (this.searching) {
      this.onSearch(first, append)
      return
    }

    BackOfficeApi.endpoints.customers
      .getAll({
        first: first,
        limit: this.fetchSize,
        status: this.state.sortStatus,
        type: this.state.type,
        tag: this.state.tag,
      })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.addData(data, append)
      })
      .catch(() => {
        this.setState({
          error: "Failed to retrieve data",
        })
      })
  }

  rowGetter = i => {
    const row = this.state.rows[i]
    if (row) {
      row.status = row.frozen
      if (row.companyName) {
        row.name = row.companyName
      } else {
        row.name = row.firstName + " " + row.lastName
      }
    }

    return row
  }

  onScroll = e => {
    let halfWayVScroll = this.state.rows.length * this.rowOffsetHeight - 700
    let currentVScroll = e.scrollTop

    if (
      currentVScroll >= halfWayVScroll &&
      !this.state.isLoading &&
      !this.noMoreData
    ) {
      if (this.state.rows.length < 1000) {
        this.loadData(this.state.rows.length, true)
      }
    }
  }

  onSearchChange = e => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout)
    }

    this.setState({
      searchText: e.target.value,
      isLoading: true,
      searchTimeout: setTimeout(() => {
        this.onSearch()
      }, 500),
    })
  }

  onSearch = (first = 0, append = false) => {
    if (this.state.searchText.length > 0) {
      this.searching = true

      BackOfficeApi.endpoints.searchCustomers
        .getAll({
          search: this.state.searchText,
          first: first,
          limit: this.fetchSize,
        })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          this.addData(data, append)
        })
        .catch(() => {
          this.setState({
            error: "Failed to retrieve data",
          })
        })
    } else {
      this.searching = false
      this.loadData()
    }
  }

  addData = (data, append) => {
    if (append) {
      if (data.length < this.fetchSize) {
        this.noMoreData = true
      }
      this.setState({
        originalRows: data,
        rows: this.state.rows.concat(data.slice(0)),
        isLoading: false,
      })
    } else {
      this.setState({
        originalRows: data,
        rows: data.slice(0),
        isLoading: false,
      })
      this.noMoreData = false
    }
  }

  getTags = () => {
    let tags = []
    tags.push({ text: "Any", value: "any" })
    if (this.state.tags) {
      this.state.tags.forEach(t => {
        tags.push({ text: t.name, value: t.name })
      })
    }
    return tags
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Customers</div>
          <div className="ml-auto position-relative">
            <SearchBar
              value={this.state.searchText}
              onChange={this.onSearchChange}
            ></SearchBar>
            <div
              className="ball-beat loader-position"
              hidden={!this.state.isLoading}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="ml-auto form-inline">
            <DropdownList
              className="mr-2"
              label="Tag"
              buttonColor="primary"
              value={this.state.tag}
              onSelectItem={this.changeTag}
            >
              {this.getTags()}
            </DropdownList>
            <DropdownList
              className="mr-2"
              label="Type"
              buttonColor="primary"
              value={this.state.type}
              onSelectItem={this.changeType}
            >
              {[
                { value: "all", text: "All" },
                { value: "premium", text: "Premium" },
                { value: "private", text: "Private" },
                { value: "corporate", text: "Corporate" },
              ]}
            </DropdownList>
            <DropdownList
              label="Status"
              buttonColor="primary"
              value={this.state.sortStatus}
              onSelectItem={this.changeSortStatus}
            >
              {[
                { value: "all", text: "All" },
                { value: "riskMedium", text: "Medium risk" },
                { value: "riskHigh", text: "High risk" },
                { value: "frozen", text: "Frozen" },
                { value: "gdpr", text: "GDPR removal" },
              ]}
            </DropdownList>
          </div>
        </div>

        <Container fluid>
          <ReactDataGrid
            columns={this._columns}
            rowGetter={this.rowGetter}
            rowsCount={this.state.rows.length}
            minHeight={700}
            emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
            minColumnWidth={200}
            rowHeight={40}
            ref={element => {
              if (element !== null) {
                let base = element.base
                base.onScroll = this.onScroll
                this.rowOffsetHeight = element.getRowOffsetHeight()
              }
            }}
          />
        </Container>
      </ContentWrapper>
    )
  }

  IdFormatter = props => {
    return <Link to={"/customers/" + props.value}>{props.value}</Link>
  }

  NameFormatter = props => {
    if (props.row.type === "Corporate") {
      return (
        <b style={{ color: "#2555B8" }}>
          {props.row.name} <img alt="" src={Business} />
        </b>
      )
    }
    if (props.row.level >= 2) {
      return (
        <b style={{ color: "#174943" }}>
          {props.row.name} <img alt="" src={Premium} />
        </b>
      )
    }
    return props.row.name
  }

  RiskProfileFormatter = props => {
    return <RiskProfileBadge customer={props.row} />
  }

  StatusFormatter = props => {
    if (props.value) {
      return <Badge color={APP_COLORS.status_frozen}>Frozen</Badge>
    }

    return ""
  }

  TagsFormatter = props => {
    let tags = props.value
    let badges = []

    tags.forEach(t => {
      badges.push(
        <div
          className="tag-badge"
          key={t.id}
          style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
        >
          {t.name}
        </div>,
      )
    })

    return badges
  }
}

export default Customers
