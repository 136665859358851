import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import Utils from "../Common/Utils"

class AdminFeeModal extends Component {
  state = {
    wallet: null,
    crypto: "BTC",
    amount: 0,
    comment: "Administration fee",
  }

  componentDidUpdate(prevProps) {
    if (this.props.wallets !== prevProps.wallets) {
      if (this.props.wallets[0]) {
        this.setState({
          wallet: this.props.wallets[0].id,
        })
      }
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  onExecute = () => {
    let data = {}
    data.wallet = this.state.wallet
    data.crypto = this.state.crypto
    data.amount = this.state.amount
    data.comment = this.state.comment

    if (this.props.onExecute) {
      this.props.onExecute(data)
    }
  }

  renderWallets = () => {
    let options = []

    this.props.wallets.forEach(w => {
      options.push(
        <option key={w.id} value={w.id}>
          {w.id} ({w.name ? w.name : "unnamed"})
        </option>,
      )
    })

    return options
  }

  renderCryptos = () => {
    let options = []

    let wallet = this.getSelectedWallet()

    if (wallet) {
      let balances = Object.entries(wallet.balances)

      balances.forEach((b, i) => {
        options.push(
          <option key={i} value={b[0]}>
            {b[0]}
          </option>,
        )
      })
    }

    return options
  }

  renderWalletBalance = () => {
    let wallet = this.getSelectedWallet()

    if (wallet) {
      let balances = Object.entries(wallet.balances)
      let b = balances.find(b => {
        return this.state.crypto === b[0]
      })
      if (b) return Utils.formatCrypto(b[1])
    }

    return 0
  }

  getSelectedWallet = () => {
    return this.props.wallets.find(w => {
      return w.id === parseInt(this.state.wallet, 10)
    })
  }

  render() {
    if (!this.props.wallets || !this.state.wallet) {
      return ""
    }

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.props.toggle}>Admin fee transfer</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="wallet">
                Wallet
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.wallet}
                  name="wallet"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  {this.renderWallets()}
                </select>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="crypto">
                Crypto
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.crypto}
                  name="crypto"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  {this.renderCryptos()}
                </select>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="crypto">
                Balance
              </Label>
              <div className="col-xl-0">{this.renderWalletBalance()}</div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="amount">
                Amount
              </Label>
              <Input
                className="col-xl-8"
                type="number"
                name="amount"
                id="amount"
                value={this.state.amount}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="comment">
                Comment
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="comment"
                id="comment"
                value={this.state.comment}
                onChange={this.onChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="mr-auto" onClick={this.onExecute}>
            Execute
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default AdminFeeModal
