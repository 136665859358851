import React, { Component } from "react"
import { Button, Row, Col, CardHeader, CardBody } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import ChainalysisModal from "./ChainalysisModal"
import ChainalysisTransactionExposureModal from "./ChainalysisTransactionExposureModal"

class ChainalysisSection extends Component {
  state = {
    isLoading: true,
    chainalysisData: null,
    chainalysisUserKyt: null,
    chainalysisModal: false,
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.data !== prevProps.data ||
      (this.props.justUser && this.props.user !== prevProps.user)
    )
      this.getData()
  }

  componentDidMount() {
    if (this.props.data) {
      this.getData()
    }
  }

  getData = () => {
    if (this.props.isWalletTx) {
      this.getChainalysisTx()
      this.getChainalysisWalletTransactionExposure()
    } else if (!this.props.justUser) {
      this.getChainalysisOrder()
    }
    this.getChanalysisUserKyt()
  }

  toggleChainalysisModal = () => {
    this.setState({
      chainalysisModal: !this.state.chainalysisModal,
    })
  }

  toggleChainalysisTransactionModal = () => {
    this.setState({
      chainalysisTransactionModal: !this.state.chainalysisTransactionModal,
    })
  }

  getChanalysisUserKyt() {
    BackOfficeApi.endpoints.chainalysisUserKyt
      .getAll({ user: this.props.user })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          chainalysisUserKyt: data,
        })
      })
  }

  getChainalysisOrder() {
    BackOfficeApi.endpoints.chainalysisOrder
      .getOne({ id: this.props.data.orderId })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          chainalysisData: data,
        })
      })
      .catch(_ => {})
  }

  getChainalysisTx() {
    BackOfficeApi.endpoints.chainalysisTransaction
      .getOne({ id: this.props.data.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          chainalysisData: data,
        })
      })
      .catch(_ => {})
  }

  getChainalysisWalletTransactionExposure() {
    BackOfficeApi.endpoints.chainalysisWalletTransactionExposure
      .getOne(null, { walletTransactionId: this.props.data.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
      })
      .then(data => {
        this.setState({
          chainalysisWalletTransactionExposure: data,
        })
      })
      .catch(_ => {})
  }

  renderChainalysisUser() {
    if (this.state.chainalysisUserKyt) {
      let data = this.state.chainalysisUserKyt
      let rows = data.exposureDetails
      if (data.score) {
        return (
          <span>
            <span style={{ color: this.getScoreColor(data.score) }}>
              ⬤ {data.score}{" "}
            </span>
            {rows.length ? (
              <Button onClick={this.toggleChainalysisModal}>
                Show details
              </Button>
            ) : (
              " (no data)"
            )}
          </span>
        )
      } else {
        return (
          <span style={{ color: this.getScoreColor("green") }}>⬤ green</span>
        )
      }
    }

    return "Missing data"
  }

  renderChainalysisData() {
    if (this.state.chainalysisData) {
      const data = this.state.chainalysisData
      const category = data.category || data.clusterCategory || "unknown"
      const name = data.name || data.clusterName || ""
      const color = data.score || this.getRatingColor(data.rating) || ""

      return (
        <span>
          <span style={{ color: this.getScoreColor(color) }}>
            ⬤ {category} {name}
          </span>
          <span>
            <Button onClick={this.toggleChainalysisTransactionModal}>
              Show details
            </Button>
          </span>
        </span>
      )
    }

    return <span>Missing data</span>
  }

  getChainalysisDirectExposure() {
    let directExposure = 0

    if (this.state.chainalysisUserKyt) {
      let kyt = this.state.chainalysisUserKyt

      kyt.exposureDetails.forEach(ed => {
        if (ed.cluster && ed.cluster.name) {
          if (ed.cluster.name.toLowerCase().includes("safello")) {
            directExposure = ed.receivedDirectExposure
          }
        }
      })
    }

    return directExposure
  }

  getChainalysisIndirectExposure() {
    let indirectExposure = 0

    if (this.state.chainalysisUserKyt) {
      let kyt = this.state.chainalysisUserKyt

      kyt.exposureDetails.forEach(ed => {
        if (ed.cluster && ed.cluster.name) {
          if (ed.cluster.name.toLowerCase().includes("safello")) {
            indirectExposure = ed.receivedIndirectExposure
          }
        }
      })
    }

    return indirectExposure
  }

  getScoreColor(score) {
    if (score === "amber") {
      return "#ffc107"
    } else if (score === "green") {
      return "#8bc34a"
    } else {
      return "#ff5722"
    }
  }

  getRatingColor(rating) {
    if (rating.toUpperCase() === "UNKNOWN") {
      return "amber"
    } else if (rating.toUpperCase() === "LOW") {
      return "green"
    } else {
      return "red"
    }
  }

  render() {
    let kytLink = "https://kyt.chainalysis.com/users/" + this.props.user
    return (
      <div>
        <CardHeader className="card-section-header">Chainalysis</CardHeader>
        <CardBody className="pt-0">
          <Row>
            <Col xl={12}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderSpacing: "0 5px",
                }}
              >
                <tbody>
                  <tr>
                    <td className="details-header">User:</td>
                    <td className="details-info">
                      {this.renderChainalysisUser()}{" "}
                      <a href={kytLink} target="_blank" rel="noreferrer">
                        <Button>Open Chainalysis</Button>
                      </a>
                    </td>
                  </tr>
                  {!this.props.justUser && (
                    <tr>
                      <td className="details-header">Crypto address:</td>
                      <td className="details-info">
                        {this.renderChainalysisData()}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
            <Col xl={4} />
            {this.props.isSellOrder && (
              <Col xl={4}>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "separate",
                    borderSpacing: "0 5px",
                  }}
                >
                  <tbody>
                    <tr>
                      <td className="details-header">
                        Safello direct receive exposure:
                      </td>
                      <td className="details-info">
                        {this.getChainalysisDirectExposure()} USD
                      </td>
                    </tr>
                    <tr>
                      <td className="details-header">
                        Safello indirect receive exposure:
                      </td>
                      <td className="details-info">
                        {this.getChainalysisIndirectExposure()} USD
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            )}
          </Row>
        </CardBody>
        <ChainalysisModal
          data={this.state.chainalysisUserKyt}
          open={this.state.chainalysisModal}
          onToggle={this.toggleChainalysisModal}
        />
        <ChainalysisTransactionExposureModal
          data={this.state.chainalysisWalletTransactionExposure}
          open={this.state.chainalysisTransactionModal}
          onToggle={this.toggleChainalysisTransactionModal}
        />
      </div>
    )
  }
}

export default ChainalysisSection
