import React, { Component } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import moment from "moment"

class BuyRefundModal extends Component {
  state = {
    refundReason: "",
    refundDate: moment().format("YYYY-MM-DD"),
    adminFee: 0,
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open && this.props.open) {
      this.setState({
        refundReason: "",
        refundDate: moment().format("YYYY-MM-DD"),
        adminFee: 0,
      })
    }
  }

  changeReason = event => {
    this.setState({
      refundReason: event.target.value,
    })
  }

  changeDate = event => {
    this.setState({
      refundDate: event.target.value,
    })
  }

  changeAdminFee = event => {
    let amount = event.target.value
    this.setState({
      adminFee: amount,
    })
  }

  onRefund = () => {
    if (this.props.onRefund) {
      this.props.onRefund(
        this.state.refundReason,
        this.state.refundDate,
        this.state.adminFee,
      )
    }

    this.onToggle()
  }

  onToggle = () => {
    if (this.props.toggle) {
      this.props.toggle()
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.open} toggle={this.onToggle}>
        <ModalHeader toggle={this.onToggle}>Refund buy order</ModalHeader>
        <ModalBody>
          <div>
            <p>Refund reason</p>
            <textarea
              id="refundReason"
              placeholder="some kind of reason..."
              rows="6"
              name="reason"
              style={{ width: "100%" }}
              value={this.state.refundReason}
              onChange={this.changeReason}
            ></textarea>
          </div>
          <div>
            <p>Refund date</p>
            <input
              type="date"
              id="refundDate"
              value={this.state.refundDate}
              onChange={this.changeDate}
            />
          </div>
          <div lang="en-US">
            <p>Admin fee</p>
            <input
              type="number"
              id="adminFee"
              style={{ width: "100%" }}
              value={this.state.adminFee}
              onChange={this.changeAdminFee}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onRefund}>
            Refund
          </Button>
          <Button color="secondary" onClick={this.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default BuyRefundModal
