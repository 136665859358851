import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import ImagePicker from "react-image-picker"
import BackOfficeApi from "../Api/BackOfficeApi"
import "react-image-picker/dist/index.css"
import ToastUtils from "../Common/ToastUtils"

class BankAccountModal extends Component {
  state = {
    editMode: false,
    bankAccountType: "2",
    bank: "0",
    otherBankName: "",
    otherBankCountryCode: "",
    currency: "SEK",
    name: "",
    routingAddress: "",
    accountNumber: "",
    image: null,
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.bankAccount &&
      this.props.bankAccount !== prevProps.bankAccount
    ) {
      this.setState({
        editMode: true,
        bankAccountType: this.props.bankAccount.bankAccountType,
        bank: this.props.bankAccount.bankId,
        currency: this.props.bankAccount.currency,
        name: this.props.bankAccount.name,
        routingAddress: this.props.bankAccount.routingAddress,
        accountNumber: this.props.bankAccount.accountNumber,
        image: this.props.bankAccount.image,
      })
    } else if (
      !this.props.bankAccount &&
      this.props.bankAccount !== prevProps.bankAccount
    ) {
      this.setState({
        editMode: false,
        bankAccountType: "2",
        bank: "0",
        currency: "SEK",
        name: "",
        routingAddress: "",
        accountNumber: "",
        image: null,
      })
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  renderImages = () => {
    if (!this.props.images) {
      return ""
    }

    return (
      <ImagePicker
        images={this.props.images.map(i => ({
          src: BackOfficeApi.url + "/image?size=m&id=" + i.id,
          value: i.id,
        }))}
        onPick={this.onPick}
      />
    )
  }

  renderBanks = () => {
    if (this.props.banks) {
      return this.props.banks.map(b => {
        return (
          <option key={b.id} value={b.id}>
            {b.countryCode}: {b.name.substring(0, 25)}
          </option>
        )
      })
    }
    return ""
  }

  onPick = image => {
    this.setState({
      image: image.value,
    })
  }

  onSave = () => {
    const isOtherBank = this.state.bank === "other"
    if (
      isOtherBank &&
      (this.state.otherBankName == "" || this.state.otherBankCountryCode == "")
    ) {
      ToastUtils.toastCustomError("Please fill in details for 'other bank'")
      return
    }

    let bankAccount = {}
    bankAccount.bankAccountType = this.state.bankAccountType
    if (isOtherBank) {
      bankAccount.otherBankName = this.state.otherBankName
      bankAccount.otherBankCountryCode = this.state.otherBankCountryCode
    } else {
      bankAccount.bank = this.state.bank
    }
    bankAccount.currency = this.state.currency
    bankAccount.name = this.state.name
    bankAccount.routingAddress = this.state.routingAddress
    bankAccount.accountNumber = this.state.accountNumber
    bankAccount.image = this.state.image

    if (this.state.editMode) {
      bankAccount.id = this.props.bankAccount.id
      if (this.props.onEdit) {
        this.props.onEdit(bankAccount)
      }
    } else {
      if (this.props.onAdd) {
        this.props.onAdd(bankAccount)
      }
    }
  }

  render() {
    const isOtherBank = this.state.bank === "other"
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.props.toggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.props.toggle}>
          {this.state.editMode ? "Edit" : "Add"} bank account
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="bankAccountType">
                Bank account type
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.bankAccountType}
                  name="bankAccountType"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  <option value="2">Swedish bank account</option>
                  <option value="0">Foreign bank account</option>
                </select>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="bank">
                Bank
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.bank}
                  name="bank"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  <option value="0">Choose a bank</option>
                  {this.renderBanks()}
                  {!this.state.editMode && (
                    <option value="other">Other bank</option>
                  )}
                </select>
              </div>
            </FormGroup>

            {isOtherBank && (
              <>
                <FormGroup row>
                  <Label
                    className="col-xl-3 col-form-label"
                    for="otherBankName"
                  >
                    Other bank name
                  </Label>
                  <Input
                    className="col-xl-4"
                    type="text"
                    name="otherBankName"
                    id="otherBankName"
                    value={this.state.otherBankName}
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup row>
                  <Label
                    className="col-xl-3 col-form-label"
                    for="otherBankCountryCode"
                  >
                    Other bank country code
                  </Label>
                  <Input
                    className="col-xl-1"
                    type="text"
                    name="otherBankCountryCode"
                    id="otherBankCountryCode"
                    value={this.state.otherBankCountryCode}
                    onChange={this.onChange}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="currency">
                Currency
              </Label>
              <div className="col-xl-0">
                <select
                  value={this.state.currency}
                  name="currency"
                  className="custom-select"
                  onChange={this.onChange}
                >
                  <option value="SEK">SEK</option>
                  <option value="EUR">EUR</option>
                  <option value="NOK">NOK</option>
                  <option value="DKK">DKK</option>
                  <option value="GBP">GBP</option>
                </select>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="currency">
                Account name
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="name"
                id="name"
                value={this.state.name}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="routingAddress">
                Routing address
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="routingAddress"
                id="routingAddress"
                value={this.state.routingAddress}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="accountNumber">
                Account number
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="accountNumber"
                id="accountNumber"
                value={this.state.accountNumber}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-12 col-form-label">
                Select image (optional)
              </Label>
              <div
                className="col-xl-12"
                style={{ maxHeight: "300px", overflow: "auto" }}
              >
                <div>{this.renderImages()}</div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="mr-auto" onClick={this.onSave}>
            {this.state.editMode ? "Edit" : "Add"}
          </Button>
          <Button color="secondary" onClick={this.props.toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default BankAccountModal
