/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.0
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { useEffect, useState } from "react"
import { BrowserRouter } from "react-router-dom"
import $ from "jquery"

// App Routes
import Routes from "./Routes"

// Vendor dependencies
import "./Vendor"
// Application Styles
import "./styles/bootstrap.scss"
import "./styles/app.scss"
import BackOfficeApi from "./components/Api/BackOfficeApi"
import StorageUtils from "./components/Common/StorageUtils"

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter(o => (o.async = true))

/*
 {
   symbol: string;
   displayName: number;
   availableActions:[string];
 }
*/
// Context for crypto ticker data fetched from BI
export const CryptoTickerContext = React.createContext({
  cryptoTickerData: [],
  fetchCryptoTickerData: () => {},
})

const App = () => {
  const [cryptoTickerData, setCryptoTickerData] = useState([])
  const user = StorageUtils.getUser()

  // specify base href from env varible 'WP_BASE_HREF'
  // use only if application isn't served from the root
  // for development it is forced to root only
  /* global WP_BASE_HREF */
  const basename =
    process.env.NODE_ENV === "development" ? "/" : WP_BASE_HREF || "/"

  const fetchCryptoTickerData = async () => {
    const response = await BackOfficeApi.endpoints.getCryptos.getOne()
    if (response?.ok) {
      const data = await response.json()
      setCryptoTickerData(data)
    } else {
      console.error("Failed fetching crypto ticker data!")
      setCryptoTickerData([])
    }
  }

  useEffect(() => {
    if (user) {
      fetchCryptoTickerData()
    }
  }, [])

  return (
    <BrowserRouter basename={basename}>
      <CryptoTickerContext.Provider
        value={{ cryptoTickerData, fetchCryptoTickerData }}
      >
        <Routes />
      </CryptoTickerContext.Provider>
    </BrowserRouter>
  )
}

export default App
