import React, { useEffect, useState, useContext, useMemo } from "react"
import {
  Button,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"
import ToastUtils from "../../Common/ToastUtils"
import { NOT_SUPPORTED_CRYPTO_BALANCER } from "../../Common/constants"
import { CryptoTickerContext } from "../../../App"

export default function LiquidityProvider() {
  const tickers = useContext(CryptoTickerContext)?.cryptoTickerData
  const filteredTickers = useMemo(
    () =>
      tickers?.filter(
        ticker => !NOT_SUPPORTED_CRYPTO_BALANCER.includes(ticker.symbol),
      ),
    [tickers],
  )

  const [providersByCrypto, setProvidersByCrypto] = useState({})
  const [selectedCrypto, setSelectedCrypto] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (filteredTickers?.length) {
      getLiquidityProviders()
    }
  }, [filteredTickers])

  const getLiquidityProviders = async () => {
    setLoading(true)
    try {
      const _providerByCrypto = {}
      for (const ticker of filteredTickers) {
        const data = await BackOfficeApi.endpoints.liquidity
          .getOne({}, { asset: ticker.symbol })
          .then(response => {
            if (!response.ok) {
              throw response.json()
            }
            return response.json()
          })

        _providerByCrypto[ticker.symbol] = {
          defaultProvider: data.defaultProvider,
          backupProvider: data.backupProvider,
        }
      }
      setProvidersByCrypto(_providerByCrypto)
    } catch (error) {
      console.error(error)
      ToastUtils.toastAPIError2()
    } finally {
      setLoading(false)
    }
  }

  const handleModalClose = () => {
    setSelectedCrypto(null)
  }

  const handleModalSubmit = async (newDefault, newBackup) => {
    setSelectedCrypto(null)
    await changeProvider(selectedCrypto, newDefault, newBackup)
  }

  const changeProvider = async (
    cryptoCurrency,
    newDefaultProvider,
    newBackupProvider,
  ) => {
    setLoading(true)
    try {
      let formData = new FormData()
      formData.append("defaultProvider", newDefaultProvider)
      formData.append("backupProvider", newBackupProvider)
      const response = await BackOfficeApi.endpoints.liquidity.create(
        formData,
        {},
        {},
        { asset: cryptoCurrency },
      )
      if (response.ok) {
        ToastUtils.toastSaveSuccess2()
        setProvidersByCrypto({
          ...providersByCrypto,
          [cryptoCurrency]: {
            defaultProvider: newDefaultProvider,
            backupProvider: newBackupProvider,
          },
        })
      } else {
        throw response.json()
      }
    } catch (error) {
      console.error(error)
      ToastUtils.toastSaveError2()
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={`card ${isLoading ? "whirl" : ""}`}>
      <CardHeader>
        <h4>
          <em className="fab fa-bitcoin mr-2" />
          Liquidity provider
        </h4>
      </CardHeader>
      <CardBody>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Crypto</th>
              <th>Default provider</th>
              <th>Backup provider</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTickers.map((ticker, i) => {
              const providersForCrypto = providersByCrypto[ticker.symbol]
              if (!providersForCrypto) return null
              return (
                <tr key={`provider-table-${i}`}>
                  <td>{ticker.symbol}</td>
                  <td>
                    {providersForCrypto.defaultProvider && (
                      <span className="p-2 rounded font-weight-bold text-center bg-gray-dark">
                        {providersForCrypto.defaultProvider}
                      </span>
                    )}
                  </td>
                  <td>
                    {providersForCrypto.backupProvider && (
                      <span className="p-2 rounded font-weight-bold text-center bg-gray-dark">
                        {providersForCrypto.backupProvider}
                      </span>
                    )}
                  </td>
                  <td>
                    <Button
                      className="mr-2 mb-2"
                      color="primary"
                      onClick={() => setSelectedCrypto(ticker.symbol)}
                    >
                      Change provider
                    </Button>
                    <Button
                      className="mr-2 mb-2"
                      color="primary"
                      onClick={() =>
                        changeProvider(
                          ticker.symbol,
                          providersForCrypto.backupProvider,
                          providersForCrypto.defaultProvider,
                        )
                      }
                    >
                      Switch default/backup
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </CardBody>
      {selectedCrypto && (
        <ChangeProviderModal
          onClose={handleModalClose}
          selectedCrypto={selectedCrypto}
          selectedProviders={providersByCrypto[selectedCrypto]}
          onSubmit={handleModalSubmit}
        />
      )}
    </div>
  )
}

const ChangeProviderModal = ({
  onClose,
  onSubmit,
  selectedCrypto,
  selectedProviders,
}) => {
  const [newDefaultProvider, setNewDefaultProvider] = useState(
    selectedProviders.defaultProvider,
  )
  const [newBackupProvider, setNewBackupProvider] = useState(
    selectedProviders.backupProvider,
  )

  const handleChangeClicked = () => {
    if (newDefaultProvider && newBackupProvider) {
      onSubmit(newDefaultProvider, newBackupProvider)
    } else {
      ToastUtils.toastCustomError("Please fill in all the fields")
    }
  }

  return (
    <Modal isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>
        Change {selectedCrypto} liquidity provider
      </ModalHeader>
      <ModalBody>
        <p>Default provider</p>
        <select
          name="defaultProvider"
          value={newDefaultProvider}
          className="custom-select mb-2"
          onChange={e => setNewDefaultProvider(e.target.value)}
        >
          <option>-- None selected --</option>
          <option value="BITSTAMP">Bitstamp</option>
          <option value="BLOCKCHAIN">Blockchain</option>
        </select>
        <p>Backup provider</p>
        <select
          name="backupProvider"
          value={newBackupProvider}
          className="custom-select mb-2"
          onChange={e => setNewBackupProvider(e.target.value)}
        >
          <option>-- None selected --</option>
          <option value="BITSTAMP">Bitstamp</option>
          <option value="BLOCKCHAIN">Blockchain</option>
        </select>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleChangeClicked}>
          Change
        </Button>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
