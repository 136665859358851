import React, { Component } from "react"
import CheckValid from "../Media/Svg/check_valid.svg"
import CheckInvalid from "../Media/Svg/check_invalid.svg"

class ValidationImage extends Component {
  render() {
    if (this.props.showBackground) {
      return (
        <div
          className="validation-image"
          style={{
            backgroundColor: this.props.valid ? "#e8fcd9" : "#fde8e7",
            ...this.props.style,
          }}
        >
          <img
            alt=""
            src={this.props.valid ? CheckValid : CheckInvalid}
            style={{
              width: this.props.checkWidth,
              height: this.props.checkHeight,
            }}
          />
        </div>
      )
    }

    return (
      <img
        alt=""
        src={this.props.valid ? CheckValid : CheckInvalid}
        style={{
          width: this.props.checkWidth,
          height: this.props.checkHeight,
          ...this.props.style,
        }}
      />
    )
  }
}

ValidationImage.defaultProps = {
  valid: true,
  showBackground: true,
  checkWidth: "10px",
  checkHeight: "8px",
}

export default ValidationImage
