import React, { Component } from "react"
import { Row, Col, CardBody, CardHeader } from "reactstrap"
import Utils from "../Common/Utils"

class PartnerDetailsSection extends Component {
  render() {
    if (!this.props.partner) return <div />

    let partner = this.props.partner

    return (
      <div>
        <CardHeader className="card-section-header">Partner info</CardHeader>
        <CardBody>
          <Row className="no-gutters">
            <Col>
              <Row className="no-gutters">
                <Col>
                  <div>
                    <div className="details-row">
                      <span className="details-header">Email:</span>
                      <span className="details-value">{partner.email}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Org Name:</span>
                      <span className="details-value">{partner.orgName}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Org Number:</span>
                      <span className="details-value">{partner.orgNumber}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Org Vat:</span>
                      <span className="details-value">{partner.orgVat}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">IBAN:</span>
                      <span className="details-value">{partner.iban}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Created:</span>
                      <span className="details-value">
                        {Utils.getUtcInLocalDateTime(partner.created)}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="details-row">
                    <span className="details-header">Org Address:</span>
                    <span className="details-value">{partner.orgAddress}</span>
                  </div>
                  <div className="details-row">
                    <span className="details-header">Org City:</span>
                    <span className="details-value">{partner.orgCity}</span>
                  </div>
                  <div className="details-row">
                    <span className="details-header">Org Zip:</span>
                    <span className="details-value">{partner.orgZip}</span>
                  </div>
                  <div className="details-row">
                    <span className="details-header">Org Url:</span>
                    <span className="details-value">{partner.orgUrl}</span>
                  </div>
                  <div className="details-row">
                    <span className="details-header">Org Country:</span>
                    <span className="details-value">{partner.country}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
}
export default PartnerDetailsSection
