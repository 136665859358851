import React, { useState, useEffect } from "react"
import { Container } from "reactstrap"
import EditBadgesModal from "./EditBadgesModal"
import { Row, Button } from "reactstrap"
// utils
import ToastUtils from "../Common/ToastUtils"
import BackOfficeApi from "../Api/BackOfficeApi"

const CustomerFeatureFlagsSection = ({ user }) => {
  const [activeFeatureFlags, setActiveFeatureFlags] = useState([])
  const [allFeatureFlags, setAllFeatureFlags] = useState([])
  const [showEditFlagsModal, setShowEditFlagsModal] = useState(false)

  const getCustomerFeatureFlags = async () => {
    const response =
      await BackOfficeApi.endpoints.getCustomerFeatureFlags.getOne({
        customerId: user.id,
      })

    const data = await response.json()
    const formattedData = data.map(flag => {
      return {
        id: flag.id,
        name: flag.flag,
      }
    })
    setActiveFeatureFlags(formattedData)
  }
  const getAllFeatureFlags = async () => {
    const response = await BackOfficeApi.endpoints.getAllFeatureFlags.getAll()

    const data = await response.json()
    const formattedData = data.map(flag => {
      return {
        id: flag.id,
        name: flag.flag,
      }
    })
    setAllFeatureFlags(formattedData)
  }

  const onAddCustomerFeatureFlag = async flag => {
    const response = await BackOfficeApi.endpoints.addCustomerFeatureFlag.post({
      customerId: user.id,
      flagKey: flag.name,
    })
    if (response?.ok) {
      ToastUtils.toastExecuteSuccess2()
      getCustomerFeatureFlags()
    } else {
      console.error(response?.error)
      ToastUtils.toastSaveError2()
    }
    setShowEditFlagsModal(false)
  }

  const onRemoveCustomerFeatureFlag = async flag => {
    const response =
      await BackOfficeApi.endpoints.removeCustomerFeatureFlag.post({
        customerId: user.id,
        flagKey: flag.name,
      })
    if (response?.ok) {
      ToastUtils.toastExecuteSuccess2()
      getCustomerFeatureFlags()
    } else {
      console.error(response?.error)
      ToastUtils.toastAPIError2()
    }
    setShowEditFlagsModal(false)
  }

  const renderFeatureFlagBadges = () => {
    let featureFlagBadges = []

    activeFeatureFlags.forEach(featureFlag => {
      featureFlagBadges.push(
        <div
          className="tag-badge"
          key={featureFlag.id}
          style={{ backgroundColor: "#484848", color: "#FFFFFF" }}
        >
          {featureFlag.name}
        </div>,
      )
    })

    return featureFlagBadges
  }

  useEffect(() => {
    if (user) {
      getCustomerFeatureFlags()
      getAllFeatureFlags()
    }
  }, [user])

  return (
    <Container style={{ paddingBottom: 12 }}>
      <br />
      <p className="details-header ml-3">
        Feature flags
        <Button
          size="xs"
          color="primary"
          className="float-right safello-font"
          onClick={() => setShowEditFlagsModal(true)}
        >
          Edit flags
        </Button>
      </p>

      <Row className="no-gutters" style={{ paddingLeft: 12 }}>
        {renderFeatureFlagBadges()}
      </Row>
      <EditBadgesModal
        open={showEditFlagsModal}
        toggle={() => setShowEditFlagsModal(false)}
        activeData={activeFeatureFlags}
        allData={allFeatureFlags}
        onAdd={onAddCustomerFeatureFlag}
        onRemove={onRemoveCustomerFeatureFlag}
        name={"flags"}
      />
    </Container>
  )
}

export default CustomerFeatureFlagsSection
