import React, { useEffect, useState } from "react"
import BackOfficeApi from "../../Api/BackOfficeApi"
// components
import ContentWrapper from "../../Layout/ContentWrapper"
import FlagTable from "./components/FlagTable"
import CustomerFeatureFlagControls from "./components/CustomerFeatureFlagControls"
import FeatureFlagControls from "./components/FeatureFlagControls"
import Divider from "../../Layout/Divider"

/*
Flag data format:
[
  {
    description: string
    enabled: boolean
    flag: string
    id:number
    name: string
  }
]
*/

const FeatureFlagPage = () => {
  const [featuresFlagData, setFeatureFlagsData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchAllFeatureFlags = async () => {
    setLoading(true)
    const response = await BackOfficeApi.endpoints.getAllFeatureFlags.getAll()
    const data = await response.json()
    setFeatureFlagsData(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchAllFeatureFlags()
  }, [])

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>Feature Flag Dashboard</div>
      </div>
      {/* Add/remove customer feature flag */}
      <CustomerFeatureFlagControls
        loading={loading}
        setLoading={setLoading}
        featuresFlagData={featuresFlagData}
      />
      <Divider />
      {/* Add/remove feature flag */}
      <FeatureFlagControls
        loading={loading}
        setLoading={setLoading}
        featuresFlagData={featuresFlagData}
        refetchFeatureFlags={fetchAllFeatureFlags}
      />
      <Divider />
      {/* Available feature flags table */}
      <div className="card-section-header" style={{ marginBottom: 10 }}>
        Available Feature Flags
      </div>
      <FlagTable
        featuresFlagData={featuresFlagData}
        setLoading={setLoading}
        refetchFeatureFlags={fetchAllFeatureFlags}
      />
    </ContentWrapper>
  )
}

export default FeatureFlagPage
