import React, { Component } from "react"
import { Card, Col, Container, Row } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"
import ReactDataGrid from "react-data-grid"
import DataGridEmpty from "../Common/DataGridEmpty"

class CustomerLogins extends Component {
  state = {
    rows: [],
    isLoading: false,
  }
  constructor(props) {
    super(props)

    this._columns = [
      {
        key: "zignsecIp",
        name: "Zignsec IP",
      },
      {
        key: "deviceIp",
        name: "Device IP",
      },
      {
        key: "deviceUserAgent",
        name: "Device user agent",
      },
      {
        key: "referral",
        name: "Referral",
      },
      {
        key: "timestamp",
        name: "Timestamp",
        formatter: DataGridLocalDateTimeFormatter,
      },
    ]
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer !== prevProps.customer) {
      this.loadData()
    }
    //Stupid workaround to force react data grid to update cell/column width when using TabPane
    if (this.props.workaround !== prevProps.workaround) {
      window.dispatchEvent(new Event("resize"))
    }
  }

  loadData = () => {
    this.setState({
      isLoading: true,
    })

    BackOfficeApi.endpoints.getCustomerLogins
      .getOne({ customerId: this.props.customer.id })
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          rows: data.slice(0),
          isLoading: false,
        })
      })
      .catch(() => {
        this.setState({
          error: "Failed to retrieve data",
          isLoading: false,
        })
      })
  }

  rowGetter = i => {
    return this.state.rows[i]
  }

  render() {
    return (
      <Card
        className="card-area"
        style={{ minHeight: "300px", marginTop: "0" }}
      >
        <Row>
          <Col>
            <Container fluid>
              <ReactDataGrid
                style={{ width: "100%" }}
                columns={this._columns}
                rowGetter={this.rowGetter}
                rowsCount={this.state.rows.length}
                minHeight={700}
                emptyRowsView={this.state.isLoading ? null : DataGridEmpty}
                minColumnWidth={200}
                rowHeight={40}
                onColumnResize={() => console.log("column resize")}
              />
            </Container>
          </Col>
        </Row>
      </Card>
    )
  }
}
export default CustomerLogins
