import React, { Component } from "react"
import { Card, CardBody, Button } from "reactstrap"
import OrderUtils from "../OrderUtils"
import Utils from "../../Common/Utils"

class SellOrderActionsArea extends Component {
  forwardAction = action => {
    if (this.props.onActionPerformed) {
      this.props.onActionPerformed(action)
    }
  }

  onMarkAsSentClicked = () => {
    Utils.showSweetAlertAreYouSure("Mark this order as sent?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_mark_as_sent")
        }
      },
    )
  }

  onFreezeClicked = () => {
    Utils.showSweetAlertAreYouSure("Freeze this order?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_freeze")
        }
      },
    )
  }

  onUnfreezeClicked = () => {
    Utils.showSweetAlertAreYouSure("Unfreeze this order?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_unfreeze")
        }
      },
    )
  }

  onDeleteClicked = () => {
    Utils.showSweetAlertAreYouSure("Lazy delete this order?", "", null).then(
      confirmed => {
        if (confirmed) {
          this.forwardAction("order_delete")
        }
      },
    )
  }

  render() {
    let buttons = this.getButtonList()

    return (
      <div>
        <span className="header-area">Actions</span>
        <Card className="card-area">
          <CardBody
            className={this.getLoadingClass()}
            style={
              this.props.isLoading && buttons.length === 0
                ? { minHeight: "100px" }
                : {}
            }
          >
            <div style={{ textAlign: "center" }}>
              {buttons.map((item, i) => {
                let className = "action_button"
                if (i + 1 < buttons.length) className += " mb-2"
                return (
                  <Button
                    key={i}
                    className={className}
                    style={{ background: item.background }}
                    onClick={item.onClick}
                  >
                    <i className={"fas fa-" + item.icon}></i>
                    {item.title}
                  </Button>
                )
              })}
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }

  getLoadingClass = () => {
    if (!this.props.isLoading) return ""
    return "whirl standard"
  }

  getButtonList = () => {
    let buttons = []
    if (!this.props.order) return buttons

    if (OrderUtils.isCurrentSellOrderStatusIssued(this.props.order)) {
      buttons.push(this.getEditButton())
      buttons.push({
        title: "Delete",
        background: "#f47f7f",
        icon: "trash",
        onClick: this.onDeleteClicked,
      })
    } else if (
      OrderUtils.isCurrentSellOrderStatusReceivedMempool(this.props.order)
    ) {
      buttons.push(this.getEditButton())
      buttons.push(this.getFreezeButton())
    } else if (OrderUtils.isCurrentSellOrderStatusReceived(this.props.order)) {
      buttons.push(this.getEditButton())
      buttons.push({
        title: "Trade now",
        background: "#37bc9b",
        icon: "exchange-alt",
        onClick: () => this.forwardAction("order_trade"),
      })
      buttons.push(this.getRefundButton())
      buttons.push(this.getFreezeButton())
      buttons = this.getReportXmlButton(buttons)
    } else if (OrderUtils.isCurrentSellOrderStatusSold(this.props.order)) {
      buttons.push(this.getEditButton())
      buttons.push(this.getQueuePayoutButton())
      buttons.push({
        title: "Mark as sent",
        background: "#37bc9b",
        icon: "check-square",
        onClick: this.onMarkAsSentClicked,
      })
      buttons.push(this.getRefundButton())
      buttons.push(this.getFreezeButton())
      buttons = this.getReportXmlButton(buttons)
    } else if (OrderUtils.isCurrentSellOrderStatusFrozen(this.props.order)) {
      buttons.push({
        title: "Unfreeze",
        background: "#ff902b",
        icon: "snowflake",
        onClick: this.onUnfreezeClicked,
      })
      buttons.push(this.getRefundButton())
      buttons = this.getReportXmlButton(buttons)
    } else if (
      OrderUtils.isCurrentSellOrderStatusSentToBank(this.props.order)
    ) {
      buttons = this.getReportXmlButton(buttons)
    } else if (OrderUtils.isCurrentSellOrderStatusRefunded(this.props.order)) {
      buttons = this.getReportXmlButton(buttons)
    }

    return buttons
  }

  getReportXmlButton = buttons => {
    buttons.push(
      {
        title: "Report (XML)",
        background: "#243948",
        icon: "flag",
        onClick: () => this.forwardAction("report_xml"),
      },
      {
        title: "Fast XML export",
        background: "#131e26",
        icon: "download",
        onClick: () => this.forwardAction("fast_xml_export"),
      },
    )
    return buttons
  }

  getRefundButton = () => {
    return {
      title: "Refund",
      background: "#7266ba",
      icon: "undo",
      onClick: () => this.forwardAction("order_refund"),
    }
  }

  getFreezeButton = () => {
    return {
      title: "Freeze",
      background: "#ff902b",
      icon: "snowflake",
      onClick: this.onFreezeClicked,
    }
  }

  getEditButton = () => {
    return {
      title: "Edit",
      background: "#5d9cec",
      icon: "edit",
      onClick: () => this.forwardAction("order_edit"),
    }
  }

  getQueuePayoutButton = () => {
    return {
      title: "Queue payout",
      background: "rgb(18, 133, 229)",
      icon: "university",
      onClick: () => this.forwardAction("queue_payout"),
    }
  }
}

export default SellOrderActionsArea
