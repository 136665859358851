import React, { useState } from "react"
import { APP_COLORS } from "../Common/constants"
import { Button, ButtonGroup } from "reactstrap"
import ChartUtils from "../Common/ChartUtils"

/**
 * Shows button group of different chart perspectives
 */
const ChartPerspectives = ({
  onChangePerspective,
  perspective,
  perspectives,
}) => {
  const [internalValue, setInternalValue] = useState(perspective)

  const handleChangePerspective = perspective => {
    setInternalValue(perspective)

    if (onChangePerspective) {
      onChangePerspective(perspective)
    }
  }

  return (
    <ButtonGroup className="float-right">
      {perspectives.map((p, i) => {
        let name = ""
        if (ChartUtils.isDailyPerspective(p)) {
          name = "Daily"
        } else if (ChartUtils.isHourlyPerspective(p)) {
          name = "Hourly"
        } else if (ChartUtils.isWeeklyPerspective(p)) {
          name = "Weekly"
        } else if (ChartUtils.isMonthlyPerspective(p)) {
          name = "Monthly"
        } else if (ChartUtils.isQuarterlyPerspective(p)) {
          name = "Quarterly"
        } else if (ChartUtils.isYearlyPerspective(p)) {
          name = "Yearly"
        }
        return (
          <Button
            key={i}
            onClick={() => handleChangePerspective(p)}
            style={
              internalValue === p ? { color: APP_COLORS.washed_green } : null
            }
          >
            {name}
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

ChartPerspectives.defaultProps = {
  perspective: ChartUtils.getDefaultPerspective(),
  perspectives: ChartUtils.getPerspectives(),
}

export default ChartPerspectives
