import { useState, useEffect, useContext } from "react"
import BackOfficeApi from "../../Api/BackOfficeApi"
import { CryptoTickerContext } from "../../../App"
import { useMemo } from "react"
import {
  NOT_SUPPORTED_CRYPTO_BALANCER,
  NOT_SUPPORTED_CRYPTO_HOT_WALLETS,
} from "../../Common/constants"

const EMPTY_BITSTAMP_BALANCE_DATA = { EUR: 0, BTC: 0 }
const EMPTY_GATEWAY_STATUS_DATA = {
  unconfirmedBalance: 0,
  balance: 0,
  ordersToExecute: 0,
  ordersAmount: 0,
}
const fetchOrLog = async fetchCall => {
  const response = await fetchCall
  if (response.ok) {
    return response.json()
  } else {
    console.error("Fetch error: ", response)
    return null
  }
}

const fetchGatewayStatus = async cryptoCurrency => {
  try {
    if (NOT_SUPPORTED_CRYPTO_HOT_WALLETS.includes(cryptoCurrency)) {
      return EMPTY_GATEWAY_STATUS_DATA
    }
    const status = await fetchOrLog(
      BackOfficeApi.endpoints.gatewayStatus.getAll({ cryptoCurrency }),
    )
    return status
  } catch (error) {
    console.error(error)
    return EMPTY_GATEWAY_STATUS_DATA
  }
}

const fetchBitstampBalance = cryptoCurrency =>
  fetchOrLog(BackOfficeApi.endpoints.bitstampBalance.getAll({ cryptoCurrency }))

const fetchBlockchainBalance = () =>
  fetchOrLog(BackOfficeApi.endpoints.getBlockchainBalances.getAll())

const fetchFireblocksBalance = () =>
  fetchOrLog(BackOfficeApi.endpoints.getFireblocksBalance.getAll())

const fetchWalletLiabilities = () =>
  fetchOrLog(BackOfficeApi.endpoints.getWalletLiabilities.getAll())

const fetchReceivedOrderSellStats = async cryptoCurrency => {
  try {
    const stats = await fetchOrLog(
      BackOfficeApi.endpoints.sellOrdersStatsByStatus.getOne({
        crypto: cryptoCurrency,
        status: "received",
      }),
    )
    return stats
  } catch (error) {
    console.error(error)
    return { cryptoSum: 0, amountSum: 0 }
  }
}

const fetchBitstampTicker = async cryptoCurrency => {
  try {
    const ticker = await fetchOrLog(
      BackOfficeApi.endpoints.bitstampTicker.getAll({ cryptoCurrency }),
    )
    return ticker
  } catch (error) {
    console.error(error)
    return { ask: 0, bid: 0 }
  }
}

const fetchInProcessTransactions = () =>
  fetchOrLog(BackOfficeApi.endpoints.inProcessTransactions.getAll())

const fetchWithdrawalDepositLiabilities = () =>
  fetchOrLog(BackOfficeApi.endpoints.getWithdrawalDepositLiabilitiesV2.getAll())

const useTreasury = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [gatewayStatusData, setGatewayStatusData] = useState(null)
  const [bitstampBalanceData, setBitstampBalanceData] = useState(null)
  const [bitstampTickerData, setBitstampTickerData] = useState(null)
  const [orderSellStatsData, setOrderSellStatsData] = useState(null)
  const [blockchainBalance, setBlockchainBalance] = useState(null)
  const [fireblocksBalance, setFireblocksBalance] = useState(null)
  const [walletLiabilities, setWalletLiabilities] = useState(null)
  const [sekToEurRate, setSekToEurRate] = useState(null)

  const [inProcessTransactions, setInProcessTransactions] = useState(null)
  const [withdrawalDepositLiabilities, setWithdrawalDepositLiabilities] =
    useState(null)

  const tickers = useContext(CryptoTickerContext)?.cryptoTickerData
  const tickersWithBalancers = useMemo(
    () =>
      tickers.filter(
        ticker => !NOT_SUPPORTED_CRYPTO_BALANCER.includes(ticker.symbol),
      ),
    [tickers],
  )
  const endpointMap = useMemo(() => {
    const endpoints = {
      blockchainBalance: {
        fetch: () => fetchBlockchainBalance(),
        setState: setBlockchainBalance,
      },
      fireblocksBalance: {
        fetch: () => fetchFireblocksBalance(),
        setState: setFireblocksBalance,
      },
      walletLiabilities: {
        fetch: () => fetchWalletLiabilities(),
        setState: setWalletLiabilities,
      },
      inProcessTransactions: {
        fetch: () => fetchInProcessTransactions(),
        setState: setInProcessTransactions,
      },
      withdrawalDepositLiabilities: {
        fetch: () => fetchWithdrawalDepositLiabilities(),
        setState: setWithdrawalDepositLiabilities,
      },
    }
    if (tickersWithBalancers) {
      tickersWithBalancers.map(ticker => {
        endpoints[`${ticker.symbol.toLocaleLowerCase()}GatewayStatus`] = {
          fetch: () => fetchGatewayStatus(ticker.symbol.toUpperCase()),
          crypto: ticker.symbol,
        }
        endpoints[`${ticker.symbol.toLocaleLowerCase()}BitstampBalance`] = {
          fetch: () => fetchBitstampBalance(ticker.symbol.toUpperCase()),
          crypto: ticker.symbol,
        }
        endpoints[`${ticker.symbol.toLocaleLowerCase()}BitstampTicker`] = {
          fetch: () => fetchBitstampTicker(ticker.symbol.toUpperCase()),
          crypto: ticker.symbol,
        }
        endpoints[`${ticker.symbol.toLocaleLowerCase()}OrderSellStats`] = {
          fetch: () => fetchReceivedOrderSellStats(ticker.symbol.toUpperCase()),
          crypto: ticker.symbol,
        }
      })
      // Add euro bitstamp endpoint
      endpoints["eurBitstampBalance"] = {
        fetch: () => fetchBitstampBalance("EUR"),
        crypto: "EUR",
      }
    }
    return endpoints
  }, [tickersWithBalancers])

  useEffect(() => {
    if (endpointMap && Object.keys(endpointMap).length > 10) {
      refresh()
    }
  }, [endpointMap])

  const refresh = async (endpointsToRefresh = Object.keys(endpointMap)) => {
    setLoading(true)
    try {
      // Should not use Promise.all() here due to possible BI congestion issues
      const responses = []
      for (const key of endpointsToRefresh) {
        const endpoint = endpointMap[key]
        if (!endpoint) {
          throw new Error(`${key} is not a valid endpoint`)
        }
        responses.push(await endpoint.fetch())
      }

      const gatewayStatus = {}
      const bitstampBalance = {}
      const bitstampTicker = {}
      const orderSellStats = {}

      responses.forEach((response, index) => {
        const endpointKey = endpointsToRefresh[index]
        const endpoint = endpointMap[endpointKey]

        if (endpointKey.toLocaleLowerCase().includes("gatewaystatus")) {
          const crypto = endpoint.crypto
          gatewayStatus[crypto.toUpperCase()] =
            response || EMPTY_GATEWAY_STATUS_DATA
        } else if (
          endpointKey.toLocaleLowerCase().includes("bitstampbalance")
        ) {
          const crypto = endpoint.crypto
          bitstampBalance[crypto.toUpperCase()] =
            response || EMPTY_BITSTAMP_BALANCE_DATA
        } else if (endpointKey.toLocaleLowerCase().includes("bitstampticker")) {
          const crypto = endpoint.crypto
          bitstampTicker[crypto.toUpperCase()] = response
        } else if (endpointKey.toLocaleLowerCase().includes("ordersellstats")) {
          const crypto = endpoint.crypto
          orderSellStats[crypto.toUpperCase()] = response
        } else {
          endpoint.setState(response)
        }
      })
      setGatewayStatusData({
        ...gatewayStatusData,
        ...gatewayStatus,
      })
      setBitstampBalanceData({
        ...bitstampBalanceData,
        ...bitstampBalance,
      })
      setBitstampTickerData({
        ...bitstampTickerData,
        ...bitstampTicker,
      })
      setOrderSellStatsData({
        ...orderSellStatsData,
        ...orderSellStats,
      })

      const eurRateResponse = await BackOfficeApi.endpoints.getFiatRate.getOne({
        from: "EUR",
      })
      if (eurRateResponse?.ok) {
        const json = await eurRateResponse.json()
        setSekToEurRate(json?.rate)
      }
    } catch (e) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }
  return {
    gatewayStatusData,
    bitstampBalanceData,
    bitstampTickerData,
    orderSellStatsData,
    blockchainBalance,
    fireblocksBalance,
    walletLiabilities,
    inProcessTransactions,
    withdrawalDepositLiabilities,
    sekToEurRate,
    loading,
    error,
    refresh,
  }
}

export default useTreasury
