import React, { Component } from "react"
import ChartUtils from "../Common/ChartUtils"
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Cell,
} from "recharts"

class BackOfficeBarChart extends Component {
  render() {
    let optionalProps = {}
    if (this.props.unit) {
      optionalProps.unit = " " + this.props.unit
    }

    let showData = null
    if (this.props.bars) {
      showData = this.props.bars.slice()
    }

    return (
      <div
        className="card-body"
        style={{
          width: "100%",
          height: "500px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        {showData && (
          <ResponsiveContainer>
            <BarChart
              data={showData}
              margin={{ top: 10, right: 22, left: 10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Bar
                dataKey="value"
                name={this.props.name}
                unit={optionalProps.unit}
              >
                {this.props.bars.map((item, i) => {
                  const color = ChartUtils.getChartLineColor(i)
                  return <Cell fill={color} key={`chart-cell-${i}`} />
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    )
  }
}

BackOfficeBarChart.defaultProps = {
  bars: [],
}

export default BackOfficeBarChart
