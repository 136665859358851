import React, { Component } from "react"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

class Cards extends Component {
  state = {
    cardData: [],
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.statsBusinessCards
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          cardData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    if (this.state.cardData && this.state.cardData.length > 0) {
      return this.state.cardData.map((card, i) => {
        let title
        let icon = "fa-2x fas fa-"
        if (card.name === "business_orders") {
          title = "Total orders"
          icon += "receipt"
        } else if (card.name === "business_revenue") {
          title = "Total revenue"
          icon += "wallet"
        } else if (card.name === "business_volume") {
          title = "Total turnover"
          icon += "wallet"
        } else if (card.name === "business_average_amount") {
          title = "Average order amount"
          icon += "wallet"
        } else if (card.name === "business_sign_ups") {
          title = "Total registrations"
          icon += "users"
        }

        return (
          <BackOfficeCard
            icon={icon}
            title={title}
            value={Utils.formatNumber(card.value)}
            unit={card.unit}
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    } else {
      let placeholders = [0, 1, 2, 3, 4]
      return placeholders.map((placeHolder, i) => {
        return (
          <BackOfficeCard
            icon=" "
            title="-"
            value="-"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
    }
  }
}

export default Cards
