/**
 * Handles local storage functionality of common used data.
 */
const StorageUtils = {
  setUser: function (user) {
    let userDataToSave = {}
    userDataToSave.id = user.id
    userDataToSave.firstName = user.firstName
    userDataToSave.lastName = user.lastName
    userDataToSave.permissions = user.permissions
    localStorage.setItem("user", JSON.stringify(userDataToSave))
  },

  getUser: function () {
    let userJson = localStorage.getItem("user")
    if (!userJson) return userJson
    return JSON.parse(userJson)
  },

  removeUser: function () {
    localStorage.removeItem("user")
  },

  getUserId: function () {
    let user = this.getUser()
    if (!user) return -1
    return user.id
  },

  getUserPermissions: function () {
    let user = this.getUser()
    if (!user || !user.permissions) return []
    return user.permissions
  },
}

export default StorageUtils
