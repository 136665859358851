import React, { Component } from "react"
import { Button, Form, FormGroup, FormText, Input, Label } from "reactstrap"
import ContentWrapper from "../Layout/ContentWrapper"
import BackOfficeApi from "../Api/BackOfficeApi"
import ToastUtils from "../Common/ToastUtils"

class Whistleblower extends Component {
  state = {
    recipients: null,
    anonymous: true,
    recipient: "1",
    text: "",
    name: "",
    files: null,
    isLoading: false,
    recipientEmail: null,
    submitted: false,
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    })

    BackOfficeApi.endpoints.whistleblowerRecipients
      .getAll()
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          ToastUtils.toastExecuteError2()
          this.setState({
            isLoading: false,
          })
        }
      })
      .then(data => {
        this.setState({
          recipients: data.slice(0),
          isLoading: false,
        })
      })
  }

  onRecipientChange = e => {
    this.setState({
      recipient: e.target.value,
    })
  }

  onRecipientEmailChange = e => {
    this.setState({
      recipientEmail: e.target.value,
    })
  }

  onAnonymousChange = e => {
    this.setState({
      anonymous: e.target.value === "1",
      name: e.target.value === "1" ? "" : this.state.name,
    })
  }

  onTextChange = e => {
    this.setState({
      text: e.target.value,
    })
  }

  onFilesChange = e => {
    this.setState({
      files: e.target.files,
    })
  }

  onNameChange = e => {
    this.setState({
      name: e.target.value,
    })
  }

  onSubmit = () => {
    if (this.state.recipient === "4" && !this.state.recipientEmail) {
      alert("Seems like you forgot to enter an email fo the recepient.")
      return
    }

    if (!this.state.text) {
      alert("Seems like you forgot to enter a Description.")
      return
    }

    if (!this.state.anonymous && !this.state.name) {
      alert("Seems like you forgot to enter your name.")
      return
    }

    this.setState({
      submitted: false,
      sending: true,
    })

    let formData = new FormData()
    formData.append("recipient", this.state.recipient)
    formData.append("text", this.state.text)

    if (this.state.files) {
      for (const file of this.state.files) {
        formData.append("files", file, file.name)
      }
    }

    if (!this.state.anonymous) {
      formData.append("name", this.state.name)
    }

    if (this.state.recipient === "4") {
      formData.append("recipientEmail", this.state.recipientEmail)
    }

    BackOfficeApi.endpoints.whistleblowerAdd.create(formData).then(response => {
      if (response.ok) {
        this.setState({
          submitted: true,
          anonymous: true,
          recipient: "1",
          text: "",
          name: "",
          files: null,
          recipientEmail: null,
        })
      } else {
        ToastUtils.toastExecuteError2()
      }

      this.setState({
        sending: false,
      })
    })
  }

  renderRecipientsOptions = () => {
    let options = []

    this.state.recipients.forEach(r => {
      options.push(<option value={r.id}>{r.name}</option>)
    })

    return options
  }

  render() {
    if (
      this.state.isLoading ||
      !this.state.recipients ||
      this.state.submitted ||
      this.state.sending
    ) {
      return (
        <ContentWrapper>
          <div
            className="block-center wd-xl"
            style={{ marginTop: "60px", minWidth: "800px" }}
          >
            {this.state.submitted ? (
              <div className="card card-flat">
                <div className="card-header text-center">
                  <br />
                  <h3>
                    The report was succesfully submitted!{" "}
                    <span role="img" aria-label="handshake">
                      🤝
                    </span>
                  </h3>
                </div>
              </div>
            ) : (
              <div className="whirl" style={{ minHeight: "230px" }} />
            )}
          </div>
        </ContentWrapper>
      )
    }

    return (
      <ContentWrapper>
        <div
          className="block-center wd-xl"
          style={{ marginTop: "60px", minWidth: "800px" }}
        >
          <div className="card card-flat">
            <div className="card-header text-center">
              <br />
              <h3>Whistleblower form</h3>
            </div>
            <div className="card-body">
              Safello, as a Swedish Financial Institution, has to follow both
              the regulations and general guidelines (FFFS 2017:11).
              <br />
              According to the Rules, Safello is, among others, required to
              provide its team members the possibility to report violations of
              codes of conduct, processes, regulations, procedures. Furthermore,
              to report in case of suspicion even when possibly not being sure
              about which type of internal process might have been violated.
              <br />
              Each team member is encouraged to report such behaviour at the
              earliest convenience, without a delay. This form is the
              implementation of the electronic system ofSafello Whistleblowing
              Policy intended to encourage the reporting of suspected or actual
              occurrence(s) of illegal, unethical or inappropriate events,
              behaviours or practises without retribution.
              <br />
              <br />
              This form has been designed to ensure that submission remains both
              anonymous and encrypted.
              <br />
              <br />
              The Whistleblower shall receive no retaliation or retribution for
              a report that was provided in good faith – that was not done
              primarily with malice to damage another or the organization.
              <br />
              <br />
              Supervisors, managers and/or Board members who receive the reports
              must take action promptly and without a delay to investigate
              and/or resolve the issue.
              <br />
              <br />
              For more details, please read the{" "}
              <a
                href="https://drive.google.com/file/d/1ktRbj8GQ6bRWwb7_-e6TrcihSHUHFbur"
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>Safello Whistleblowing Policy</b>
              </a>
              .
            </div>
            <div className="card-body">
              <Form>
                <FormGroup>
                  <Label for="recipient">
                    Recipient of the whistleblower complaint
                  </Label>
                  <Input
                    type="select"
                    name="recipient"
                    id="recipient"
                    value={this.state.recipient}
                    onChange={this.onRecipientChange}
                  >
                    {this.renderRecipientsOptions()}
                  </Input>
                </FormGroup>
                {this.state.recipient === "4" && (
                  <FormGroup>
                    <Input
                      type="text"
                      name="recipientEmail"
                      id="recipientEmail"
                      placeholder="Enter the email of the recipient"
                      onChange={this.onRecipientEmailChange}
                      value={this.state.recipientEmail}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="text">Description</Label>
                  <FormText color="muted">
                    Describe the violation in detail. What rules have been
                    violated? Who is a suspect of the violation? When was the
                    violation committed and when was it discovered? Add any
                    additional details needed to investigate the case.
                  </FormText>
                  <Input
                    rows="8"
                    type="textarea"
                    name="text"
                    id="text"
                    onChange={this.onTextChange}
                    value={this.state.text}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="exampleFile">Attachments</Label>
                  <FormText>
                    {`Make sure the attachments don't contain any personal info if
                    you want to remain anonymous`}
                  </FormText>
                  <Input
                    multiple
                    type="file"
                    name="files"
                    id="attachments"
                    onChange={this.onFilesChange}
                  />
                  <FormText>Max size 10MB</FormText>
                </FormGroup>
                <FormGroup tag="fieldset">
                  <Label for="anonymous">Anonymity</Label>
                  <FormText>
                    {`By default, the form is fully anonymous and the system will
                    not collect your user details, IP address or any other
                    information to connect them with you. You can choose not to
                    be anonymous. If you write your name below, you will receive
                    a reply to your report within five days.`}
                  </FormText>
                  <FormGroup check>
                    <Label check>
                      <Input
                        defaultChecked
                        type="radio"
                        name="anonymous"
                        value="1"
                        onChange={this.onAnonymousChange}
                      />
                      Remain anonymous
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="anonymous"
                        value="0"
                        onChange={this.onAnonymousChange}
                      />
                      Not anonymous
                    </Label>
                  </FormGroup>
                  {!this.state.anonymous && (
                    <FormGroup>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Please enter your name here.."
                        onChange={this.onNameChange}
                        value={this.state.name}
                      />
                    </FormGroup>
                  )}
                </FormGroup>
                <div>
                  By clicking “Submit”, you will send a direct and urgent
                  notification to the selected recipient who will be responsible
                  to take appropriate actions, as described in the Safello
                  Whistleblowing Policy.
                </div>
                <br />
                <Button onClick={this.onSubmit}>Submit</Button>
              </Form>
            </div>
          </div>
        </div>
      </ContentWrapper>
    )
  }
}

export default Whistleblower
