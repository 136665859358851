import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row } from "reactstrap"
import FraudTransactionsChart from "./FraudTransactionsChart"
import FraudTransactionsPercentageChart from "./FraudTransactionsPercentageChart"
import FraudTransactionsAmountChart from "./FraudTransactionsAmountChart"
import FraudTransactionsAmountPercentageChart from "./FraudTransactionsAmountPercentageChart"
import FraudTransactionsAmountPercentageCompletedChart from "./FraudTransactionsAmountPercentageCompletedChart"
import Cards from "./Cards"
import Pies from "./Pies"
import TaggedOrdersChart from "./TaggedOrdersChart"
import TaggedOrdersVolumeChart from "./TaggedOrdersVolumeChart"

class Compliance extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Compliance</div>
        </div>
        <Row>
          <Cards />
        </Row>
        <Row>
          <Pies />
        </Row>
        <Row>
          <FraudTransactionsChart />
        </Row>
        <Row>
          <FraudTransactionsPercentageChart />
        </Row>
        <Row>
          <FraudTransactionsAmountChart />
        </Row>
        <Row>
          <FraudTransactionsAmountPercentageChart />
        </Row>
        <Row>
          <FraudTransactionsAmountPercentageCompletedChart />
        </Row>
        <Row>
          <TaggedOrdersChart />
        </Row>
        <Row>
          <TaggedOrdersVolumeChart />
        </Row>
      </ContentWrapper>
    )
  }
}

export default Compliance
