import React, { Component } from "react"
import { Row } from "reactstrap"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import CardUtils from "../../Common/CardUtils"
import BackOfficeApi from "../../Api/BackOfficeApi"
import BackOfficeCard from "../../Common/Card/BackOfficeCard"

class Cards extends Component {
  state = {
    cardData: {},
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    BackOfficeApi.endpoints.buyOrdersStats
      .getOne()
      .then(response => {
        if (response.ok) {
          return response.json()
        }
        throw Error()
      })
      .then(data => {
        this.setState({
          cardData: data,
        })
      })
      .catch(() => {
        ToastUtils.toastAPIError2()
      })
  }

  render() {
    if (this.state.cardData.waiting) {
      const stats = this.state.cardData
      return (
        <Row>
          <BackOfficeCard
            icon="fa-2x fas fa-paper-plane"
            title={Utils.formatNumber(stats.cryptoSent.amountSum, 0) + " SEK"}
            value={"Crypto sent: " + stats.cryptoSent.count}
            url="/orders/buy?status=cryptoSent"
            unit=""
            key="1"
            {...CardUtils.getCardColorForColumn(0)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-money-bill"
            title={Utils.formatNumber(stats.paid.amountSum, 0) + " SEK"}
            value={"Paid: " + stats.paid.count}
            url="/orders/buy?status=paid"
            unit=""
            key="2"
            {...CardUtils.getCardColorForColumn(1)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-clock"
            title={Utils.formatNumber(stats.waiting.amountSum, 0) + " SEK"}
            value={"Waiting: " + stats.waiting.count}
            url="/orders/buy?status=waiting"
            unit=""
            key="3"
            {...CardUtils.getCardColorForColumn(2)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-phone"
            title={
              Utils.formatNumber(stats.supportFollowUp.amountSum, 0) + " SEK"
            }
            value={"Follow up: " + stats.supportFollowUp.count}
            url="/orders/buy?status=fraud-follow"
            unit=""
            key="4"
            {...CardUtils.getCardColorForColumn(3)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-hand-spock"
            title={
              Utils.formatNumber(stats.supportHandling.amountSum, 0) + " SEK"
            }
            value={"Support handling: " + stats.supportHandling.count}
            url="/orders/buy?status=support-handling"
            unit=""
            key="5"
            {...CardUtils.getCardColorForColumn(4)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-undo"
            title={Utils.formatNumber(stats.toBeRefunded.amountSum, 0) + " SEK"}
            value={"To be refunded: " + stats.toBeRefunded.count}
            url="/orders/buy?status=to-be-refunded"
            unit=""
            key="6"
            {...CardUtils.getCardColorForColumn(5)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-user-secret"
            title={Utils.formatNumber(stats.suspicious.amountSum, 0) + " SEK"}
            value={"Suspicious: " + stats.suspicious.count}
            url="/orders/buy?status=suspicious"
            unit=""
            key="7"
            {...CardUtils.getCardColorForColumn(6)}
          />
          <BackOfficeCard
            icon="fa-2x fas fa-snowflake"
            title={Utils.formatNumber(stats.frozen.amountSum, 0) + " SEK"}
            value={"Frozen: " + stats.frozen.count}
            url="/orders/buy?status=frozen"
            unit=""
            key="8"
            {...CardUtils.getCardColorForColumn(7)}
          />
        </Row>
      )
    } else {
      let placeholders = [0, 1, 2, 3, 4, 5, 6, 7]
      let placeholderNodes = placeholders.map((placeHolder, i) => {
        return (
          <BackOfficeCard
            icon="fa-2x fas fa-question"
            title="*** *** SEK"
            value="Loading: ***"
            key={i}
            {...CardUtils.getCardColorForColumn(i)}
          />
        )
      })
      return <Row>{placeholderNodes}</Row>
    }
  }
}

export default Cards
