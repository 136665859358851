import React, { Component } from "react"
import { Button, Row, Col, CardBody, CardHeader } from "reactstrap"
import Utils from "../Common/Utils"
import ToastUtils from "../Common/ToastUtils"
import BackOfficeApi from "../Api/BackOfficeApi"

class UserDetailsSection extends Component {
  onResetPassword = () => {
    BackOfficeApi.endpoints.resetPassword
      .post({ id: this.props.user.id })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
        } else {
          ToastUtils.toastAPIError2()
        }
      })
  }

  onInactivate = () => {
    BackOfficeApi.endpoints.inactivate
      .post({ id: this.props.user.id })
      .then(response => {
        if (response.ok) {
          ToastUtils.toastExecuteSuccess2()
          window.location.href = "/admin/users/"
        } else {
          ToastUtils.toastAPIError2()
        }
      })
  }

  render() {
    if (!this.props.user) return <div />

    let user = this.props.user

    return (
      <div>
        <CardHeader className="card-section-header">User info</CardHeader>
        <CardBody>
          <Row className="no-gutters">
            <Col>
              <Row className="no-gutters">
                <Col>
                  <div>
                    <div className="details-row">
                      <span className="details-header">Email:</span>
                      <span className="details-value">{user.email}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">First name:</span>
                      <span className="details-value">{user.firstName}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Last name:</span>
                      <span className="details-value">{user.lastName}</span>
                    </div>
                    <div className="details-row">
                      <span className="details-header">Created:</span>
                      <span className="details-value">
                        {Utils.getUtcInLocalDateTime(user.created)}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="details-row">
                    <span className="details-header">Title:</span>
                    <span className="details-value">{user.title}</span>
                  </div>
                  <div className="details-row">
                    <span className="details-header">Username:</span>
                    <span className="details-value">{user.username}</span>
                  </div>
                  <Button
                    color="primary"
                    className="mt-2"
                    onClick={this.onResetPassword}
                  >
                    Reset password
                  </Button>{" "}
                  <Button
                    color="primary"
                    className="mt-2"
                    onClick={this.onInactivate}
                  >
                    Inactivate
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </div>
    )
  }
}
export default UserDetailsSection
