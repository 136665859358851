import React, { useEffect, useState } from "react"
import { Row, CardBody } from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"
import Utils from "../Common/Utils"

const CustomerExposureSection = ({ customer }) => {
  const [chainalysisData, setChainalysisData] = useState(null)

  useEffect(() => {
    if (customer)
      BackOfficeApi.endpoints.chainalysisUserKyt
        .getAll({ user: customer.id })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
        })
        .then(data => {
          setChainalysisData(data)
        })
  }, [customer])

  const toggleCategory = e => {
    let el = e.target.closest("tr")
    let name = el.getAttribute("id")
    let subCategories = document.getElementsByClassName(name)
    for (var i = 0; i < subCategories.length; i++) {
      subCategories[i].classList.toggle("d-none")
    }
  }
  const exposureData = exposure => {
    if (!exposure || !chainalysisData) return ""
    const data = chainalysisData
    if (!data) return ""
    let exposureTable = []

    let exposureRow = exposureDetails(data, exposure)

    if (exposureRow) {
      let categories = exposureRow.categories

      categories.sort(function (a, b) {
        return b.indirectSum - a.indirectSum
      })

      for (var key in categories) {
        const directPercent = (
          (100 * categories[key].directSum) /
          exposureRow.directSum
        ).toFixed(2)
        const indirectPercent = (
          (100 * categories[key].indirectSum) /
          exposureRow.indirectSum
        ).toFixed(2)
        let subCategories = categories[key].subCategories
        let catClass = key.replace(/\s+/g, "-")
        catClass += `-${exposure}`
        subCategories.sort(function (a, b) {
          return b.indirect - a.indirect
        })

        exposureTable.push(
          <React.Fragment key={key}>
            <tr
              id={catClass}
              onClick={toggleCategory}
              style={{ cursor: "pointer" }}
            >
              <td
                style={{
                  borderLeftColor: Utils.getExposureCategoryColor(
                    categories[key].category,
                  ),
                  borderLeftWidth: 6,
                }}
              >
                <div style={{ fontSize: 12 }}>{categories[key].category}</div>
              </td>
              <td>
                {directPercent > 0 ? (
                  <div
                    style={{
                      fontSize: 10,
                      justifyContent: "space-between",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    <div>
                      {`$${Utils.formatNumber(
                        categories[key].directSum.toFixed(0),
                      )}`}
                    </div>

                    <div>{directPercent}%</div>
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td>
                <div
                  style={{
                    fontSize: 10,
                    justifyContent: "space-between",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <div>
                    {`$${Utils.formatNumber(
                      categories[key].indirectSum.toFixed(0),
                    )}`}
                  </div>

                  <div>{indirectPercent}%</div>
                </div>
              </td>
            </tr>

            {subCategories &&
              subCategories.map(row => {
                return (
                  <tr
                    key={row.name}
                    className={"d-none animated fadeIn " + catClass}
                    style={{ fontSize: 10 }}
                  >
                    <td>{row.name}</td>
                    <td>
                      {row.direct ? (
                        <span>${Utils.formatNumber(row.direct)}</span>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>${Utils.formatNumber(row.indirect)}</td>
                  </tr>
                )
              })}
          </React.Fragment>,
        )
      }
    }

    let direction = exposure === "sent" ? "Sending" : "Receiving"

    if (exposureTable.length) {
      exposureTable = [
        <table
          key="header"
          className="table table-bordered table-sm"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr>
              <th style={{ padding: 10 }}> </th>
              <th style={{ padding: 10 }}>Direct</th>
              <th style={{ padding: 10 }}>Total</th>
            </tr>
          </thead>
          <tbody>{exposureTable}</tbody>
        </table>,
      ]
    } else {
      direction = "No " + direction
    }

    exposureTable.unshift(
      <div key="hr">
        <h4>{direction} Exposure</h4>
      </div>,
    )

    return exposureTable
  }

  const exposureDetails = (data, exposure) => {
    if (!exposure) return null

    let rows = data.exposureDetails
    if (!rows) return null

    let categories = {}
    let directSum = 0
    let indirectSum = 0

    for (let i = 0; i < rows.length; i++) {
      if (rows[i][exposure + "IndirectExposure"]) {
        const category = rows[i].cluster.category
        const name = rows[i].cluster.name
        const direct = rows[i][exposure + "DirectExposure"]
        const indirect = rows[i][exposure + "IndirectExposure"]
        if (!categories[category]) {
          categories[category] = {
            directSum: 0,
            indirectSum: 0,
            subCategories: [],
            category,
          }
        }
        categories[category].directSum += direct
        categories[category].indirectSum += indirect
        categories[category].subCategories.push({ name, direct, indirect })
      }
      directSum += rows[i][exposure + "DirectExposure"]
      indirectSum += rows[i][exposure + "IndirectExposure"]
    }

    let categoriesArray = []
    for (let cat in categories) {
      categoriesArray.push(categories[cat])
    }

    return { categories: categoriesArray, directSum, indirectSum }
  }

  return (
    <div>
      <CardBody className="pt-0">
        <div
          className="details-header"
          style={{ padding: "1.5rem 1.25rem 0.75rem 0" }}
        >
          <p>Exposure:</p>
        </div>
        <Row style={{ padding: 12, gap: 12, flexWrap: "nowrap" }}>
          <CardBody
            className={"card shadow-sm border"}
            style={{ maxWidth: "75%" }}
          >
            {!!chainalysisData && exposureData("sent")}
          </CardBody>
          <CardBody
            className={"card shadow-sm border"}
            style={{ maxWidth: "75%" }}
          >
            {!!chainalysisData && exposureData("received")}
          </CardBody>
        </Row>
      </CardBody>
    </div>
  )
}

export default CustomerExposureSection
