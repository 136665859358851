import React, { useState, useEffect, useContext, useMemo } from "react"

import {
  Col,
  Row,
  CardBody,
  CardHeader,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"

import BackOfficeApi from "../../Api/BackOfficeApi"
import TreasuryContext from "./TreasuryContext"
import { CryptoTickerContext } from "../../../App"
import CryptoSelector from "../../Common/CryptoSelector"
import { NOT_SUPPORTED_CRYPTO_BALANCER } from "../../Common/constants"

const GeneralActions = ({ disabled }) => {
  const { gatewayStatusData, bitstampBalanceData, blockchainBalance } =
    useContext(TreasuryContext)
  const tickers = useContext(CryptoTickerContext)?.cryptoTickerData
  const filteredTickers = useMemo(
    () =>
      tickers?.filter(
        ticker => !NOT_SUPPORTED_CRYPTO_BALANCER.includes(ticker.symbol),
      ),
    [tickers],
  )

  const [showSendLiquidityModal, setShowSendLiquidityModal] = useState(false)
  const [sendFrom, setSendFrom] = useState("")
  const [sendTo, setSendTo] = useState("")
  const [crypto, setCrypto] = useState("")
  const [amount, setAmount] = useState(0)
  const [balance, setBalance] = useState(0)
  const [providerCryptoBalanceMap, setProviderCryptoBalanceMap] = useState(null)

  let cardClass = "card mb-2"
  const toggleSendLiquidityModal = () => {
    setShowSendLiquidityModal(!showSendLiquidityModal)
  }

  const getBalance = (provider, crypto) => {
    const _balance = providerCryptoBalanceMap[`${provider}_${crypto}`]
    if (_balance == null) return null // data has not loaded
    if (!_balance) return 0
    return _balance
  }

  useEffect(() => {
    if (gatewayStatusData && bitstampBalanceData) {
      const providerCryptoBalanceMap = {}
      filteredTickers.map(({ symbol }) => {
        providerCryptoBalanceMap[`HOTWALLET_${symbol}`] = gatewayStatusData[
          symbol
        ]
          ? gatewayStatusData[symbol].balance
          : null
        providerCryptoBalanceMap[`BITSTAMP_${symbol}`] = bitstampBalanceData[
          symbol
        ]
          ? bitstampBalanceData[symbol].BTC
          : null
        providerCryptoBalanceMap[`BLOCKCHAIN_${symbol}`] = blockchainBalance[
          symbol
        ]
          ? blockchainBalance[symbol]
          : null
      })
      setProviderCryptoBalanceMap(providerCryptoBalanceMap)
    }
  }, [gatewayStatusData, bitstampBalanceData])

  useEffect(() => {
    if (sendFrom && crypto) {
      setBalance(getBalance(sendFrom, crypto))
    }
  }, [sendFrom, crypto])
  const doTransfer = () => {
    if (checkValidInput()) {
      Utils.showSweetAlertYesCancel(
        "Please confirm the transfer?",
        `From: ${sendFrom}\nTo: ${sendTo}\nAmount: ${amount} ${crypto}`,
      ).then(confirmed => {
        if (confirmed) {
          const params = {
            from: sendFrom,
            to: sendTo,
            crypto,
            amount,
          }
          BackOfficeApi.endpoints.transferLiquidity
            .post({}, params)
            .then(response => {
              if (response.ok) {
                ToastUtils.toastExecuteSuccess2()
                toggleSendLiquidityModal()
                return
              }
              throw Error()
            })
            .catch(() => {
              ToastUtils.toastAPIError2()
            })
        }
      })
    } else {
      ToastUtils.toastExecuteError2()
    }
  }

  const checkValidInput = () => {
    if (sendFrom && sendTo && crypto && !isNaN(amount) && amount > 0) {
      if (balance && amount <= balance) {
        return true
      }
    }
    return false
  }

  const providers = {
    HOTWALLET: {
      value: "HOTWALLET",
      name: "Hotwallet",
    },
    BITSTAMP: {
      value: "BITSTAMP",
      name: "Bitstamp",
    },
    BLOCKCHAIN: {
      value: "BLOCKCHAIN",
      name: "Blockchain",
    },
    FIREBLOCKS: {
      value: "FIREBLOCKS",
      name: "Fireblocks",
    },
  }
  const toProviders = Object.values(providers)
  const fromProviders = toProviders.filter(
    provider => provider.value !== "FIREBLOCKS",
  )

  return (
    <div className={cardClass}>
      <CardHeader>
        <h4>
          <em className="fa-fw fa fa-exclamation-circle mr-2" />
          General Actions
        </h4>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Button
              color="primary"
              className="mr-2 mb-2"
              onClick={() => setShowSendLiquidityModal(true)}
              disabled={disabled}
            >
              Transfer liquidity
            </Button>
          </Col>
        </Row>
      </CardBody>

      <Modal isOpen={showSendLiquidityModal} toggle={toggleSendLiquidityModal}>
        <ModalHeader toggle={toggleSendLiquidityModal}>
          Transfer liquidity
        </ModalHeader>
        <ModalBody>
          <p>From</p>
          <select
            name="from"
            value={sendFrom}
            className="custom-select mb-2"
            onChange={e => setSendFrom(e.target.value)}
          >
            <option value="">-- None selected --</option>
            {fromProviders.map(
              provider =>
                provider.value !== sendTo && (
                  <option key={"from_" + provider.value} value={provider.value}>
                    {provider.name}
                  </option>
                ),
            )}
          </select>
          <p>To</p>
          <select
            name="to"
            value={sendTo}
            className="custom-select mb-2"
            onChange={e => setSendTo(e.target.value)}
          >
            <option value="">-- None selected --</option>
            {toProviders.map(
              provider =>
                provider.value !== sendFrom && (
                  <option key={"to_" + provider.value} value={provider.value}>
                    {provider.name}
                  </option>
                ),
            )}
          </select>
          <p>Crypto</p>
          <CryptoSelector
            onChange={setCrypto}
            value={crypto}
            style={{ width: "100%" }}
            nullValue={"-- None selected --"}
            filteredTickers={filteredTickers}
          />

          <p>Amount to transfer</p>
          <div lang="en-US">
            <Input
              type="number"
              placeholder="Enter amount here"
              onChange={e => setAmount(e.target.value)}
              value={amount}
            />
            {crypto && sendFrom && balance !== null && (
              <p>{`Available balance: ${balance} ${crypto}`}</p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={doTransfer}>
            Send
          </Button>
          <Button color="secondary" onClick={toggleSendLiquidityModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default GeneralActions
