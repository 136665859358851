import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../../Api/BackOfficeApi"

class EditBuyOrderModal extends Component {
  state = {
    amount: "",
    fee: "",
    cryptoAddress: "",
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.order !== prevProps.order ||
      prevProps.open !== this.props.open
    ) {
      let o = this.props.order

      this.setState({
        amount: o.amount,
        fee: o.fee,
        cryptoAddress: o.cryptoAddress,
      })
    }
  }

  onEdit = () => {
    let formData = new FormData()
    formData.append("amount", this.getValue(this.state.amount))
    formData.append("fee", this.getValue(this.state.fee))
    formData.append("cryptoAddress", this.getValue(this.state.cryptoAddress))

    BackOfficeApi.endpoints.editBuyOrder
      .create(formData, { id: this.props.order.id })
      .then(response => {
        if (response.ok) {
          if (this.props.onSave) {
            this.props.onSave()
          }
        } else {
          response.text().then(text => {
            if (this.props.onError) {
              this.props.onError(text)
            }
          })
        }
      })
  }

  onToggle = () => {
    if (this.props.onToggle) {
      this.props.onToggle()
    }
  }

  getValue = value => {
    return value == null ? "" : value
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    if (!this.props.order) {
      return ""
    }

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.onToggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.onToggle}>Edit Buy Order</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="amount">
                Amount
              </Label>
              <Input
                className="col-xl-8"
                type="number"
                name="amount"
                id="amount"
                value={this.state.amount}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="fee">
                Fee
              </Label>
              {this.props.order.apiInternal ? (
                <Input
                  className="col-xl-8"
                  type="number"
                  name="fee"
                  id="fee"
                  value={this.state.fee}
                  onChange={this.onChange}
                />
              ) : (
                <span>
                  {Math.round(this.state.fee * 10000) / 100}% (through partner)
                </span>
              )}
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="cryptoAddress">
                Crypto address
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="cryptoAddress"
                id="cryptoAddress"
                value={this.state.cryptoAddress}
                onChange={this.onChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onEdit}>
            Save
          </Button>
          <Button color="secondary" onClick={this.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default EditBuyOrderModal
