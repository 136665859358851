const Menu = [
  {
    heading: "Navigation",
  },
  {
    name: "Order-handling",
    icon: "icon-speedometer",
    permission: "ORDER_HANDLING",
    submenu: [
      {
        name: "Overview dashboard",
        path: "/dashboards/support",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Buy orders",
        path: "/orders/buy",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Sell orders",
        path: "/orders/sell?status=received-sold",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Payouts",
        path: "/orders/payout?channel=1&status=PENDING",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Wallet deposits",
        path: "/wallet/incoming",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Wallet withdrawals",
        path: "/wallet/outgoing",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Swaps",
        path: "/wallet/swaps",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Customers",
        path: "/customers",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Comment search",
        path: "/comment/search",
        permission: "ORDER_HANDLING",
      },
      {
        name: "Payment notifications",
        path: "/transactions/paymentnotifications",
        permission: "ORDER_HANDLING",
      },
    ],
  },
  {
    name: "Compliance",
    icon: "icon-magnifier",
    permission: "COMPLIANCE",
    submenu: [
      {
        name: "Overview dashboard",
        path: "/dashboards/compliance",
        permission: "COMPLIANCE",
      },
      {
        name: "Customer review list",
        path: "/review",
        permission: "COMPLIANCE",
      },
      {
        name: "Tools",
        path: "/tools",
        permission: "COMPLIANCE",
      },
    ],
  },
  {
    name: "Partners",
    icon: "icon-people",
    permission: "PARTNERS",
    submenu: [
      {
        name: "Overview dashboard",
        path: "/dashboards/partners",
        permission: "PARTNERS",
      },
      {
        name: "Partner list",
        path: "/partners",
        permission: "PARTNERS",
      },
    ],
  },
  {
    name: "Business",
    icon: "icon-diamond",
    permission: "BUSINESS",
    submenu: [
      {
        name: "Overview dashboard",
        path: "/dashboards/business",
        permission: "BUSINESS",
      },
    ],
  },
  {
    name: "Mobile",
    icon: "icon-phone",
    permission: "MOBILE",
    submenu: [
      {
        name: "Overview dashboard",
        path: "/dashboards/mobile-app",
        permission: "MOBILE",
      },
      {
        name: "Push notification board",
        path: "/dashboards/mobile",
        permission: "MOBILE",
      },
    ],
  },
  {
    name: "Financial & Statistics",
    icon: "icon-book-open",
    permission: "FINANCIAL_STATISTICS",
    submenu: [
      {
        name: "Financial",
        path: "/dashboards/financial",
        permission: "FINANCIAL_STATISTICS",
      },
      {
        name: "Wallet",
        path: "/dashboards/wallet",
        permission: "FINANCIAL_STATISTICS",
      },
      {
        name: "Accounting",
        path: "/dashboards/accounting",
        permission: "FINANCIAL_STATISTICS",
      },
      {
        name: "Metrics",
        path: "/dashboards/metrics",
        permission: "FINANCIAL_STATISTICS",
      },
      {
        name: "Statistics",
        path: "/dashboards/statistics",
        permission: "FINANCIAL_STATISTICS",
      },
      {
        name: "Demographics",
        path: "/dashboards/demographics",
        permission: "FINANCIAL_STATISTICS",
      },
    ],
  },
  {
    name: "Treasury",
    icon: "icon-credit-card",
    path: "/dashboards/treasury",
    permission: "TREASURY",
    submenu: [
      {
        name: "Dashboard",
        path: "/dashboards/treasury",
        permission: "TREASURY",
      },
      {
        name: "Order limits",
        path: "/orders/limits",
        permission: "TREASURY",
      },
    ],
  },
  {
    name: "Operations",
    icon: "icon-reload",
    permission: "OPERATIONS",
    submenu: [
      {
        name: "Coin Functions",
        path: "/operations/coin-functions/",
        permission: "OPERATIONS",
      },
    ],
  },
  {
    name: "Admin",
    icon: "icon-settings",
    permission: "ADMIN",
    submenu: [
      {
        name: "User list",
        path: "/admin/users/",
        permission: "ADMIN",
      },
      {
        name: "Add a new user",
        path: "/admin/adduser",
        permission: "ADMIN",
      },
      {
        name: "Permissions",
        path: "/admin/permissions/",
        permission: "ADMIN",
      },
    ],
  },
  {
    name: "Whistleblower",
    icon: "icon-eye",
    path: "/whistleblower",
  },
  {
    name: "Developer",
    icon: "icon-cup",
    permission: "DEVELOPER",
    submenu: [
      {
        name: "Feature flag",
        path: "/developer/feature-flag/",
        permission: "DEVELOPER",
      },
      {
        name: "Customer takeover",
        path: "/developer/customer-takeover/",
        permission: "DEVELOPER",
      },
    ],
  },
  {
    name: "Log out",
    icon: "icon-logout",
  },
]

export default Menu
