import React, { useEffect, useRef, useState } from "react"
import { Redirect } from "react-router-dom"
import { Input } from "reactstrap"
import FormValidator from "../Common/FormValidator.js"
import BackOfficeApi from "../Api/BackOfficeApi.js"
import FormData from "form-data"
import bodymovin from "bodymovin"
import logoAnimationData from "../Media/Json/logo_animation.json"
import StorageUtils from "../Common/StorageUtils.js"
import { useContext } from "react"
import { CryptoTickerContext } from "../../App.js"

const Login = () => {
  const [formLogin, setFormLogin] = useState({
    username: "",
    password: "",
  })
  const [redirectDashboard, setRedirectDashboard] = useState(false)
  const [error, setError] = useState("")
  const animationContainer = useRef(null)
  const { fetchCryptoTickerData } = useContext(CryptoTickerContext)

  useEffect(() => {
    const animationProperties = {
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: logoAnimationData,
    }

    bodymovin.loadAnimation(animationProperties)
  }, [])

  const validateOnChange = event => {
    const input = event.target
    const value = input.type === "checkbox" ? input.checked : input.value

    const result = FormValidator.validate(input)

    setFormLogin(prevState => ({
      ...prevState,
      [input.name]: value,
      errors: {
        ...prevState.errors,
        [input.name]: result,
      },
    }))
  }

  const onSubmit = e => {
    e.preventDefault()

    const form = e.target
    const inputs = [...form.elements].filter(i =>
      ["INPUT", "SELECT"].includes(i.nodeName),
    )

    const { errors, hasError } = FormValidator.bulkValidate(inputs)

    setFormLogin(prevState => ({
      ...prevState,
      errors,
    }))
    setError("")

    if (hasError) return

    let formData = new FormData()
    formData.append("username", formLogin.username)
    formData.append("password", formLogin.password)

    BackOfficeApi.endpoints.login
      .create(formData)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          let errorMessage = "Something went wrong, please contact support"
          if (response.status === 403) {
            errorMessage = "Wrong username or password"
          } else {
            errorMessage = "Failed to retrieve data"
          }
          throw errorMessage
        }
      })
      .then(user => {
        StorageUtils.setUser(user)
        setRedirectDashboard(true)
        // Fetch crypto ticket data from BI on login
        fetchCryptoTickerData()
      })
      .catch(err => {
        if (typeof err !== "object") {
          setError(err)
        }
      })
  }

  const hasError = (inputName, method) =>
    formLogin.errors &&
    formLogin.errors[inputName] &&
    formLogin.errors[inputName][method]

  if (redirectDashboard) {
    var currentUrl = new URL(window.location.href)
    var redirectback = currentUrl.searchParams.get("redirectback")
    if (redirectback) {
      return (window.location.href = redirectback)
    } else {
      return <Redirect to="/welcome" />
    }
  }

  return (
    <div className="block-center wd-xl" style={{ marginTop: "80px" }}>
      <div className="card card-flat">
        <div className="card-header text-center">
          <div>
            <div
              style={{ width: 60, height: 60, display: "inline-block" }}
              ref={animationContainer}
            />
            <img
              style={{ display: "inline-block" }}
              src="/img/safellologo_text.png"
              alt="Logo"
            />
          </div>
        </div>
        <div className="card-body">
          <form
            className="mb-3"
            name="formLogin"
            onSubmit={onSubmit}
            method="post"
          >
            <div className="form-group">
              <h4>
                <center>Login</center>
              </h4>
              <div className="input-group with-focus">
                <Input
                  type="text"
                  name="username"
                  className="border-right-0"
                  placeholder="Enter your username"
                  invalid={
                    hasError("email", "required") ||
                    hasError("username", "username")
                  }
                  onChange={validateOnChange}
                  data-validate='["required"]'
                  value={formLogin.username}
                  autoFocus
                />
                <div className="input-group-append">
                  <span className="input-group-text text-muted bg-transparent border-left-0">
                    <em className="fa fa-envelope" />
                  </span>
                </div>
                {hasError("username", "required") && (
                  <span className="invalid-feedback">Field is required</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="input-group with-focus">
                <Input
                  type="password"
                  id="id-password"
                  name="password"
                  className="border-right-0"
                  placeholder="Enter your password"
                  invalid={hasError("password", "required")}
                  onChange={validateOnChange}
                  data-validate='["required"]'
                  value={formLogin.password}
                />
                <div className="input-group-append">
                  <span className="input-group-text text-muted bg-transparent border-left-0">
                    <em className="fa fa-lock" />
                  </span>
                </div>
                <span className="invalid-feedback">Field is required</span>
              </div>
            </div>
            {error && (
              <div className="text-center text-danger">
                <span>{error}</span>
              </div>
            )}
            <button className="btn btn-block btn-primary mt-3" type="submit">
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
