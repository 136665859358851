import React, { useEffect, useState } from "react"
import BackOfficeApi from "../../Api/BackOfficeApi"
// components
import ContentWrapper from "../../Layout/ContentWrapper"
import CoinFunctionsTable from "./CoinFunctionsTable"
import EditBadgesModal from "../../Customers/EditBadgesModal"
import { useMemo } from "react"
import ToastUtils from "../../Common/ToastUtils"
import Utils from "../../Common/Utils"
import Divider from "../../Layout/Divider"
import TradingPairsTable from "./TradingPairsTable"

const AVAILABLE_ACTIONS = [
  {
    id: "buy",
    name: "buy",
  },
  {
    id: "sell",
    name: "sell",
  },
  {
    id: "withdraw",
    name: "withdraw",
  },
  {
    id: "deposit",
    name: "deposit",
  },
]

const CoinFunctionsPage = () => {
  const [fetchingCryptoTickers, setFetchingCryptoTickers] = useState(false)
  const [fetchingTradingPairs, setFetchingTradingPairs] = useState(false)

  const [coinFunctionsData, setCoinFunctionsData] = useState([])
  const [tradingPairsData, setTradingPairsData] = useState([])
  const [showEditCryptoActionModal, setShowEditCryptoActionModal] =
    useState(false)
  const [selectedCryptoToEdit, setSelectedCryptoToEdit] = useState(null)

  const fetchCryptoTickerData = async () => {
    setFetchingCryptoTickers(true)

    const response = await BackOfficeApi.endpoints.getCryptos.getOne()
    if (response?.ok) {
      const data = await response.json()
      setCoinFunctionsData(data)
      setFetchingCryptoTickers(false)
    } else {
      console.error("Failed fetching crypto functions data!")
      setCoinFunctionsData([])
      setFetchingCryptoTickers(false)
    }
  }

  const fetchTradingPairsData = async () => {
    setFetchingTradingPairs(true)

    const response = await BackOfficeApi.endpoints.getAllTradingPairs.getOne()
    if (response?.ok) {
      const data = await response.json()
      setTradingPairsData(data)
      setFetchingTradingPairs(false)
    } else {
      console.error("Failed fetching trading pairs data!")
      setTradingPairsData([])
      setFetchingTradingPairs(false)
    }
  }

  const onEditCryptoFunction = selectedCrypto => {
    setShowEditCryptoActionModal(true)
    setSelectedCryptoToEdit(selectedCrypto)
  }

  const onEditTradingPair = async (pair, active) => {
    const newStatusText = active ? "ACTIVE" : "INACTIVE"
    const newStatusHint = active
      ? "As a result, customers will be able to trade with this pair."
      : "As a result, customers will no longer be able to trade with this pair."

    const confirmed = await Utils.showSweetAlertAreYouSure(
      "Are you sure?",
      `- Action: Change the trading pair '${pair}' to '${newStatusText}' \n
      ${newStatusHint}`,
    )
    let formData = new FormData()
    formData.append("pair", pair)
    formData.append("active", active)
    if (confirmed) {
      const response =
        await BackOfficeApi.endpoints.setTradingPairActive.create(formData)
      if (response?.ok) {
        ToastUtils.toastExecuteSuccess2()
        fetchTradingPairsData()
      } else {
        Utils.displayError(response)
      }
    }
  }

  const executeEditCryptoActions = async (action, isAdd) => {
    const params = {
      crypto: selectedCryptoToEdit,
      allowedActions: {
        [action?.id]: isAdd,
      },
    }
    const response =
      await BackOfficeApi.endpoints.editCryptoFunctionalities.postJson({
        body: params,
      })
    if (response?.ok) {
      ToastUtils.toastExecuteSuccess2()
      fetchCryptoTickerData()
    } else {
      Utils.displayError(response)
    }
  }

  const selectedCryptoActiveActions = useMemo(
    () =>
      !selectedCryptoToEdit
        ? []
        : coinFunctionsData
            ?.find(data => data.symbol === selectedCryptoToEdit)
            ?.availableActions?.map(action => ({ id: action, name: action })),
    [selectedCryptoToEdit, coinFunctionsData],
  )

  useEffect(() => {
    fetchCryptoTickerData()
    fetchTradingPairsData()
  }, [])

  return (
    <ContentWrapper>
      <div className="content-heading">
        <div>Coin Functions Dashboard</div>
      </div>
      <CoinFunctionsTable
        coinFunctionsData={coinFunctionsData}
        onEditCryptoFunction={onEditCryptoFunction}
        loading={fetchingCryptoTickers}
      />
      <Divider />
      <div className="card-section-header" style={{ marginBottom: 10 }}>
        Trading pairs (Swapping)
      </div>
      <TradingPairsTable
        tradingPairsData={tradingPairsData}
        onEditTradingPair={onEditTradingPair}
        loading={fetchingTradingPairs}
      />
      <EditBadgesModal
        open={showEditCryptoActionModal}
        toggle={() => setShowEditCryptoActionModal(!showEditCryptoActionModal)}
        activeData={selectedCryptoActiveActions}
        onAdd={action => executeEditCryptoActions(action, true)}
        onRemove={action => executeEditCryptoActions(action, false)}
        allData={AVAILABLE_ACTIONS}
        name={`${selectedCryptoToEdit} actions`}
      />
    </ContentWrapper>
  )
}

export default CoinFunctionsPage
