import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import ReactDataGrid from "react-data-grid"
import ContentWrapper from "../Layout/ContentWrapper"
import DataGridEmpty from "../Common/DataGridEmpty"
import BackOfficeApi from "../Api/BackOfficeApi"
// utils
import ToastUtils from "../Common/ToastUtils"
import { DataGridLocalDateTimeFormatter } from "../Common/Utils"
import useWindowDimensions from "../../utils/hooks/useWindowDimensions"
import {} from "../../utils/fetch"
import { swapTransactionToWalletTransaction } from "../../utils/format"
// components
import DropdownList from "../Common/DropdownList"
import {
  orderIDFormatter,
  statusFormatter,
  customerFormatter2,
  tagsFormatter,
} from "../../utils/format"
import CryptoSelector from "../Common/CryptoSelectorV2"
import EditTags from "../Common/EditTags"
import XmlReport from "../Orders/XmlReport"

const _fetchSize = 30

const SwapTransactionList = ({ location }) => {
  const searchParams = new URLSearchParams(location.search)
  const customerId = searchParams.get("customer")

  const [rowOffsetHeight, setRowOffsetHeight] = useState(0)
  const [orders, setOrders] = useState([])
  const [chosenIds, setChosenIds] = useState([])
  const [noMoreData, setNoMoreData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusFilterValue, setStatusFilterValue] = useState("All")
  const [selectedCryptoFilter, setSelectedCryptoFilter] = useState("All")
  const [editTagsModal, setEditTagsModal] = useState(false)
  const [xmlReportModal, setXmlReportModal] = useState(false)

  const { height } = useWindowDimensions()

  const transactionColumns = [
    {
      key: "orderInfo",
      name: "ID",
      width: 120,
      formatter: props =>
        orderIDFormatter(props, chosenIds, handleIdCheckClick),
    },
    {
      key: "customerId",
      name: "Customer",
      formatter: props => customerFormatter2(props.value),
      width: 80,
    },
    {
      key: "from",
      name: "From",
      width: 150,
    },
    {
      key: "to",
      name: "To",
      width: 150,
    },
    {
      key: "fiatAmount",
      name: "Fiat Amount",
      width: 150,
    },
    {
      key: "issued",
      name: "Created",
      formatter: DataGridLocalDateTimeFormatter,
      width: 140,
    },
    {
      key: "status",
      name: "Status",
      width: 120,
      formatter: statusFormatter,
    },
    {
      key: "tags",
      name: "Tags",
      formatter: tagsFormatter,
    },
  ]

  const columns = transactionColumns

  useEffect(() => {
    loadTransactionData()
  }, [statusFilterValue, selectedCryptoFilter])

  const handleIdCheckClick = e => {
    const checked = e.target.checked
    const value = e.target.value

    let newChosenIds = chosenIds

    const index = newChosenIds.indexOf(value)

    if (checked) {
      if (index === -1) {
        newChosenIds.push(value)
      }
    } else {
      if (index > -1) {
        newChosenIds.splice(index, 1)
      }
    }
    setChosenIds(newChosenIds)
  }

  const loadTransactionData = async (first = 0, append = false) => {
    setIsLoading(true)

    let params = {
      first: first,
      limit: _fetchSize,
    }
    if (statusFilterValue !== "All") {
      params.status = statusFilterValue
    }

    if (selectedCryptoFilter !== "All") {
      params.asset = selectedCryptoFilter
    }

    if (customerId) {
      // Fetch all swap orders made by customer
      const response =
        await BackOfficeApi.endpoints.walletSwapTransactionsCustomer.getOne({
          id: customerId,
        })
      if (response?.ok) {
        const json = await response.json()
        const formattedData = formatTransactionData(json.data)
        addData(formattedData, append)
        setIsLoading(false)
      } else {
        ToastUtils.toastAPIError2()
        setIsLoading(false)
      }
    } else {
      // Fetch all swap orders
      BackOfficeApi.endpoints.walletSwapTransactions
        .getAll(params)
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw Error()
        })
        .then(data => {
          const formattedData = formatTransactionData(data.data)
          addData(formattedData, append)
        })
        .catch(() => {
          ToastUtils.toastAPIError2()
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const onScroll = e => {
    const ordersSize = orders.length
    let halfWayVScroll = ordersSize * rowOffsetHeight - 800
    let currentVScroll = e.scrollTop
    if (currentVScroll >= halfWayVScroll && !isLoading && !noMoreData) {
      if (ordersSize < 1000) {
        loadTransactionData(ordersSize, true)
      }
    }
  }

  const addData = (data, append) => {
    if (append) {
      if (data.length < _fetchSize) {
        setNoMoreData(true)
      }
      setOrders(orders.concat(data.slice(0)))
    } else {
      setOrders(data.slice(0))
      setNoMoreData(false)
    }
  }

  const renderStatusFilters = () => {
    return (
      <div className="ml-auto form-inline">
        <DropdownList
          className="pr-2"
          label="Status"
          buttonColor="primary"
          value={statusFilterValue}
          onSelectItem={item => setStatusFilterValue(item.value)}
        >
          {[
            //TODO filter by available statuses
            { value: "All", text: "All" },
            { divider: true },
            { value: "PENDING", text: "Pending" },
            { value: "PROCESSING", text: "Processing" },
            { value: "REJECTED", text: "Rejected" },
            { value: "COMPLETED", text: "Completed" },
          ]}
        </DropdownList>
      </div>
    )
  }

  const renderFilters = () => {
    return (
      <React.Fragment>
        {renderStatusFilters()}
        <CryptoSelector
          onChange={setSelectedCryptoFilter}
          selectedCrypto={selectedCryptoFilter}
        />
      </React.Fragment>
    )
  }

  const renderHeaderActions = () => {
    let list = [{ value: "edit_tags", text: "Edit tags" }]

    if (customerId) {
      list.push({ value: "report", text: "Report" })
    }
    return (
      <DropdownList
        className="pr-2"
        buttonColor="secondary"
        label="Bulk action"
        onSelectItem={doHeaderAction}
      >
        {list}
      </DropdownList>
    )
  }

  const doHeaderAction = item => {
    if (item.value === "select_all") {
      const x = document.getElementsByClassName("custom-control-input")
      for (let i = 0; i <= x.length; i++) {
        if (x[i] && !x[i].checked) {
          x[i].click()
        }
      }
      return
    }

    if (!chosenIds || chosenIds.length === 0) {
      alert("Please choose some orders first.")
      return
    }

    switch (item.value) {
      case "edit_tags":
        setEditTagsModal(true)
        break
      case "report":
        setXmlReportModal(true)
        break
      default:
      //do nothing
    }
  }

  const tagsEditFinished = () => {
    clearSelectedCheckboxes()
    loadTransactionData()
    ToastUtils.toastExecuteSuccess2()
  }

  const clearSelectedCheckboxes = () => {
    setChosenIds(chosenIds.splice(0, chosenIds.length))

    const x = document.getElementsByClassName("custom-control-input")
    for (let i = 0; i <= x.length; i++) {
      if (x[i] && x[i].checked) {
        x[i].checked = false
      }
    }
  }

  const headerTitle = customerId
    ? `Swap orders for customer: ${customerId}`
    : "Swap orders"

  return (
    <ContentWrapper>
      <div className="content-heading">
        {renderHeaderActions()}
        <div>{headerTitle}</div>
        {/* Only able to use filters for "All Swap Orders" view */}
        {!customerId && renderFilters()}
      </div>
      <Container fluid className={isLoading ? "whirl standard" : ""}>
        <ReactDataGrid
          columns={columns}
          rowGetter={i => orders[i]}
          rowsCount={orders.length}
          minHeight={height * 0.8}
          emptyRowsView={orders ? null : DataGridEmpty}
          onScroll={onScroll}
          ref={element => {
            if (element !== null) {
              setRowOffsetHeight(element.getRowOffsetHeight())
            }
          }}
        />
      </Container>
      {editTagsModal && (
        <EditTags
          ids={chosenIds}
          open={editTagsModal}
          toggle={() => setEditTagsModal(!editTagsModal)}
          onChange={tagsEditFinished}
          isAggregated={true}
          type={"SWAP_ORDER"}
        />
      )}
      <XmlReport
        orderOrTransactionIds={getIdsFromChosenIds(chosenIds)}
        customerId={customerId}
        open={xmlReportModal}
        type={"SWAP"}
        onToggle={() => setXmlReportModal(!xmlReportModal)}
      />
    </ContentWrapper>
  )
}

// Formats from example: "BUY-1823" => 1823
const getIdsFromChosenIds = chosenIds => {
  return chosenIds.map(value => value.split("-")[1])
}

const formatTransactionData = ordersData => {
  return ordersData.map(order => swapTransactionToWalletTransaction(order))
}

export default SwapTransactionList
