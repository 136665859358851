import React, { Component } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import BackOfficeApi from "../Api/BackOfficeApi"

class NewFeeRevisionModal extends Component {
  state = {
    internalBuyFee: "0.05",
    internalSellFee: "0.05",
    externalBuyFee: "0.02",
    externalSellFee: "0.02",
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.appApi !== prevProps.appApi ||
      prevProps.open !== this.props.open
    ) {
      let a = this.props.appApi

      this.setState({
        internalBuyFee: a.currentFeeRevision.internalBuyFee,
        internalSellFee: a.currentFeeRevision.internalSellFee,
        externalBuyFee: a.currentFeeRevision.externalBuyFee,
        externalSellFee: a.currentFeeRevision.externalSellFee,
      })
    }
  }

  onSave = () => {
    let formData = new FormData()

    formData.append("internalBuyFee", this.getValue(this.state.internalBuyFee))
    formData.append(
      "internalSellFee",
      this.getValue(this.state.internalSellFee),
    )
    formData.append("externalBuyFee", this.getValue(this.state.externalBuyFee))
    formData.append(
      "externalSellFee",
      this.getValue(this.state.externalSellFee),
    )

    BackOfficeApi.endpoints.newFeeRevision
      .create(formData, {
        id: this.props.partner.id,
        appId: this.props.appApi.id,
      })
      .then(response => {
        if (response.ok) {
          if (this.props.onSave) {
            this.props.onSave()
          }
        } else {
          response.text().then(text => {
            if (this.props.onError) {
              this.props.onError(text)
            }
          })
        }
      })
  }

  onToggle = () => {
    if (this.props.toggle) {
      this.props.toggle()
    }
  }

  getValue = value => {
    return value == null ? "" : value
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    if (!this.props.appApi) return ""

    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.onToggle}
        style={{ maxWidth: "600px" }}
      >
        <ModalHeader toggle={this.onToggle}>
          New fee revision for app {this.props.appApi.id}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="internalBuyFee">
                Internal buy fee
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="internalBuyFee"
                id="internalBuyFee"
                value={this.state.internalBuyFee}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="internalSellFee">
                Internal sell fee
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="internalSellFee"
                id="internalSellFee"
                value={this.state.internalSellFee}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="externalBuyFee">
                External buy fee
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="externalBuyFee"
                id="externalBuyFee"
                value={this.state.externalBuyFee}
                onChange={this.onChange}
              />
            </FormGroup>
            <FormGroup row>
              <Label className="col-xl-3 col-form-label" for="externalSellFee">
                External sell fee
              </Label>
              <Input
                className="col-xl-8"
                type="text"
                name="externalSellFee"
                id="externalSellFee"
                value={this.state.externalSellFee}
                onChange={this.onChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onSave}>
            Save
          </Button>
          <Button color="secondary" onClick={this.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}
export default NewFeeRevisionModal
