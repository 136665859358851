import React, { Component } from "react"
import { DropdownItem } from "reactstrap"

class CustomDropdownItem extends Component {
  handleClick = () => {
    this.props.onSelect(this.props.value, this.props.children)
  }

  render() {
    return (
      <DropdownItem onClick={this.handleClick}>
        {this.props.children}
      </DropdownItem>
    )
  }
}

export default CustomDropdownItem
