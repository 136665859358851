import React, { Component } from "react"
import ContentWrapper from "../../Layout/ContentWrapper"
import { Row } from "reactstrap"
import Cards from "./Cards"
import ActiveWalletsCount from "./ActiveWalletsCount"
import WalletAum from "./WalletAum"
import WalletVolumeStats from "./WalletVolumeStats"
import WalletCountStats from "./WalletCountStats"

class Wallet extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Wallet KPI data</div>
        </div>
        <Row>
          <Cards />
        </Row>
        <Row>
          <ActiveWalletsCount />
        </Row>
        <Row>
          <WalletAum />
        </Row>
        <Row>
          <WalletVolumeStats />
        </Row>
        <Row>
          <WalletCountStats />
        </Row>
      </ContentWrapper>
    )
  }
}

export default Wallet
