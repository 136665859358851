import React, { Component } from "react"
import ContentWrapper from "../Layout/ContentWrapper"
import { Col, Row } from "reactstrap"
import StorageUtils from "../Common/StorageUtils"

class Welcome extends Component {
  state = {
    user: null,
  }

  componentDidMount() {
    this.setState({
      user: StorageUtils.getUser(),
    })
  }

  render() {
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Welcome {this.state.user ? this.state.user.firstName : ""}</div>
        </div>
        <Row>
          <Col></Col>
        </Row>
      </ContentWrapper>
    )
  }
}

export default Welcome
